import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  Checkbox,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  TableBody,
  TableHead,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { RoleService } from "src/api/services";
import { useStore } from "src/store/Store";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { isMobile } from "src/utils/Util";

// ----------------------------------------------------------------------

export default function AdminRoleForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
  formType = "add",
}) {
  const { t } = useTranslation();
  const [roleFunctions, setRoleFunctions] = useState([]);
  const [searchFunctionQuery, setSearchFunctionQuery] = useState("");

  useEffect(() => {
    if (formType === "add") {
      RoleService.listAdminFunctionGroups({ size: 100 })
        .then((response) => {
          let functions = response.data.items.map((funct) => ({
            id: funct.id,
            name: funct.name
              .split("_")
              .map((word) =>
                word.length <= 3
                  ? word.toUpperCase()
                  : word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" "),
            is_read: false,
            is_write: false,
          }));
          setRoleFunctions(functions);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      RoleService.getFunctionGroupsOfAdminRole(formData.id)
        .then((response) => {
          let functions = response.data
            .map((funct) => ({
              id: funct.id,
              name: funct.name
                .split("_")
                .map((word) =>
                  word.length <= 3
                    ? word.toUpperCase()
                    : word.charAt(0).toUpperCase() + word.slice(1)
                )
                .join(" "),
              is_read: funct.read_allowed,
              is_write: funct.write_allowed,
            }))
            .filter((funct) => {
              if (formType === "view") {
                return funct.is_read || funct.is_write;
              }
              return true;
            });
          setRoleFunctions(functions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const UserSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("role-name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
    },
    validationSchema: UserSchema,
    onSubmit: (values, actions) => {
      let functionGroupsPayload = roleFunctions.map((item) => ({
        function_group_id: item.id,
        read_allowed: item.is_read,
        write_allowed: item.is_write,
      }));

      let payload = {
        role_name: values.name,
        role_parameters: {},
        function_groups: functionGroupsPayload,
        status: 1,
      };

      let successMessage, failMessage, service;

      switch (formType) {
        case "add":
          successMessage = t("new-role-has-been-successfully-added");
          failMessage = t("new-role-could-not-be-added");
          service = RoleService.addAdminRole(payload);

          break;

        case "edit":
          successMessage = t("has-been-successfully-updated", {
            name: formData.name,
          });
          failMessage = t("could-not-be-updated", { name: formData.name });
          service = RoleService.updateAdminRole(formData.id, payload);
          break;
      }

      service
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw "role operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const handleIsReadToggle = (functionGroup) => {
    setRoleFunctions((prevRoleFunctions) =>
      prevRoleFunctions.map((item) =>
        item.id === functionGroup.id
          ? { ...item, is_read: !item.is_read }
          : item
      )
    );
  };

  const handleIsWriteToggle = (functionGroup) => {
    setRoleFunctions((prevRoleFunctions) =>
      prevRoleFunctions.map((item) =>
        item.id === functionGroup.id
          ? {
              ...item,
              is_write: !item.is_write,
              is_read: !item.is_write ? true : item.is_read,
            }
          : item
      )
    );
  };

  const renderFunctions = () => {
    return roleFunctions
      .filter((functionGroup) =>
        functionGroup.name
          .toLowerCase()
          .includes(searchFunctionQuery.toLowerCase())
      )
      .map((functionGroup) => (
        <TableRow>
          <TableCell size="small">{functionGroup.name}</TableCell>
          <TableCell size="small">
            <Checkbox
              disabled={
                (functionGroup.is_write && functionGroup.is_read) ||
                formType === "view"
              }
              checked={functionGroup.is_read}
              onChange={() => handleIsReadToggle(functionGroup)}
            />
          </TableCell>
          <TableCell size="small">
            <Checkbox
              disabled={formType === "view"}
              checked={functionGroup.is_write}
              onChange={() => handleIsWriteToggle(functionGroup)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box textAlign={"left"} width={"60%"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="roleName"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("role-name")}
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled={formType === "view"}
              id="roleName"
              {...getFieldProps("name")}
              placeholder={t("type-role-name-here")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>
          {formType !== "view" && (
            <Box>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {formType === "add" ? t("save-new-role") : t("save-role")}
              </LoadingButton>
            </Box>
          )}
        </Box>
      );
    }
    if (fieldName === "functions") {
      return (
        <Stack spacing={1} alignItems={"center"} style={{ width: "100%" }}>
          <Card style={{ width: "100%", height: "70vh", overflow: "auto" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <TextField
                  placeholder={t("search-function")}
                  size="small"
                  variant="standard"
                  value={searchFunctionQuery}
                  onChange={(e) => setSearchFunctionQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ border: "none" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("function")}</TableCell>
                      <TableCell>{t("allow-read")}</TableCell>
                      <TableCell>{t("allow-write")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderFunctions()}</TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Stack>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {getFieldByName("name")}
            {getFieldByName("functions")}
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
