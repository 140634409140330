import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Grid, TextField, FormControl } from "@mui/material";
import { TableFilterContainer } from "src/components/table";
import { NumberService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const SaveButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  minWidth: "275px",
}));

export default function SharedNumber() {
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const SaveSharedNumberSchema = Yup.object().shape({
    sharedNumber: Yup.string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        t("form.validation.matches.alphanumeric", {
          fieldName: t("shared-number"),
        })
      )
      .min(4, t("form.validation.min"))
      .max(24, t("form.validation.max"))
      .required(
        t("form.validation.required", { fieldName: t("shared-number") })
      ),
  });

  const formik = useFormik({
    initialValues: {
      sharedNumber: "",
    },
    validationSchema: SaveSharedNumberSchema,
    onSubmit: (values, actions) => {
      const payload = {
        number_value: values.sharedNumber,
        direction_type: 1,
        did_id: 0,
        set_type: 1,
        is_magic: true,
      };

      NumberService.addNumber(payload)
        .then((response) => {
          if (response.status === 201) {
            setMessage(
              t("has-been-successfully-added-as-shared-number", {
                number: values.sharedNumber,
              })
            );
            setSnackbarStatus(true);
          } else {
            throw "shared number could not be added";
          }
        })
        .catch((err) => {
          setMessage(
            t("could-not-be-added-as-shared-number", {
              number: values.sharedNumber,
            })
          );
          setSnackbarStatus(true);
        })
        .finally(() => {
          setSnackbarStatus(true);
          actions.setSubmitting(false);
        });
    },
  });

  const fetchSharedNumber = () => {
    const params = {
      is_magic: 1,
    };
    NumberService.listNumbers(params)
      .then((response) => {
        if (response.data.results.length > 0)
          formik.setFieldValue(
            "sharedNumber",
            response.data.results[0]["number_value"]
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchSharedNumber();
  }, []);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TableFilterContainer>
            <Grid
              direction="column"
              sx={{ alignItems: "center" }}
              container
              spacing={0}
            >
              <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                  <TextFieldStyled
                    label={t("shared-number")}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    required
                    {...getFieldProps("sharedNumber")}
                    error={Boolean(touched.sharedNumber && errors.sharedNumber)}
                    helperText={touched.sharedNumber && errors.sharedNumber}
                  />
                </FormControl>
              </Grid>
              <SaveButtonContainer item md={3} xs={12}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  endIcon={<SaveIcon />}
                  loading={isSubmitting}
                  sx={{ mt: 2, mb: 1 }}
                >
                  {t("common.save")}
                </LoadingButton>
              </SaveButtonContainer>
            </Grid>
          </TableFilterContainer>
        </Form>
      </FormikProvider>
    </>
  );
}
