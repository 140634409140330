import api from "src/api/Api";
import urls from "src/api/Urls";


// Service Packages

export const listSubscriptionPackages = (params) => {
    return api.get(urls.list_subscription_packages, { params: params });
}

export const listSubscriptionPackagesWithoutPagination = () => {
    return api.get(urls.list_subscription_packages_without_pagination);
}

export const addSubscriptionPackage = (payload) => {
    return api.post(urls.add_subscription_package, payload);
}

export const deleteSubscriptionPackage = (id) => {
    return api.delete(urls.delete_subscription_package(id));
}

export const updateSubscriptionPackage = (params, payload) => {
    return api.put(urls.update_subscription_package, payload, { params: params });
}

export const getServicePackage = (id) => {
    return api.get(urls.get_service_package(id));
}

//Packages

export const listPackages = (params) => {
    return api.get(urls.list_packages, { params: params });
}

export const getPackage = (id) => {
    return api.get(urls.get_package(id));
} 

export const addPackage = (payload) => {
    return api.post(urls.add_package, payload);
}

export const deletePackage = (id , params) => {
    params = params ? params : {proceed : false};
    return api.delete(urls.delete_package(id), {params});
}

export const updatePackage = (id, payload) => {
    return api.put(urls.update_package(id), payload);
}

export const enableDisablePackage = (id , params) => {
    return api.put(urls.disable_enable_package(id), null, {params});
}

// Services
export const listCpaasServices = (params) => {
    return api.get(urls.list_cpaas_services, { params: params });
}

// change later
export const listOutboundServices = (params) => {
    return api.get(urls.list_cpaas_services, { params: params });
}

//service engine map
export const createServiceEngineMap = (payload) => {
    return api.post(urls.create_service_engine_map, payload);
}

export const createServiceEngineMapArray = (payload) => {
    return api.post(urls.create_service_engine_map_array, payload);
}

export const updateServiceEngineMapping = (payload) => {
    return api.put(urls.update_service_engine_map, payload);
}