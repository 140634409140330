import { useState } from "react";
// material
import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { UcManagementService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteUcUserForm({
  formData,
  formType,
  setModalStatus,
  selectedUcService,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingRemoveService, setLoadingRemoveService] = useState(false);

  const deleteUcUser = () => {
    setLoading(true);

    UcManagementService.deleteUcUser(formData.id)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("uc-user-delete-success"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "uc user delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          if (err.response.status === 400) {
            setModalStatus(false);
            successCallback(true);
          } else {
            setMessage(t("uc-user-delete-fail"));
            setSnackbarStatus(true);
            setModalStatus(false);
          }
          setLoading(false);
        }, 1000);
      });
  };

  const deleteServiceFromUser = () => {
    setLoadingRemoveService(true);

    let params = {
      uc_user_id: formData.id,
      service_id: selectedUcService,
    };
    UcManagementService.deleteServiceFromUser(params)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("service-removed-from-user-success"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoadingRemoveService(false);
          successCallback();
        } else {
          throw "uc user delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          if (err.response.status === 400) {
            setModalStatus(false);
            successCallback(true);
          } else {
            setMessage(t("service-removed-from-user-fail"));
            setSnackbarStatus(true);
            setModalStatus(false);
          }
          setLoadingRemoveService(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">
          {t("are-you-sure-delete-this-uc-user")}
        </Typography>
        <Typography component="subtitle1" variant="h6">
          {formData.first_name + " " + formData.last_name}
        </Typography>
        {formType === "deleteOnly" ? (
          <>
            <Stack sx={{ display: "block" }} direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={() => setModalStatus(false)}
              >
                {t("cancel")}
              </LoadingButton>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={loading}
                onClick={deleteUcUser}
              >
                {t("delete")}
              </LoadingButton>
            </Stack>
          </>
        ) : (
          <Stack sx={{ display: "block" }} direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "2vw",
              }}
            >
              <LoadingButton
                sx={{ width: "40%", height: "5vh", fontSize: "80%" }}
                type="submit"
                color="error"
                variant="contained"
                loading={loading}
                onClick={deleteUcUser}
              >
                {t("delete-uc-user")}
              </LoadingButton>
              <LoadingButton
                sx={{ width: "40%", height: "5vh", fontSize: "80%" }}
                type="submit"
                color="warning"
                variant="contained"
                loading={loadingRemoveService}
                onClick={deleteServiceFromUser}
              >
                {t("remove-service-from-user")}
              </LoadingButton>
            </Box>
            <LoadingButton
              sx={{
                mt: "2vh",
                width: "45%",

                alignSelf: "center",
              }}
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={() => setModalStatus(false)}
            >
              {t("cancel")}
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </>
  );
}
