import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  Tooltip,
  Stack,
  IconButton,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import { BaseTable, TableFilterContainer } from "src/components/table";
import {
  DefaultPaginationData,
  getLabelByValue,
  SmsHistoryStatuses,
} from "src/constants";
import SearchButton from "src/components/buttons/SearchButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { fDate, fDateTime } from "src/utils/formatTime";
import { useTranslation } from "react-i18next";
import { LogViewerService } from "src/api/services";
import { rowArrayToObject } from "src/utils/Util";
import { fCurrency } from "../../../utils/formatNumber";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function Cdr({ cdrType = "voice" }) {
  const { t } = useTranslation();
  // TODO: create enum class to keep constant values project wide
  // TODO: add parameter to this function to get table fields
  //       instead of setting the values based on below conditions
  let apiService, tableFieldMapping, tableHead;
  if (cdrType === "voice") {
    apiService = LogViewerService.listLogsVoice;
    tableFieldMapping = {
      id: { key: "id", cellComponentType: "th", index: 0 },
      created: { key: "createdDate", index: 1 },
      from_number: { key: "fromNumber", index: 2 },
      to_number: { key: "toNumber", index: 3 },
      duration: { key: "duration", index: 4, align: "center" },
      call_status: { key: "status", index: 5, align: "center" },
      amount: { key: "amount", index: 6, align: "right" },
      sip_status: { key: "sipCode", index: 7, noRender: true },
      account: { key: "account", index: 8, noRender: true },
      call_sid: { key: "callSid", index: 9, noRender: true },
      direction: { key: "direction", index: 10, noRender: true },
      originating_sip_ip: { key: "ip", index: 11, noRender: true },
      originating_sip_trunk_name: { key: "trunk", index: 12, noRender: true },
    };
    tableHead = [
      { key: "id", label: t("common.id") },
      { key: "createdDate", label: t("common.createdDate") },
      { key: "fromNumber", label: t("common.fromNumber") },
      { key: "toNumber", label: t("common.toNumber") },
      { key: "duration", label: t("common.duration"), align: "center" },
      { key: "status", label: t("common.status"), align: "center" },
      { key: "amount", label: t("amount") },
    ];
  } else {
    apiService = LogViewerService.listLogsSms;
    tableFieldMapping = {
      id: { key: "id", cellComponentType: "th", index: 0 },
      created: { key: "createdDate", index: 1 },
      from_number: { key: "fromNumber", index: 2 },
      to_number: { key: "toNumber", index: 3 },
      status: { key: "status", index: 4, align: "center" },
      amount: { key: "amount", index: 5, align: "right" },
      reason: { key: "reason", index: 6, noRender: true },
      account: { key: "account", index: 7, noRender: true },
    };
    tableHead = [
      { key: "id", label: t("common.id") },
      { key: "createdDate", label: t("common.createdDate") },
      { key: "fromNumber", label: t("common.fromNumber") },
      { key: "toNumber", label: t("common.toNumber") },
      { key: "status", label: t("common.status"), align: "center" },
      { key: "amount", label: t("amount") },
    ];
  }
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "month"),
    moment(),
  ]);
  const [filterFromNumber, setFromNumber] = useState("");
  const [filterToNumber, setToNumber] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [openViewCdrModal, setViewCdrModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const onDateChange = (values) => {
    setDateRange(values);
  };

  const fetchCdr = () => {
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
      date_after: dateRange ? fDate(dateRange[0]) : undefined,
      date_before: dateRange ? fDate(dateRange[1]) : undefined,
      from_number: filterFromNumber ? filterFromNumber : undefined,
      to_number: filterToNumber ? filterToNumber : undefined,
    };
    setLoadingData(true);
    apiService(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.count);
        for (const idx in response.data.results) {
          let item = new Array(tableHead.length - 1).fill({});
          Object.entries(response.data.results[idx]).forEach(([key, value]) => {
            if (key in tableFieldMapping) {
              item[tableFieldMapping[key].index] = {
                ...tableFieldMapping[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCdr();
    return () => {
      setData([]);
    };
  }, [paginationData, cdrType]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "createdDate") {
        formatted.push({
          ...d,
          value: fDateTime(+d.value * 1000),
        });
      } else if (d.key === "status" && cdrType === "sms") {
        formatted.push({
          ...d,
          key: d.key,
          value: getLabelByValue(SmsHistoryStatuses(), d.value),
          tooltipText:
            d.value == "2"
              ? rowData.filter((cell) => cell.key === "reason")[0].value
              : undefined,
        });
      } else if (d.key === "status" && cdrType === "voice") {
        // TODO: set status codes instead of status text
        // as it is for jambonz right now
        let statusText = d.value;
        statusText =
          typeof statusText === "string"
            ? statusText[0].toUpperCase() + statusText.slice(1)
            : "";
        const sipCode = rowData.filter((cell) => cell.key === "sipCode");
        formatted.push({
          ...d,
          key: d.key,
          value: statusText,
          tooltipText:
            sipCode.length === 1
              ? rowData.filter((cell) => cell.key === "sipCode")[0].value
              : "",
        });
      } else if (d.key === "amount") {
        formatted.push({
          ...d,
          value: fCurrency(d),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "view") {
      setViewCdrModalStatus(!openViewCdrModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  return (
    <>
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                startText={t("common.dateRange.start")}
                endText={t("common.dateRange.end")}
                value={/* dateRange */ dayjs()}
                onChange={onDateChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      sx={{ marginTop: "0" }}
                      {...startProps}
                      color="secondary"
                      variant="filled"
                    />
                    <Box sx={{ mx: 2 }}> {t("common.dateRange.to")} </Box>
                    <TextField
                      sx={{ marginTop: "0" }}
                      {...endProps}
                      color="secondary"
                      variant="filled"
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterFromNumber}
                label={t("common.fromNumber")}
                name="fromNumber"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFromNumber(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterToNumber}
                label={t("common.toNumber")}
                name="toNumber"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setToNumber(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={3} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCdr();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={tableHead}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
