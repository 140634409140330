import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Box,
  Autocomplete,
  Typography,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { PartitionService, RoleService, UserService } from "src/api/services";
import { canUserWriteIt, isMobile } from "src/utils/Util";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";

import { useStore } from "src/store/Store";
//edit ?
export default function AdminUserForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();

  const [store] = useStore();
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [partitions, setPartitions] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState(null);
  const [partitionAdmin, setPartitionAdmin] = useState(formData?.partitionAdmin || false)

  const AddUserSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  useEffect(() => {
    RoleService.listAdminRoles({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let rolesOptions = response.data.items.map((option) => ({
            value: option.id,
            label: option.role_name,
          }));

          setRoles(rolesOptions);
        /*   if (!selectedRole) {
            setSelectedRole(rolesOptions[0].value);
          } */
        } else {
          throw "list roles failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (store.admin.user.root_admin) {
      PartitionService.listPartitions({ size: 100 })
        .then((response) => {
          if (response.status === 200) {
            let partitionOptions = response.data.items.map((option) => ({
              value: option.id,
              label: option.name,
            }));
            setPartitions(partitionOptions);
            if (!selectedPartition) {
              setSelectedPartition(partitionOptions[0].value);
            }
          } else {
            throw "failed to get partitions";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      email: formData.email || "",
      password: formData.password || "",
      partitionAdmin: formData.partitionAdmin || false,
    },
    validationSchema: AddUserSchema,
    onSubmit: (values, actions) => {
      let payload;
      if (formType === "add") {
        payload = {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          role_id: selectedRole,
        };
      } else if (formType === "edit") {
        payload = {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          partition_admin: partitionAdmin,
        };
      } else if (formType === "password") {
        payload = {
          password: values.password,
        };
      }

      let successMessage =
        formType === "add"
          ? t("new-admin-user-invitation-sent-successfully")
          : t("admin-user-editted-successfully");
      let failMessage =
        formType === "add"
          ? t("new-admin-user-could-not-be-added")
          : t("admin-user-edit-fail");
      const apiService =
        formType === "add"
          ? UserService.addAdminuser(payload)
          : UserService.updateAdminUser(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          setMessage(failMessage);
          setSnackbarStatus(true);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (
      fieldName === "firstName" &&
      formType !== "password" &&
      formType !== "role"
    ) {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="firstName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("first-name-0")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="firstName"
            {...getFieldProps("firstName")}
            placeholder={t("type-first-name-here")}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Box>
      );
    }
    if (
      fieldName === "lastName" &&
      formType !== "password" &&
      formType !== "role"
    ) {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="lastName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("last-name-0")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="lastName"
            {...getFieldProps("lastName")}
            placeholder={t("type-last-name-here")}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Box>
      );
    }
    if (
      fieldName === "email" &&
      formType !== "password" &&
      formType !== "role"
    ) {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="email"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("email-address")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="email"
            type="email"
            {...getFieldProps("email")}
            placeholder={t("type-email-here")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
      );
    }

    if (
      fieldName === "partitionAdmin" &&
      formType !== "password" &&
      formType !== "role"
    ) {
      return (
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
          <Typography>
            {t("partition-admin")}
          </Typography>
          <Checkbox 
            disabled = {selectedRole === 1}
            checked = {partitionAdmin || selectedRole === 1}
            onChange={(e) => setPartitionAdmin(e.target.checked)}
          />
        </Box>
      );
    }
    if (fieldName === "role" && formType === "add") {
      return (
        <FormControl>
          <Autocomplete
            disablePortal
            options={roles}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={selectedRole ? t("role") : t("select-role")} />
            )}
            value={
              roles.find((option) => option.value === selectedRole) || null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedRole(newValue.value);
              }else {
                setSelectedRole(null)
              }
            }}
          />
        </FormControl>
      );
    }
   /*  if (
      fieldName === "partition" &&
      formType === "add" &&
      store.admin.user.root_admin
    ) {
      return (
        <FormControl>
          <Autocomplete
            disablePortal
            options={partitions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={t("partition")} />
            )}
            value={
              partitions.find((option) => option.value === selectedPartition) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedPartition(newValue.value);
              }
            }}
          />
        </FormControl>
      );
    } */
    if (fieldName === "password" && formType === "password") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="password"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("password")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="password"
            type={showPassword ? "text" : "password"}
            {...getFieldProps("password")}
            placeholder={t("type-password-here")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={formType === "edit" && <SaveIcon />}
          endIcon={formType === "add" && <SendIcon />}
        >
          {formType === "add" ? t("send-invitation") : t("save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("partition")}
            {getFieldByName("firstName")}
            {getFieldByName("lastName")}
            {getFieldByName("email")}
            {getFieldByName("partitionAdmin")}
            {getFieldByName("password")}
            {canUserWriteIt("admin_permissions") && getFieldByName("role")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
