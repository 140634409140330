import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Typography, Stack, Box, Checkbox, FormGroup, FormControlLabel, Button } from '@mui/material';
import { ProfileService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import { useStore } from 'src/store/Store';
import { LandingOptions } from 'src/constants';
// ----------------------------------------------------------------------


const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.lightmost,
    border: `2px solid ${theme.palette.secondary.main}`,
    cursor: "pointer",
    padding: theme.spacing(4),
    borderRadius: 10,
    minWidth: 320,
    textAlign: "center",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: "4px"
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));

const TitleTypographyStyle = styled(Typography)(({ theme }) => ({
    marginTop: "-24px",
    marginBottom: "12px",
    color: theme.palette.secondary.dark,
    textAlign: "center",
}));


// ----------------------------------------------------------------------

export default function LandingViewSelection() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [store] = useStore();
    const [selectedView, setSelectedView] = useState();
    const [selectedRoute, setSelectedRoute] = useState("/");
    const [rememberSelection, setRememberSelection] = useState(false);

    const handleSubmit = () => {
        if (rememberSelection) {
            const payload = {
                preferred_landing_view: selectedView,
            };
            //TODO: Implement prefered landing view for both [ADMIN/PORTAL] => Not sure about admin
            ProfileService.updatePreferredLandingView(store.user.id, payload)
                .then((response) => {
                    navigate(selectedRoute, { replace: true });
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            navigate(selectedRoute, { replace: true });
        }
    }

    const selectedBoxStyle = {
        border: `8px solid ${store.palette.secondary.darker}`,
        backgroundColor: store.palette.secondary.lighter,
    };

    return (
        <>
            <TitleTypographyStyle sx={{ mt: 5 }} variant="h4">{t('landing-view-selection-title')}</TitleTypographyStyle>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="center" alignItems="center" spacing={2}>
                {LandingOptions().map(option => 
                    <StyledBox sx={ option.value === selectedView ? {...selectedBoxStyle} : null } key={option.value} onClick={() => {setSelectedView(option.value); setSelectedRoute(option.route);}}>
                        <StyledTypography variant="h4">{option.title}</StyledTypography>
                    </StyledBox>
                )}
            </Stack>
            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                <FormGroup>
                    <StyledCheckboxLabel label={t("remember-selection")} control={
                        <Checkbox
                            color="secondary"
                            checked={rememberSelection}
                            onChange={() => setRememberSelection(!rememberSelection)} />
                    } />
                </FormGroup>
                <Button
                    disabled={selectedView !== undefined ? false : true}
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                >{t("navigate")}</Button>
            </Stack>
        </>
    );
}
