import api from "src/api/Api";
import urls from "src/api/Urls";

export const listFilterDidTypes = () => {
  return api.get(urls.list_filter_did_types);
};
export const listFilterCapabilities = () => {
  return api.get(urls.list_filter_capabilities);
};
export const listFilterInboundCountries = () => {
  return api.get(urls.list_filter_inbound_countries);
};
export const listFilterOutboundCountries = () => {
  return api.get(urls.list_filter_outbound_countries);
};

