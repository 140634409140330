import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  IconButton,
  Tooltip,
  Autocomplete,
  Button,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { DefaultPaginationData } from "src/constants/index";
import {
  canUserReadIt,
  canUserWriteIt,
  rowArrayToObject,
} from "src/utils/Util";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import { CustomerService, UcManagementService } from "src/api/services";
import { useTranslation } from "react-i18next";
import AddNewButton from "src/components/buttons/AddNewButton";
import { useStore } from "src/store/Store";
import UcUserForm from "./form/UcUserForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BulkUploadUcUsers from "./BulkUploadUcUsers";
import DeleteUcUserForm from "./form/DeleteUcUserForm";
import DeleteAnyway from "src/components/DeleteAnyway";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function UcUsers() {
  const { t } = useTranslation();
  const [store, dispatch] = useStore();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditModal, setOpenEditModalStatus] = useState(false);
  const [openDeleteModal, setOpenDeleteModalStatus] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openAssignNumberModal, setOpenAssignNumberModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);

  const TABLE_HEAD = [
    { key: "first_name", label: t("first-name") },
    { key: "last_name", label: t("last-name") },
    { key: "email", label: t("email") },
    { key: "customer_name", label: t("customer") },
    /*   { key: "uc_service", label: t("uc-service") }, */
    { key: "number", label: t("phone-number") },
    canUserWriteIt("uc_management")
      ? { key: "action", label: t("actions"), align: "right", padding: "2vw" }
      : null,
  ].filter(Boolean);

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    first_name: { key: "first_name", index: 1 },
    last_name: { key: "last_name", index: 2 },
    email: { key: "email", index: 3 },
    customer_name: { key: "customer_name", index: 4 },
    /* uc_service: { key: "uc_service", index: 5 }, */
    number: { key: "number", index: 5 },
    customer: { key: "customer", index: 6, noRender: true },
    uc_username: { key: "uc_username", index: 7, noRender: true },
    customer_id: { key: "customer_id", index: 8, noRender: true },
    services: { key: "services", index: 9, noRender: true },
  };

  const fetchUcUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      customer_id: selectedCustomer,
      name: filterName,
    };
    UcManagementService.listUcUsers(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let number =
            itemData.number !== null ? (
              itemData.number.number
            ) : canUserReadIt("numbers") ? (
              <Button
                sx={{
                  color: store.palette.info.contrastText,
                  backgroundColor: store.palette.info.main,
                }}
                variant="contained"
                size="small"
                aria-label="view-contact"
                onClick={() => {
                  setSelectedRow({
                    id: itemData.id,
                    first_name: itemData.first_name,
                    last_name: itemData.last_name,
                    email: itemData.email,
                    customer: itemData.customer,
                  });
                  setOpenAssignNumberModal(true);
                }}
              >
                {t("assign-number")}
              </Button>
            ) : (
              <HorizontalRuleIcon sx={{ ml: "1.3em" }} />
            );

          item[TABLE_FIELD_MAPPING["customer_name"].index] = {
            ...TABLE_FIELD_MAPPING["customer_name"],
            value: itemData.customer.name,
          };

          item[TABLE_FIELD_MAPPING["number"].index] = {
            ...TABLE_FIELD_MAPPING["number"],
            value: number,
          };

          // There is gonna be exceptions. Probably ucUsers ucPackages
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "number") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          items.push(item);
        }

        setData(items);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const fetchCustomerOptions = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  useEffect(() => {
    fetchCustomerOptions();
  }, []);

  useEffect(() => {
    fetchUcUsers();
  }, [paginationData]);
  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "edit") {
      setOpenEditModalStatus(!openEditModal);
    } else if (modalType === "add") {
      setOpenAddUserModal(!openAddUserModal);
    } else if (modalType === "delete") {
      setOpenDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "upload") {
      setOpenUploadModal(!openUploadModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-forwarding")} sx={{ mr: "0.5vw" }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-forwarding"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-user")} sx={{ mr: "0.5vw" }}>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchUcUsers();
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        title={t("add-number-map")}
        open={openAssignNumberModal}
        setOpen={setOpenAssignNumberModal}
        children={
          <UcUserForm
            successCallback={fetchUcUsers}
            formType="assignNumber"
            formData={selectedRow}
            setModalStatus={setOpenAssignNumberModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "20rem",
          width: "70%",
          height: "80%",
        }}
        title={t("upload-uc-users")}
        open={openUploadModal}
        setOpen={setOpenUploadModal}
        children={
          <BulkUploadUcUsers
            successCallback={fetchUcUsers}
            formData={selectedRow}
            setModalStatus={setOpenUploadModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "20rem",
          width: "30%",
          maxHeight: "80vh",
          overflow: "auto",
        }}
        title={t("edit-uc-user")}
        open={openEditModal}
        setOpen={setOpenEditModalStatus}
        children={
          <UcUserForm
            successCallback={fetchUcUsers}
            formType="edit"
            formData={selectedRow}
            setModalStatus={setOpenEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        /* sx={{
          minWidth: "20rem",
          width: "30%",
          maxHeight: "80vh",
          overflow: "auto",
        }} */
        title={t("delete-uc-user")}
        open={openDeleteModal}
        setOpen={setOpenDeleteModalStatus}
        children={
          <DeleteUcUserForm
            successCallback={deleteCallback}
            formType="deleteOnly"
            formData={selectedRow}
            setModalStatus={setOpenDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("delete-uc-user")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchUcUsers}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              UcManagementService.deleteUcUser(selectedRow.id, {
                proceed: true,
              })
            }
            name={t("uc-user")}
            usedIn={t("uc-services")}
            serviceList={
              selectedRow.services && selectedRow.services.length > 0 ? (
                <Paper
                  elevation={3}
                  sx={{ padding: "1rem", backgroundColor: "#f5f5f5" }}
                >
                  <List>
                    {selectedRow.services.map((service, indx) => (
                      <ListItem key={service.id}>
                        <ListItemText
                          primary={
                            <Typography
                              color="primary"
                              variant="h6"
                              component="span"
                            >
                              {indx + 1} - {service.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              ) : (
                <p>{t("no-services-found")}</p>
              )
            }
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "30rem",
          width: "40%",
          maxHeight: "95vh",
          overflow: "auto",
        }}
        title={t("add-new-uc-user")}
        open={openAddUserModal}
        setOpen={setOpenAddUserModal}
        children={
          <UcUserForm
            successCallback={fetchUcUsers}
            formType="add"
            formData={{ customer: selectedCustomer }}
            customerOptions={customerOptions}
            setModalStatus={setOpenAddUserModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-customer")}
                  sx={{ backgroundColor: "white" }}
                />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchUcUsers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      {canUserWriteIt("uc_management") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <AddNewButton
            sx={{ height: "fit-content" }}
            label={t("add-uc-user")}
            onClick={() => modalHandler("add")}
          />
          <AddNewButton
            sx={{ height: "fit-content" }}
            label={t("upload-uc-users")}
            onClick={() => modalHandler("upload")}
          />
        </Box>
      )}

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={canUserWriteIt("uc_management") && getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
