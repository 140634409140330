import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import OutboundVoice from './outboundConfig/outboundTariff/voice/OutboundVoice';
import OutboundSms from './outboundConfig/outboundTariff/sms/OutboundSms';
import OutboundWhatsapp from './outboundConfig/outboundTariff/whatsapp/OutboundWhatsapp';
import OutboundTelegram from './outboundConfig/outboundTariff/telegram/OutboundTelegram';
import OutboundRcs from './outboundConfig/outboundTariff/rcs/OuttboundRcs';
import OutboundGbc from './outboundConfig/outboundTariff/gbc/OutboundGbc';
import OutboundAppleMessage from './outboundConfig/outboundTariff/appleMessage/OutboundAppleMessage';

// ----------------------------------------------------------------------


export default function OutboundTariffs() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('voice'), component: <OutboundVoice/> },
        { id: 1, label: t('SMS'), component: <OutboundSms/> },
        { id: 2, label: t('whatsapp'), component: <OutboundWhatsapp/> },
        { id: 3, label: t('telegram'), component: <OutboundTelegram/> },
        { id: 4, label: t('rcs-mms'), component: <OutboundRcs/> },
        { id: 5, label: t('google-business-chat'), component: <OutboundGbc/> },
        { id: 6, label: t('apple-message'), component: <OutboundAppleMessage/> },

    ];

    const TITLE = t('outbound-tariffs');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
