import { useState } from "react";
// material
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { TariffService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteOutboundTariffForm({
  formData,
  tariffType,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  let apiService, successMessage, failMessage, name;
  let commonName;
  const deleteTariff = () => {
    setLoading(true);

    switch (tariffType) {
      case "voice":
        name = t("outbound-voice-tariff");
        apiService = TariffService.deleteOutboundVoiceTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
      case "sms":
        name = t("outbound-sms-tariff");
        apiService = TariffService.deleteOutboundSmsTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
      case "whatsapp":
        name = t("outbound-whatsapp-tariff");
        apiService = TariffService.deleteOutboundWhatsappTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "telegram":
        name = t("outbound-telegram-tariff");
        apiService = TariffService.deleteOutboundTelegramTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "rcs":
        name = t("outbound-rcs-tariff");
        apiService = TariffService.deleteOutboundRcsTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "gbc":
        commonName = "Google Business Chat";
        name = t("outbound-gbc-tariff", { commonName });
        apiService = TariffService.deleteOutboundGbcTariff(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "appleMessage":
        commonName = "Apple Message";
        name = t("outbound-gbc-tariff", { commonName });
        apiService = TariffService.deleteOutboundAppleMessageTariff(
          formData.id
        );
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
    }

    apiService
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "container delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          if (err.response.status === 400) {
            setModalStatus(false);
            successCallback(true);
          } else {
            setMessage(failMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
          }
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">{t("sure-delete", { name })}</Typography>
        <Typography component="subtitle1" variant="h6">
          {formData.name}
        </Typography>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteTariff}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
