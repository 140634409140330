import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import OrderHistory from "./uccServices/ucPurchase/OrderHistory";
import UcPurchase from "./uccServices/ucPurchase/UcPurchase";
// ----------------------------------------------------------------------

export default function PurchaseUc() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("Ucc-packages"), component: <UcPurchase /> },
    { id: 1, label: t("order-history"), component: <OrderHistory /> },
  ];

  const TITLE = t("purchase");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
