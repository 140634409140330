import { useEffect, useState } from "react";
import {
  Capability,
  DefaultPaginationData,
  getLabelByValue,
  getSelectOptions,
} from "src/constants/index";
import {
  CommonService,
  CountriesService,
  DidService,
  ProductsService,
} from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Autocomplete,
  InputLabel,
  Select,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { DeleteForm, ProductForm } from "./forms";
import DeleteAnyway from "src/components/DeleteAnyway";
import { options } from "numeral";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumbersProduct() {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [filterCountry, setFilterCountry] = useState(null);
  const [filterCapability, setFilterCapability] = useState("");
  const [filterType, setFilterType] = useState("");
  const [openDeleteAnywayModal, setOpenDeleteAnywayModal] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("product") },
    { key: "country", label: t("country") },
    { key: "type", label: t("type") },
    { key: "capability", label: t("capability") },
    { key: "range", label: t("sell-type") },
    { key: "provider", label: t("inbound-provider") },
    { key: "actions", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    country: { key: "country", index: 2 },
    type: { key: "type", index: 3 },
    capability_id: { key: "capability", index: 4 },
    range: { key: "range", index: 5 },
    provider: { key: "provider", index: 6 },
    filter_providers: { key: "filter_providers", index: 7, noRender: true },
    active: { key: "active", index: 8, noRender: true },
    type_id: { key: "type_id", index: 9, noRender: true },
    country_id: { key: "country_id", index: 10, noRender: true },
  };

  const fetchProducts = () => {
    setLoadingData(true);
    const params = {
      /* engine: selectedEngine ? selectedEngine : undefined, */
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    let apiService = ProductsService.listNumberProducts(params);

    apiService
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];


          let typeName = itemData.did_type?.name;
          let countryName = itemData.country?.name;
          let rangeType = itemData.properties.individual
            ? t("individual")
            : t("block");

          item[TABLE_FIELD_MAPPING["range"].index] = {
            ...TABLE_FIELD_MAPPING["range"],
            value: rangeType,
          };

          item[TABLE_FIELD_MAPPING["country"].index] = {
            ...TABLE_FIELD_MAPPING["country"],
            value: countryName,
          };

          item[TABLE_FIELD_MAPPING["type"].index] = {
            ...TABLE_FIELD_MAPPING["type"],
            value: typeName,
          };

          let active = itemData.properties.active ?? false;

          item[TABLE_FIELD_MAPPING["active"].index] = {
            ...TABLE_FIELD_MAPPING["active"],
            value: active,
          };

          let provider =
            itemData.filter_providers.length > 0 ? (
              <>
                <ol>
                  {itemData.filter_providers
                    .sort((a, b) => a.filter_order - b.filter_order)
                    .map(
                      (item) =>
                        item.filter_enable && (
                          <li>
                            {item.provider.engine_subtype.name +
                              " / " +
                              item.provider.properties.pop}
                          </li>
                        )
                    )}
                </ol>
              </>
            ) : (
              ""
            );
          item[TABLE_FIELD_MAPPING["provider"].index] = {
            ...TABLE_FIELD_MAPPING["provider"],
            value: provider,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "country") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          if (
            /*  selectedEngine === itemData.engine.toString() && */
            itemData.name.toLowerCase().includes(filterName.toLowerCase())
          ) {
            values.push(item);
          }
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchProducts();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const fetchCountries = () => {
    CountriesService.getInboundCountries()
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.map((item) => ({
            value: item.country_id,
            label: item.name,
          }));
          setCountries(options);
        } else {
          throw "list countries error";
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    DidService.listDidTypes().then((response) => {
      let didOptions = response.data.items.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      setDidTypes(didOptions);
    });

    fetchCountries();
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "capability") {
        formatted.push({
          ...d,
          value: getLabelByValue(Capability(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };
  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-numbers-product")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-numbers-product")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setOpenDeleteAnywayModal(true);
    } else {
      fetchProducts();
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          maxHeight: "100%",
          overflow: "auto",
          width: "50%",
        }}
        title={t("add-numbers-product")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <ProductForm
            formType="add"
            successCallback={fetchProducts}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          maxHeight: "100%",
          overflow: "auto",
          width: "50%",
        }}
        title={t("edit-numbers-product")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <ProductForm
            formType="edit"
            successCallback={fetchProducts}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-numbers-product")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-numbers-product")}
        open={openDeleteAnywayModal}
        setOpen={setOpenDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchProducts}
            formData={selectedRow}
            setModalStatus={setOpenDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              ProductsService.deleteNumberProduct(selectedRow.id, {
                proceed: true,
              })
            }
            name={t("numbers-product")}
            usedIn={t("numbers-package")}
          />
        }
      />

      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="country-filter"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
                value={countries.find((option) => option.value === filterCountry) || null}
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterCountry(newValue.value);
                  } else {
                    setFilterCountry(null);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth sx={{ mt: "0.5em" }}>
              <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
              <Select
                label={t("capability")}
                labelId="filter-capability-label"
                name="capability"
                color="secondary"
                value={filterCapability}
                onChange={(event) => {
                  setFilterCapability(event.target.value);
                }}
              >
                {getSelectOptions(Capability())}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={didTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("type")} />
                )}
                value={
                  didTypes.find((option) => option.value === filterType) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterType(newValue.value);
                  }else{
                    setFilterType(null)
                  }
                }}
              />
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchProducts();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-numbers-product")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
