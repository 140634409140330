import { useState } from "react";
// material
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { OutboundRatesService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteOutboundRateForm({
  formData,
  rateType,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  let apiService, successMessage, failMessage, name;

  const deleteRate = () => {
    setLoading(true);

    switch (rateType) {
      case "voice":
        console.log(formData.id,"deleting");
        name = t("outbound-voice-rate");
        apiService = OutboundRatesService.deleteOutboundVoiceRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
      case "sms":
        name = t("outbound-sms-rate");
        apiService = OutboundRatesService.deleteOutboundSmsRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
      case "whatsapp":
        name = t("outbound-whatsapp-rate");
        apiService = OutboundRatesService.deleteOutboundWhatsappRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "telegram":
        name = t("outbound-telegram-rate");
        apiService = OutboundRatesService.deleteOutboundTelegramRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "rcs":
        name = t("outbound-rcs-rate");
        apiService = OutboundRatesService.deleteOutboundRcsRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "gbc":
        name = t("outbound-gbc-rate");  
        apiService = OutboundRatesService.deleteOutboundGbcRate(formData.id);
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;

      case "appleMessage":
        name = t("outbound-apple-message-rate");  
        apiService = OutboundRatesService.deleteOutboundAppleMessageRate(
          formData.id
        );
        successMessage = t("outbound-tariff-delete-success", { name });
        failMessage = t("outbound-tariff-delete-fail", { name });

        break;
    }

    apiService
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "container delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">{t("sure-delete-rate")}</Typography>
        {/*         <Typography component="subtitle1" variant="h6">
          {formData.name}
        </Typography> */}
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteRate}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
