import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Card,
  TextField,
  Box,
  CardContent,
  Divider,
  Typography,
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Switch,
  Grid,
  Button,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { UcManagementService, NumberService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import { useState, useEffect } from "react";
import SearchButton from "src/components/buttons/SearchButton";
import { useStore } from "src/store/Store";
import UcUserForm from "./UcUserForm";
import BaseModal from "src/components/BaseModal";
import { isMobile } from "src/utils/Util";

const SearchButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ManageForm({
  formData,
  setModalStatus,
  formType = "add",
  successCallback,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();

  const [store] = useStore();
  const [selectedUcUsers, setSelectedUcUsers] = useState(
    formType === "assignNumber"
      ? [formData.id]
      : formData.uc_users.map((item) => item.uc_user.id)
  );
  const [ucUsers, setUcUsers] = useState([]);
  const [onlyOwneds, setOnlyOwneds] = useState(false);
  const [filterName, setFilterName] = useState("");

  //TODO : implement is uc service required username and password from uc user
  const [ucCredentialsRequired, setUcCredentialsRequired] = useState(true);
  const [openAddCredentials, setOpenaddCredentials] = useState(false);
  const [selectedUser, setSelecetedUser] = useState(null);

  const fetchUcUsers = () => {
    UcManagementService.listUcUsers({
      page: 1,
      size: 100,
      customer_id: formData.customer.id,
    })
      .then((response) => {
        if (response.status === 200) {
          setUcUsers(response.data.items.sort((a, b) => a.id - b.id));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUcUsers();
  }, []);

  const handleUserToggle = (user) => {
    const updatedSelectedUsers = selectedUcUsers.includes(user.id)
      ? selectedUcUsers.filter((item) => item !== user.id)
      : [...selectedUcUsers, user.id];

    setSelectedUcUsers(updatedSelectedUsers);
  };

  const formik = useFormik({
    initialValues: {
      selectedUcUsers: selectedUcUsers,
    },

    onSubmit: (values, actions) => {
      let payload = selectedUcUsers;

      let apiService = UcManagementService.assignUsersToService(
        formData.id,
        payload
      );

      let successMessage = t("changes-saved-success");
      let failMessage = t("fail-save-changes");

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback(response);
            actions.setSubmitting(false);
          } else {
            throw "contact operation failed";
          }
        })
        .catch((err) => {
          if (
            err.response?.status === 400 &&
            err.response?.data?.detail === "Not enough seats available"
          ) {
            setMessage(t("not-enough-seat-available"));
            setTimeout(() => {
              actions.setSubmitting(false);
            }, [1000]);
          } else {
            setMessage(failMessage);
          }
          setSnackbarStatus(true);
        });
    },
    validateOnMount: true,
  });

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const addCredentials = (user) => {
    setSelecetedUser(user);
    setOpenaddCredentials(true);
  };

  const isSuitable = () => {
    if (ucCredentialsRequired) {
      let selectedUsers = ucUsers.filter((user) =>
        selectedUcUsers.includes(user.id)
      );
      if (selectedUsers.every((user) => user.uc_username)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const isChangeExist = () => {
    let initial_ids = formData.uc_users.map((item) => item.uc_user.id);

    for (let id of initial_ids) {
      if (!selectedUcUsers.includes(id)) {
        return true;
      }
    }
    if (selectedUcUsers.length !== initial_ids.length) {
      return true;
    }

    return false;
  };

  const renderUserRows = () => {
    return ucUsers
      .filter((user) => {
        const nameMatchedUsers =
          user.first_name.toLowerCase().includes(filterName.toLowerCase()) ||
          user.last_name.toLowerCase().includes(filterName.toLowerCase()) ||
          user.email.toLowerCase().includes(filterName.toLowerCase()) ||
          (user.uc_username &&
            user.uc_username.toLowerCase().includes(filterName.toLowerCase()));
        return onlyOwneds
          ? selectedUcUsers.includes(user.id) && nameMatchedUsers
          : nameMatchedUsers;
      })
      .map((user) => (
        <TableRow key={user.id}>
          <TableCell>{user.first_name}</TableCell>
          <TableCell>{user.last_name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell
            sx={{
              color:
                ucCredentialsRequired && !user.uc_username
                  ? store.palette.error.main
                  : "inherit",
              width: "17vw",
            }}
          >
            {user.uc_username || (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {ucCredentialsRequired && selectedUcUsers.includes(user.id) && (
                  <Box
                    sx={{
                      width: "100%",
                      marginLeft: "-8vw",
                      height: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => addCredentials(user)}
                      sx={{
                        width: "100%",
                        height: "70%",
                        backgroundColor: store.palette.warning.main,
                        color: store.palette.warning.contrastText,
                        fontSize: "70%",
                      }}
                    >
                      {t("add-username-password")}
                    </Button>
                    <Box sx={{ fontSize: "70%", textAlign: "center" }}>
                      {t("username-password-required")}
                    </Box>
                  </Box>
                )}
                {ucCredentialsRequired &&
                  !selectedUcUsers.includes(user.id) && (
                    <Box sx={{ width: "14vw" }} />
                  )}
              </Box>
            )}
          </TableCell>
          <TableCell>
            <Checkbox
              checked={selectedUcUsers.includes(user.id)}
              onChange={() => handleUserToggle(user)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  return (
    <>
      <BaseModal
        title={t("add-credentials")}
        open={openAddCredentials}
        setOpen={setOpenaddCredentials}
        children={
          <UcUserForm
            successCallback={fetchUcUsers}
            formType="addCredentials"
            formData={selectedUser}
            setModalStatus={setOpenaddCredentials}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item md={6}></Grid>
            <Grid item md={6}></Grid>
            <Grid item md={12}>
              <Box>
                <Typography variant="h6" color="textSecondary">
                  {t("uc-users")}
                </Typography>
                <Divider />

                <Typography variant="h4" textAlign={"left"}>
                  #{formData.customer.name + " / " + formData.name}
                </Typography>
                <Typography textAlign={"left"} color={"error"} fontSize={12}>{t("uc-user-credentials-warning")}</Typography>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2vh",
                    gap: "2vw",
                  }}
                >
                  <TextField
                    size="small"
                    value={filterName}
                    label={t("name")}
                    name="name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={(event) => setFilterName(event.target.value)}
                  />
                  <SearchButtonContainer>
                    <SearchButton />
                  </SearchButtonContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "auto",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      sx={{ paddingRight: "1vw" }}
                    >
                      {t("assigned-users")}
                    </Typography>
                    <Switch
                      checked={onlyOwneds}
                      onChange={(e) => setOnlyOwneds(e.target.checked)}
                    />
                  </Box>
                </Box>

                {/* Table displaying UC users */}
                <Card
                  sx={{ width: "100%", maxHeight: "65vh", overflow: "auto" }}
                >
                  <CardContent>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("first-name")}</TableCell>
                            <TableCell>{t("last-name")}</TableCell>
                            <TableCell>{t("email")}</TableCell>
                            <TableCell>{t("uc-username")}</TableCell>
                            <TableCell>{t("select")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Render table rows for UC users */}
                          {renderUserRows()}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={!isSuitable() || !isChangeExist()}
                  loading={formik.isSubmitting}
                  endIcon={formType === "test" ? <SendIcon /> : <SaveIcon />}
                >
                  {t("save")}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
