import {
  Stack,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function CustomerRoleForm({
  formData
}) {
  const { t } = useTranslation();

  return (
    <Card sx={{ boxShadow: 3, borderRadius: 2, maxWidth: 400, margin: 'auto' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {"#"+ formData.name + " - " + t("role-functions")} 
        </Typography>
        <Divider sx={{ mb: 2 }} />
  
        <List>
          {formData?.properties?.role_functions.map((item, index) => (
            <ListItem key={index} sx={{ borderBottom: '1px solid #ddd' }}>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
