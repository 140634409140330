import { useState, useEffect } from "react";
import { Stack, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable } from "src/components/table";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { canUserWriteIt, rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import { RoleForm, DeleteRoleForm } from "./form";
import { RoleService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import DeleteAnyway from "src/components/DeleteAnyway";

export default function AdminRoles() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "name", label: t("role") },
    { key: "action", label: t("actions"), align: "right", padding: "3em" },
  ];
  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    role_name: { key: "name", index: 1 },
    function_mappings: { key: "functions", index: 2, noRender: true },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddRoleModal, setAddRoleModalStatus] = useState(false);
  const [openEditRoleModal, setEditRoleModalStatus] = useState(false);
  const [openShowRoleModal, setShowRoleModalStatus] = useState(false);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const fetchRoles = () => {
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    RoleService.listAdminRoles(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchRoles();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddRoleModalStatus(!openAddRoleModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "edit") {
      setEditRoleModalStatus(!openEditRoleModal);
    } else if (modalType === "show") {
      setShowRoleModalStatus(!openShowRoleModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        {canUserWriteIt("admin_permissions") && (
          <Tooltip title={t("edit-role")}>
            <IconButton
              disabled={data[index][0].value === 1}
              color="secondary"
              size="small"
              aria-label="edit-role"
              onClick={() => modalHandler("edit", index)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={t("show-role-details")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="show-role"
            onClick={() => modalHandler("show", index)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>

        {canUserWriteIt("admin_permissions") && (
          <Tooltip
            title={
              data[index][0].value !== 1
                ? t("delete-role")
                : t("root-role-delete")
            }
          >
            <IconButton
              disabled={data[index][0].value === 1}
              color="secondary"
              size="small"
              aria-label="delete-role"
              onClick={() => modalHandler("delete", index)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchRoles();
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          minWidth: "60rem",
          maxHeight: "95vh",
        }}
        title={t("add-new-role")}
        open={openAddRoleModal}
        setOpen={setAddRoleModalStatus}
        children={
          <RoleForm
            successCallback={fetchRoles}
            formType="add"
            formData={{}}
            setModalStatus={setAddRoleModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "60rem",
          maxHeight: "95vh",
        }}
        title={t("edit-role-0")}
        open={openEditRoleModal}
        setOpen={setEditRoleModalStatus}
        children={
          <RoleForm
            successCallback={fetchRoles}
            formType="edit"
            formData={selectedRow}
            setModalStatus={setEditRoleModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "60rem",
          maxHeight: "95vh",
        }}
        title={t("view-role-functions")}
        open={openShowRoleModal}
        setOpen={setShowRoleModalStatus}
        children={
          <RoleForm
            formData={selectedRow}
            setModalStatus={setShowRoleModalStatus}
            formType="view"
          />
        }
      />

      <BaseModal
        title={t("delete-role-0")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteRoleForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-role")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchRoles}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              RoleService.deleteAdminRole(selectedRow.id, { proceed: true })
            }
            name={t("role")}
            usedIn={t("admin-user")}
          />
        }
      />
      {canUserWriteIt("admin_permissions") && (
        <AddNewButton
          label={t("add-new-role-0")}
          onClick={() => modalHandler("add")}
        />
      )}

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
