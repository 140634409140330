import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  Button,
  InputLabel,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import {
  Capability,
  DefaultPaginationData,
  NumberStatus,
  getSelectOptions,
} from "src/constants/index";
import BaseSnackbar from "src/components/BaseSnackbar";
import { CountriesService, DidService, NumberService } from "src/api/services";
import { useTranslation } from "react-i18next";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function IotService() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "service", label: t("service") },
    { key: "type", label: t("type") },
    { key: "status", label: t("status") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    country: { key: "country", index: 1 },
    capability: { key: "capability", index: 2 },
    number: { key: "number", index: 3 },
    type: { key: "type", index: 4 },
    status: { key: "status", index: 5 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterCapability, setFilterCapability] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
  const [openPortOutModal, setPortOutModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const fetchNumbers = async () => {
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      country: filterCountry ? filterCountry : undefined,
      capability: filterCapability ? filterCapability : undefined,
      type: filterType ? filterType : undefined,
      phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
    };
    setLoadingData(true);
    const items = [];
    try {
      const response = await NumberService.listNumbers(params);
      setTotalCount(response.data.count);
      for (const idx in response.data.results) {
        let item = new Array(TABLE_HEAD.length - 1).fill({});
        Object.entries(response.data.results[idx]).forEach(([key, value]) => {
          if (key in TABLE_FIELD_MAPPING) {
            item[TABLE_FIELD_MAPPING[key].index] = {
              ...TABLE_FIELD_MAPPING[key],
              value: value,
            };
          }
        });
        items.push(item);
      }
      setData(items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    CountriesService.getCountries({ size: 100 })
      .then((response) => {
        const options = response.data.items.map((country) => ({
          value: country.country_id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });

    DidService.listDidTypes({ size: 100 })
      .then((response) => {
        const options = response.data.items.map((did) => ({
          value: did.id,
          label: did.name,
        }));
        setDidTypes(options);
      })
      .catch((err) => {
        console.log(err);
      });

    //getRuleTypesFunction like countries when api ready FTODO
  }, []);

  useEffect(() => {
    fetchNumbers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "deallocate") {
      setDeallocateModalStatus(!openDeallocateModal);
    } else if (modalType === "portOut") {
      setPortOutModalStatus(!openPortOutModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          color="secondary"
          size="small"
          aria-label="view-contact"
          onClick={() => modalHandler("dealllocate", index)}
        >
          {t("deallocate")}
        </Button>

        <Button
          color="secondary"
          size="small"
          aria-label="view-contact"
          onClick={() => modalHandler("portOut", index)}
        >
          {t("port-out")}
        </Button>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("service-name")}
                labelId="filter-number-label"
                name="number"
                color="secondary"
                value={filterPhoneNumber}
                onChange={(event) => {
                  setFilterCountry(event.target.value);
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={didTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("type")} />
                )}
                value={
                  didTypes.find((option) => option.value === filterType) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterType(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} sx={{ mt: "0.5em" }}>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">{t("status")}</InputLabel>
              <Select
                label={t("status")}
                labelId="filter-status-label"
                name="status"
                color="secondary"
                value={filterStatus}
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
              >
                {getSelectOptions(NumberStatus())}
              </Select>
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchNumbers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
