import { useParams } from "react-router-dom";
import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';

import GeneralPartitionInfo from './partitions/GeneralPartitionInfo';
import ManagePartitionServices from './partitions/ManagePartitionServices';
// ----------------------------------------------------------------------


export default function ManagePartitions() {
    const { t } = useTranslation();
    const { id } = useParams();

    const TABS = [
        { id: 0, label: t('general-info'), component: <GeneralPartitionInfo id={id} /> },
        { id: 1, label: t('partition-services'), component: <ManagePartitionServices id={id}/> },
        
    ];

    const TITLE = t('manage-partitions');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
