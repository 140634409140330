import { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CountriesService, CurrencyService } from "src/api/services";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { canUserWriteIt } from "src/utils/Util";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function Currencies() {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterCurrency, setFilterCurrency] = useState("");
  const [loading, setLoading] = useState(false);
  const [onlyActives, setOnlyActives] = useState(false);
  const [onlyInActives, setOnlyInActives] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "symbol", label: t("symbol") },
    { key: "short_code", label: t("short-code") },
    { key: "select" },
  ];

  const TABLE_FIELD_MAPPING = {
    currency_id: {
      key: "id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    name: { key: "name", cellComponentType: "th", index: 1 },
    symbol: { key: "symbol", cellComponentType: "th", index: 2 },
    short_code: { key: "short_code", cellComponentType: "th", index: 3 },
    select: { key: "select", index: 4 },
  };

  const fetchCurrencies = () => {
    setLoadingData(true);
    CurrencyService.listCurrencies({ size: 100 })
      .then((response) => {
        let items = [];
        for (const idx in response.data) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data[idx];
          item[TABLE_FIELD_MAPPING["select"].index] = {
            ...TABLE_FIELD_MAPPING["select"],
            value: itemData.is_active,
          };

          Object.entries(response.data[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "select") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          if (
            itemData.name.toLowerCase().includes(filterCurrency.toLowerCase())
          ) {
            if (onlyActives && itemData.is_active) {
              items.push(item);
            } else if (onlyInActives && !itemData.is_active) {
              items.push(item);
            } else if (!onlyActives && !onlyInActives) {
              items.push(item);
            }
          }
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };
  useEffect(() => {
    fetchCurrencies();
    return () => {
      setData([]);
    };
  }, [onlyActives, onlyInActives]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const onSelectAll = async (checked) => {
    let tempData = [...data];
    for (const idx in tempData) {
      tempData[idx][4].value = checked;
    }
    setData(tempData);
  };

  const onCurrencySelect = async (checked, index) => {
    /*  if(!canUserWriteIt("currencies")){
      return null
    } */
    let tempData = [...data];
    tempData[index][4].value = checked;
    setData(tempData);
  };

  const onSubmit = (actions) => {
    let currencies = [];
    data.map((item) => {
      currencies.push({
        id: item[0].value,
        is_active: item[4].value,
      });
    });

    const payload = currencies;

    let successMessage = t("currencies-status-changed-successfully");
    let failMessage = t("currencies-status-fail");

    setLoading(true);
    CurrencyService.setCurrencies(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
        } else {
          throw failMessage;
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
      })
      .finally(() => {
        setLoading(false);
        fetchCurrencies();
      });
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={2}>
          <Grid item md={3} xs={8}>
            <FormControl fullWidth>
              <TextField
                value={filterCurrency}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterCurrency(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1vw",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"primary"} fontWeight={900}>
                  {t("only-actives")}
                </Typography>
                <Checkbox
                  color="primary"
                  checked={onlyActives}
                  onChange={(event) => {
                    setOnlyActives(!onlyActives);
                    if (!onlyActives && onlyInActives) {
                      setOnlyInActives(false);
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"primary"} fontWeight={900}>
                  {t("only-inactives")}
                </Typography>
                <Checkbox
                  color="primary"
                  checked={onlyInActives}
                  onChange={(event) => {
                    setOnlyInActives(!onlyInActives);
                    if (!onlyInActives && onlyActives) {
                      setOnlyActives(false);
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <SearchButtonContainer item md={5} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCurrencies();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      {canUserWriteIt("currencies") && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: 3,
          }}
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
            loading={loading}
            startIcon={<SaveIcon />}
          >
            {t("common.__i18n_ally_root__.save")}
          </LoadingButton>
        </Box>
      )}

      <br />
      <BaseTable
        head={[...TABLE_HEAD]}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{}}
        loadingData={loadingData}
        select={true}
        onRowSelect={onCurrencySelect}
        onSelectAll={onSelectAll}
        onRowSelectIndex={4}
        haveSelectAccess={canUserWriteIt("currencies")}
      />
    </>
  );
}
