// material
import { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { EngineService, TariffService } from "src/api/services";
import { useTranslation } from "react-i18next";
import {
  CpassEngineTypes,
  IotEngineTypes,
  NumbersEngineTypes,
  UcEngineTypes,
  getLabelByValue,
} from "src/constants";
// ----------------------------------------------------------------------

export default function DeleteEngineForm({
  formData,
  engineType,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  /*   const [engineName, setEngineName] = useState(null); */
  const [name, setName] = useState("");

  useEffect(() => {
    switch (engineType) {
      case "CPaaS":
        setName(t("cpaas-engine"));
        /*  setEngineName(
          getLabelByValue(CpassEngineTypes(), formData.engine_type.toString())
        ); */
        break;
      case "UCaaS":
        setName(t("ucaas-engine"));
        /* setEngineName(
          getLabelByValue(UcEngineTypes(), formData.engine_type.toString())
        ); */
        break;
      case "inbound":
        setName(t("inbound-provider"));
        /* setEngineName(
          getLabelByValue(NumbersEngineTypes(), formData.engine_type.toString())
        ); */
        break;
      /*       case "outbound":
        name = t("inbound-provider");
        setEngineName(
          getLabelByValue(NumbersEngineTypes(), formData.engine_type.toString())
        );
        break; */
      case "iot":
        setName(t("iot-engine"));
        /* setEngineName(
          getLabelByValue(IotEngineTypes(), formData.engine_type.toString())
        ); */
        break;
      default:
        break;
    }
  }, []);
  const deleteTariff = () => {
    setLoading(true);

    let apiService, successMessage, failMessage;
    apiService = EngineService.deleteEngine(formData.id);
    successMessage = t("name-delete-success", { name });
    failMessage = t("name-delete-fail", { name });
    apiService
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "container delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">{t("sure-delete", { name })}</Typography>
        <Typography component="subtitle1" variant="h6">
          {formData.pop + "/" + formData.engine_subtype}
        </Typography>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteTariff}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
