import api from "src/api/Api";
import urls from "src/api/Urls";

export const listEngines = (params) => {
  return api.get(urls.list_engines, { params: params });
};

export const addEngine = (payload) => {
  return api.post(urls.add_engine, payload);
};

export const getEngine = (id) => {
  return api.get(urls.get_engine(id));
};

export const updateEngine = (id, payload) => {
  return api.put(urls.update_engine(id), payload);
};

export const deleteEngine = (id) => {
  return api.delete(urls.delete_engine(id));
};

//Engine Subtypes

export const listEngineSubTypes = (params) => {
  return api.get(urls.list_engine_sub_types, { params: params });
};

export const testEngine = (payload) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const mockResponse = {
        status: 200,
        data: payload,
      };

      resolve(mockResponse);
    }, 2000);
  });
};
