import React from "react";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultPaginationData } from "src/constants";
import BaseSnackbar from "src/components/BaseSnackbar";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { formatDate, formatDateInput } from "src/utils/Util";
import SearchButton from "src/components/buttons/SearchButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import { AuditService } from "src/api/services";
import { useTheme } from "@mui/material/styles";

export default function AdminUserLog() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [effectiveStartDate, setEffectiveStartDate] = useState(null);
  const [effectiveEndDate, setEffectiveEndDate] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);


  const TABLE_HEAD = [
    { key: "id", label: "#" },
    { key: "user", label: t("user") },
    { key: "email", label: t("email") },
    { key: "ip_address", label: t("ip-address") },
    { key: "timestamp", label: t("timestamp") },
    { key: "endpoint", label: t("endpoint") },
    { key: "method", label: t("method") },
    { key: "status_code", label: t("status-code") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    user: { key: "user", index: 1 },
    email: { key: "email", index: 2 },
    ip_address: { key: "ip_address", index: 3 },
    time: { key: "timestamp", index: 4 },
    path: { key: "endpoint", index: 5 },
    method: { key: "method", index: 6 },
    status_code: { key: "status_code", index: 7 },
  };

  const fetchLogs = () => {
    setLoadingData(true);
    const params = {
      user_type: 1,
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    if (effectiveStartDate) {
      params.start_date = formatDateInput(effectiveStartDate);
    }

    if (effectiveEndDate) {
      params.end_date = formatDateInput(effectiveEndDate);
    }
    AuditService.getAuditLogs(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);

        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let userName = itemData.admin_user
            ? itemData.admin_user.first_name +
              " " +
              itemData.admin_user.last_name
            : "";

          item[TABLE_FIELD_MAPPING["user"].index] = {
            ...TABLE_FIELD_MAPPING["user"],
            value: userName,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchLogs();
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "timestamp") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "status_code"){
        formatted.push({
          ...d,
          value: <Typography sx={{
            color: d.value === 200 ? theme.palette.success.main : theme.palette.error.main
          }}>
            {d.value}
          </Typography>,
        });
      } 
      
      else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={1} padding={2}>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("start-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveStartDate(newValue);
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("end-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveEndDate(newValue);
                  }}
                  minDate={effectiveStartDate}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item md={3} xs={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("endpoint")}</Typography>
              <Autocomplete
                disablePortal
                options={endpoints}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!selectedEndpoint && t("select-endpoint")}
                  />
                )}
                value={
                  endpoints.find(
                    (option) => option.value === selectedEndpoint
                  ) || null
                }
                onChange={(newValue) => {
                  if (newValue && newValue.value) {
                    setSelectedEndpoint(newValue.value);
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item md={1} xs={4} mt={"3vh"}>
            <SearchButton onClick={() => fetchLogs()} />
          </Grid>
        </Grid>
        {/*  <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            ml: "1vw",
            gap: "1vw",
          }}
        >
          <Button
            sx={{
              border: "2px solid",
              padding: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <GetAppIcon />
            <Typography>{t("export-current-page")}</Typography>
          </Button>

          <Button
            sx={{
              border: "2px solid",
              padding: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <GetAppIcon />
            <Typography>{t("export-all-records")}</Typography>
          </Button>
        </Box> */}
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
