import api from "src/api/Api";
import urls from "src/api/Urls";

export const listOutboundVoiceRates = (params) => {
    return api.get(urls.list_outbound_voice_rates, { params: params });
}
export const getOutboundVoiceRate = (id, params) => {
    return api.get(urls.get_outbound_voice_rate(id), {params: params});
}

export const addOutboundVoiceRate = (payload) => {
    return api.post(urls.add_outbound_voice_rate, payload);
}

export const deleteOutboundVoiceRate = (id) => {
    return api.delete(urls.delete_outbound_voice_rate(id));
}

export const updateOutboundVoiceRate = (id, payload) => {
    return api.put(urls.update_outbound_voice_rate(id), payload);
}

// Sms

export const listOutboundSmsRates = (params) => {
    return api.get(urls.list_outbound_sms_rates, { params: params });
}
export const getOutboundSmsRate = (id, params) => {
    return api.get(urls.get_outbound_sms_rate(id), {params: params});
}

export const addOutboundSmsRate = (payload) => {
    return api.post(urls.add_outbound_sms_rate, payload);
}

export const deleteOutboundSmsRate = (id) => {
    return api.delete(urls.delete_outbound_sms_rate(id));
}

export const updateOutboundSmsRate = (id, payload) => {
    return api.put(urls.update_outbound_sms_rate(id), payload);
}

// Whatsapp

export const listOutboundWhatsappRates = (params) => {
    return api.get(urls.list_outbound_whatsapp_rates, { params: params });
}
export const getOutboundWhatsappRate = (id, params) => {
    return api.get(urls.get_outbound_whatsapp_rate(id), {params: params});
}

export const addOutboundWhatsappRate = (payload) => {
    return api.post(urls.add_outbound_whatsapp_rate, payload);
}

export const deleteOutboundWhatsappRate = (id) => {
    return api.delete(urls.delete_outbound_whatsapp_rate(id));
}

export const updateOutboundWhatsappRate = (id, payload) => {
    return api.put(urls.update_outbound_whatsapp_rate(id), payload);
}

// Telegram

export const listOutboundTelegramRates = (params) => {
    return api.get(urls.list_outbound_telegram_rates, { params: params });
}
export const getOutboundTelegramRate = (id, params) => {
    return api.get(urls.get_outbound_telegram_rate(id), {params: params});
}

export const addOutboundTelegramRate = (payload) => {
    return api.post(urls.add_outbound_telegram_rate, payload);
}

export const deleteOutboundTelegramRate = (id) => {
    return api.delete(urls.delete_outbound_telegram_rate(id));
}

export const updateOutboundTelegramRate = (id, payload) => {
    return api.put(urls.update_outbound_telegram_rate(id), payload);
}

// RCS

export const listOutboundRcsRates = (params) => {
    return api.get(urls.list_outbound_rcs_rates, { params: params });
}

export const getOutboundRcsRate = (id, params) => {
    return api.get(urls.get_outbound_rcs_rate(id), {params: params});
}

export const addOutboundRcsRate = (payload) => {
    return api.post(urls.add_outbound_rcs_rate, payload);
}

export const deleteOutboundRcsRate = (id) => {
    return api.delete(urls.delete_outbound_rcs_rate(id));
}

export const updateOutboundRcsRate = (id, payload) => {
    return api.put(urls.update_outbound_rcs_rate(id), payload);
}

// Google Business Chat

export const listOutboundGbcRates = (params) => {
    return api.get(urls.list_outbound_gbc_rates, { params: params });
}

export const getOutboundGbcRate = (id, params) => {
    return api.get(urls.get_outbound_gbc_rate(id), {params: params});
}

export const addOutboundGbcRate = (payload) => {
    return api.post(urls.add_outbound_gbc_rate, payload);
}

export const deleteOutboundGbcRate = (id) => {
    return api.delete(urls.delete_outbound_gbc_rate(id));
}

export const updateOutboundGbcRate = (id, payload) => {
    return api.put(urls.update_outbound_gbc_rate(id), payload);
}

// Apple Message

export const listOutboundAppleMessageRates = (params) => {
    return api.get(urls.list_outbound_apple_message_rates, { params: params });
}

export const getOutboundAppleMessageRate = (id, params) => {
    return api.get(urls.get_outbound_apple_message_rate(id), params);
}

export const addOutboundAppleMessageRate = (payload) => {
    return api.post(urls.add_outbound_apple_message_rate, payload);
}

export const deleteOutboundAppleMessageRate = (id) => {
    return api.delete(urls.delete_outbound_apple_message_rate(id));
}

export const updateOutboundAppleMessageRate = (id, payload) => {
    return api.put(urls.update_outbound_apple_message_rate(id), payload);
}
