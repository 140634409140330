import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {
  Stack,
  TextField,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { RoleService, UserService } from "src/api/services";

//edit ?
export default function UcaasUserForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false); // (?)

  const AddUserSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    username: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    password: Yup.string()
      .min(8, t("too-short-3"))
      .required(t("password-is-required"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      email: formData.email || "",
      username: formData.username || "",
      password: formData.password || "",
    },
    validationSchema: AddUserSchema,
    onSubmit: (values, actions) => {
      console.log("onSubmit called!!!");
      let payload;

      if (formType === "add") {
        payload = {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          password: values.password,
        };
      } else if (formType === "edit") {
        payload = {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          password: values.password,
        };
      } else if (formType === "password") {
        payload = {
          password: values.password,
        };
      }

      let successMessage =
        formType === "add"
          ? t("new-ucaas-user-has-been-successfully-added")
          : t("ucaas-user-editted-successfully");
      let failMessage =
        formType === "add"
          ? t("new-ucaas-user-could-not-be-added")
          : t("ucaas-user-edit-fail");
      const apiService =
        formType === "add"
          ? UserService.addAdminuser(payload)
          : UserService.updateAdminUser(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          setMessage(failMessage);
          setSnackbarStatus(true);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "firstName" && formType !== "password") {
      return (
        <TextField
          fullWidth
          label={t("first-name-0")}
          {...getFieldProps("firstName")}
          error={Boolean(touched.firstName && errors.firstName)}
          helperText={touched.firstName && errors.firstName}
          sx={{ mr: 2 }}
        />
      );
    }
    if (fieldName === "lastName" && formType !== "password") {
      return (
        <TextField
          fullWidth
          label={t("last-name-0")}
          {...getFieldProps("lastName")}
          error={Boolean(touched.lastName && errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
      );
    }
    if (fieldName === "email" && formType !== "password") {
      return (
        <TextField
          fullWidth
          type="email"
          label={t("email-address")}
          {...getFieldProps("email")}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />
      );
    }
    if (fieldName === "username" && formType !== "password") {
      return (
        <TextField
          fullWidth
          label={t("username")}
          {...getFieldProps("username")}
          error={Boolean(touched.username && errors.username)}
          helperText={touched.username && errors.username}
        />
      );
    }
    if (fieldName === "password" && formType !== "edit") {
      return (
        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          label={t("password")}
          {...getFieldProps("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t("add-new-user")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("firstName")}
            {getFieldByName("lastName")}
            {getFieldByName("email")}
            {getFieldByName("username")}
            {getFieldByName("password")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
