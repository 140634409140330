import { useState, useEffect } from "react";
// material
import {
  Stack,
  Checkbox,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
// app
import { RoleService, UserService } from "src/api/services";
import { useStore } from "src/store/Store";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function AsignRoleForm({
  formData,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const [store] = useStore();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleRoleToggle = (roleID) => {
    if (selectedRoles.includes(roleID)) {
      UserService.updateRoleOfAdmin(formData.id, roleID, true)
        .then((response) => {
          if (response.status === 200) {
            setMessage(t("role-removed-from-admin-user"));
            setSnackbarStatus(true);
            successCallback();
            const updatedSelectedRoles = selectedRoles.includes(roleID)
              ? selectedRoles.filter((id) => id !== roleID)
              : [...selectedRoles, roleID];
            setSelectedRoles(updatedSelectedRoles);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setMessage(t("root-role-unassign-warning"));
            setSnackbarStatus(true);
          } else {
            let failMessage = err.data.detail || t("failed-to-remove-role-from-admin-user");

            

            setMessage(failMessage);
            setSnackbarStatus(true);
            successCallback();
          }
        });
    } else {
      UserService.updateRoleOfAdmin(formData.id, roleID, false)
        .then((response) => {
          if (response.status === 200) {
            setMessage(t("role-added-to-admin-user"));
            setSnackbarStatus(true);
            successCallback();
            const updatedSelectedRoles = selectedRoles.includes(roleID)
              ? selectedRoles.filter((id) => id !== roleID)
              : [...selectedRoles, roleID];
            setSelectedRoles(updatedSelectedRoles);
          }
        })
        .catch((err) => {
          setMessage(t("failed-add-role-to-admin-user"));
          setSnackbarStatus(true);
          successCallback();
        });
    }
  };

  useEffect(() => {
    RoleService.listAdminRoles({ size: 100 })
      .then((response) => {
        setRoles(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
    if (formData.roles) {
      let existingRoles = [];
      formData.roles.map((role) => {
        existingRoles.push(role?.role?.id);
      });
      setSelectedRoles(existingRoles);
    }
  }, []);

  const getFieldByName = (fieldName) => {
    if (fieldName === "roles") {
      return (
        <Stack spacing={1} alignItems={"center"} style={{ width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <CardContent>
              <Typography variant="subtitle2" color="textSecondary">
                {t("roles")}
              </Typography>
              <List
                sx={{
                  alignItems: "center",
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                {roles.map((role) => (
                  <ListItem key={role.id} sx={{ textAlign: "center" }}>
                    <Checkbox
                      checked={selectedRoles.includes(role.id)}
                      onChange={() => handleRoleToggle(role.id)}
                    />
                    <ListItemText primary={role.role_name} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Stack>
      );
    }
  };

  return (
    <>
      <Stack spacing={3}>{getFieldByName("roles")}</Stack>
    </>
  );
}
