import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { IotTypes, getSelectOptions } from "src/constants";
import { useState } from "react";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function IotPackageForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [type, setType] = useState(formData.type ? formData.type : 1);
  const PackageSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    quota: Yup.number()
      .required(t("quota-is-required"))
      .max(1000, t("quota-is-too-long")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      quota: formData.quota,
      type: formData.type || 0,
      isEnabled: formData.is_enabled || false,
    },
    validationSchema: PackageSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        package_type: 5,
        properties: {
          quota: values.quota,
          type: type,
        },
        is_enabled: values.isEnabled,
        status: 1,
      };
      let successMessage =
        formType === "add"
          ? t("iot-package-add-success")
          : t("iot-package-edit-success");
      let failMessage =
        formType === "add"
          ? t("iot-package-add-fail")
          : t("iot-package-edit-fail");
      const apiService =
        formType === "add"
          ? PackagesService.addPackage(payload)
          : PackagesService.updatePackage(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback(response.data);
            actions.setSubmitting(false);
          } else {
            throw "failed to create outbound package";
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "quota") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="quota"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("quota")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="quota"
            label={isMobile() ? t("quota") : undefined}
            {...getFieldProps("quota")}
            placeholder="0"
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.quota && errors.quota)}
            helperText={touched.quota && errors.quota}
          />
        </Box>
      );
    }

    if (fieldName === "type") {
      return (
        <FormControl fullWidth>
          <InputLabel id="type-label">{t("type")}</InputLabel>
          <Select
            label={t("type")}
            labelId="type-label"
            color="secondary"
            value={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
          >
            {getSelectOptions(IotTypes(), "", "")}
          </Select>
        </FormControl>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("quota")}
            {getFieldByName("type")}
          </Stack>

          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
