import { useEffect, useState } from "react";

import { Box, FormControl, Grid, TextField , Typography, Checkbox} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CountriesService } from "src/api/services";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { canUserWriteIt } from "src/utils/Util";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function OutboundCountries() {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterCountry, setFilterCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [onlyActives, setOnlyActives] = useState(false);
  const [onlyInActives, setOnlyInActives] = useState(false);

  const TABLE_HEAD = [
    { key: "country_name", label: t("name") },
    { key: "country_code", label: t("country-code") },
    { key: "iso_code_2", label: t("iso2-code") },
    { key: "iso_code_3", label: t("iso3-code") },
    { key: "select" },
  ];

  const TABLE_FIELD_MAPPING = {
    country_id: {
      key: "id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    name: { key: "country_name", index: 1 },
    country_code: { key: "country_code", index: 2 },
    iso_code_2: { key: "iso_code_2", index: 3 },
    iso_code_3: { key: "iso_code_3", index: 4 },
    select: { key: "select", index: 5 },
  };

  const fetchCountries = () => {
    setLoadingData(true);
    CountriesService.getOutboundCountries({ size: 100 })
      .then((response) => {
        let items = [];
        for (const idx in response.data) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data[idx];
          item[TABLE_FIELD_MAPPING["select"].index] = {
            ...TABLE_FIELD_MAPPING["select"],
            value: itemData.is_active,
          };

          Object.entries(response.data[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "select") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          if (
            itemData.name.toLowerCase().includes(filterCountry.toLowerCase())
          ) {
            if(onlyActives && itemData.is_active){
              items.push(item)
            }else if(onlyInActives && !itemData.is_active){
              items.push(item)
            }else if(!onlyActives && !onlyInActives){
              items.push(item);
            }
            
          }
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };
  useEffect(() => {
    fetchCountries();
    return () => {
      setData([]);
    };
  }, [onlyActives, onlyInActives]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const onSelectAll = async (checked) => {
    let tempData = [...data];
    for (const idx in tempData) {
      tempData[idx][5].value = checked;
    }
    setData(tempData);
  };

  const onCountrySelect = async (checked, index) => {
    let tempData = [...data];
    tempData[index][5].value = checked;
    setData(tempData);
  };

  const onSubmit = (actions) => {
    let outbound_countries = [];
    data.map((item) => {
      outbound_countries.push({
        id: item[0].value,
        is_active: item[5].value,
      });
    });

    const payload = outbound_countries;

    let successMessage = t("outbound-countries-status-changed-successfully");
    let failMessage = t("putbound-countries-status-fail");

    setLoading(true);
    CountriesService.setOutboundCountries(payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
        } else {
          throw failMessage;
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
      })
      .finally(() => {
        setLoading(false);
        fetchCountries();
      });
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterCountry}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterCountry(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1vw",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"primary"} fontWeight={900}>
                  {t("only-actives")}
                </Typography>
                <Checkbox
                  color="primary"
                  checked={onlyActives}
                  onChange={(event) => {
                    setOnlyActives(!onlyActives);
                    if (!onlyActives && onlyInActives) {
                      setOnlyInActives(false);
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"primary"} fontWeight={900}>
                  {t("only-inactives")}
                </Typography>
                <Checkbox
                  color="primary"
                  checked={onlyInActives}
                  onChange={(event) => {
                    setOnlyInActives(!onlyInActives);
                    if (!onlyInActives && onlyActives) {
                      setOnlyActives(false);
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCountries();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          onClick={onSubmit}
          loading={loading}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      </Box>

      <br />
      <BaseTable
        head={[...TABLE_HEAD]}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{}}
        loadingData={loadingData}
        select={true}
        onRowSelect={onCountrySelect}
        onSelectAll={onSelectAll}
        onRowSelectIndex={5}
        smallRow={true}
        haveSelectAccess={canUserWriteIt("outbound_countries")}
      />
    </>
  );
}
