import { Autocomplete, Box, Card, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useStore } from "src/store/Store";

export default function EmailSettings() {
  const { t } = useTranslation();
  const [providerOptions, setProviderOptions] = useState([
    {
      value: 0,
      label: "Sendgrid",
    },
  ]);

  
  const [message, setMessage] = useState(false)
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [apiKey, setApiKey] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [store] = useStore()


  const handleSubmit = () => {

    setSubmitting(true);
    
    let successMessage = t("email-setup-success");
    let failMessage = t("email-setup-fail");
    //TODO implement endpoint here after ready
    setTimeout(() => {

      setMessage(successMessage);
      setSnackbarStatus(true);
      setSubmitting(false);
    }, 1000);

  }

  return (
    <>
      <BaseSnackbar 
        message={message}
        open={snackbarStatus}
        setOpen={setSnackbarStatus}
      />
      <Card
        sx={{
          minHeight: "50vh",
          width: "40vw",
          margin: "auto",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          padding: "2em",
          gap: "2em",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h3" color={store.palette.secondary.dark}>{t("email-setup")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" color={store.palette.secondary.dark}>{t("provider")}</Typography>
          <Autocomplete
            disablePortal
            options={providerOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} />}
            value={
              providerOptions.find(
                (option) => option.value === selectedProvider
              ) || null
            }
            onChange={(newValue) => {
              if (newValue && newValue.value) {
                setSelectedProvider(newValue.value);
              }
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" color={store.palette.secondary.dark}>{t("api-key")}</Typography>
          <TextField
            value={apiKey}
            placeholder={t("api-key-here")}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </Box>
        <Box sx={{margin: "auto"}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            startIcon={<SaveIcon />}
            onClick={() => handleSubmit()}
          >
            {t("common.__i18n_ally_root__.save")}
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
}
