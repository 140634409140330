import React from "react";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultPaginationData } from "src/constants";
import BaseSnackbar from "src/components/BaseSnackbar";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { formatDate, formatDateInput } from "src/utils/Util";
import SearchButton from "src/components/buttons/SearchButton";
import GetAppIcon from "@mui/icons-material/GetApp";



export default function ProvisioningLog() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [effectiveStartDate, setEffectiveStartDate] = useState(dayjs());
  const [effectiveEndDate, setEffectiveEndDate] = useState(dayjs());
  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);

  const TABLE_HEAD = [
    { key: "id", label: "#" },
    { key: "trunk", label: t("trunk") },
    { key: "type", label: t("type") },
    { key: "requested_at", label: t("requested-at") },
    { key: "last_execution_at", label: t("last-execution-at") },
    { key: "success", label: t("success") },
    { key: "status", label: t("status") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    trunk: { key: "trunk", index: 1 },
    type: { key: "type", index: 2 },
    requested_at: { key: "requested_at", index: 3 },
    last_execution_at: { key: "last_execution_at", index: 4 },
    success: { key: "success", index: 5 },
    status: { key: "status", index: 6 },
  };

  const fetchLogs = () => {
    //implement fetch log
  };

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "requested_at") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "last_execution_at") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      }
    });
    return formatted;
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={1} padding={2}>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("start-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveStartDate(newValue);
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("end-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveEndDate(newValue);
                  }}
                  minDate={effectiveStartDate}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={1} xs={4} mt={"3vh"}>
            <SearchButton onClick={() => fetchLogs()} />
          </Grid>
        </Grid>
      {/*   <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            ml: "1vw",
            gap: "1vw",
          }}
        >
          <Button
            sx={{
              border: "2px solid",
              padding: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <GetAppIcon />
            <Typography>{t("export-current-page")}</Typography>
          </Button>

          <Button
            sx={{
              border: "2px solid",
              padding: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s",
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <GetAppIcon />
            <Typography>{t("export-all-records")}</Typography>
          </Button>
        </Box> */}
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
