import * as Yup from "yup";
import {
  TextField,
  Stack,
  Typography,
  Card,
  CardContent,
  Divider,
  ListItemText,
  ListItem,
  List,
  Checkbox,
  Box,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomerOrderService, PackagesService } from "src/api/services";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import { useFormik, Form, FormikProvider } from "formik";
import { useStore } from "src/store/Store";

export default function UcPurchaseForm({
  formData,
  formType,
  setModalStatus,
  setMessage,
  setSnackbarStatus,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [store, dispatch] = useStore();
  const [features, setfeatures] = useState(formData.features ?? []);
  const [paymentType, setPaymentType] = useState("month");
  const PaymentSchema = Yup.object().shape({
    seat_count: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("seat-count-is-required"))
      .min(
        formData.min_seat_count,
        t("seat-count-must-be-at-least", { min: formData.min_seat_count })
      )
      .max(
        formData.max_seat_count,
        t("seat-count-must-be-at-most", { max: formData.max_seat_count })
      ),
  });

  const formik = useFormik({
    initialValues: {
      seat_count: undefined,
    },
    validationSchema: PaymentSchema,
    onSubmit: (values, actions) => {
      const payload = {
        package_id: formData.id,
        seat_count: Number(values.seat_count),
        monthly: paymentType === "month",
        annual: paymentType === "annual",
      };

      let successMessage = t("subscribe-success", { name: `${formData.name}` });
      let failMessage = t("subscribe-fail", { name: `${formData.name}` });

      CustomerOrderService.createUcOrder(payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          /* if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          } */

          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const unSubscribePackage = () => {
    /* setLoading(true);
    PackagesService.unSubscribeUcassPackage(formData.id)
        .then((response) => {
            if (response.status === 200) {
                setMessage(t('has-been-successfully-deleted', { name: `${formData.name}` }))
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw "contact could not be deleted";
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-deleted', { name: `${formData.name}` }))
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        }); */
    setModalStatus(false);
  };
  const calculatePrice = (seatCount) => {
    const pricing =
      paymentType === "month"
        ? formData.monthly_pricing
        : formData.annual_pricing;
    return seatCount * pricing;
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  let price = 0;
  if (formik.values.seat_count) {
    price = calculatePrice(formik.values.seat_count);
  }
  let content;
  switch (formType) {
    case "view":
      content = (
        <Card>
          <CardContent>
            {formData.description && (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  {formData.description}
                </Typography>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <Typography variant="h5">
              ${formData.monthly_pricing} / {t("month")}
            </Typography>
            <Typography variant="h5">
              ${formData.annual_pricing} / {t("annual")}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              {t("min-seat-count")}: {formData.min_seat_count}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {t("max-seat-count")}: {formData.max_seat_count}
            </Typography>

            {features.length > 0 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" color="textSecondary">
                  {t("features")}:
                </Typography>
                <List
                  sx={{
                    alignItems: "center",
                    maxHeight: "300px",
                    overflowY: "scroll",
                  }}
                >
                  {features.map((feature) => (
                    <ListItem key={feature.id} sx={{ textAlign: "center" }}>
                      <CheckIcon />
                      <ListItemText primary={feature.name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </CardContent>
        </Card>
      );
      break;

    case "unsubscribe":
      content = (
        <Stack spacing={3}>
          <Typography component="h6">
            {t("are-you-sure-unsubscribe-to-this-package")}
          </Typography>
          <Typography component="subtitle1" variant="h6">
            {formData.name}
          </Typography>
          <Stack sx={{ display: "block" }} direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={() => setModalStatus(false)}
            >
              {t("cancel")}
            </LoadingButton>
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              loading={loading}
              onClick={unSubscribePackage}
            >
              {t("unsubscribe")}
            </LoadingButton>
          </Stack>
        </Stack>
      );
      break;

    case "subscribe":
      content = (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Typography component="h6">
                {t("are-you-sure-subscribe")}
              </Typography>
              <Typography component="subtitle1" variant="h6">
                {formData.name}
              </Typography>
              <Grid
                sx={{ flexGrow: 1, alignItems: "center", pt: 1.5, pb: 1.5 }}
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <strong>{t("monthly-pricing")}: </strong>
                  <Typography fontSize={22} color={"green"}>
                    ${formData.monthly_pricing}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <strong>{t("annual-pricing")}: </strong>
                  <Typography fontSize={22} color={"green"}>
                    ${formData.annual_pricing}
                  </Typography>
                </Grid>
              </Grid>
              <Typography component="subtitle1" variant="h6">
                <strong>{t("cost")}: </strong>${price}
              </Typography>
              {paymentType === "annual" && formik.values.seat_count && (
                <Typography component="subtitle1" variant="h6">
                  <strong>{t("your-savings")} </strong>$
                  {(
                    formik.values.seat_count *
                    (formData.monthly_pricing - formData.annual_pricing / 12.0)
                  ).toFixed(2)}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={paymentType === "month"}
                    onChange={(e) => {
                      e.target.checked && setPaymentType("month");
                    }}
                  />
                  <Typography component="h6">{t("monthly")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={paymentType === "annual"}
                    onChange={(e) => {
                      e.target.checked && setPaymentType("annual");
                    }}
                  />
                  <Typography component="h6">{t("annually")}</Typography>
                </Box>
              </Box>
              <TextField
                sx={{ width: "80%", alignSelf: "center" }}
                type="number"
                label={t("seat-count")}
                placeholder={formData.min_seat_count}
                {...getFieldProps("seat_count")}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                error={Boolean(touched.seat_count && errors.seat_count)}
                helperText={touched.seat_count && errors.seat_count}
              />
              <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={() => setModalStatus(false)}
                >
                  {t("cancel")}
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t("subscribe")}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      );

      break;

    case "upgrade":
      content = (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Typography component="h6">
                {t("are-you-sure-upgrade")}
              </Typography>
              <Typography component="subtitle1" variant="h4">
                {formData.name}
              </Typography>
              <Divider />
              <Typography component="subtitle1" variant="h4">
                {t("current-seat-count-status")}
              </Typography>
              <Divider />
              <Typography component="subtitle1" variant="h6">
                {t("owned")}:{formData?.service_usage_info?.total_seat_count}
              </Typography>
              <Typography component="subtitle1" variant="h6">
                {t("used")} :{formData?.service_usage_info?.used_seat_count}
              </Typography>
              <Typography component="subtitle1" variant="h6">
                {t("available")}:
                {formData?.service_usage_info?.total_seat_count -
                  formData?.service_usage_info?.used_seat_count}
              </Typography>
              <Divider />
              <Grid
                sx={{ flexGrow: 1, alignItems: "center", pt: 1.5, pb: 1.5 }}
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <strong>{t("monthly-pricing")}: </strong>
                  <Typography fontSize={22} color={"green"}>
                    ${formData.monthly_pricing}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <strong>{t("annual-pricing")}: </strong>
                  <Typography fontSize={22} color={"green"}>
                    ${formData.annual_pricing}
                  </Typography>
                </Grid>
              </Grid>
              <Typography component="subtitle1" variant="h6">
                <strong>{t("cost")}: </strong>${price}
              </Typography>
              {paymentType === "annual" && formik.values.seat_count && (
                <Typography component="subtitle1" variant="h6">
                  <strong>{t("your-savings")} </strong>$
                  {(
                    formik.values.seat_count *
                    (formData.monthly_pricing - formData.annual_pricing / 12.0)
                  ).toFixed(2)}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={paymentType === "month"}
                    onChange={(e) => {
                      e.target.checked && setPaymentType("month");
                    }}
                  />
                  <Typography component="h6">{t("monthly")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={paymentType === "annual"}
                    onChange={(e) => {
                      e.target.checked && setPaymentType("annual");
                    }}
                  />
                  <Typography component="h6">{t("annually")}</Typography>
                </Box>
              </Box>
              <TextField
                sx={{ width: "80%", alignSelf: "center" }}
                type="number"
                label={t("seat-count")}
                placeholder={formData.min_seat_count}
                {...getFieldProps("seat_count")}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                error={Boolean(touched.seat_count && errors.seat_count)}
                helperText={touched.seat_count && errors.seat_count}
              />
              <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={() => setModalStatus(false)}
                >
                  {t("cancel")}
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t("subscribe")}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      );

      break;
  }

  return <>{content}</>;
}
