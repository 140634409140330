import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Branding, SupportedLanguage, PaymentOptions } from './settings/';
import { useTranslation } from 'react-i18next';
import PortalSettings from './settings/portalSettings.js/PortalSettings';
import EmailSettings from './settings/emailSettings/EmailSettings';
import Currencies from './settings/currencies/Currencies';
import { canUserReadIt } from 'src/utils/Util';
// ----------------------------------------------------------------------


export default function Settings() {
    const { t } = useTranslation();

    const TABS = [
        canUserReadIt("access_management") ? { id: 0, label: t('portal-settings'), component: <PortalSettings /> } : null,
        canUserReadIt("branding") ? { id: 1, label: t('branding'), component: <Branding /> } : null,
        { id: 2, label: t('email'), component: <EmailSettings /> },
        canUserReadIt("currencies") ? { id: 3, label: t('currencies'), component: <Currencies /> } : null,
    ].filter(Boolean);

    const TITLE = t('portal-and-white-labels');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
