import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { EngineService, UserService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useTranslation } from "react-i18next";
import UcaasUserForm from "./form/UcaasUserForm";
import DeleteUcaasUserForm from "./form/DeleteUcaasUserForm";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function AdminUser() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "firstName", label: t("first-name") },
    { key: "lastName", label: t("last-name") },
    { key: "email", label: t("email") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    first_name: { key: "firstName", index: 0 },
    last_name: { key: "lastName", index: 1 },
    email: { key: "email", index: 2 },
    id: { key: "id", cellComponentType: "th", index: 3, noRender: true },
};

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openPasswordModal, setPasswordModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});


/*   useEffect(() => {

    EngineService.listEngines()


  }, []) */

  // fetchUcaasUsers
  const fetchUcaasUser = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterName ? filterName : undefined,
    };
    UserService.listAdminUsers(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "role") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };


  useEffect(() => {
    fetchUcaasUser();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "password") {
        setPasswordModalStatus(!openPasswordModal);
      } 

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit-admin-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-admin-user"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("override-password")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="update-password"
            onClick={() => modalHandler("password", index)}
          >
            <LockResetIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-admin-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-admin-user"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("add-admin-user")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <UcaasUserForm
            formType={"add"}
            formData={{}}
            successCallback={fetchUcaasUser}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("edit-admin-user")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UcaasUserForm
            formType={"edit"}
            formData={selectedRow}
            successCallback={fetchUcaasUser}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("set-password")}
        open={openPasswordModal}
        setOpen={setPasswordModalStatus}
        children={
          <UcaasUserForm
            formType={"password"}
            formData={selectedRow}
            successCallback={fetchUcaasUser}
            setModalStatus={setPasswordModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("delete-admin-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteUcaasUserForm
            packageType="ucass"
            successCallback={fetchUcaasUser}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchUcaasUser();
                }}
              />
            </SearchButtonContainer>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <AddNewButton
              label={t("add-new-ucaas-user")}
              onClick={() => modalHandler("add")}
            />
          </Grid>
        </Grid>
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
