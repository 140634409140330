import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import CpaasContainer from './cpaasConfig/cpaasContainer/CpaasContainer';

// ----------------------------------------------------------------------


export default function CpaasContainers() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('cpaas-container'), component: <CpaasContainer /> },
    ];

    const TITLE = t('cpaas-container');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
