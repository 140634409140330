import api from "src/api/Api";
import urls from "src/api/Urls";

export const listDidTypes = (params) => {
  return api.get(urls.list_did_types, { params: params });
};

export const addDidType = (payload) => {
  return api.post(urls.add_did_type, payload);
};

export const deleteDidType = (id) => {
  return api.delete(urls.delete_did_type(id));
};

export const updateDidType = (id, payload) => {
  return api.put(urls.update_did_type(id), payload);
};



export const listTelesmartDidTypes = (params) => {
  return api.get(urls.list_telesmart_did_types, { params: params });
};


export const listDidTypeMappings = (params) => {
  return api.get(urls.list_did_type_mappings, { params: params });
};

export const addDidTypeMapping = (payload) => {
  return api.post(urls.create_did_type_mapping, payload);
};

export const updateDidTypeMapping = (id, payload) => {
  return api.put(urls.update_did_type_mapping(id), payload);
};

export const deleteDidTypeMapping = (id) => {
  return api.delete(urls.delete_did_type_mapping(id));
};

export const updateDidTypeMappingList = (params ,payload) => {
  return api.put(urls.update_did_type_mappings_list, payload, {params: params});
};
