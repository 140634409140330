import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import OutboundContainer from './outboundConfig/outboundContainer/OutboundContainer';

// ----------------------------------------------------------------------


export default function OutboundContainers() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('outbound-container'), component: <OutboundContainer /> },
    ];

    const TITLE = t('outbound-container');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
