import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useStore } from "src/store/Store";
import { ProfileService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
// ----------------------------------------------------------------------

export default function AcceptAdminInvitation() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(null);
  const [store, dispatch] = useStore();
  const [createSuccess, setCreateSuccess] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("password-is-required"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwords-must-match"))
      .required(t("confirm-password-required")),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      const payload = {
        email: email,
        token: token,
        password: values.password,
      };

      let successMessage = t("success-invitation-admin");
      let failMessage = t("fail-invitation-admin");
      ProfileService.adminAcceptInvitation(payload)
        .then((response) => {
          if (response.status !== 200) {
            throw { message: "login failed", response: response };
          }
          setMessage(successMessage);
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000);
        })
        .catch((err) => {
          setMessage(/* err.response.data.detail */ failMessage);
          setOpenSnackbar(true);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              disabled
              autoComplete="username"
              type="email"
              label={t("email-address")}
              value={email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={t("password")}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              autoComplete="confirm-password"
              type={showPassword ? "text" : "password"}
              label={t("confirm-password")}
              {...getFieldProps("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("register")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
