import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ManageSimData from "./iotServices/manageSim/ManageSimData";
// ----------------------------------------------------------------------

export default function ManageSimDatas() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("manage-sim-data"), component: <ManageSimData /> },
  ];

  const TITLE = t("iot");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
