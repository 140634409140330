import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import SubscriptionLog from './subscriptionManage/subscriptionLogs/SubscriptionLog';


// ----------------------------------------------------------------------


export default function SubscriptionLogs() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('subscription-logs'), component: <SubscriptionLog /> },      
    ];

    const TITLE = t('subscription-management');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
