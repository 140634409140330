import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import {
  ContainerService,
  TariffService,
} from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { isMobile, rowArrayToObject } from "src/utils/Util";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { useStore } from "src/store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function CpaasPackageConfig({ formData }) {
  const { t } = useTranslation();
  const [store, dispatch] = useStore();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [cpaasTariffs, setCpaasTariffs] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [gotIt, setGotIt] = useState(false);

  const TABLE_HEAD = [
    { key: "enabled", label: t("selected") },
    { key: "cpaas_package_name", label: t("cpaas-package")},
    { key: "tariff_select", label: t("cpaas-tariff") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    is_enabled: { key: "enabled", index: 1 },
    cpaas_package_name: { key: "cpaas_package_name", index: 2 },
    cpaas_tariff_id: { key: "tariff_select", index: 3 },
    cpaas_package_id: { key: "cpaas_package_id", index: 4, noRender: true },
  };

  useEffect(() => {
    fetchCpaasTariff();
    makeTable();

    setGotIt(false);
    /* if (store.cpaasGotIt) {
      setGotIt(store.cpaasGotIt);
    } */
  }, []);

  const makeTable = () => {
    let filteredPackages = [];

    ContainerService.getContainer(formData.id).then((response) => {
      if (response.status === 200) {
        response.data.container_package_tariff_mappings.map((mapping) => {
          if (
            mapping.package.name
              .toLowerCase()
              .includes(filterName.toLowerCase())
          ) {
            filteredPackages.push(mapping);
          }
        });
        let extractedData = [];
        for (const mapping of filteredPackages.sort((a, b) => a.id - b.id)) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          let packageName = mapping.package ? mapping.package.name : "";
          let packageID = mapping.package ? mapping.package.id : "";
          let tariffID = mapping.cpaas_tariff ? mapping.cpaas_tariff.id : "";

          item[TABLE_FIELD_MAPPING["cpaas_package_name"].index] = {
            ...TABLE_FIELD_MAPPING["cpaas_package_name"],
            value: packageName,
          };
          item[TABLE_FIELD_MAPPING["cpaas_package_id"].index] = {
            ...TABLE_FIELD_MAPPING["cpaas_package_id"],
            value: packageID,
          };

          item[TABLE_FIELD_MAPPING["cpaas_tariff_id"].index] = {
            ...TABLE_FIELD_MAPPING["cpaas_tariff_id"],
            value: tariffID,
          };

          Object.entries(mapping).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "cpaas_package_name" &&
              key !== "cpaas_tariff_id"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          extractedData.push(item);
        }
        setData(extractedData);
        const deepCopy = JSON.parse(JSON.stringify(extractedData));
        setInitialData(deepCopy);
      }
    });
  };

  const fetchCpaasTariff = () => {
    TariffService.listCpaasTariff({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.items.map((tariff) => ({
            value: tariff.id,
            label: tariff.name,
          }));
          setCpaasTariffs(options);
        }
      })
      .catch((err) => {
        console.error("failed to get tariffs");
      });
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        disabled={!gotIt}
        checked={data[index][1].value}
        onChange={() => handleOnChange(index)}
      />
    );
  };

  const tariffDropdownSelect = (index) => {
    return (
      <FormControl sx={{ width: isMobile() ? "30vw" : "20vw" }}>
        <Autocomplete
          /* disablePortal */
          disabled={!gotIt}
          id="combo-box-demo"
          options={cpaasTariffs}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-cpaas-tariff")} />
          )}
          value={
            cpaasTariffs.find((option) => option.value === data[index][3].value) ||
            null
          }
          onChange={(event, newValue) => {
            const newData = [...data];
            data[index][3].value = newValue?.value || null;
            setData(newData);
          }}
        />
      </FormControl>
    );
  };

  const handleOnChange = (index) => {
    const newData = data.map((item, i) => {
      if (i === parseInt(index)) {
        console.log("sadasda", item[1].value);
        item[1].value = !item[1].value;
      } else {
        item[1].value = false;
        console.log(item[1].value);
      }
      return item;
    });
    setData(newData);
  };

  const isEqual = () => {
    for (const [index, item] of data.entries()) {
      const initialItem = initialData[index];
      if (
        item[1].value !== initialItem[1].value ||
        item[3].value !== initialItem[3].value
      ) {
        return false;
      }
    }
    return true;
  };
  const saveChanges = async () => {
    setSubmitting(true);
    try {
      for (const [index, item] of data.entries()) {
        const initialItem = initialData[index];

        if (
          item[1].value !== initialItem[1].value ||
          item[3].value !== initialItem[3].value
        ) {
          const payload = {
            container_id: formData.id,
            package_id: item[4].value,
            cpaas_tariff_id: item[3].value === "" ? null : item[3].value,
            is_enabled: item[1].value,
          };
          await ContainerService.updatePackageTariffMapping(
            item[0].value,
            payload
          );
        }
      }

      const newData = JSON.parse(JSON.stringify(data));
      setInitialData(newData);
      setMessage(t("changes-saved-successfully"));
      setSnackbarStatus(true);
      setSubmitting(false);
    } catch (err) {
      setMessage(t("failed-to-save-changes"));
      setSnackbarStatus(true);
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxHeight: isMobile() ? "80vh" : "80vh",
      }}
    >
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                makeTable();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      {!gotIt && (
        <Box>
          <Typography mt={2} variant="body2" color={store.palette.error.main}>
            {t("cpaas-select-package-warning")}
          </Typography>
          <Tooltip title={t("got-it")}>
            <IconButton
              color="secondary"
              size="small"
              aria-label="got-it"
              onClick={() => {
                setGotIt(true);
                dispatch({
                  type: "UPDATE_CPAAS_GOT_IT",
                  payload: { cpaasGotIt: true },
                });
              }}
            >
              <ThumbUpAltIcon />
              <span style={{ marginLeft: "0.5rem" }}>{t("got-it")}</span>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box sx={{
        maxHeight: isMobile() ? "40vh" : "45vh",
        overflow: "auto"
      }}>
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          pagination={{}}
          loadingData={loadingData}
          enableSwitch={enableSwitch}
          tariffSelect={tariffDropdownSelect}
          smallRow={false}
        />
      </Box>
      <Box mt={"2vh"}>
        <LoadingButton
          disabled={initialData.length > 0 && isEqual()}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={() => saveChanges()}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      </Box>
    </Box>
  );
}
