import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { EngineService, PackagesService } from "src/api/services";
import { isMobile, rowArrayToObject } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ConfigOutboundPackageForm({ formData }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [engineOptions, setEngineOptions] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const TABLE_HEAD = [
    { key: "enabled", label: t("Enabled") },
    { key: "name", label: t("service") },
    { key: "engines", label: t("engine") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    is_enabled: { key: "enabled", index: 1 },
    service_name: { key: "name", index: 2 },
    engine_id: { key: "engines", index: 3 },
    service_id: { key: "service_id", index: 4, noRender: true },
  };

  const makeTable = () => {
    let filteredServices = [];
    PackagesService.getPackage(formData.id).then((response) => {
      if (response.status === 200) {
        response.data.service_engine_mappings.map((OutboundServiceMap) => {
          if (
            OutboundServiceMap.service.name
              .toLowerCase()
              .includes(filterName.toLowerCase())
          ) {
            filteredServices.push(OutboundServiceMap);
          }
        });

        let extractedData = [];
        for (const mapping of filteredServices.sort((a, b) => a.id - b.id)) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});

          let serviceName = mapping.service.name;
          let serviceID = mapping.service.id;
          let engineID = mapping.engine ? mapping.engine.id : "";

          if (mapping.service) {
            item[TABLE_FIELD_MAPPING["service_name"].index] = {
              ...TABLE_FIELD_MAPPING["service_name"],
              value: serviceName,
            };
            item[TABLE_FIELD_MAPPING["service_id"].index] = {
              ...TABLE_FIELD_MAPPING["service_id"],
              value: serviceID,
            };
          }

          if (mapping) {
            item[TABLE_FIELD_MAPPING["engine_id"].index] = {
              ...TABLE_FIELD_MAPPING["engine_id"],
              value: engineID,
            };
          }

          Object.entries(mapping).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "name" &&
              key !== "engine" &&
              key !== "service_id"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          extractedData.push(item);
        }
        setData(extractedData);
        if (initialData.length === 0) {
          const deepCopy = JSON.parse(JSON.stringify(extractedData));
          setInitialData(deepCopy);
        }
      }
    });
  };

  const outboundEngineOptions = () => {
    EngineService.listEngines({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let options = [];
          response.data.items.forEach((item) => {
            if (
              item.is_running &&
              item.engine_subtype.service_group.id === 1 /*CPaaS ID = 1*/
            ) {
              let pop = item.properties.pop;
              let engineSubtype = item.engine_subtype.name;
              let option = {
                value: item.id,
                label: pop + "/" + engineSubtype,
                supportedServices: item.engine_subtype.supported_services,
              };
              options.push(option);
            }
          });
          setEngineOptions(options);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    outboundEngineOptions();
    makeTable();
  }, []);

  const outboundEngineSelect = (index) => {
    return (
      <FormControl sx={{ width: isMobile() ? "30vw" : "20vw" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={engineOptions.filter((option) =>
            option.supportedServices.some(
              (service) => service.id === data[index][4].value
            )
          )}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-outbound-engine")} />
          )}
          value={
            engineOptions.find(
              (option) => option.value === data[index][3].value
            ) || null
          }
          onChange={(event, newValue) => {
            const newData = [...data];
            newData[index][3].value = newValue?.value || null;
            setData(newData);
            let successMessage = t(
              "outbound-service-engine-changed-successfully"
            );
            setMessage(successMessage);
            setSnackbarStatus(true);
          }}
        />
      </FormControl>
    );
  };

  const handleOnChangeEnabled = (index) => {
    if (!data[index][3].value) {
      setMessage(t("you-must-choice-an-engine-first"));
      setSnackbarStatus(true);
    } else {
      let successMessage = data[index][1].value
        ? t("outbound-service-disabled-successfully")
        : t("outbound-service-enabled-successfully");

      data[index][1].value = !data[index][1].value;
      setMessage(successMessage);
      setSnackbarStatus(true);
    }
  };
  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][1].value}
        onChange={() => handleOnChangeEnabled(index)}
      />
    );
  };

  const handleSave = (index) => {
    let payload = {
      id: data[index][0].value,
      service_id: data[index][4].value,
      engine_id: data[index][3].value,
      package_id: formData.id,
      is_enabled: data[index][1].value,
    };

    let successMessage = t("changes-are-applied-successfully");
    let failMessage = t("failed-to-apply-chaanges");

    PackagesService.updateServiceEngineMapping(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        }
      })
      .catch((err) => {
        if (!payload.engine_id) {
          failMessage = t("select-engine-error");
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].value !== arr2[i].value) return false;
    }
    return true;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <Box
      sx={{
        maxHeight: "80vh",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: "7px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
        },
      }}
    >
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                makeTable();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <Box sx={{ overflow: "none" }}>
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          actionItemPrep={getActionItems}
          pagination={{}}
          loadingData={loadingData}
          enableSwitch={enableSwitch}
          enginesSelect={outboundEngineSelect}
        />
      </Box>
    </Box>
  );
}
