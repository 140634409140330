import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import SettingsInputHdmiIcon from "@mui/icons-material/SettingsInputHdmi";

// app
import { AccountService, EngineService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { AccountChannelTypes, getLabelByValue } from "src/constants";
import { isMobile } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";

export default function CpassEngineForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
}) {
  const { t } = useTranslation();
  const [testPassed, setTestPassed] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const CpassEngineSchema = Yup.object().shape({
    formType: Yup.string(),
    pop: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("pop-is-required")),
    username: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    password: Yup.string()
      .min(6, t("too-short"))
      .required(t("password-is-required")),
    apiUrl: Yup.string().required(t("api-url-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      engine_subtype_id: formData.engine_subtype_id,
      channelType: formData.channel_type,
      pop: formType === "add" ? "" : formData.properties.pop,
      username: formType === "add" ? "" : formData.properties.username,
      password: formType === "add" ? "" : formData.properties.password,
      apiUrl: formType === "add" ? "" : formData.properties.api_url,
      isRunning: formType === "add" ? false : formData.is_running,
    },

    validationSchema: CpassEngineSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.username,
        engine_subtype_id: values.engine_subtype_id,
        engine_type: 1,
        supported_services: [],
        is_running: values.isRunning,
        properties: {
          channel_type: values.channelType,
          pop: values.pop,
          username: values.username,
          password: values.password,
          api_url: values.apiUrl,
        },
      };
      if (isTesting) {
        EngineService.testEngine(payload)
          .then((response) => {
            if (response.status === 200) {
              setTestPassed(true);
              setMessage(t("test-passed"));
              setSnackbarStatus(true);
            } else {
              setTestPassed(false);
              setMessage(t("test-failed"));
              setSnackbarStatus(true);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            actions.setSubmitting(false);
            setIsTesting(false);
          });
      } else {
        let apiService, successMessage, failMessage;
        if (formType === "add") {
          apiService = EngineService.addEngine(payload);
          successMessage = t("cpaas-engine-has-been-successfully-added");
          failMessage = t("new-cpaas-engine-could-not-be-added");
        } else {
          apiService = EngineService.updateEngine(formData.id, payload);
          successMessage = t("cpaas-engine-has-been-successfully-updated-0");
          failMessage = t("cpaas-engine-could-not-be-updated");
        }
        apiService
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setMessage(successMessage);
              setSnackbarStatus(true);
              setModalStatus(false);
              successCallback();
              actions.setSubmitting(false);
            } else {
              throw "cpass engine operation failed";
            }
          })
          .catch((err) => {
            if (err.response.data.error) {
              failMessage = `${failMessage}. ${err.response.data.error[0]}`;
            }
            if (setMessage) {
              setMessage(failMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (testPassed) {
      setTestPassed(false);
    }
  }, [formik.values]);

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "engineType") {
      return (
        <TextField
          fullWidth
          disabled
          label={t("engine-type")}
          {...getFieldProps("engineType")}
          value={formData.engine_subtype}
        />
      );
    }
    if (fieldName === "channelType") {
      return (
        <TextField
          fullWidth
          disabled
          label={t("channel-type")}
          value={getLabelByValue(AccountChannelTypes(), formData.channel_type)}
        />
      );
    }

    if (fieldName === "pop") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="pop"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("pop")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="pop"
            label={isMobile() ? t("pop") : undefined}
            placeholder={t("type-pop-here")}
            {...getFieldProps("pop")}
            error={Boolean(touched.pop && errors.pop)}
            helperText={touched.pop && errors.pop}
          />
        </Box>
      );
    }
    if (fieldName === "username") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="username"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("username")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="username"
            label={isMobile() ? t("username") : undefined}
            placeholder={t("type-username-here")}
            {...getFieldProps("username")}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
        </Box>
      );
    }
    if (fieldName === "password") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="password"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("password")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="password"
            type="password"
            label={isMobile() ? t("password") : undefined}
            placeholder={t("type-password-here")}
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
      );
    }
    if (fieldName === "apiUrl") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="apiUrl"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("api-url")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="apiUrl"
            label={isMobile() ? t("api-url") : undefined}
            placeholder={t("type-api-url-here")}
            {...getFieldProps("apiUrl")}
            error={Boolean(touched.apiUrl && errors.apiUrl)}
            helperText={touched.apiUrl && errors.apiUrl}
          />
        </Box>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
    if (fieldName === "testButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SettingsInputHdmiIcon />}
          onClick={() => setIsTesting(true)}
        >
          {t("test-engine")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("engineType")}
            {getFieldByName("channelType")}
            {getFieldByName("pop")}
            {getFieldByName("username")}
            {getFieldByName("password")}
            {getFieldByName("apiUrl")}
          </Stack>
          <br />
          <Grid container justifyContent="center">
            <Grid item>
              {formType === "view" ? null : (
                <Box>
                  {testPassed
                    ? getFieldByName("submitButton")
                    : getFieldByName("testButton")}
                </Box>
              )}
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
