import { useState } from "react";
import { Stack, TextField, IconButton, FormLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export default function FileUpload({
  item,
  label,
  uploadHandler,
  uploading,
  accept = "image/*",
}) {
  const { t } = useTranslation();
  const [attachment, setAttachment] = useState(null);

  const handleFile = (e) => {
    if (attachment) {
      if (item) {
        uploadHandler(attachment, item);
      } else {
        uploadHandler(attachment);
      }
    } else {
      const files = Array.from(e.target.files);
      const [file] = files;
      setAttachment(file);
    }
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Stack direction="row">
        <TextField
          variant="standard"
          margin="normal"
          fullWidth
          disabled
          placeholder={t("select-a-file")}
          value={attachment?.name || ""}
        />
        {attachment ? (
          <IconButton color="error" onClick={() => setAttachment(null)}>
            <DeleteIcon />
          </IconButton>
        ) : null}
        <LoadingButton
          variant="contained"
          component="label"
          loading={uploading}
          startIcon={attachment ? <FileUploadIcon /> : null}
          endIcon={attachment ? null : <SearchIcon />}
          onClick={() =>
            attachment
              ? item
                ? uploadHandler(attachment, item)
                : uploadHandler(attachment)
              : {}
          }
        >
          {attachment ? t("upload") : t("select")}
          {attachment ? null : (
            <input type="file" accept={accept} hidden onChange={handleFile} />
          )}
        </LoadingButton>
      </Stack>
    </>
  );
}
