import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import { TariffService, CommonService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import IotTariffForm from "./forms/IotTariffForm";
import DeleteIotTariffForm from "./forms/DeleteIotTariffForm";
import DeleteAnyway from "src/components/DeleteAnyway";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function IotTariff() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterName, setFilterName] = useState("");
  const [countries, setCountries] = useState([]);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "quota", label: t("quota") },
    { key: "package_price", label: t("package-price") },
    { key: "overage_price", label: t("overage-price") },
    { key: "validity_duration_text", label: t("validity-duration") },
    { key: "action", label: t("actions"), align: "right", padding: "1.7em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    quota: { key: "quota", index: 2 },
    package_price: { key: "package_price", index: 3 },
    overage_price: { key: "overage_price", index: 4 },
    validity_duration_text: { key: "validity_duration_text", index: 5 },
    validity_type: { key: "validity_type", index: 6, noRender: true },
    validity_duration: { key: "validity_duration", index: 7, noRender: true },
  };

  const fetchTariffs = () => {
    const params = {
      page: paginationData.page + 1,
      ize: paginationData.rowsPerPage,
    };
    setLoadingData(true);

    TariffService.listIotTariffs(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let duration =
            itemData.validity_duration +
            "    " +
            (itemData.validity_type === 1 ? t("month-0") : t("day-0"));
          item[TABLE_FIELD_MAPPING["validity_duration_text"].index] = {
            ...TABLE_FIELD_MAPPING["validity_duration_text"],
            value: (
              <>
                <Typography
                  color={
                    itemData.validity_type === 1
                      ? "secondary.dark"
                      : "secondary.light"
                  }
                >
                  {duration}
                </Typography>
              </>
            ),
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchTariffs();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    //changeFurkan  -ask for any necessary format
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddTariffModalStatus(!openAddTariffModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-tariff"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-sms-tariff"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchTariffs();
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          overFlow: "auto",
          maxHeight: "90vh",
          width: "35vw",
        }}
        title={t("add-new-tariff-0")}
        open={openAddTariffModal}
        setOpen={setAddTariffModalStatus}
        children={
          <IotTariffForm
            successCallback={fetchTariffs}
            formData={{}}
            formType="add"
            setModalStatus={setAddTariffModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        sx={{
          overFlow: "auto",
          maxHeight: "90vh",
          width: "35vw",
        }}
        title={t("edit-tariff-0")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <IotTariffForm
            formType="edit"
            successCallback={fetchTariffs}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        title={t("delete-tariff-0")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteIotTariffForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-iot-tariff")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchTariffs}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              TariffService.deleteOIotTariff(selectedRow.id, { proceed: true })
            }
            name={t("iot-tariff")}
            usedIn={t("iot-package")}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchTariffs();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-iot-tariff")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
