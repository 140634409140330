import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import PortalUserLog from "./auditLogs/PortalUserLog";

// ----------------------------------------------------------------------

export default function PortalUserLogs() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("portal-user-logs"), component: <PortalUserLog /> },
];

  const TITLE = t("audit-logs");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
