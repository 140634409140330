import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import NumbersProduct from "./numbersConfig/numbersProduct/NumbersProduct";
import BulkUploadProducts from "./numbersConfig/numbersProduct/BulkUploadProducts";

// ----------------------------------------------------------------------

export default function NumbersProducts() {
  const { t } = useTranslation();

  const TABS = [
    {
      id: 0,
      label: t("manage-numbers-product"),
      component: <NumbersProduct />,
    },
    { id: 1, label: t("bulk-upload"), component: <BulkUploadProducts /> },
  ];

  const TITLE = t("numbers-product");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
