import api from "src/api/Api";
import urls from "src/api/Urls";

export const addNumber = (payload) => {
  return api.post(urls.add_number, payload);
};
export const buyNumber = (payload) => {
  return api.post(urls.buy_number, payload);
};

export const uploadDocument = (file) => {
  return api.post(urls.upload_number_document, file);
};
export const listNumbersAdmin = (params) => {
  return api.get(urls.list_numbers_admin, { params: params });
};

export const listNumbers = (params) => {
  return api.get(urls.list_numbers, { params: params });
};
export const listAvailableNumbers = (params) => {
  return api.get(urls.list_available_numbers, { params: params });
};

export const searchNumbers = (params) => {
  return api.get(urls.search_numbers, { params: params });
};

export const deallocateNumber = (id) => {
  return api.delete(urls.deallocate_number(id));
};

export const updateNumber = (id, payload) => {
  return api.put(urls.update_number(id), payload);
};

export const getCountries = (params) => {
  return api.get(urls.number_enabled_countries, { params: params });
};

export const getForwardedNumbers = (params) => {
  return api.get(urls.get_forwareded_numbers, { params: params });
};

export const listVoiceRoutings = (params) => {
  return api.get(urls.list_numbers, { params: params });
};

export const listSmsRotings = (params) => {
  return api.get(urls.list_numbers, { params: params });
};

export const removeService = (id) => {
  return api.delete(urls.remove_service(id));
};
