import { id_ID } from "faker/lib/locales";
import api from "src/api/Api";
import urls from "src/api/Urls";

// Service Packages
export const listNumberProducts = (params) => {
  return api.get(urls.list_number_products, { params: params });
};

export const addNumberProduct = (payload) => {
  return api.post(urls.add_number_product, payload);
};

export const deleteNumberProduct = (id, params) => {
  params = params ? params : {proceed : false};
  return api.delete(urls.delete_number_product(id), {params});
};

export const updateNumberProduct = (id, payload) => {
  return api.put(urls.update_number_product(id), payload);
};

export const listTelesmartNumberProducts = (params) => {
  return api.get(urls.list_telesmart_number_products, { params: params });
};

export const updateTelesmartNumberProduct = (id, params) => {
  return api.put(urls.update_telesmart_number_product(id), null, {
    params: params,
  });
};

export const addTelesmartNumberProduct = (payload) => {
  return api.post(urls.add_telesmart_number_product, payload);
};


// Package Product mapping

export const listProductPackageMapping = (params) => {
  return api.get(urls.list_product_package_mappings, {params: params});
};

export const addProductPackageMapping = (payload) => {
  return api.post(urls.add_product_package_mapping, payload)
}

export const deleteProductPackageMapping = (id) => {
  return api.delete(urls.delete_product_package_mapping(id))
}



export const listProductTariffMappings = (params) => {
  return api.get(urls.list_product_tariff_mappings, {params});
};

export const addProductTariffMapping = (payload) => {
  return api.post(urls.add_product_tariff_mapping, payload)
}

export const getProductTariffMapping = (id) => {
  return api.get(urls.get_product_tariff_mapping(id));
}

export const updateProductTariffMapping = (id, payload) => {
  return api.put(urls.update_product_tariff_mapping(id), payload);
}

export const deleteProductTariffMapping = (id) => {
  return api.delete(urls.delete_product_tariff_mapping(id))
}

