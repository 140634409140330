import api from "src/api/Api";

const Reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN":
      if (payload.portal) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.portal.token}`;
        localStorage.setItem("portal", JSON.stringify(payload.portal));
      }

      if (payload.admin) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.admin.token}`;
        localStorage.setItem("admin", JSON.stringify(payload.admin));
      }

      return {
        ...state,
        portal: payload.portal,
        admin: payload.admin,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        /* portal: null,
        admin: null, */
        errorMessage: "Login failed",
      };
    case "PORTAL_LOGOUT":
      localStorage.removeItem("portal");
      return {
        ...state,
        portal: null,
      };
    case "ADMIN_LOGOUT":
      localStorage.removeItem("admin");
      return {
        ...state,
        admin: null,
        cpaasGotIt: false,
        outboundGotIt: false,
      };
    case "UPDATE_USER":
      const updatedUser = payload.user;

      const currentPortalData = state.portal;

      const newPortalData = {
        ...currentPortalData,
        user: {
          ...currentPortalData.user,
          ...updatedUser,
        },
      };
      localStorage.setItem("portal", JSON.stringify(newPortalData));

      return {
        ...state,
        portal: newPortalData,
      };

    case "CHANGE_PORTAL_PASSWORD":
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.portal.token}`;

      const updatedPortalData = {
        ...state.portal,
        token: payload.portal.token,
      };
      localStorage.setItem("portal", JSON.stringify(updatedPortalData));
      return {
        ...state,
        portal: updatedPortalData,
      };
    case "UPDATE_PALETTE":
      return {
        ...state,
        services: {
          ...state.services,
          palettes: {
            ...state.services.palettes,
            ...payload.palette,
          },
        },
        manualUpdateTriggeredForPalette:
          payload.manualUpdateTriggeredForPalette,
      };
    case "UPDATE_INITIAL_PALETTE_LOGO":
      // Store the services object in localStorage
      localStorage.setItem("services", JSON.stringify(payload.services));

      // !X! May cause bug at different tabs check later:
      localStorage.setItem("userType", payload.userType);


      return {
        ...state,
        services: {
          ...state.services,
          ...payload.services,
        },
        palette: payload.palette,
        manualUpdateTriggeredForPalette:
          payload.manualUpdateTriggeredForPalette,
        userType: payload.userType
      };

  /*   case "UPDATE_PALETTES":
      const updatedPalettes = payload.palettes;
      const currentServices = state.services;

      const newServices = {
        ...currentServices,
        palettes: {
          ...payloadPalettes,
          ...updatedPalettes,
        },
      };
      localStorage.setItem("services", JSON.stringify(newServices));

      return {
        ...state,
        services: {
          newServices
        },
      }; */
    case "UPDATE_BALANCE":
      return {
        ...state,
        balance: payload.balance,
      };
    case "UPDATE_LOGO":
      return {
        ...state,
        logo: payload.logo,
      };
    case "UPDATE_FAV_ICON":
      return {
        ...state,
        favIcon: payload.favIcon,
      };
    case "UPDATE_LANDING_IMAGE":
      return {
        ...state,
        landingImage: payload.landingImage,
      };
    case "UPDATE_SUPPORTED_LANGUAGES":
      localStorage.setItem(
        "supported_languages",
        JSON.stringify(payload.supported_languages)
      );
      return {
        ...state,
        supported_languages: payload.supported_languages,
      };
    case "UPDATE_SELECTED_LANGUAGE":
      localStorage.setItem("language", payload.language);
      return {
        ...state,
        language: payload.language,
      };

/*     case "SET_SERVICE_STATUSES":
      localStorage.setItem("services", JSON.stringify(payload.services));
      return {
        ...state,
        portalSettings: {
          ...state.portalSettings,
          services: payload.services,
        },
      };
 */
/*     case "UPDATE_SERVICE_ID":
      localStorage.setItem("serviceID", payload.serviceID);
      return {
        ...state,
        serviceID: payload.serviceID,
      }; */
    case "UPDATE_CUSTOMER_ID":
      return {
        ...state,
        customerID: payload.customerID,
      };
    case "UPDATE_CPAAS_GOT_IT":
      return {
        ...state,
        cpaasGotIt: payload.cpaasGotIt,
      };

    case "UPDATE_OUTBOUND_GOT_IT":
      return {
        ...state,
        outboundGotIt: payload.outboundGotIt,
      };

    case "UPDATE_PACKAGE_ID":
      return {
        ...state,
        packageID: payload.packageID,
      };

    case "UPDATE_USER_TYPE":
      return {
        ...state,
        userType: payload.userType,
      };


    default:
      return state;
  }
};

export default Reducer;
