import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  styled,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import BaseSnackbar from "src/components/BaseSnackbar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTranslation } from "react-i18next";
import { CustomerService, UcManagementService } from "src/api/services";
import { useStore } from "src/store/Store";

const FileInput = styled("input")({
  display: "none",
});

export default function BulkUploadProducts() {
  const [store, dispatch] = useStore();
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState(",");
  const [fileName, setFileName] = useState("Choose a file!");
  /*   const [customerOptions, setCustomerOptions] = useState([]); */
  /*   const [selectedCustomer, setSelectedCustomer] = useState(null); */

  /*   const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };
 */
  /*   useEffect(() => {
    fetchCustomerNames();
    if(store.customerID){
      setSelectedCustomer(store.customerID);
      dispatch({ type: 'UPDATE_CUSTOMER_ID', payload: { customerID: null } });
    }
  }, []); */

  const PackageSchema = Yup.object().shape({
    customerName: Yup.string()
      .required(t("customer-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    file: Yup.mixed().required(t("file-is-required")),
  });

  let sampleData =
    "Name,Country,DID Type,Capability,Compliance Rule,Active\n" +
    "Product1,USA,Mobile,Voice,example rule,True\n" +
    "Product2,Canada,Geographic,SMS,example rule,True\n" +
    "Product3,UK,Mobile,Voice + SMS,example rule,False\n" +
    "Product4,Australia,Geographic,Voice,example rule,True\n" +
    "Product5,Germany,Mobile,SMS,example rule,False\n" +
    "Product6,France,Geographic,Voice + SMS,example rule,True\n" +
    "Product7,Italy,Mobile,Voice,example rule,True\n" +
    "Product8,Spain,Geographic,SMS,example rule,False\n" +
    "Product9,Japan,Mobile,Voice + SMS,example rule,True\n" +
    "Product10,Brazil,Geographic,Voice,example rule,False\n" +
    "Product11,India,Mobile,SMS,example rule,True\n" +
    "Product12,China,Geographic,Voice + SMS,example rule,True\n" +
    "Product13,Russia,Mobile,Voice,example rule,False\n" +
    "Product14,South Africa,Geographic,SMS,example rule,True";

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      const uploadedFileName = uploadedFile.name;
      setFile(uploadedFile);
      setFileName(uploadedFileName);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage(t("please-select-file-to-uplaod"));
      setSnackbarStatus(true);
      return;
    }

    const params = {
      delimiter: delimiter,
    };
    const payload = new FormData();
    payload.append("csv_file", file);

    UcManagementService.bulkUploadUcUser(params, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("file-uploaded-successfully"));
          setSnackbarStatus(true);
        } else {
          setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
          setSnackbarStatus(true);
        }
      })
      .catch((err) => {
        setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
        setSnackbarStatus(true);
      });
  };

  const handleDelimiterChange = (event) => {
    setDelimiter(event.target.value);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          border: "0.2px solid white",
          borderRadius: 5,
          boxShadow: 10,
          width: "100%",
        }}
      >
        <Box
          className="functionalities"
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/*           <Box sx={{ mb: 5 }}>
            <InputLabel id="delimiter-label">{t("select-customer")}</InputLabel>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Customer" />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Box> */}
          <Box
            className="DelimiterPart"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="delimiter-label">{t("delimiter")}</InputLabel>
            <Select
              labelId="delimiter-label"
              id="delimiter"
              value={delimiter}
              onChange={handleDelimiterChange}
            >
              <MenuItem value={","}>{t("comma")} ( , )</MenuItem>
              <MenuItem value={";"}>{t("semicolon")} ( ; )</MenuItem>
            </Select>
          </Box>
          <Box
            className="FileUpload"
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 5,
            }}
          >
            <InputLabel id="upload-label">{t("file-upload")}</InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField fullWidth value={fileName} sx={{ height: "100%" }} />
              </Grid>
              <Grid item xs={5} md={2}>
                <label htmlFor="file" style={{ height: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{ mt: 1, height: "90%", width: "100%" }}
                  >
                    {t("browse")}
                  </Button>
                  <FileInput
                    id="file"
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileUpload}
                  />
                </label>
              </Grid>
              <Grid item xs={5} md={4}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleSubmit}
                  sx={{ mt: 1, ml: 2, height: "90%", width: "100%" }}
                >
                  <UploadFileIcon /> {t("upload")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          className="sampleDataFile"
          sx={{
            width: "60%",
            ml: 5,
          }}
        >
          <InputLabel id="sampleData-label" sx={{ fontSize: 20 }}>
            {t("example-data-file")}
          </InputLabel>

          <textarea
            readOnly
            defaultValue={sampleData}
            style={{ width: "100%", height: "100%", fontSize: 14 }}
          />
        </Box>
      </Box>
    </>
  );
}
