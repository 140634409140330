import React from "react";
import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import BaseSnackbar from "src/components/BaseSnackbar";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function SynchronizeTypes({
  formData,
  setMessage,
  setModalStatus,
  successCallback,
  setSnackbarStatus,
}) {
  console.log(formData);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [currentProviderTypes, setCurrentProviderTypes] = useState([]);
  const [savedProviderTypes, setSavedProviderTypes] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [name, setName] = useState(formData ? formData.engine_subtype : "");

  const fetchDidTypes = () => {
    let savedTypes = [
      {
        id: 1,
        name: "Mobile",
      },
      {
        id: 2,
        name: "Geographic",
      },
      {
        id: 3,
        name: "Toll Free",
      },
    ];

    let currentTypes = [
      {
        id: 1,
        name: "Mobile",
      },
      {
        id: 2,
        name: "Geographic",
      },
      {
        id: 3,
        name: "Toll Free",
      },
      {
        id: 4,
        name: "Test",
      },
    ];

    setCurrentProviderTypes(currentTypes);
    setSavedProviderTypes(savedTypes);
  };

  useEffect(() => {
    fetchDidTypes();
  }, []);

  const handleAddType = (type) => {
    let updatedProviderTypes = [...savedProviderTypes, type];
    setSavedProviderTypes(updatedProviderTypes);
  };

  const handleSubmit = () => {
    setIsSubmiting(true);

    let successMessage = t("types-saved-succes", { name });
    let failMessage = t("types-saved-fail", { name });

    setTimeout(() => {
      setIsSubmiting(false);
      setMessage(successMessage);
      setSnackbarStatus(true);
      setModalStatus(false);
    }, 1200);
  };

  return (
    <>
      <br />
      <Card sx={{ width: "100%", height: "100%", overflow: "auto" }}>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("saved-did-types")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{t("did-types-2", { name })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProviderTypes.map((type) => (
                  <TableRow key={type.id}>
                    <TableCell>
                      {savedProviderTypes.find((item) => item.id === type.id)
                        ? savedProviderTypes.find((item) => item.id === type.id)
                            .name
                        : " "}
                    </TableCell>
                    <TableCell>
                      {savedProviderTypes.find(
                        (item) => item.id === type.id
                      ) ? (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <CheckCircleIcon />
                        </span>
                      ) : (
                        <Tooltip title={t("match-type")}>
                          <IconButton
                            color="secondary"
                            aria-label="match-type"
                            onClick={() => handleAddType(type)}
                          >
                            <ArrowCircleLeftIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{type.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <LoadingButton
        sx={{ mt: 2 }}
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmiting}
        startIcon={<SaveIcon />}
        onClick={() => handleSubmit()}
      >
        {t("common.__i18n_ally_root__.save")}
      </LoadingButton>
    </>
  );
}
