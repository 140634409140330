import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { CustomerService, ReportsService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import { formatDate } from "src/utils/Util";
import { FormControl, Grid, TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useStore } from "src/store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function SubscriptionLog() {
  const [store] = useStore();
  const { t } = useTranslation();
  const [serviceGroups] = useState(
    store.services.serviceGroups.sort((a, b) => a - b) ?? []
  );

  const TABLE_HEAD = [
    { key: "subcsription_name", label: t("subscription-package") },
    { key: "customer_name", label: t("customer") },
  ];

  const tableColumns = {
    1: { key: "cpaas_container", label: t("cpaas-container") },
    2: { key: "uc_container", label: t("uc-container") },
    3: { key: "number_container", label: t("numbers-container") },
    4: { key: "outbound_container", label: t("outbound-container") },
    5: { key: "iot_container", label: t("iot-container") },
  };

  serviceGroups.forEach((group) => {
    const column = tableColumns[group];
    if (column) {
      TABLE_HEAD.splice(-1, 0, column); // Insert the column just before the last one
    }
  });

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    subcsription_name: { key: "subcsription_name", index: 1 },
    customer_name: { key: "customer_name", index: 2 },
    cpaas_container: {
      key: "cpaas_container",
      index: 3,
      noRender: !serviceGroups.includes(1),
    },
    uc_container: {
      key: "uc_container",
      index: 4,
      noRender: !serviceGroups.includes(2),
    },

    numbers_container: {
      key: "numbers_container",
      index: 5,
      noRender: !serviceGroups.includes(3),
    },
    outbound_container: {
      key: "outbound_container",
      index: 6,
      noRender: !serviceGroups.includes(4),
    },
    customer_id: {
      key: "customer_id",
      index: 7,
      noRender: true,
      noRender: !serviceGroups.includes(5),
    },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterCustomer, setFilterCustomer] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  const fetchReports = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      customer_id: filterCustomer ? filterCustomer.id : undefined,
    };
    ReportsService.listSubscriptionLogs(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let reportID = itemData.id;
          item[TABLE_FIELD_MAPPING["id"].index] = {
            ...TABLE_FIELD_MAPPING["id"],
            value: reportID,
          };
          if (itemData.customer) {
            let customerName = itemData.customer.name;
            item[TABLE_FIELD_MAPPING["customer_name"].index] = {
              ...TABLE_FIELD_MAPPING["customer_name"],
              value: customerName,
            };
          }
          if (itemData.service_package) {
            let subcsriptionName = itemData.service_package.name;
            let ucContainer = itemData.service_package.uc_container.name;
            let cpaasContainer = itemData.service_package.cpaas_container.name;
            let numbersContainer =
              itemData.service_package.number_container.name;
            let outboundContainer =
              itemData.service_package.outbound_container.name;
            item[TABLE_FIELD_MAPPING["subcsription_name"].index] = {
              ...TABLE_FIELD_MAPPING["subcsription_name"],
              value: subcsriptionName,
            };
            item[TABLE_FIELD_MAPPING["uc_container"].index] = {
              ...TABLE_FIELD_MAPPING["uc_container"],
              value: ucContainer,
            };
            item[TABLE_FIELD_MAPPING["cpaas_container"].index] = {
              ...TABLE_FIELD_MAPPING["cpaas_container"],
              value: cpaasContainer,
            };
            item[TABLE_FIELD_MAPPING["numbers_container"].index] = {
              ...TABLE_FIELD_MAPPING["numbers_container"],
              value: numbersContainer,
            };
            item[TABLE_FIELD_MAPPING["outbound_container"].index] = {
              ...TABLE_FIELD_MAPPING["outbound_container"],
              value: outboundContainer,
            };
          }
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchReports();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  useEffect(() => {
    fetchCustomerNames();
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "processed_at") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  return (
    <>
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={customerOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Customer" />
                )}
                value={
                  customerOptions.find(
                    (option) => option.value === filterCustomer?.id
                  ) || null
                }
                onChange={(event, newValue) => {
                  setFilterCustomer({
                    id: newValue?.value || null,
                    name: newValue?.label || null,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchReports();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
