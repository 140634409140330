import { useEffect, useState } from "react";
import {
  CustomerStatuses,
  DefaultPaginationData,
  getLabelByValue,
} from "src/constants/index";
import { useStore } from "src/store/Store";
import {
  CustomerService,
  UcManagementService,
} from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from "src/components/buttons/SearchButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Box,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function UcServices({ changeTab }) {
  const { t } = useTranslation();

  useEffect(() => {
    changeTab(1, null);
  }, []);

  const TABLE_HEAD = [
    { key: "serviceName", label: t("service-name") },
    { key: "customer", label: t("customer") },
    { key: "user_count", label: t("uc-users-count") },
    { key: "package_count", label: t("total_package_count") },
    { key: "action", label: t("actions"), align: "right", padding: "1.8em" },
  ];

  const TABLE_FIELD_MAPPING = {
    name: { key: "serviceName", index: 0 },
    customer_name: { key: "customer", index: 1 },
    used_seat_count: { key: "user_count", index: 2 },
    seat_count: { key: "package_count", index: 3 },
    id: { key: "id", cellComponentType: "th", index: 4, noRender: true },
    uc_service_type: { key: "uc_service_type", index: 5, noRender: true },
  };

  const [store, dispatch] = useStore();
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const fetchCustomerOptions = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  useEffect(() => {
    fetchCustomerOptions();

    if (store.customerID) {
      setSelectedCustomer(store.customerID);
      dispatch({ type: "UPDATE_CUSTOMER_ID", payload: { customerID: null } });
    }
  }, []);

  const fetchServices = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      customer_id: selectedCustomer,
    };

    UcManagementService.listUcServices(params)
      .then((response) => {
        
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          console.log(itemData)
          item[TABLE_FIELD_MAPPING["customer_name"].index] = {
            ...TABLE_FIELD_MAPPING["customer_name"],
            value: itemData.customer.name,
          };

          // There is gonna be exceptions. Probably ucUsers ucPackages
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          
          items.push(item);
        }

        setData(items);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };


  useEffect(() => {
    fetchServices();
    return () => {
      setData([]);
    };
  }, [paginationData, selectedCustomer]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(CustomerStatuses(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("manage-uc-users")} sx={{ mr: 1 }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="link-uc-users"
            onClick={() =>
              changeTab(3, rowArrayToObject(data[index]).id, selectedCustomer)
            }
          >
            <GroupIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-customer")}
                  sx={{ backgroundColor: "white" }}
                />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchServices();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <Box sx={{ mb: "4vh" }} />
      {selectedCustomer ? (
        <BaseTable
          head={TABLE_HEAD}
          data={[...data].map((d, idx) => {
            return formatRowData(d);
          })}
          actionItemPrep={getActionItems}
          pagination={{
            paginationData: { ...paginationData, totalCount: totalCount },
            setPaginationData: setPaginationData,
          }}
          loadingData={loadingData}
        />
      ) : (
        <StyledBox>Please select customer</StyledBox>
      )}
    </>
  );
}
