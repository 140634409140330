import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useEffect, useState } from "react";
// material
import {
  Grid,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  List,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { CampaignManagementService, DtmfMenuService } from "src/api/services";

export default function DtmfFileUploadForm({
  formData,
  announcementData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const dtmf_menus_id = formData?.id;

  const PartitionSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const [dtmfMenuConfiugration, setdtmfMenuConfiugration] = useState(
    formData
      ? formData.configuration
      : {
          announcement_id: "",
          menus: [],
        }
  );
  const formik = useFormik({
    initialValues: {
      name: formData?.name,
    },
    validationSchema: PartitionSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        configuration: dtmfMenuConfiugration,
      };
      const successMessage = t("DTMF-menu-process-success");
      const failMessage = t("DTMF-menu-process-fail");
      if (dtmf_menus_id) {
        DtmfMenuService.updateDtmfMenu(dtmf_menus_id, payload)
          .then((response) => {
            if (199 <= response.status <= 300) {
              if (setMessage) {
                setMessage(successMessage);
              }
              if (successCallback) {
                successCallback(response);
              }
            } else {
              throw "Updating DTMF menu operation failed";
            }
          })
          .catch((err) => {
            if (setMessage) {
              setMessage(failMessage);
            }
          })
          .finally(() => {
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            actions.setSubmitting(false);
          });
      } else {
        DtmfMenuService.addDtmfMenu(payload)
          .then((response) => {
            if (199 <= response.status <= 300) {
              if (setMessage) {
                setMessage(successMessage);
              }
              if (successCallback) {
                successCallback(response);
              }
            } else {
              throw "Adding DTMF menu operation failed";
            }
          })
          .catch((err) => {
            if (setMessage) {
              setMessage(failMessage);
            }
          })
          .finally(() => {
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            actions.setSubmitting(false);
          });
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const addMenuItems = () => {
    setdtmfMenuConfiugration((config) => {
      return {
        announcement_id: config.announcement_id,
        menus: [
          ...config.menus,
          {
            number: `${config.menus.length + 1}`,
            action: "announcement",
            action_value: "",
          },
        ],
      };
    });
  };

  const cloneMenuConfiguration = (config) => {
    return {
      announcement_id: config.announcement_id,
      menus: [...config.menus],
    };
  };

  const removeMenuItems = (index) => {
    setdtmfMenuConfiugration((config) => {
      config.menus.splice(index, 1);
      return cloneMenuConfiguration(config);
    });
  };

  const dtmfMenuOnchange = (value, index, type) => {
    setdtmfMenuConfiugration((config) => {
      let item = config.menus[index];
      if (type === "number") {
        item.number = value;
      } else if (type === "action") {
        if (value !== item.action) {
          item.action_value = "";
        }
        item.action = value;
      } else if (type === "action_value") {
        item.action_value = value;
      }
      config.menus[index] = item;
      return cloneMenuConfiguration(config);
    });
  };

  const getFieldByName = (fieldName) => {
    if (fieldName === "dtmf-menu") {
      return (
        <FormControl sx={{ ml: 1, mt: 1 }}>
          <FormLabel sx={{ textAlign: "left" }}>{t("dtmf-menu")}</FormLabel>
          <TextField
            fullWidth
            label={t("name")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <br />
          <FormLabel sx={{ textAlign: "left" }}>
            {t("dtmf-menu.announcement")}
          </FormLabel>
          <Select
            value={dtmfMenuConfiugration.announcement_id}
            color="secondary"
            onChange={(event) => {
              setdtmfMenuConfiugration((config) => {
                config.announcement_id = event.target.value;
                return cloneMenuConfiguration(config);
              });
            }}
          >
            {announcementData.map((item, i) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
          <br />
          <LoadingButton
            sx={{ mb: 2 }}
            size="small"
            type="button"
            variant="contained"
            onClick={addMenuItems}
            startIcon={<AddIcon />}
          >
            {t("dtmf-menu.menu")}
          </LoadingButton>
          <Paper style={{ maxHeight: 200, overflow: "auto" }}>
            <List>
              {dtmfMenuConfiugration.menus.map((item, i) => {
                return (
                  <Grid sx={{ alignItems: "center" }} container>
                    <TextField
                      sx={{
                        width: 50,
                      }}
                      value={item.number}
                      size="small"
                      label={t("key")}
                      margin="normal"
                      variant="outlined"
                      color="secondary"
                      onChange={(event) => {
                        dtmfMenuOnchange(event.target.value, i, "number");
                      }}
                    />
                    <Select
                      sx={{
                        width: 180,
                        ml: 1,
                      }}
                      size="small"
                      value={item.action}
                      onChange={(event) => {
                        dtmfMenuOnchange(event.target.value, i, "action");
                      }}
                    >
                      <MenuItem value={"announcement"}>
                        {t("announcement")}
                      </MenuItem>
                      <MenuItem value={"call_forwarding"}>
                        {t("call-forwarding")}
                      </MenuItem>
                      <MenuItem value={"hangup"}>{t("hang-up")}</MenuItem>
                    </Select>
                    {item.action === "announcement" ? (
                      <Select
                        sx={{
                          width: 220,
                          ml: 1,
                        }}
                        size="small"
                        value={item.action_value}
                        onChange={(event) => {
                          dtmfMenuOnchange(
                            event.target.value,
                            i,
                            "action_value"
                          );
                        }}
                      >
                        {announcementData.map((item, i) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    ) : item.action === "call_forwarding" ? (
                      <TextField
                        sx={{
                          width: 220,
                          ml: 1,
                        }}
                        value={item.action_value}
                        size="small"
                        label={t("to-number")}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                          dtmfMenuOnchange(
                            event.target.value,
                            i,
                            "action_value"
                          );
                        }}
                      />
                    ) : null}

                    <LoadingButton
                      sx={{
                        ml: 1,
                      }}
                      type="button"
                      variant="contained"
                      onClick={() => {
                        removeMenuItems(i);
                      }}
                      color="error"
                      startIcon={<RemoveIcon />}
                    ></LoadingButton>
                  </Grid>
                );
              })}
            </List>
          </Paper>
        </FormControl>
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>{getFieldByName("dtmf-menu")}</Stack>
        <br />
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
