import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import OutboundCountries from './settings/countrySettings/outbounCountries/OutboundCountries';
// ----------------------------------------------------------------------


export default function OutboundSettings() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('outbound-countries'), component: <OutboundCountries /> },
    ];

    const TITLE = t('outbound-settings');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
