import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Divider,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { TariffService, PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

export default function IotTariffForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType,
  successCallback,
}) {
  const { t } = useTranslation();

  const [method, setMethod] = useState(
    formData.validity_type === 2 ? "day" : "month"
  );

  const IotTariffSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    quota: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("price-is-required")),
    packagePrice: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("price-is-required")),
    overagePrice: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("price-is-required")),
    dailyDuration: Yup.number().typeError(t("must-be-number")).nullable(true),
    monthlyDuration: Yup.number().typeError(t("must-be-number")).nullable(true),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      quota: formData.quota || null,
      packagePrice: formData.package_price || null,
      overagePrice: formData.overage_price || null,
      monthlyDuration:
        formData.validity_type === 1 ? formData.validity_duration : 0.0,
      dailyDuration:
        formData.validity_type === 2 ? formData.validity_duration : 0.0,
    },

    validationSchema: IotTariffSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        quota: Number(values.quota),
        package_price: Number(values.packagePrice),
        overage_price: Number(values.overagePrice),
        validity_duration:
          method === "month"
            ? Number(values.monthlyDuration)
            : Number(values.dailyDuration),
        validity_type: method === "month" ? 1 : 2,
      };

      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = TariffService.addIotTariff(payload);
        successMessage = t("new-tariff-has-been-successfully-added");
        failMessage = t("new-tariff-could-not-be-added");
      } else {
        apiService = TariffService.updateIotTariff(formData.id, payload);
        successMessage = t("tariff-has-been-successfully-updated");
        failMessage = t("tariff-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "quota") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="quota"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("quota")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="quota"
            label={isMobile() ? t("quota") : undefined}
            {...getFieldProps("quota")}
            placeholder="0"
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.quota && errors.quota)}
            helperText={touched.quota && errors.quota}
          />
        </Box>
      );
    }
    if (fieldName === "packagePrice") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="packagePrice"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("package-price")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="packagePrice"
            label={isMobile() ? t("package-price") : undefined}
            placeholder="0.0"
            {...getFieldProps("packagePrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.packagePrice && errors.packagePrice)}
            helperText={touched.packagePrice && errors.packagePrice}
          />
        </Box>
      );
    }
    if (fieldName === "overagePrice") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="overagePrice"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("overage-price")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="overagePrice"
            label={isMobile() ? t("overage-price") : undefined}
            placeholder="0.0"
            {...getFieldProps("overagePrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.overagePrice && errors.overagePrice)}
            helperText={touched.overagePrice && errors.overagePrice}
          />
        </Box>
      );
    }

    if (fieldName === "duration") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box>
            <Typography color={"secondary"}>{t("duration")}</Typography>
          </Box>
          <Box>
            <Divider sx={{ width: "20em", marginTop: 1 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", width: "50%" }}>
              <Checkbox
                checked={method === "month"}
                onChange={(e) => {
                  e.target.checked && setMethod("month");
                }}
              />
              <TextField
                fullWidth
                label={t("duration-month")}
                disabled={method === "day"}
                {...getFieldProps("monthlyDuration")}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "." &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                error={Boolean(
                  touched.monthlyDuration && errors.monthlyDuration
                )}
                helperText={touched.monthlyDuration && errors.monthlyDuration}
              />
            </Box>

            <Box sx={{ display: "flex", width: "50%" }}>
              <Checkbox
                checked={method === "day"}
                onChange={(e) => {
                  e.target.checked && setMethod("day");
                }}
              />
              <TextField
                fullWidth
                label={t("duration-days")}
                disabled={method === "month"}
                {...getFieldProps("dailyDuration")}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "." &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                error={Boolean(touched.dailyDuration && errors.dailyDuration)}
                helperText={touched.dailyDuration && errors.dailyDuration}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("quota")}
            {getFieldByName("packagePrice")}
            {getFieldByName("overagePrice")}
            {getFieldByName("duration")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
