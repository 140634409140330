import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject, formatDate } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import { NumberTariffForm, DeleteTariffForm } from "./forms";
import { TariffService, CurrencyService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import DeleteAnyway from "src/components/DeleteAnyway";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumberTariff() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "currency", label: t("currency") },
    { key: "nrc", label: t("NRC") },
    { key: "mrc", label: t("MRC") },
    { key: "initial_time", label: t("initial-time") },
    { key: "rounding", label: t("rounding") },
    {
      key: "rates",
      label: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>{t("rates")}</Box>
          <Box sx={{ width: "12rem" }}>{t("peak-offpeak-weekend")}</Box>
        </Box>
      ),
    },
    {
      key: "origin_rates",
      label: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "8rem" }}>{t("mobile-price")}</Box>
          <Box>{t("origin-rates")}</Box>
        </Box>
      ),
    },
    { key: "connect_fee", label: t("connect-fee") },
    { key: "port_in_fee", label: t("port-in-fee") },
    { key: "port_out_fee", label: t("port-out-fee") },
    { key: "sms_rate", label: t("sms-rate") },
    { key: "sms_rate_threshold", label: t("sms-rate-threshold") },

    { key: "effective_start", label: t("effective-start") },
    { key: "action", label: t("actions"), align: "right", padding: "1.8em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    currency : { key: "currency", index: 2 },
    nrc: { key: "nrc", index: 3 },
    mrc: { key: "mrc", index: 4 },
    initial_time: { key: "initialTime", index: 6, align: "center" },
    rounding: { key: "rounding", index: 5, align: "center" },
    rates: { key: "rates", index: 7 },
    origin_rates: { key: "origin_rates", index: 8 },
    connect_fee: { key: "connect_fee", index: 9 },
    port_in_fee: { key: "port_in_fee", index: 10 },
    port_out_fee: { key: "port_out_fee", index: 11 },
    sms_rate: { key: "sms_rate", index: 12 },
    sms_rate_threshold: { key: "sms_rate_threshold", index: 13 },
    effective_start: { key: "effectiveStart", index: 14 },
    peak_rate: { key: "peak_rate", index: 15, noRender: true },
    off_peak_rate: { key: "off_peak_rate", index: 16, noRender: true },
    weekend_rate: { key: "weekend_rate", index: 17, noRender: true },
    mobile_origin_rate: {
      key: "mobile_origin_rate",
      index: 18,
      noRender: true,
    },
    payphone_origin_rate: {
      key: "payphone_origin_rate",
      index: 19,
      noRender: true,
    },
    currency_id: {
      key: "currency_id",
      index: 20,
      noRender: true,
    },
  };

  const fetchTariffs = () => {
    const params = {
      currency: selectedCurrency,
      name: filterName,
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    TariffService.listNumberTariffs(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let peakRate = itemData.peak_rate;
          let offPeakRate = itemData.off_peak_rate;
          let weekendRate = itemData.weekend_rate;

          item[TABLE_FIELD_MAPPING["rates"].index] = {
            ...TABLE_FIELD_MAPPING["rates"],
            value: (
              <Box ml={"4vw"}>
                {peakRate + " / " + offPeakRate + " / " + weekendRate}
              </Box>
            ),
          };

          let mobileOriginRate = itemData.mobile_origin_rate;
          let payPhoneRate = itemData.payphone_origin_rate;

          item[TABLE_FIELD_MAPPING["origin_rates"].index] = {
            ...TABLE_FIELD_MAPPING["origin_rates"],
            value: (
              <Box
                sx={{
                  marginLeft: "3vw",
                }}
              >
                {" "}
                {mobileOriginRate + " / " + payPhoneRate}
              </Box>
            ),
          };

          let currencyName = itemData.currency ? itemData.currency.name : "";
          item[TABLE_FIELD_MAPPING["currency"].index] = {
            ...TABLE_FIELD_MAPPING["currency"],
            value: currencyName,
          }

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "currency") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchTariffs();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  useEffect(() => {
    CurrencyService.listCurrencies()
      .then((response) => {
        let active_currencies = [];
        response.data.forEach((item) => {
          if (item.is_active) {
            active_currencies.push(item);
          }
        });
        const options = active_currencies.map((currency) => ({
          value: currency.currency_id,
          label: currency.name,
        }));
        setCurrencies(options);
      })
      .catch((err) => {
        console.log("Error fetching currencies:", err);
        throw err;
      });
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "effectiveStart") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "effectiveEnd") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddTariffModalStatus(!openAddTariffModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchTariffs();
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-tariff"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-sms-tariff"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          width: "50vw",
        }}
        title={t("add-new-numbers-tariff")}
        open={openAddTariffModal}
        setOpen={setAddTariffModalStatus}
        children={
          <NumberTariffForm
            successCallback={fetchTariffs}
            formType="add"
            formData={{}}
            setModalStatus={setAddTariffModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            currencies={currencies}
          />
        }
      />
      <BaseModal
        sx={{
          width: "50vw",
        }}
        title={t("edit-tariff-0")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <NumberTariffForm
            formType="edit"
            successCallback={fetchTariffs}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            currencies={currencies}
          />
        }
      />
      <BaseModal
        title={t("delete-tariff-0")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteTariffForm
            tariffType="number"
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-numbers-tariff")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchTariffs}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              TariffService.deleteNumberTariff(selectedRow.id, { proceed: true })
            }
            name={t("numbers-tariff")}
            usedIn={t("numbers-package")}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth sx={{ mb: "1.5vh" }}>
              <Autocomplete
                id="combo-box-demo"
                options={currencies}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      selectedCurrency ? t("currency") : t("select-currency")
                    }
                  />
                )}
                value={
                  currencies.find(
                    (option) => option.value === selectedCurrency
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setSelectedCurrency(newValue.value);
                  } else {
                    setSelectedCurrency(null);
                  }
                }}
              />
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchTariffs();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-numbers-tariff")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
