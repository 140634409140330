import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Tooltip,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { DefaultPaginationData } from "src/constants/index";
import { EngineService, ProductsService } from "src/api/services";
import { NumbersEngineTypes } from "src/constants";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumbersPackageConfig({ formData }) {
  const { t } = useTranslation();
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(-1);
  const [filterName, setFilterName] = useState("");
  const [providerOptions, setProviderOptions] = useState([]);

  const [initialPackageProducts, setInitialPackageProducts] = useState(() =>
    formData?.package_product
      ? formData.package_product.map((item) => ({
          id: item.id,
          product_id: item.product_id,
        }))
      : []
  );

  const [packageProducts, setPackageProducts] = useState(
    initialPackageProducts.map((item) => item.product_id)
  );

  const TABLE_HEAD = [
    { key: "enabled", label: t("Enabled")},
    { key: "name", label: t("product") },
    { key: "provider", label: t("inbound-provider") },
    { key: "actions", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    enabled: { key: "enabled", index: 1 },
    name: { key: "name", index: 2 },
    provider: { key: "provider", index: 3 },
  };

  const fetchProducts = () => {
    setLoadingData(true);
    const params = {
      size: 100,
      /* engine: selectedEngine ? selectedEngine : undefined, */
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
    };

    ProductsService.listNumberProducts(params)
      .then((response) => {
        let values = [];
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let enabled = packageProducts.some((id) => id === itemData.id);

          let provider =
            itemData.filter_providers.length > 0 ? (
              <>
                <ol>
                  {itemData.filter_providers.map(
                    (item) =>
                      item.filter_enable && (
                        <li>
                          {item.provider.engine_subtype.name +
                            " / " +
                            item.provider.properties.pop}
                        </li>
                      )
                  )}
                </ol>
              </>
            ) : (
              ""
            );
          item[TABLE_FIELD_MAPPING["provider"].index] = {
            ...TABLE_FIELD_MAPPING["provider"],
            value: provider,
          };

          item[TABLE_FIELD_MAPPING["enabled"].index] = {
            ...TABLE_FIELD_MAPPING["enabled"],
            value: enabled,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          if (itemData.name.toLowerCase().includes(filterName.toLowerCase())) {
            values.push(item);
          }
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const fetchProviders = () => {
    EngineService.listEngines({ size: 100 }).then((response) => {
      if (response.status === 200) {
        let options = response.data.items
          .filter((provider) => provider.engine_subtype.service_group.id === 3)
          .map((provider) => ({
            value: provider.id,
            label:
              provider.properties.pop + " / " + provider.engine_subtype.name,
            enumValue: /* provider.engine_subtype.value */ 31,
          }));
        options.unshift({
          value: -1,
          label: t("common.form.any"),
          enumValue: -1,
        });
        setProviderOptions(options);
      }
    });
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [selectedProvider]);

  const handleOnChangeEnabled = (index) => {
    /*     let successMessage = data[index][1].value
      ? t("numbers-product-disabled-successfully")
      : t("numbers-product-enabled-successfully");
    let failMessage = data[index][1].value
      ? t("numbers-product-could-not-be-disabled-0")
      : t("numbers-product-could-not-be-enabled"); */

    let newData = [...data];
    newData[index][1].value = !data[index][1].value;
    setData(newData);

    if (data[index][1].value) {
      setPackageProducts([...packageProducts, data[index][0].value]);
    } else {
      setPackageProducts((prevProducts) =>
        prevProducts.filter((item) => item !== newData[index][0].value)
      );
    }

    /* 
    if (data[index][1].value) {
        setPackageProducts((prevProducts) => [...prevProducts, data[index][0].value]);
    } else {
        setPackageProducts((prevProducts) => prevProducts.filter((item) => item.product_id !== data[index][0].value));
    } */
  };

  const handleSave = (index) => {
    let successMessage = data[index][1].value
      ? t("numbers-product-enabled-successfully")
      : t("numbers-product-disabled-successfully");
    let failMessage = data[index][1].value
      ? t("numbers-product-could-not-be-enabled")
      : t("numbers-product-could-not-be-disabled-0");

    if (data[index][1].value) {
      let payload = {
        package_id: formData.id,
        product_id: data[index][0].value,
      };
      ProductsService.addProductPackageMapping(payload)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setInitialPackageProducts((prevData) => [
              ...prevData,
              {
                id: response.data.id,
                product_id: data[index][0].value,
              },
            ]);
          } else {
            throw "fail";
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
        });
    } else {
      let mappingID = initialPackageProducts.find(
        (item) => item.product_id === data[index][0].value
      ).id;

      ProductsService.deleteProductPackageMapping(mappingID)
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setInitialPackageProducts((prevData) =>
              prevData.filter(
                (item) => item.product_id !== data[index][0].value
              )
            );
          } else {
            throw "fail";
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
        });
    }
  };
  const enableSwitch = (index) => {
    return (
      <>
        <Switch
          checked={data[index][1].value}
          onChange={() => handleOnChangeEnabled(index)}
        />
      </>
    );
  };

  const arraysEqual = (index) => {
    let initialProducts = initialPackageProducts.map((item) => item.product_id);

    if (
      initialProducts.includes(data[index][0].value) &&
      packageProducts.includes(data[index][0].value)
    ) {
      return true;
    } else if (
      !initialProducts.includes(data[index][0].value) &&
      !packageProducts.includes(data[index][0].value)
    ) {
      return true;
    }

    return false;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={arraysEqual(index)}
            color="secondary"
            size="small"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={providerOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("inbound-provider")} />
                )}
                value={
                  providerOptions.find(
                    (option) => option.value === selectedProvider
                  ) || null
                }
                onChange={(event, newValue) => {
                  setSelectedProvider(newValue?.value || null);
                }}
              />
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchProducts();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        actionItemPrep={getActionItems}
        loadingData={loadingData}
        enableSwitch={enableSwitch}

      />
    </>
  );
}
