import { useEffect, useState } from "react";
import {
  AccountStatuses,
  UcEngineTypes,
  getLabelByValue,
  getSelectOptions,
} from "src/constants/index";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { rowArrayToObject } from "../../../utils/Util";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Stack,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import SearchButton from "../../../components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { styled } from "@mui/system";
import { EngineService } from "src/api/services";
import { useTranslation } from "react-i18next";
import SelectEngineForm from "./Forms/SelectEngineForm";
import UcassEngineForm from "./Forms/UcassEngineForm";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import DeleteEngineForm from "./Forms/DeleteEngineForm";
import BaseDialogSimple from "src/components/BaseDialogSimple";
import LoadingSpinner from "src/components/animatedComponenets/LaodingSpinner";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
  disabledDeleteButton: {
    color: theme.palette.error.light,
  },
}));

export default function UcassEngine() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [filterStatus, setStatus] = useState("0");
  const [selectedRow, setSelectedRow] = useState({});
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [openSelectNewEngineModal, setSelectNewEngineModalStatus] =
    useState(false);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openViewModal, setViewModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openConfirmDisableDialog, setConfirmDisableDialog] = useState(false);
  const [statusChanging, setStatusChanging] = useState(false);
  const [disableIndex, setDisableIndex] = useState(null);

  const confirmDisableMessage = t("dissable-engine-warning-ucaas");

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "pop", label: t("pop") },
    { key: "engine_subtype", label: t("engine-type") },
    {
      key: "active",
      label: t("common.__i18n_ally_root__.status"),
      align: "center",
    },
    { key: "action", label: t("actions"), align: "right", padding: "4em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    pop: { key: "pop", cellComponentType: "th", index: 1 },
    engine_subtype: { key: "engine_subtype", index: 2 },
    active: { key: "active", index: 3, align: "center" },
    properties: { key: "properties", index: 4, noRender: true },
    is_running: { key: "is_running", index: 5, noRender: true },
    is_mapped: { key: "is_mapped", index: 6, noRender: true },
  };

  const fetchEngines = () => {
    const params = {
      engine_type: 2,
      is_running:
        filterStatus !== "0"
          ? filterStatus === "1"
            ? true
            : false
          : undefined,
      size: 100,
    };

    setLoadingData(true);
    EngineService.listEngines(params)
      .then((response) => {
        let items = [];

        response.data.items.forEach((item) => {
          let formattedItem = new Array(TABLE_HEAD.length - 1).fill({});

          if (item.properties.pop) {
            const pop = item.properties.pop;

            formattedItem[TABLE_FIELD_MAPPING["pop"].index] = {
              ...TABLE_FIELD_MAPPING["pop"],
              value: pop,
            };
          }
          if (item.engine_subtype) {
            const engineSubtype = item.engine_subtype.name;
            formattedItem[TABLE_FIELD_MAPPING["engine_subtype"].index] = {
              ...TABLE_FIELD_MAPPING["engine_subtype"],
              value: engineSubtype,
            };
          }

          if (item.is_running !== "generating") {
            let statusValue = item.is_running ? t("running") : t("stopped");
            formattedItem[TABLE_FIELD_MAPPING["active"].index] = {
              ...TABLE_FIELD_MAPPING["active"],
              value: (
                <>
                  <Typography
                    color={item.is_running ? "success.main" : "warning.main"}
                  >
                    {statusValue}
                  </Typography>
                </>
              ),
            };
          }

          Object.entries(item).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "engine_subtype") {
              formattedItem[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(formattedItem);
        });

        setData(items);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchEngines();
    return () => {
      setData([]);
    };
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };
  const disableEngine = (index) => {
    if (statusChanging) {
      setMessage(t("wait-warn"));
      setSnackbarStatus(true);
    } else {
      const payload = {
        is_running: false,
      };

      let successMessage = t("name-stopped-success", { name: "UCaaS" });
      let failMessage = t("name-stopped-fail", { name: "UCaaS" });
      data[index][5].value = "generating";
      EngineService.updateEngine(data[index][0].value, payload)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setStatusChanging(true);
            setTimeout(() => {
              setMessage(successMessage);
              setSnackbarStatus(true);
              data[index][5].value = false;
              data[index][3].value = (
                <Typography color={"warning.main"}>{t("stopped")}</Typography>
              );

              setStatusChanging(false);
            }, 2000);
          } else {
            throw "cpass engine operation failed";
          }
        })
        .catch((err) => {
          data[index][5].value = true;
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
        });
    }
  };

  const enableEngine = (index) => {
    if (statusChanging) {
      setMessage(t("wait-warn"));
      setSnackbarStatus(true);
    } else {
      const payload = {
        is_running: true,
      };

      let successMessage = t("name-start-success", { name: "UCaaS" });
      let failMessage = t("name-start-fail", { name: "UCaaS" });
      data[index][5].value = "generating";
      EngineService.updateEngine(data[index][0].value, payload)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setStatusChanging(true);
            setTimeout(() => {
              setMessage(successMessage);
              setSnackbarStatus(true);
              data[index][5].value = true;
              data[index][3].value = (
                <Typography color={"success.main"}>{t("running")}</Typography>
              );
              setStatusChanging(false);
            }, 2000);
          } else {
            throw "ucaas engine operation failed";
          }
        })
        .catch((err) => {
          data[index][5].value = false;
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
        });
    }
  };
  const modalHandler = (modalType, index) => {
    const item = index ? rowArrayToObject(data[index]) : {};
    if (modalType === "view") {
      setViewModalStatus(!openViewModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "warningDelete") {
      setMessage(t("warning-delete-engine-ucaas"));
      setSnackbarStatus(!openSnackbar);
    } else if (modalType === "disableConfirm") {
      setDisableIndex(index);
      setConfirmDisableDialog(!openConfirmDisableDialog);
    } else if (modalType === "enable") {
      enableEngine(index);
    } else if (modalType === "disable") {
      disableEngine(index);
    }
    setSelectedRow(item);
  };

  const getActionItems = (index) => {
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Tooltip title={t("view-engine")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="view-engine"
            onClick={() => modalHandler("view", index)}
          >
            <EyeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("edit-engine")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-engine"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {data[index][5].value !== "generating" ? (
          data[index][5].value ? (
            <>
              <Tooltip title={t("stop-engine")}>
                <IconButton
                  color="secondary"
                  size="small"
                  aria-label="stop-engine"
                  onClick={() =>
                    modalHandler(
                      data[index][6].value ? "disableConfirm" : "disable",
                      index
                    )
                  }
                >
                  <PauseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("delete-engine")}>
                <IconButton
                  disabled
                  className={classes.disabledDeleteButton}
                  size="small"
                  aria-label="delete-engine"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={t("start-engine")}>
                <IconButton
                  color="secondary"
                  size="small"
                  aria-label="enable-account"
                  onClick={() => modalHandler("enable", index)}
                >
                  <PlayArrowIcon />
                </IconButton>
              </Tooltip>
              {data[index][6].value ? (
                <Tooltip title={t("delete-engine")}>
                  <IconButton
                    className={classes.disabledDeleteButton}
                    size="small"
                    aria-label="delete-engine"
                    onClick={() => modalHandler("warningDelete", index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("delete-engine")}>
                  <IconButton
                    className={classes.deleteButton}
                    size="small"
                    aria-label="delete-engine"
                    onClick={() => modalHandler("delete", index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )
        ) : (
          <>
            <Box
              sx={{
                width: "34px",
                height: "34px",
              }}
            >
              <LoadingSpinner
                size={25}
                spinnerColor={theme.palette.secondary.contrastText}
                spinnerBorderColor={theme.palette.secondary.main}
              />
            </Box>
            <Tooltip title={t("delete-engine")}>
              <IconButton
                disabled
                className={classes.disabledDeleteButton}
                size="small"
                aria-label="delete-engine"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  const selectNewEngineHandler = (data) => {
    setSelectedRow(data);
    setAddModalStatus(!openAddModal);
  };
  const disableCallback = (sure) => {
    if (sure) {
      disableEngine(disableIndex);
    }
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseDialogSimple
        title={t("warning-2")}
        message1={t("cancel")}
        message2={t("stop-anyway")}
        open={openConfirmDisableDialog}
        message={confirmDisableMessage}
        setOpen={setConfirmDisableDialog}
        callback={disableCallback}
      />
      <BaseModal
        title={t("select-new-engine")}
        open={openSelectNewEngineModal}
        setOpen={setSelectNewEngineModalStatus}
        children={
          <SelectEngineForm
            setModalStatus={setSelectNewEngineModalStatus}
            successCallback={selectNewEngineHandler}
            serviceTypeID={2}
          />
        }
      />
      <BaseModal
        sx={{
          maxHeight: "90%",
          overflow: "auto",
          width: "40%",
        }}
        title={t("add-new-engine")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <UcassEngineForm
            formType="add"
            setModalStatus={setAddModalStatus}
            successCallback={fetchEngines}
            formData={selectedRow}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          maxHeight: "90%",
          overflow: "auto",
          width: "40%",
        }}
        title={t("edit-engine")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UcassEngineForm
            formType="edit"
            setModalStatus={setEditModalStatus}
            successCallback={fetchEngines}
            formData={selectedRow}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          maxHeight: "90%",
          overflow: "auto",
          width: "40%",
        }}
        title={t("view-engine")}
        open={openViewModal}
        setOpen={setViewModalStatus}
        children={
          <UcassEngineForm
            formType="view"
            setModalStatus={setViewModalStatus}
            successCallback={fetchEngines}
            formData={selectedRow}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("delete-engine")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteEngineForm
            engineType={"UCaaS"}
            setModalStatus={setDeleteModalStatus}
            successCallback={fetchEngines}
            formData={selectedRow}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          s
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">
                {t("common.__i18n_ally_root__.status")}
              </InputLabel>
              <Select
                label={t("common.__i18n_ally_root__.status")}
                labelId="filter-type-label"
                name="status"
                color="secondary"
                value={filterStatus}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                {getSelectOptions(AccountStatuses())}
              </Select>
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchEngines();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-engine-0")}
        onClick={() => setSelectNewEngineModalStatus(true)}
      />
      <br />
      <BaseTable
        head={[...TABLE_HEAD]}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{}}
        loadingData={loadingData}
      />
    </>
  );
}
