import { useState, useEffect } from "react";
// material
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useStore } from "src/store/Store";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

import { EngineService } from "src/api/services";

// TODO: Modify when enum class implemented

const accountTypeChannelMap = {
  1: "2",
  2: "3",
  3: "1",
  4: "1",
  5: "2",
  7: "1",
};

const cpassEngineTypeChannelMap = {
  1: "2",
  2: "3",
  3: "1",
  4: "1",
};

export default function SelectEngineForm({
  setModalStatus,
  successCallback,
  serviceTypeID,
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  const [selectedEngine, setSelectedEngine] = useState("");
  const [selectOptions, setSelectOptions] = useState(null);

  useEffect(() => {
    EngineService.listEngineSubTypes({ size: 100 })
      .then((response) => {
        let filteredItems = response.data.items.filter(
          (item) => item.service_group.id === serviceTypeID
        );
        let options = filteredItems.map((item) => ({
          enumValue: /* item.value */ 3,
          label: item.name,
          subTypeID: item.id,
        }));
        setSelectOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSubmit = () => {
    let channel_type;
    let data;

    const selectedOption = selectOptions.find(
      (option) => option.subTypeID === selectedEngine
    );

    if (serviceTypeID === 1) {
      channel_type = cpassEngineTypeChannelMap[selectedOption.enumValue];
    }

    if (serviceTypeID === 1) {
      data = {
        engine_subtype: selectedOption ? selectedOption.label : null,
        channel_type: accountTypeChannelMap[selectedOption.enumValue],
        engine_subtype_id: selectedOption ? selectedOption.subTypeID : null,
        /* account_properties: credentials[accountType], */
        status: "1",
      };
    } else if (serviceTypeID === 2) {
      data = {
        engine_subtype: selectedOption ? selectedOption.label : null,
        engine_subtype_id: selectedOption ? selectedOption.subTypeID : null,
        status: "1",
      };
    } else if (serviceTypeID === 3) {
      data = {
        engine_subtype: selectedOption ? selectedOption.label : null,
        engine_subtype_id: selectedOption ? selectedOption.subTypeID : null,
        channel_type: 3,
        /* account_properties: credentials[accountType], */
        status: "1",
      };
    } else if (serviceTypeID === 5) {
      data = {
        engine_subtype: selectedOption ? selectedOption.label : null,
        engine_subtype_id: selectedOption ? selectedOption.subTypeID : null,
        status: "1",
      };
    }
    successCallback(data);
    setModalStatus(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <FormControl fullWidth>
          <InputLabel id="filter-type-label">
            {serviceTypeID !== 3 ? t("select-engine-type") : t("provider")}
          </InputLabel>
          <Select
            label={serviceTypeID !== 3 ? t("select-engine-type") : t("provider")}
            labelId="filter-type-label"
            color="secondary"
            value={selectedEngine}
            onChange={(event) => {
              setSelectedEngine(event.target.value);
            }}
          >
            {selectOptions &&
              selectOptions.map((option) => (
                <MenuItem key={option.subTypeID} value={option.subTypeID}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <br />
      <LoadingButton
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
      >
        {t("select")}
      </LoadingButton>
    </>
  );
}
