import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { TariffService, PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDateInput, isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

export default function UcassTariffForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType,
  successCallback,
  countries,
}) {
  const { t } = useTranslation();
  const [effectiveStartDate, setEffectiveStartDate] = useState(
    formData ? dayjs(formData.effectiveStart) : dayjs()
  );
  const [effectiveEndDate, setEffectiveEndDate] = useState(
    formData ? dayjs(formData.effectiveEnd) : dayjs()
  );

  const UcaasTariffSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    pricePerSeatMonthly: Yup.number()
      .min(0.1, t("pricing-is-must-be-greater-than-0"))
      .required(t("pricing-is-required")),
    pricePerSeatAnnual: Yup.number()
      .min(0.1, t("pricing-is-must-be-greater-than-0"))
      .required(t("pricing-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      pricePerSeatMonthly: formData.price_per_seat_monthly,
      pricePerSeatAnnual: formData.price_per_seat_annual,
      effectiveStart: formData.effectiveStart
        ? dayjs(formData.effectiveStart)
        : dayjs(),
      effectiveEnd: formData.effectiveEnd
        ? dayjs(formData.effectiveEnd)
        : dayjs(),
    },

    validationSchema: UcaasTariffSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        seat_monthly_price: values.pricePerSeatMonthly,
        seat_annual_price: values.pricePerSeatAnnual,
        effective_start: formatDateInput(values.effectiveStart),
        effective_end: formatDateInput(values.effectiveEnd),
      };

      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = TariffService.addUcassTariff(payload);
        successMessage = t("new-tariff-has-been-successfully-added");
        failMessage = t("new-tariff-could-not-be-added");
      } else {
        console.log("I am alive !!");
        apiService = TariffService.updateUcassTariff(formData.id, payload);
        successMessage = t("tariff-has-been-successfully-updated");
        failMessage = t("tariff-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "pricePerSeatMonthly") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="pricePerSeatMonthly"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("price-per-seat-monthly")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="pricePerSeatMonthly"
            label={isMobile() ? t("price-per-seat-monthly") : undefined}
            placeholder="0.0"
            {...getFieldProps("pricePerSeatMonthly")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            error={Boolean(
              touched.pricePerSeatMonthly && errors.pricePerSeatMonthly
            )}
            helperText={
              touched.pricePerSeatMonthly && errors.pricePerSeatMonthly
            }
          />
        </Box>
      );
    }
    if (fieldName === "pricePerSeatAnnual") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="pricePerSeatAnnual"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("price-per-seat-annual")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="pricePerSeatAnnual"
            label={isMobile() ? t("price-per-seat-annual") : undefined}
            placeholder="0.0"
            {...getFieldProps("pricePerSeatAnnual")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            error={Boolean(
              touched.pricePerSeatAnnual && errors.pricePerSeatAnnual
            )}
            helperText={touched.pricePerSeatAnnual && errors.pricePerSeatAnnual}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
          />
        </Box>
      );
    }
    if (fieldName === "effectiveStartDate") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-start-date")}
            value={effectiveStartDate}
            onChange={(newValue) => {
              setEffectiveStartDate(newValue);
              setFieldValue("effectiveStart", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }
    if (fieldName === "effectiveEndDate") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-end-date")}
            value={effectiveEndDate}
            onChange={(newValue) => {
              setEffectiveEndDate(newValue);
              setFieldValue("effectiveEnd", newValue.toISOString());
            }}
            minDate={dayjs()}
          />
        </LocalizationProvider>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("pricePerSeatMonthly")}
            {getFieldByName("pricePerSeatAnnual")}
            {getFieldByName("effectiveStartDate")}
            {getFieldByName("effectiveEndDate")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
