import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useStore } from "src/store/Store";

export default function SelectNumberForm({
  successCallback,
  number,
  setModalStatus,
}) {
  const { t } = useTranslation();

  const [store] = useStore();

  return (
    <>
      <Typography sx={{ mt: "1rem" }}>
        {t("you-are-buying-0", { number: number })}
      </Typography>
      <Box
        sx={{
          mt: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.5em",
        }}
      >
        <Button
          sx={{
            backgroundColor: store.palette.error.light,
          }}
          variant="contained"
          onClick={() => setModalStatus(false)}
        >
          <Typography
            fontWeight={1000}
            color={store.palette.success.contrastText}
          >
            {t("cancel")}
          </Typography>
        </Button>
        <Button
          sx={{
            backgroundColor: store.palette.success.main,
          }}
          variant="contained"
          onClick={() => {
            setModalStatus(false);
            successCallback(true);
          }}
        >
          <Typography
            fontWeight={1000}
            color={store.palette.success.contrastText}
          >
            {t("buy")}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
