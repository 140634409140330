import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { FeatureService, PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function FeatureForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const PackageSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
    },
    validationSchema: PackageSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
      };

      let successMessage =
        formType === "add"
          ? t("new-feature-added-succesfully")
          : t("feature-has-been-succesfully-updated");
      let failMessage =
        formType === "add"
          ? t("new-feature-could-not-be-added")
          : t("feature-could-not-be-updated");
      const apiService =
        formType === "add"
          ? FeatureService.addFeature(payload)
          : FeatureService.updateFeature(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "feature") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("feature")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-feature-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>{getFieldByName("feature")}</Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
