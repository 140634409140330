import { useEffect, useState } from "react";
import {
  CustomerStatuses,
  DefaultPaginationData,
  getLabelByValue,
} from "src/constants/index";
import { useStore } from "src/store/Store";
import { CustomerService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import {
  canUserReadIt,
  canUserWriteIt,
  rowArrayToObject,
} from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import DeleteIcon from "@mui/icons-material/Delete";
import { ManageBalanceForm, SetServicePackageForm } from "./Forms";
import { useTranslation } from "react-i18next";
import AddCustomerForm from "./Forms/AddCustomerForm";
import DeleteCustomerForm from "./Forms/DeleteCustomerForm";
import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function Customer() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "name", label: t("customer-name") },
    { key: "servicePackageName", label: t("subscription-package") },
    { key: "status", label: t("common.__i18n_ally_root__.status") },
    canUserReadIt("customers_and_users")
      ? { key: "user_list", label: t("user-list") }
      : null,
    { key: "balance", label: t("balance") },
    canUserWriteIt("customers_and_users")
      ? { key: "action", label: t("actions"), align: "right", padding: "2.5vw" }
      : null,
  ].filter(Boolean);

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
    service_package_name: { key: "servicePackageName", index: 2 },
    status: { key: "status", index: 3 },
    user_list: { key: "user_list", index: 4 },
    balance: { key: "balance", index: 5 },
    service_package_id: { key: "servicePackageId", index: 6, noRender: true },
  };

  const [store] = useStore();
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterCustomer, setCustomer] = useState("");
  const [openAddCustomerModal, setAddCustomerModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openManageBalanceModal, setManageBalanceModalStatus] = useState(false);
  const [openSetServicePackageModal, setSetServicePackageModalStatus] =
    useState(false);
  const [openDeleteCustomerModal, setDeleteCustomerModalStatus] =
    useState(false);

  const fetchCustomers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterCustomer ? filterCustomer : undefined,
    };
    CustomerService.listCustomers(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          if (itemData.service_package) {
            const servicePackageName = itemData.service_package.name;

            item[TABLE_FIELD_MAPPING["service_package_name"].index] = {
              ...TABLE_FIELD_MAPPING["service_package_name"],
              value: servicePackageName,
            };
          }
          if (canUserReadIt("customers_and_users")) {
            item[TABLE_FIELD_MAPPING["user_list"].index] = {
              ...TABLE_FIELD_MAPPING["user_list"],
              value: (
                <Tooltip title={t("show-user-list")}>
                  <Link
                    to={`/admin/users-roles/portal/${itemData.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton size="small">
                      <GroupIcon sx={{ ml: 1 }} />
                    </IconButton>
                  </Link>
                </Tooltip>
              ),
            };
          }
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "users_list") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(CustomerStatuses(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddCustomerModalStatus(!openAddCustomerModal);
    } else if (modalType === "balance") {
      setManageBalanceModalStatus(!openManageBalanceModal);
    } else if (modalType === "package") {
      setSetServicePackageModalStatus(!openSetServicePackageModal);
    } else if (modalType === "delete") {
      setDeleteCustomerModalStatus(!openDeleteCustomerModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("manage-balance")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="manage-balance"
            onClick={() => modalHandler("balance", index)}
          >
            <CurrencyExchangeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("manage-subscription-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="manage-package"
            onClick={() => modalHandler("package", index)}
          >
            <FolderSpecialIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-customer")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-customer"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          width: "40%",
          maxHeight: "95vh",
          overflow: "auto",
        }}
        title={t("add-new-customer")}
        open={openAddCustomerModal}
        setOpen={setAddCustomerModalStatus}
        children={
          <AddCustomerForm
            successCallback={fetchCustomers}
            setModalStatus={setAddCustomerModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("manage-balance-0")}
        open={openManageBalanceModal}
        setOpen={setManageBalanceModalStatus}
        children={
          <ManageBalanceForm
            formData={selectedRow}
            successCallback={fetchCustomers}
            setModalStatus={setManageBalanceModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("manage-subscription-package")}
        open={openSetServicePackageModal}
        setOpen={setSetServicePackageModalStatus}
        children={
          <SetServicePackageForm
            formData={selectedRow}
            successCallback={fetchCustomers}
            setModalStatus={setSetServicePackageModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-customer")}
        open={openDeleteCustomerModal}
        setOpen={setDeleteCustomerModalStatus}
        children={
          <DeleteCustomerForm
            formData={selectedRow}
            successCallback={fetchCustomers}
            setModalStatus={setDeleteCustomerModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterCustomer}
                label={t("customer")}
                name="customer"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setCustomer(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCustomers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      {canUserWriteIt("customers_and_users") && (
        <AddNewButton
          label={t("add-new-customer-0")}
          onClick={() => modalHandler("add")}
        />
      )}
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={canUserReadIt("customers_and_users") && getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
