import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  InputLabel,
  Box,
  Typography,
  Divider,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { NetworksService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function SmsEndpointForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType,
  successCallback,
}) {
  const { t } = useTranslation();
  console.log(formData);
  const [checked, setChecked] = useState(formData.is_default ?? false);

  const VoiceSipTrunkSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    httpEndpoint: Yup.string().required(t("field-required")),
    fromField: Yup.string().required(t("field-required")),
    toField: Yup.string().required(t("field-required")),
    bodyField: Yup.string().required(t("field-required")),
    idField: Yup.string().required(t("field-required")),
    typeField: Yup.string().required(t("field-required")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      httpEndpoint: formData.http_endpoint || "",
      fromField: formData.from_field,
      toField: formData.to_field,
      bodyField: formData.body_field,
      idField: formData.id_field,
      typeField: formData.type_field,
    },

    validationSchema: VoiceSipTrunkSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        http_post_url: values.httpEndpoint,
        from_field: values.fromField,
        to_field: values.toField,
        body_field: values.bodyField,
        id_field: values.idField,
        type_field: values.typeField,
      };

      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = NetworksService.addSmsEndpoint(payload);
        successMessage = t("new-sms-endpoint-success");
        failMessage = t("new-sms-endpoint-fail");
      } else {
        apiService = NetworksService.updateSmsEndpoint(formData.id, payload);
        successMessage = t("sms-endpoint-has-been-successfully-updated");
        failMessage = t("sms-endpoint-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw "sip trunk operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "httpEndpoint") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="httpEndpoint"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("http-endpoint")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="httpEndpoint"
            label={isMobile() ? t("http-endpoint") : undefined}
            placeholder={t("type-http-endpoint-here")}
            {...getFieldProps("httpEndpoint")}
            error={Boolean(touched.httpEndpoint && errors.httpEndpoint)}
            helperText={touched.httpEndpoint && errors.httpEndpoint}
          />
        </Box>
      );
    }
    if (fieldName === "fromField") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="fromField"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("from-field")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="fromField"
            label={isMobile() ? t("from-field") : undefined}
            placeholder={t("type-from-field-here")}
            {...getFieldProps("fromField")}
            error={Boolean(touched.fromField && errors.fromField)}
            helperText={touched.fromField && errors.fromField}
          />
        </Box>
      );
    }

    if (fieldName === "toField") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="toField"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("to-field")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="toField"
            label={isMobile() ? t("to-field") : undefined}
            placeholder={t("type-to-field-here")}
            {...getFieldProps("toField")}
            error={Boolean(touched.toField && errors.toField)}
            helperText={touched.toField && errors.toField}
          />
        </Box>
      );
    }

    if (fieldName === "bodyField") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="bodyField"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("body-field")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="bodyField"
            label={isMobile() ? t("body-field") : undefined}
            placeholder={t("type-body-field-here")}
            {...getFieldProps("bodyField")}
            error={Boolean(touched.bodyField && errors.bodyField)}
            helperText={touched.bodyField && errors.bodyField}
          />
        </Box>
      );
    }

    if (fieldName === "idField") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="idField"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("id-field")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="idField"
            label={isMobile() ? t("id-field") : undefined}
            placeholder={t("type-id-field-here")}
            {...getFieldProps("idField")}
            error={Boolean(touched.idField && errors.idField)}
            helperText={touched.idField && errors.idField}
          />
        </Box>
      );
    }

    if (fieldName === "typeField") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="typeField"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("type-field")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="typeField"
            label={isMobile() ? t("type-field") : undefined}
            placeholder={t("type-type-field-here")}
            {...getFieldProps("typeField")}
            error={Boolean(touched.typeField && errors.typeField)}
            helperText={touched.typeField && errors.typeField}
          />
        </Box>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {getFieldByName("name")}
            {getFieldByName("httpEndpoint")}
            {getFieldByName("fromField")}
            {getFieldByName("toField")}
            {getFieldByName("bodyField")}
            {getFieldByName("idField")}
            {getFieldByName("typeField")}
            <Box
              sx={{
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography>{t("is-default")}</Typography>
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            </Box>
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
