import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  styled,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import BaseSnackbar from "src/components/BaseSnackbar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"; // may need later

const FileInput = styled("input")({
  display: "none",
});

export default function UploadUcUsers() {


  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState(",");
  const [fileName, setFileName] = useState("Choose a file!");


  let sampleData =
  "Email,First Name,Last Name,uc_username,uc_password\n" +
  "john.doe@example.com,John,Doe,johndoe,password123\n" +
  "jane.smith@example.com,Jane,Smith,janesmith,securepass456\n" +
  "bob.johnson@example.com,Bob,Johnson,bobjohnson,letmein789\n" +
  "alice.williams@example.com,Alice,Williams,alicew,pass1234\n" +
  "david.miller@example.com,David,Miller,davidm,securepass789\n" +
  "susan.jones@example.com,Susan,Jones,susanj,letmein567\n" +
  "michael.brown@example.com,Michael,Brown,michaelb,password5678\n" +
  "linda.white@example.com,Linda,White,lindaw,securepass890\n" +
  "tom.davis@example.com,Tom,Davis,tomd,letmein890\n" +
  "emily.wilson@example.com,Emily,Wilson,emilyw,password8901\n" +
  "ryan.anderson@example.com,Ryan,Anderson,ryana,securepass901\n" +
  "olivia.moore@example.com,Olivia,Moore,oliviam,letmein9012\n" +
  "henry.clark@example.com,Henry,Clark,hc123,securepass012\n" +
  "grace.hill@example.com,Grace,Hill,graceh,letmein0123";

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      const uploadedFileName = uploadedFile.name;
      setFile(uploadedFile);
      setFileName(uploadedFileName);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage(t("please-select-file-to-uplaod"));
      setSnackbarStatus(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("delimiter", delimiter);

    // Make API call to handle file upload
    try {
      const response = await fetch("your_api_url", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setMessage(t("file-uploaded-successfully"));
        setSnackbarStatus(true);
      } else {
        setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
        setSnackbarStatus(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
      setSnackbarStatus(true);
    }
  };

  const handleDelimiterChange = (event) => {
    setDelimiter(event.target.value);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          border: "0.2px solid white",
          borderRadius: 5,
          boxShadow: 10,
          width: "100%",
        }}
      >
        <Box
          className="functionalities"
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            className="DelimiterPart"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="delimiter-label">{t("delimiter")}</InputLabel>
            <Select
              labelId="delimiter-label"
              id="delimiter"
              value={delimiter}
              onChange={handleDelimiterChange}
            >
              <MenuItem value={","}>{t("comma")} ( , )</MenuItem>
              <MenuItem value={";"}>{t("semicolon")} ( ; )</MenuItem>
            </Select>
          </Box>
          <Box
            className="FileUpload"
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 5,
            }}
          >
            <InputLabel id="upload-label">{t("file-upload")}</InputLabel>
            <Grid container spacing={0}>
              <Grid item xs={12} md={7.5}>
                <TextField fullWidth value={fileName} sx={{ height: "100%" }} />
              </Grid>
              <Grid item xs={5} md={2}>
                <label htmlFor="file" style={{ height: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{ mt: 1, height: "90%", width: "100%" }}
                  >
                    <UploadFileIcon /> {t("browse")}
                  </Button>
                  <FileInput
                    id="file"
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                  />
                </label>
              </Grid>
              <Grid item xs={5} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleSubmit}
                  sx={{ mt: 1, ml: 2, height: "90%", width: "100%" }}
                >
                  {t("upload")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          className="sampleDataFile"
          sx={{
            width: "60%",
            ml: 5,
          }}
        >
          <InputLabel id="sampleData-label" sx={{ fontSize: 20 }}>
            {t("example-data-file")}
          </InputLabel>

          <textarea
            readOnly
            defaultValue={sampleData}
            style={{ width: "100%", height: "100%", fontSize: 14 }}
          />
        </Box>
      </Box>
    </>
  );
}
