import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import UcassTariffForm from "./forms/UcassTariffForm";
import { TariffService, CommonService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DeleteUcaaSTariffForm from "./forms/DeleteUcaaSTariffForm";
import DeleteAnyway from "src/components/DeleteAnyway";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function UcaasTariff() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterPackage, setFilterPackage] = useState(null);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "price_per_seat_monthly", label: t("price-per-seat-monthly") },
    { key: "price_per_seat_annual", label: t("price-per-seat-annual") },
    { key: "action", label: t("actions"), align: "right", padding: "1.7em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    seat_monthly_price: { key: "price_per_seat_monthly", index: 2 },
    seat_annual_price: { key: "price_per_seat_annual", index: 3 },
    effective_start: { key: "effectiveStart", index: 4, noRender: true },
    effective_end: { key: "effectiveEnd", index: 5, noRender: true },
  };

  const fetchTariffs = () => {
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterPackage
    };
    setLoadingData(true);

    TariffService.listUcassTariff(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchTariffs();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  useEffect(() => {
    CommonService.getCountries({})
      .then((response) => {
        let items = [];
        Object.entries(response.data).forEach(([key, value]) => {
          Object.entries(value).forEach(([code, name]) => {
            if (code === "null") {
              items.push({ code: "0", name: name });
            } else {
              items.push({ code: code, name: name });
            }
          });
        });
        setCountries(items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatRowData = (rowData) => {
    //changeFurkan  -ask for any necessary format
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddTariffModalStatus(!openAddTariffModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-tariff"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-tariff-0")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-sms-tariff"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchTariffs();
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{ minWidth: "40rem", width: "30%" }}
        title={t("add-new-tariff-0")}
        open={openAddTariffModal}
        setOpen={setAddTariffModalStatus}
        children={
          <UcassTariffForm
            successCallback={fetchTariffs}
            formData={{}}
            formType="add"
            setModalStatus={setAddTariffModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        sx={{ minWidth: "40rem", width: "30%" }}
        title={t("edit-tariff-0")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UcassTariffForm
            formType="edit"
            successCallback={fetchTariffs}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        title={t("delete-tariff-0")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteUcaaSTariffForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-ucaas-tariff")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchTariffs}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              TariffService.deleteUcassTariff(selectedRow.id, { proceed: true })
            }
            name={t("ucaas-tariff")}
            usedIn={t("ucaas-package")}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPackage}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterPackage(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchTariffs();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-ucaas-tariff")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
