import axios from "axios";

const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      if (localStorage.getItem("userType") === "admin") {
        localStorage.removeItem("admin");
      } else if (localStorage.getItem("userType") === "portal") {
        localStorage.removeItem("portal");
      }

      window.location = "/";
    } else if (error.response.status === 403) {
      //TODO: Find another solution
      /* if (localStorage.getItem("userType") === "admin") {
        localStorage.removeItem("admin");
      } else if (localStorage.getItem("userType") === "portal") {
        localStorage.removeItem("portal");
      }
      window.location = "/"; */
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (request) => {
    let token =
      localStorage.getItem("userType") === "admin"
        ? JSON.parse(localStorage.getItem("admin"))?.token
        : JSON.parse(localStorage.getItem("portal"))?.token;

    if (!("Authorization" in request.headers.common) && token) {
      request.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
