import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {
  Stack,
  TextField,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  CustomerService,
  RoleService,
  UserService,
  CountriesService,
} from "src/api/services";
import { isMobile } from "src/utils/Util";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
//edit ?
export default function CustomerUserForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [addDirectly, setAddDirectly] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(formData?.properties?.country_id || "");


  const AddUserSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .when([], {
        is: () => (addDirectly || formType === 'edit'),
        then: Yup.string().required(t("first-name-is-required")),
        otherwise: Yup.string(),
      })
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .when([], {
        is: () => (addDirectly || formType === 'edit'),
        then: Yup.string().required(t("last-name-is-required")),
        otherwise: Yup.string(),
      })
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    telephone: Yup.number()
    .when([], {
      is: () => (addDirectly || formType === 'edit'),
      then: Yup.number().required(t("telephone-is-required")),
      otherwise: Yup.number(),
    }),
    address1: Yup.string().when([], {
      is: () => (addDirectly || formType === 'edit'),
      then: Yup.string().required(t("address-is-required-0")),
      otherwise: Yup.string(),
    }),
    address2: Yup.string().when([], {
      is: () => (addDirectly || formType === 'edit'),
      then: Yup.string().required(t("address-is-required-0")),
      otherwise: Yup.string(),
    }),
    password: Yup.string()
      .when([], {
        is: () => (addDirectly || formType === 'password'),
        then: Yup.string().required(t("password-is-required")),
        otherwise: Yup.string(),
      })
      .min(8, t("too-short-3"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
    country: Yup.number().when([], {
      is: () => (addDirectly || formType === 'edit'),
      then: Yup.number().required(t("country-is-required")),
      otherwise: Yup.number(),
    }),
    customer: Yup.number().when([], {
      is: () => formType === "add",
      then: Yup.number().required(t("customer-required")),
      otherwise: Yup.number(),
    }),
  });

  const fetchRoles = () => {
    RoleService.listCustomerRoles({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let roleOptions = response.data.items.map((role) => ({
            value: role.id,
            label: role.name,
          }));
          setRoles(roleOptions);
        } else {
          throw "list partitions failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCountries = () => {
    CountriesService.getCountries().then((response) => {
      if (response.status === 200) {
        let countryOptions = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));

        setCountries(countryOptions);
      } else {
        throw "error listing countries";
      }
    });
  };

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  useEffect(() => {
    fetchRoles();
    fetchCountries();
    fetchCustomerNames();
  }, []);

  const formik = useFormik({
    initialValues: {
      customer: formData.customer_id || "",
      email: formData.email || "",
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      address1: formData?.properties?.address1 || "",
      address2: formData?.properties?.address2 || "",
      telephone: formData?.properties?.telephone || "",
      password: formData.password || "",
      country: formData?.properties?.country_id || "",
    },
    validationSchema: AddUserSchema,  
    onSubmit: (values, actions) => {
      console.log("onSubmit called!!!");
      let payload;

      if (formType === "add") {
        if(addDirectly){
          payload = {
            customer_id : values.customer,
            email: values.email,
            role_id : selectedRole,
            first_name : values.firstName,
            last_name : values.lastName,
            address1 : values.address1,
            address2 : values.address2,
            telephone : values.telephone,
            country_id : values.country,
            password : values.password
          };
        }else {
          payload = {
            customer_id : values.customer,
            email: values.email,
            role_id: values.roleID
          };
        }
      } else if (formType === "edit") {
        payload = {
          customer_id : values.customer,
          email: values.email,
          first_name : values.firstName,
          last_name : values.lastName,
          address1 : values.address1,
          address2 : values.address2,
          telephone : values.telephone,
          country_id : values.country
        };
      } else if (formType === "password") {
        payload = {
          password: values.password,
        };
      } else {
        payload = {
          role_id: values.roleID,
        };
      }

      let successMessage;
      let failMessage;

      if(formType === "add"){
        if(addDirectly){
          successMessage = t("new-customer-user-has-been-successfully-added");
          failMessage = t("new-customer-user-could-not-be-added");
        }else{
          successMessage = t("invitation-succes-customer-user");
          failMessage = t("invitation-fail-customer-user");
        }

      }else if(formType === "edit"){
        successMessage = /* t("customer-user-editted-successfully"); */ t("new-customer-user-has-been-successfully-added");
        failMessage = t("customer-user-edit-fail");
      }else {
        successMessage = t("customer-user-password-changed-successfully")
        failMessage = t("failed-to-cange-customer-user-password")

      }

      const apiService =
        formType === "add"
          ? (addDirectly ? UserService.createCustomerUser(payload) : UserService.createCustomerUserInvitation(payload))
          : UserService.updateCustomerUser(formData.id, payload);

      console.log("apiService: ", apiService);


      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          setMessage(err?.response?.detail || failMessage);
          setSnackbarStatus(true);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "customerName") {
      return (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={customerOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label="Customer" />}
          value={
            customerOptions.find(
              (option) => option.value === selectedCustomer
            ) || null
          }
          onChange={(event, newValue) => {
            const customerValue = newValue
              ? Number(newValue.value)
              : null;
            setSelectedCustomer(customerValue);
            formik.setFieldValue("customer", customerValue);
          }}
        />
      );
    }
    if (fieldName === "firstName") {
      return (
        <Box textAlign="start" flex={1}>
          {!isMobile() && (
            <InputLabel
              htmlFor="firstName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("first-name-0")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="firstName"
            {...getFieldProps("firstName")}
            placeholder={t("type-first-name-here")}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Box>
      );
    }
    if (fieldName === "lastName") {
      return (
        <Box textAlign="start" flex={1}>
          {!isMobile() && (
            <InputLabel
              htmlFor="lastName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("last-name-0")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="lastName"
            {...getFieldProps("lastName")}
            placeholder={t("type-last-name-here")}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Box>
      );
    }
    if (fieldName === "email") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="email"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("email-address")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="email"
            type="email"
            {...getFieldProps("email")}
            placeholder={t("type-email-here")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
      );
    }
    if (fieldName === "role") {
      return (
        <FormControl>
           <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={roles}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label="Role" />}
          value={
            roles.find(
              (option) => option.value === selectedRole
            ) || null
          }
          onChange={(event, newValue) => {
            setSelectedRole(newValue?.value || null);
          }}
        />
         {/*  <InputLabel id="select-role">{t("role")}</InputLabel>
          <Select
            label={t("role")}
            labelId="select-role"
            name="role"
            color="primary"
            {...getFieldProps("roleID")}
          >
            {roles.map((role, idx) => {
              return (
                <MenuItem key={idx} value={role.id}>
                  {role.role_name}
                </MenuItem>
              );
            })}
          </Select> */}
        </FormControl>
      );
    }
    if (fieldName === "password") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="password"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("password")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="password"
            type={showPassword ? "text" : "password"}
            {...getFieldProps("password")}
            placeholder={t("type-password-here")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
      );
    }

    if (fieldName === "address1") {
      return (
        <Box textAlign="start" flex={1}>
          {!isMobile() && (
            <InputLabel
              htmlFor="address1"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("address-1")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="address1"
            {...getFieldProps("address1")}
            placeholder={t("type-address-here")}
            error={Boolean(touched.address1 && errors.address1)}
            helperText={touched.address1 && errors.address1}
          />
        </Box>
      );
    }

    if (fieldName === "address2") {
      return (
        <Box textAlign="start" flex={1}>
          {!isMobile() && (
            <InputLabel
              htmlFor="address1"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("address-2")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="address2"
            {...getFieldProps("address2")}
            placeholder={t("type-address-here")}
            error={Boolean(touched.address2 && errors.address2)}
            helperText={touched.address2 && errors.address2}
          />
        </Box>
      );
    }

    if (fieldName === "telephone") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="telephone"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("telephone")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            label={t("telephone")}
            {...getFieldProps("telephone")}
            error={Boolean(touched.telephone && errors.telephone)}
            helperText={touched.telephone && errors.telephone}
          />
        </Box>
      );
    }

    if (fieldName === "country") {
      return (
        <Autocomplete
        id="countries"
        options={countries}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select-country")}
            error={Boolean(touched.country && errors.country)}
            helperText={touched.country && errors.country}
          />
        )}
        value={
          countries.find((option) => option.value === selectedCountry) || null
        }
        onChange={(event, newValue) => {
          const countryValue = newValue ? Number(newValue.value) : null;
          setSelectedCountry(countryValue);
          formik.setFieldValue("country", countryValue);
        }}
      />
      );
    }
      if (fieldName === "submitButton") {
        return (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            startIcon={!(formType === "add" && !addDirectly) && <SaveIcon />}
            endIcon={formType === "add" && !addDirectly && <SendIcon />}
          >
            {formType === "add" ? (addDirectly ? t("add-new-user") : t("send-invitaiton")) : (formType === "edit" ?  t("edit-customer-user") : t("change-password"))}
          </LoadingButton>
        );
      }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={1}>
            {formType === "add" &&  <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{
                paddingLeft :"10%",
                paddingRight :"10%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={!addDirectly}
                  onChange={(e) => setAddDirectly(!e.target.checked)}
                />
                <Typography>{t("send-invitaiton")}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={addDirectly}
                  onChange={(e) => setAddDirectly(e.target.checked)}
                />
                <Typography>{t("add-directly")}</Typography>
              </Box>
            </Stack>}
            {formType === 'add' &&  getFieldByName("customerName")}
            {formType !== 'password' && getFieldByName("email")}
            {formType === 'add' &&  getFieldByName("role")}

            {(addDirectly || formType === "edit") && (
              <>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                  {formType !== 'password' && getFieldByName("firstName")}
                  {formType !== 'password' && getFieldByName("lastName")}
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                  {formType !== 'password' && getFieldByName("address1")}
                  {formType !== 'password' && getFieldByName("address2")}
                </Stack>
                {formType !== 'password' && getFieldByName("telephone")}
                {formType !== 'password' && getFieldByName("country")}
               
                
              </>
            )}
             {(formType === "password" || addDirectly) &&  getFieldByName("password")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
