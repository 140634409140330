import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import { UcCustomers , UcServices, UcServiceManage,UcUsers, BulkUploadUcUsers} from "./ucManagement";
import { useState } from "react";
import { useStore } from "src/store/Store";

export default function UcManagements() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [store, dispatch] = useStore();

  const changeTab = (number, serviceID, customerID) => {
    dispatch({
      type: "UPDATE_SERVICE_ID",
      payload: { serviceID: serviceID || null },
    });
    if(customerID){
      dispatch({
        type: "UPDATE_CUSTOMER_ID",
        payload: { customerID : customerID || null },
      });
    }
    setActiveTab(number);
  };

  
  const TABS = [
    {
      id: 0,
      label: t("uc-subscribers"),
      component: <UcCustomers changeTab={changeTab} />,
    } ,
    {
      id: 1,
      label: t("uc-services"),
      component: <UcServices changeTab={changeTab} />,
    } ,
    {
      id: 2,
      label: t("uc-users"),
      component: <UcUsers />,
    },
    {
      id: 3,
      label: t("manage-uc-services"),
      component: <UcServiceManage />,
    },
  ];

  const TITLE = t("uc-management");

  return (
    <SmartcpaasAppLayout tabs={TABS} title={TITLE} activeTab={activeTab} />
  );
}
