import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  Box,
  Checkbox,
  Typography,
  Card,
  CardContent,
  Divider,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { OrdersService } from "src/api/services";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function OrderForm({
  formType = "add",
  selectedCustomer,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [isAnnual, setIsAnnual] = useState(formData.annual || false);

  const OrderSchema = Yup.object().shape({
    seatCount: Yup.number()
      .typeError(t("must-be-number"))
      .min(formData.min_seat_count, t("seat-count-must-be-greater-than-1"))
      .max(formData.max_seat_count, t("seat-count-must-be-lower-than-1"))
      .required(t("seat-count-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      package_id: formData.package_id || "",
      customer_id: formData.customer_id || selectedCustomer.id,
      seatCount: formData.seat_count || 0,
      monthly: !isAnnual,
      annual: !isAnnual,
    },
    validationSchema: OrderSchema,
    onSubmit: (values, actions) => {
      const payload = {
        package_id: values.package_id,
        customer_id: values.customer_id,
        seat_count: values.seatCount,
        monthly: !isAnnual,
        annual: isAnnual,
      };

      let successMessage =
        formType === "add"
          ? t("new-order-created-successfully")
          : t("uc-order-updated-successfully");
      let failMessage =
        formType === "add"
          ? t("new-order-could-not-be-created")
          : t("uc-order-could-not-be-updated");
      const apiService =
        formType === "add"
          ? OrdersService.addUcOrder(payload)
          : OrdersService.updateUcOrder(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            successCallback();
            setModalStatus(false);

            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "seatCount") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel htmlFor="seatCount" style={{ color: "secondary" }}>
              {t("seat-count")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="seatCount"
            type="number"
            placeholder="1"
            {...getFieldProps("seatCount")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.seatCount && errors.seatCount)}
            helperText={touched.seatCount && errors.seatCount}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("create-order")}
        </LoadingButton>
      );
    }
    if (fieldName === "pricingPlan") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            minHeight: "100px",
          }}
        >
          <Box
            sx={{
              ml: "20%",
            }}
          >
            <Typography>{t("monthly")}</Typography>
            <Checkbox
              checked={!isAnnual}
              onChange={(e) => {
                setIsAnnual(!e.target.checked);
              }}
            />
          </Box>
          <Box
            sx={{
              mr: "20%",
            }}
          >
            <Typography>{t("annual")}</Typography>
            <Checkbox
              checked={isAnnual}
              onChange={(e) => {
                setIsAnnual(e.target.checked);
              }}
            />
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("customer")}
                </Typography>
                <Typography variant="h5">
                  {selectedCustomer
                    ? selectedCustomer.name
                    : "Example Customer"}
                </Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" color="textSecondary">
                  {t("ucaas-package")}
                </Typography>
                <Typography variant="h5">{formData.package_name}</Typography>
              </CardContent>
            </Card>
          </Stack>

          <Stack spacing={3} marginTop={3}>
            {getFieldByName("pricingPlan")}
          </Stack>
          <Stack spacing={3}>{getFieldByName("seatCount")}</Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
