import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  TextField,
  InputLabel,
  Autocomplete,
  Box,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TariffService, PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { formatDateInput, isMobile } from "src/utils/Util";

// ----------------------------------------------------------------------

export default function NumberTariffForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
  currencies,
}) {
  const { t } = useTranslation();

  const [selectedCurrency, setSelectedCurrency] = useState(
    formData.currency_id || null
  );
  const [effectiveStartDate, setEffectiveStartDate] = useState(
    formData ? dayjs(formData.effectiveStart) : dayjs()
  );

  const NumberTariffSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),

    nrc: Yup.number()
      .min(0, t("nrc-must-be-greater-than-zero"))
      .max(9999, t("nrc-must-be-less-than-10000"))
      .required(t("nrc-is-required")),
    mrc: Yup.number()
      .min(0, t("must-be-zero-or-greater"))
      .max(9999, t("mrc-must-be-less-than-10000"))
      .required(t("mrc-is-required")),
    smsRate: Yup.number()
      .min(0.000001, t("sms-rate-must-be-greater-than-zero"))
      .max(9999, t("sms-rate-must-be-less-than-10000"))
      .required(t("sms-rate-is-required")),
    peakRate: Yup.number()
      .min(0.000001, t("rate-must-be-greater-than-zero"))
      .max(9999, t("rate-must-be-less-than-10000"))
      .required(t("rate-is-required")),
    offPeakRate: Yup.number()
      .min(0.000001, t("rate-must-be-greater-than-zero"))
      .max(9999, t("rate-must-be-less-than-10000"))
      .required(t("rate-is-required")),
    weekendRate: Yup.number()
      .min(0.000001, t("rate-must-be-greater-than-zero"))
      .max(9999, t("rate-must-be-less-than-10000"))
      .required(t("rate-is-required")),
    mobileOriginRate: Yup.number()
      .min(0.000001, t("rate-must-be-greater-than-zero"))
      .max(9999, t("rate-must-be-less-than-10000"))
      .required(t("rate-is-required")),
    payphoneOriginRate: Yup.number()
      .min(0.000001, t("rate-must-be-greater-than-zero"))
      .max(9999, t("rate-must-be-less-than-10000"))
      .required(t("rate-is-required")),
    connectFee: Yup.number()
      .min(0.000001, t("fee-must-be-greater-than-zero"))
      .required(t("fee-is-required")),
    portInFee: Yup.number()
      .min(0.000001, t("fee-must-be-greater-than-zero"))
      .required(t("fee-is-required")),
    portOutFee: Yup.number()
      .min(0.000001, t("fee-must-be-greater-than-zero"))
      .required(t("fee-is-required")),
    smsRateThreshold: Yup.number()
      .min(0.000001, t("sms-rate-must-be-greater-than-zero"))
      .max(9999, t("sms-rate-must-be-less-than-10000"))
      .required(t("sms-rate-is-required")),
    rounding: Yup.number()
      .min(1, t("rounding-must-be-greater-than-1"))
      .integer(t("rounding-must-be-an-integer"))
      .required(t("rounding-is-required")),
    initialTime: Yup.number()
      .min(1, t("initial-time-must-be-greater-than-1"))
      .integer(t("initial-time-must-be-an-integer"))
      .required(t("initial-time-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      nrc: formData.nrc,
      mrc: formData.mrc,
      rounding: formData.rounding,
      initialTime: formData.initialTime,
      smsRate: formData.sms_rate,
      peakRate: formData.peak_rate,
      offPeakRate: formData.off_peak_rate,
      weekendRate: formData.weekend_rate,
      mobileOriginRate: formData.mobile_origin_rate,
      payphoneOriginRate: formData.payphone_origin_rate,
      connectFee: formData.connect_fee,
      portInFee: formData.port_in_fee,
      portOutFee: formData.port_out_fee,
      smsRateThreshold: formData.sms_rate_threshold,

      effectiveStart: formData.effectiveStart
        ? dayjs(formData.effectiveStart)
        : dayjs(),
    },

    validationSchema: NumberTariffSchema,

    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        currency_id: selectedCurrency,
        nrc: values.nrc,
        mrc: values.mrc,
        rounding: values.rounding,
        initial_time: values.initialTime,
        sms_rate: values.smsRate,
        peak_rate: values.peakRate,
        off_peak_rate: values.offPeakRate,
        weekend_rate: values.weekendRate,
        mobile_origin_rate: values.mobileOriginRate,
        payphone_origin_rate: values.payphoneOriginRate,
        connect_fee: values.connectFee,
        port_in_fee: values.portInFee,
        port_out_fee: values.portOutFee,
        sms_rate_threshold: values.smsRateThreshold,
        effective_start: formatDateInput(values.effectiveStart),
      };
      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = TariffService.addNumberTariff(payload);
        successMessage = t("new-tariff-has-been-successfully-added");
        failMessage = t("new-tariff-could-not-be-added");
      } else {
        apiService = TariffService.updateNumberTariff(formData.id, payload);
        successMessage = t("tariff-has-been-successfully-updated");
        failMessage = t("tariff-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "currency") {
      return (
        <Box textAlign={"left"} mt={"-2vh"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="currency"
              style={{
                color: "secondary",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              {t("currency")}:
            </InputLabel>
          )}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={currencies}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={selectedCurrency ? t("currency") : t("select-currency")}
              />
            )}
            value={
              currencies.find((option) => option.value === selectedCurrency) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedCurrency(newValue.value);
              }
            }}
          />
        </Box>
      );
    }
    if (fieldName === "nrc") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="nrc"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("non-recuring-charge")}:
            </InputLabel>
          )}
          <TextField
            id="nrc"
            fullWidth
            label={isMobile() ? t("non-recuring-charge") : undefined}
            type="number"
            placeholder="0.0"
            {...getFieldProps("nrc")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.nrc && errors.nrc)}
            helperText={touched.nrc && errors.nrc}
          />
        </Box>
      );
    }

    if (fieldName === "mrc") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="mrc"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("monthly-recurring-charge")}
            </InputLabel>
          )}
          <TextField
            id="mrc"
            fullWidth
            type="number"
            label={isMobile() ? t("monthly-recurring-charge") : undefined}
            placeholder="0.0"
            {...getFieldProps("mrc")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.mrc && errors.mrc)}
            helperText={touched.mrc && errors.mrc}
          />
        </Box>
      );
    }

    if (fieldName === "initialTime") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="initialTime"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("initial-time")}:
            </InputLabel>
          )}
          <TextField
            id="initialTime"
            fullWidth
            type="number"
            label={isMobile() ? t("initial-time") : undefined}
            placeholder="1.0"
            {...getFieldProps("initialTime")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.initialTime && errors.initialTime)}
            helperText={touched.initialTime && errors.initialTime}
          />
        </Box>
      );
    }

    if (fieldName === "rounding") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="rounding"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("rounding")}:
            </InputLabel>
          )}
          <TextField
            id="rounding"
            fullWidth
            type="number"
            placeholder="1.0"
            label={isMobile() ? t("rounding") : undefined}
            {...getFieldProps("rounding")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.rounding && errors.rounding)}
            helperText={touched.rounding && errors.rounding}
          />
        </Box>
      );
    }

    if (fieldName === "peakRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="peakRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("peak-rate")}:
            </InputLabel>
          )}
          <TextField
            id="peakRate"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("peak-rate") : undefined}
            {...getFieldProps("peakRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.peakRate && errors.peakRate)}
            helperText={touched.peakRate && errors.peakRate}
          />
        </Box>
      );
    }
    if (fieldName === "offPeakRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="offPeakRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("off-peak-rate")}:
            </InputLabel>
          )}
          <TextField
            id="offPeakRate"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("off-peak-rate") : undefined}
            {...getFieldProps("offPeakRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.offPeakRate && errors.offPeakRate)}
            helperText={touched.offPeakRate && errors.offPeakRate}
          />
        </Box>
      );
    }
    if (fieldName === "weekendRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="weekendRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("weekend-rate")}:
            </InputLabel>
          )}
          <TextField
            id="weekendRate"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("weekend-rate") : undefined}
            {...getFieldProps("weekendRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.weekendRate && errors.weekendRate)}
            helperText={touched.weekendRate && errors.weekendRate}
          />
        </Box>
      );
    }
    if (fieldName === "mobileOriginRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="mobileOriginRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("mobile-origin-rate")}:
            </InputLabel>
          )}
          <TextField
            id="mobileOriginRate"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("mobile-origin-rate") : undefined}
            {...getFieldProps("mobileOriginRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.mobileOriginRate && errors.mobileOriginRate)}
            helperText={touched.mobileOriginRate && errors.mobileOriginRate}
          />
        </Box>
      );
    }
    if (fieldName === "payphoneOriginRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="payphoneOriginRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("payphone-origin-rate")}:
            </InputLabel>
          )}
          <TextField
            id="payphoneOriginRate"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("payphone-origin-rate") : undefined}
            {...getFieldProps("payphoneOriginRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.payphoneOriginRate && errors.payphoneOriginRate
            )}
            helperText={touched.payphoneOriginRate && errors.payphoneOriginRate}
          />
        </Box>
      );
    }
    if (fieldName === "connectFee") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="connectFee"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("connect-fee")}:
            </InputLabel>
          )}
          <TextField
            id="connectFee"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("connect-fee") : undefined}
            {...getFieldProps("connectFee")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.connectFee && errors.connectFee)}
            helperText={touched.connectFee && errors.connectFee}
          />
        </Box>
      );
    }
    if (fieldName === "portInFee") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="portInFee"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("port-in-fee")}:
            </InputLabel>
          )}
          <TextField
            id="portInFee"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("port-in-fee") : undefined}
            {...getFieldProps("portInFee")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.portInFee && errors.portInFee)}
            helperText={touched.portInFee && errors.portInFee}
          />
        </Box>
      );
    }
    if (fieldName === "portOutFee") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="portOutFee"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("port-out-fee")}:
            </InputLabel>
          )}
          <TextField
            id="portOutFee"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("port-out-fee") : undefined}
            {...getFieldProps("portOutFee")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.portOutFee && errors.portOutFee)}
            helperText={touched.portOutFee && errors.portOutFee}
          />
        </Box>
      );
    }

    if (fieldName === "smsRate") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="smsRate"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("sms-rate")}:
            </InputLabel>
          )}
          <TextField
            id="smsRate"
            fullWidth
            type="number"
            label={isMobile() ? t("sms-rate") : undefined}
            placeholder="0.0"
            {...getFieldProps("smsRate")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.smsRate && errors.smsRate)}
            helperText={touched.smsRate && errors.smsRate}
          />
        </Box>
      );
    }
    if (fieldName === "smsRateThreshold") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="smsRateThreshold"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("sms-rate-threshold")}:
            </InputLabel>
          )}
          <TextField
            id="smsRateThreshold"
            fullWidth
            type="number"
            placeholder="0.0"
            label={isMobile() ? t("sms-rate-threshold") : undefined}
            {...getFieldProps("smsRateThreshold")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.smsRateThreshold && errors.smsRateThreshold)}
            helperText={touched.smsRateThreshold && errors.smsRateThreshold}
          />
        </Box>
      );
    }

    if (fieldName === "effectiveStart") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-start-date")}
            value={effectiveStartDate}
            onChange={(newValue) => {
              setEffectiveStartDate(newValue);
              setFieldValue("effectiveStart", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Paper sx={{ height: "70vh", overflowY: "scroll", padding: 2 }}>
            <Grid container spacing={3} justifyContent={"center"}>
              <Grid item xs={12} sm={12}>
                {getFieldByName("name")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("currency")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("nrc")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("mrc")}
              </Grid>

              <Grid container item xs={12} sm={12}>
                <Grid item sm={12} textAlign={"left"}>
                  {t("call-timers")}
                  <Divider sx={{ marginY: 1 }} />
                </Grid>

                <Grid item sm={6}>
                  {getFieldByName("initialTime")}
                </Grid>
                <Grid item sm={6}>
                  {getFieldByName("rounding")}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("peakRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("offPeakRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("weekendRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("mobileOriginRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("payphoneOriginRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("connectFee")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("portInFee")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("portOutFee")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("smsRate")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("smsRateThreshold")}
              </Grid>

              <Grid item xs={12} sm={6}>
                {getFieldByName("effectiveStart")}
              </Grid>
            </Grid>
          </Paper>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
