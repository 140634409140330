import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import UcContainer from './ucConfig/ucContainers/UcContainer';

// ----------------------------------------------------------------------


export default function UcContainers() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('uc-container'), component: <UcContainer /> },
    ];

    const TITLE = t('uc-container');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
