import api from "src/api/Api";
import urls from "src/api/Urls";

export const addVoiceEndpoint = (payload) => {
  return api.post(urls.add_feature, payload);
};

export const listVoiceEndpoints = (params) => {
  return api.get(urls.list_numbers, { params: params });
};

export const updateVoiceEndpoint = (id, payload) => {
  return api.patch(urls.update_feature(id), payload);
};

export const deleteVoiceEndpoint = (id) => {
  return api.delete(urls.delete_feature(id));
};

export const addSmsEndpoint = (payload) => {
  return api.post(urls.add_feature, payload);
};

export const listSmsEndpoints = (params) => {
  return api.get(urls.list_numbers, { params: params });
};

export const updateSmsEndpoint = (id, payload) => {
  return api.patch(urls.update_feature(id), payload);
};

export const deleteSmsEndpoint = (id) => {
  return api.delete(urls.delete_feature(id));
};

export const listNumbersWithRoutings = (params) => {
  return api.get(urls.list_numbers, { params: params });
};
