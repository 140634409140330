import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteThemeForm({
  setModalStatus,
  selectedTheme,
  successCallback,

}) {
  const { t } = useTranslation();

  const handleDelete = () => {
    setModalStatus(false); // Close the modal
    successCallback();     // Execute the successCallback logic
  };
  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">
        {t("are-you-want-to-sure-delete-this-theme")}
        </Typography>
        <Typography component="subtitle1" variant="h6">
          {selectedTheme}
        </Typography>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            onClick={() => handleDelete()}
          >
            {t("delete")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
