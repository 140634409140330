import api from "src/api/Api";
import urls from "src/api/Urls";



//Containers

export const listContainers = (params) => {
    return api.get(urls.list_containers, {params});
}

export const addContainer = (payload) => {
    return api.post(urls.add_container, payload);
}
export const getContainer = (id) => {
    return api.get(urls.get_container(id));
} 

export const updateContainers = (id, payload) => {
    return api.put(urls.update_container(id), payload);
} 

export const deleteContainer = (id, params) => {
    return api.delete(urls.delete_container(id), {params});
}

//Numbers Service ?
export const listNumberServiceContainers = (params) => {
    return api.get(urls.list_number_containers, {params});
}

//Outbound Service
export const listOutboundServiceContainers = (params) => {
    return api.get(urls.list_outbound_containers, {params});
}

//Package Tariff Mapping


export const listPackageTariffMapping = (params) => {
    return(api.get(urls.list_package_tariff_mapping, {params}));
}
export const addPackageTariffMapping = (payload) => {
    return api.post(urls.add_package_tariff_map, payload);
}

export const addPackageTariffMappingArray = (payload) => {
    return api.post(urls.add_package_tariff_map_array, payload);
}

export const updatePackageTariffMapping = (id, payload) => {
    return api.put(urls.update_package_tariff_map(id), payload);
} 

export const deletePackageTariffMapping = (id) => {
    return api.delete(urls.delete_package_tariff_map(id));
}
