import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ShoppingCart from "./shoopingCart/ShoppingCart";
// ----------------------------------------------------------------------

export default function ShoppingCarts() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("shopping-cart"), component: <ShoppingCart /> },
  ];

  const TITLE = t("purchase");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
