import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { UserService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { canUserWriteIt, rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import LoupeIcon from "@mui/icons-material/Loupe";
import { useTranslation } from "react-i18next";
import { UserForm, DeleteUserForm } from "./form";
import { useParams } from "react-router-dom";
import AsignCustomerRoleForm from "./form/AsignCustomerRoleForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function CustomerUser() {
  const { t } = useTranslation();
  const { companyName } = useParams();
  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "companyName", label: t("company-name") },
    { key: "firstName", label: t("first-name") },
    { key: "lastName", label: t("last-name") },
    { key: "role", label: t("role") },
    { key: "email", label: t("email"), align: "center" },
    canUserWriteIt("customers_and_users")
      ? { key: "action", label: t("actions"), align: "right", padding: "4em" }
      : null,
  ].filter(Boolean);

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    customer_name: { key: "companyName", index: 1 },
    first_name: { key: "firstName", index: 2 },
    last_name: { key: "lastName", index: 3 },
    role: { key: "role", index: 4 },
    email: { key: "email", index: 5, align: "center" },
    roles: { key: "roles", index: 6, noRender: true },
    properties: { key: "properties", index: 7, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterCustomerName, setFilterCustomerName] = useState(companyName);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openPasswordModal, setPasswordModalStatus] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchCustomerUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterName ? filterName : undefined,
      customer_name: filterCustomerName ? filterCustomerName : undefined,
    };
    UserService.listCustomerUsers(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.roles) {
            console.log(itemData.roles);
            let roleNames = itemData.roles
              .sort((a, b) => a?.role?.id - b?.role?.id)
              .map((item) => item?.role?.name);

            item[TABLE_FIELD_MAPPING["role"].index] = {
              ...TABLE_FIELD_MAPPING["role"],
              value: roleNames.join(", "),
            };
          }

          if (itemData.customer) {
            const customerName = itemData.customer.name;

            item[TABLE_FIELD_MAPPING["customer_name"].index] = {
              ...TABLE_FIELD_MAPPING["customer_name"],
              value: customerName,
            };
          }
          Object.entries(itemData).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "role" &&
              key !== "customer_name"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomerUsers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "password") {
      setPasswordModalStatus(!openPasswordModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "role") {
      setOpenRoleModal(!openRoleModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };
  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-customer-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-customer-user"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {canUserWriteIt("customer_permissions") && (
          <Tooltip title={t("assign-role")}>
            <IconButton
              color="secondary"
              size="small"
              aria-label="asign-role"
              onClick={() => modalHandler("role", index)}
            >
              <LoupeIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("override-password")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="update-password"
            onClick={() => modalHandler("password", index)}
          >
            <LockResetIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-customer-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleSelection = (data) => {
    setSelectedRow(data);
    setAddModalStatus(!openAddModal);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        sx={{
          maxHeight: "100%",
          overflow: "auto",
          width: "40%",
        }}
        title={t("add-customer-user")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <UserForm
            formType={"add"}
            formData={{}}
            successCallback={fetchCustomerUsers}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          maxHeight: "100%",
          overflow: "auto",
          width: "40%",
        }}
        title={t("edit-customer-user")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UserForm
            formType={"edit"}
            formData={selectedRow}
            successCallback={fetchCustomerUsers}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("assign-role")}
        open={openRoleModal}
        setOpen={setOpenRoleModal}
        children={
          <AsignCustomerRoleForm
            formData={selectedRow}
            successCallback={fetchCustomerUsers}
            setModalStatus={setOpenRoleModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("set-password")}
        open={openPasswordModal}
        setOpen={setPasswordModalStatus}
        children={
          <UserForm
            formType={"password"}
            formData={selectedRow}
            successCallback={fetchCustomerUsers}
            setModalStatus={setPasswordModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-admin-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteUserForm
            packageType="ucass"
            successCallback={fetchCustomerUsers}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("company-name")}
                value={filterCustomerName}
                onChange={(event) => {
                  setFilterCustomerName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchCustomerUsers();
                }}
              />
            </SearchButtonContainer>
          </Grid>
          {canUserWriteIt("customers_and_users") && (
            <Grid
              item
              md={4}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AddNewButton
                label={t("add-new-customer-user")}
                onClick={() => modalHandler("add")}
              />
            </Grid>
          )}
        </Grid>
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={canUserWriteIt("customers_and_users") && getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
