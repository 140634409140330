import { id_ID } from "faker/lib/locales";

const urls = {
  // common
  tts_languages: "tts_languages", // GET
  countries: "/core/v1/country/get-countries", // GET
  number_enabled_countries: "organizations/phone_numbers/countries", // GET
  documentation: "swagger.json", // GET
  currencies: "/core/v1/currency/get-currencies", //GET
  // user
  login: "customer/v1/auth/access-token",

  update_customer_user_profile: (id) => {
    return `/customer/v1/users/update-customer-user/${id}`;
  }, // PUT
  change_customer_user_password: (id) => {
    return `/customer/v1/users/change-customer-user-password/${id}`;
  }, // PUT

  //Outbound Countries
  outbound_countries: "/admin/v1/outbound-countries/get-outbound-countries", // GET
  set_outbound_countries:
    "/admin/v1/outbound-countries/multiple-update-outbound-country", // PUT

  //Inbound Countries
  inbound_countries: "/admin/v1/inbound-countries/get-inbound-countries", // GET
  set_inbound_countries:
    "/admin/v1/inbound-countries/multiple-update-inbound-country", // PUT

  //Countries

  list_countries: "/core/v1/country/get-countries", // GET

  list_partition_countries: "/customer/v1/countries/get-countries", // GET

  //Currencies
  currencies: "/core/v1/currency/get-currencies", // GET
  set_currencies: "/admin/v1/currencies/multiple-update-currencies", // PUT

  partitionCurrencies:
    "/admin/v1/currencies/get-partition-currencies", //GET
  set_partition_currencies:
    "/admin/v1/currencies/multiple-update-partition-currency", //PUT

  admin_accept_invitation: "/admin/v1/admin-users/accept_admin_user_invitation", //POST

  admin_login: "/admin/v1/auth/access-token", // TODO: convert back to admin_login when roles are implemented
  update_admin_user_profile: (id) => {
    return `/admin/v1/admin-users/update-admin-user/${id}`;
  }, // PATCH
  get_profile: (id) => {
    return `users/${id}`;
  }, // GET
  update_password: (id) => {
    return `users/${id}/update_password`;
  }, // PUT
  update_selected_language: (id) => {
    return `users/${id}/update_selected_language`;
  }, // PUT
  update_preferred_landing_view: (id) => {
    return `users/${id}/update_preferred_landing_view`;
  }, // PUT
  update_always_ask_landing_page: (id) => {
    return `users/${id}/update_always_ask_landing_page`;
  }, // PUT
  update_service_groups: "users/set_service_groups", // PUT

  change_admin_user_password: (id) => {
    return `/admin/v1/admin-users/change-admin-user-password/${id}`;
  }, // PUT

  // 2fa
  tfa_services: "2fa_services",
  tfa_services_update: (id) => {
    return `2fa_services/${id}`;
  },
  otp_send: "2fa/send",
  otp_verify: "2fa/verify",
  tfa_service_reports: "reports/2fa_services",
  account_reports: "reports/account",
  //number masking
  number_masking_services: "number_masking_services",
  number_masking_services_update: (id) => {
    return `number_masking_services/${id}`;
  },
  masking_numbers: "masking_numbers",
  add_masking_number: "number_masking_services/add_masking_number",
  number_masking_sessions: "number_masking_sessions",
  number_masking_participants: "number_masking_participants",
  // contacts
  list_add_contacts: "contacts",
  get_update_delete_contact: (id) => {
    return `contacts/${id}`;
  },
  list_add_contact_groups: "contact_groups",
  get_update_delete_contact_group: (id) => {
    return `contact_groups/${id}`;
  },
  assign_contact: "contact_groups/assign_contact", // POST
  // campaign management
  list_campaigns: "campaigns", // GET
  add_campaign: "campaigns", // POST
  get_campaign: (id) => {
    return `campaigns/${id}`;
  }, // GET
  update_campaign: (id) => {
    return `campaigns/${id}`;
  }, // PATCH
  delete_campaign: (id) => {
    return `campaigns/${id}`;
  }, // DELETE
  clone_campaign: (id) => {
    return `campaigns/${id}/clone`;
  }, // POST
  // announcement
  list_announcements: "announcements", // GET
  add_announcement: "announcements", // POST
  get_announcement: (id) => {
    return `announcements/${id}`;
  }, // GET
  update_announcement: (id) => {
    return `announcements/${id}`;
  }, // POST,
  delete_announcement: (id) => {
    return `announcements/${id}`;
  }, // DELETE,
  get_file: (id) => {
    return `files/${id}`;
  },
  // programmable sms
  list_sms: "programmable_sms", // GET
  send_sms: "programmable_sms", // POST
  // transactions
  add_credit_paypal: (id) => {
    return `sub_organizations/${id}/create_paypal_redirect_url`;
  }, // POST
  finalize_credit_paypal: "sub_organizations/execute_paypal_payment", // GET
  //--------------------------------admin-----------------------------------//
  // organization accounts

  list_customers: "/admin/v1/users/customers", // GET
  list_customers_with_details: "/admin/v1/uc-management/customers_with_uc_details", // GET
  create_customer: "/customer/v1/users/register/customer_user", // POST
  add_customer: "/admin/v1/users/create_customer", // POST
  add_customer_without_user: "/admin/v1/users/create_customer_without_user", // POST

  register_customer_with_user: "/customer/v1/users/register/customer_and_customer_user", // POST

  delete_customer: (id) => {
    return `/admin/v1/users/delete-customer/${id}`;
  }, // DELETE

  manage_customer_balance: (id) => {
    return `organizations/admin/${id}/manage_balance`;
  }, // POST
  list_organization_accounts: (id) => {
    return `organizations/admin/${id}/list_accounts`;
  }, // GET
  get_organization_accounts_report: (id) => {
    return `organizations/admin/${id}/accounts_report`;
  }, // GET
  add_organization_account: (id) => {
    return `organizations/admin/${id}/add_account`;
  }, // POST
  delete_organization_account: (id) => {
    return `organizations/admin/${id}/delete_account`;
  }, // DELETE
  disable_organization_account: (id) => {
    return `organizations/admin/${id}/disable_account`;
  }, // POST
  enable_organization_account: (id) => {
    return `organizations/admin/${id}/enable_account`;
  }, // POST
  update_organization_account: (id) => {
    return `organizations/admin/${id}/update_account`;
  }, // PUT
  get_organization_account_credentials: (id) => {
    return `organizations/admin/${id}/accounts_credentials`;
  }, // GET
  list_partitions: "/admin/v1/partitions/get_partitions", // GET
  // sms tariffs
  list_sms_tariffs: "/sms_tariffs/admin", // GET
  get_sms_tariff: (id) => {
    return `/sms_tariffs/admin/${id}`;
  }, // GET
  add_sms_tariff: "/sms_tariffs/admin", // POST
  update_sms_tariff: (id) => {
    return `/sms_tariffs/admin/${id}`;
  }, // PUT
  delete_sms_tariff: (id) => {
    return `/sms_tariffs/admin/${id}`;
  }, // DELETE
  // voice tariffs
  list_voice_tariffs: "/voice_tariffs/admin", // GET
  get_voice_tariff: (id) => {
    return `/voice_tariffs/admin/${id}`;
  }, // GET
  add_voice_tariff: "/voice_tariffs/admin", // POST
  update_voice_tariff: (id) => {
    return `/voice_tariffs/admin/${id}`;
  }, // PUT
  delete_voice_tariff: (id) => {
    return `/voice_tariffs/admin/${id}`;
  }, // DELETE
  // tts tariffs
  list_tts_tariffs: "/tts_tariffs/admin", // GET
  get_tts_tariff: (id) => {
    return `/tts_tariffs/admin/${id}`;
  }, // GET
  add_tts_tariff: "/tts_tariffs/admin", // POST
  update_tts_tariff: (id) => {
    return `/tts_tariffs/admin/${id}`;
  }, // PUT
  delete_tts_tariff: (id) => {
    return `/tts_tariffs/admin/${id}`;
  }, // DELETE
  // stt tariffs
  list_stt_tariffs: "/stt_tariffs/admin", // GET
  get_stt_tariff: (id) => {
    return `/stt_tariffs/admin/${id}`;
  }, // GET
  add_stt_tariff: "/stt_tariffs/admin", // POST
  update_stt_tariff: (id) => {
    return `/stt_tariffs/admin/${id}`;
  }, // PUT
  delete_stt_tariff: (id) => {
    return `/stt_tariffs/admin/${id}`;
  }, // DELETE
  // number tariffs
  list_number_tariffs: "/admin/v1/tariffs/number/get-number-tariffs", // GET

  get_number_tariff: (id) => {
    return `/number_tariffs/admin/${id}`;
  }, // GET
  add_number_tariff: "/admin/v1/tariffs/number/create-number-tariff", // POST
  update_number_tariff: (id) => {
    return `/admin/v1/tariffs/number/update-number-tariff/${id}`;
  }, // PUT
  delete_number_tariff: (id) => {
    return `/admin/v1/tariffs/number/delete-uc-tariff-package/${id}`;
  }, // DELETE
  // 2fa tariffs
  list_tfa_tariffs: "/tfa_tariffs/admin", // GET
  get_tfa_tariff: (id) => {
    return `/tfa_tariffs/admin/${id}`;
  }, // GET
  add_tfa_tariff: "/tfa_tariffs/admin", // POST
  update_tfa_tariff: (id) => {
    return `/tfa_tariffs/admin/${id}`;
  }, // PUT
  delete_tfa_tariff: (id) => {
    return `/tfa_tariffs/admin/${id}`;
  }, // DELETE
  // number masking session tariffs
  list_number_masking_tariffs: "/number_masking_tariffs/admin", // GET
  get_number_masking_tariff: (id) => {
    return `/number_masking_tariffs/admin/${id}`;
  }, // GET
  add_number_masking_tariff: "/number_masking_tariffs/admin", // POST
  update_number_masking_tariff: (id) => {
    return `/number_masking_tariffs/admin/${id}`;
  }, // PUT
  delete_number_masking_tariff: (id) => {
    return `/number_masking_tariffs/admin/${id}`;
  }, // DELETE

  //UCaaS Tariffs

  list_ucass_tariffs: "/admin/v1/tariffs/ucaas/get-ucaas-tariffs", // GET
  get_ucass_tariff: (id) => {
    return `/ucass_tariffs/admin/${id}`;
  }, // GET
  add_ucass_tariff: "/admin/v1/tariffs/ucaas/create-ucaas-tariff", // POST
  update_ucass_tariff: (id) => {
    return `/admin/v1/tariffs/ucaas/update-ucaas-tariff/${id}`;
  }, // PUT
  delete_ucass_tariff: (id) => {
    return `/admin/v1/tariffs/ucaas/delete-uc-tariff-package/${id}`;
  }, // DELETE

  // logs
  list_logs_sms: "/logs/sms", // GET
  list_logs_voice: "/logs/voice", // GET
  // organization users
  /*  (organization_id) => { return `/organizations/admin/${organization_id}/list_users` } */
  list_organization_users: (organization_id) => {
    return `/admin/v1/users/customer-users`;
  }, // GET
  add_organization_user: (organization_id) => {
    return `/organizations/admin/${organization_id}/add_user`;
  }, // POST
  delete_organization_user: (organization_id) => {
    return `/organizations/admin/${organization_id}/delete_user`;
  }, // DELETE
  update_organization_user: (organization_id) => {
    return `/organizations/admin/${organization_id}/update_user`;
  }, // PUT

  // ADMIN PERMISSIONS - ROLES / FUNCTION GROUPS

  list_admin_roles: "/admin/v1/admin-permissions/get-roles", // GET
  add_admin_role: "/admin/v1/admin-permissions/create-role-with-function-groups", // POST
  update_admin_role: (id) => {
    return `/admin/v1/admin-permissions/update-role/${id}`;
  }, // PATCH
  delete_admin_role: (id) => {
    return `/admin/v1/admin-permissions/delete-role/${id}`;
  }, // DELETE

  list_admin_function_groups: "/admin/v1/admin-permissions/get-function-groups", // GET
  get_function_groups_of_admin_role : (id) => {
    return `/admin/v1/admin-permissions/roles/${id}`
  },

  get_admin_user_roles : (id) => {
    return `/admin/v1/admin-permissions/admin-roles/${id}`
  },

  assign_function_group_to_role : (id, function_group_id, is_delete, read_allowed, write_allowed) => {
    return `/admin/v1/admin-permissions/roles/${id}?function_group_id=${function_group_id}&is_delete=${is_delete}&read_allowed=${read_allowed}&write_allowed=${write_allowed}`
  },

  assign_role_to_admin: (id, roleID, isDelete) => {
    return `/admin/v1/admin-permissions/admin-roles/${id}?role_id=${roleID}&is_delete=${isDelete}`;
  }, //PUT


  //Customer Permissions

  list_customer_roles: "/admin/v1/customer-user-permissions/get-roles", // GET
  list_customer_function_groups: "/admin/v1/customer-user-permissions/get-function-groups",
  get_function_groups_of_customer_role: (id) => {
    return `/admin/v1/customer-user-permissions/roles/${id}`
  },
  get_customer_user_roles: (id) => {
    return `/admin/v1/customer-user-permissions/customer-roles/${id}`
  },

  

  // numbers
  list_numbers_admin: "/admin/v1/numbers/get-numbers", //GET
  list_numbers: "/customer/v1/numbers_ts/get_my_numbers", //GET
  list_available_numbers: "/customer/v1/numbers_ts/list_available_numbers", //GET
  add_number: "/organizations/phone_numbers", //POST
  buy_number: "/customer/v1/numbers_ts/buy_number", //POST
  upload_number_document: "/customer/v1/numbers_ts/document_upload_for_numbers", //POST
  search_numbers: "/organizations/phone_numbers/search_numbers", //GET
  deallocate_number: (id) => {
    return `/organizations/phone_numbers/${id}`;
  }, //DELETE
  remove_service: (id) => {
    return `/organizations/remove-service/phone_numbers/${id}`;
  }, //DELETE
  update_number: (id) => {
    return `/organizations/phone_numbers/${id}`;
  }, // PUT
  get_forwareded_numbers: "organizations/phone_numbers/get_forwareded_numbers", // GET
  // site config
  set_style: "/sites/set_style", // PUT
  get_style: "/sites/get_style", // GET
  set_logo: "/sites/set_logo", // PUT
  set_favicon: "/sites/set_favicon", // PUT
  set_landing_image: "/sites/set_landing_image", // PUT
  get_all_supported_languages: "supported_languages", // GET
  update_supported_languages: "supported_languages/update_supported_languages", // POST
  update_settings: (id) => `/organizations/admin/${id}/update_settings`, // PUT
  get_settings: (id) => `/organizations/admin/${id}/get_settings`, // PUT
  // number masking
  list_number_masking_services: "/number_masking_services", // GET
  update_number_masking_service: (id) => {
    return `number_masking_services/${id}`;
  }, // PATCH
  delete_number_masking_service: (id) => {
    return `number_masking_services/${id}`;
  }, // DELETE
  list_masking_numbers: "/masking_numbers", // GET
  update_masking_number: (id) => {
    return `masking_numbers/${id}`;
  }, // PATCH
  delete_masking_number: (id) => {
    return `masking_numbers/${id}`;
  }, // DELETE
  list_number_masking_sessions: "/number_masking_sessions", // GET
  update_number_masking_session: (id) => {
    return `number_masking_sessions/${id}`;
  }, // PATCH
  delete_number_masking_session: (id) => {
    return `number_masking_sessions/${id}`;
  }, // DELETE
  list_number_masking_sessions_participants: "/number_masking_participants", // GET
  update_number_masking_session_participants: (id) => {
    return `number_masking_participants/${id}`;
  }, // PATCH
  delete_number_masking_session_participants: (id) => {
    return `number_masking_participants/${id}`;
  }, // DELETE
  assign_masking_number_to_service:
    "/number_masking_services/add_masking_number", // POST

  // voice configuration
  list_voice_configuration: "/admin/v1/network-nodes/voice", //GET
  add_voice_configuration: "/admin/v1/network-nodes/voice", //POST
  get_voice_configuration: (id) => {
    return `/admin/v1/network-nodes/voice/${id}`;
  }, // GET
  update_voice_configuration: (id) => {
    return `/admin/v1/network-nodes/voice/${id}`;
  }, //PUT
  delete_voice_configuration: (id) => {
    return `/admin/v1/network-nodes/voice/${id}`;
  }, //DELETE

  // sms configuration
  list_sms_configuration: "/admin/v1/network-nodes/sms", //GET
  add_sms_configuration: "/admin/v1/network-nodes/sms", //POST
  get_sms_configuration: (id) => {
    return `/admin/v1/network-nodes/sms/${id}`;
  }, // GET
  update_sms_configuration: (id) => {
    return `/admin/v1/network-nodes/sms/${id}`;
  }, //PUT
  delete_sms_configuration: (id) => {
    return `/admin/v1/network-nodes/sms/${id}`;
  }, //DELETE

  // DTMF Menu
  dtmf_menu_services: "/dtmf_menus_services", //GET
  dtmf_menu_services_sid: (sid) => {
    return `/dtmf_menus_services/${sid}`;
  },

  // PACKAGES

  list_packages: "/admin/v1/packages/list-packages", // GET
  add_package: "/admin/v1/packages/create-package", //POST
  get_package: (id) => {
    return `/admin/v1/packages/get-package/${id}`;
  }, // GET
  update_package: (id) => {
    return `/admin/v1/packages/update-package/${id}`;
  }, // PATCH
  delete_package: (id) => {
    return `/admin/v1/packages/delete-package/${id}`;
  }, // DELETE

  disable_enable_package: (id) => {
    return `/admin/v1/packages/change-enabled/${id}`;
  }, // PUT


  //Subscription Packages (Service Packages)
  list_subscription_packages: "/admin/v1/subscription-packages/get-subscription-packages", // GET
  list_subscription_packages_without_pagination: "/admin/v1/subscription-packages/get-subscription-packages-no-pagination", // GET
  add_subscription_package: "/admin/v1/subscription-packages/create-subscription-package", // POST
  update_subscription_package: "/admin/v1/subscription-packages/update-subscription-package", // PUT
  delete_subscription_package: (id) => {
    return `/admin/v1/subscription-packages/delete-subscription-package/${id}`;
  }, // DELETE
  set_subscription_package: (id) => {
    return `/admin/v1/users/update-customer/${id}`; // POST
  },


  //Services -CPaaS
  list_cpaas_services: "/admin/v1/services/list-services", // GET
  


  // features
  list_features: `/admin/v1/ucaas-features/get-uc-features`, // GET
  add_feature: `/admin/v1/ucaas-features/create-uc-feature`, // POST
  update_feature: (id) => {
    return `/admin/v1/ucaas-features/update-uc-feature/${id}`;
  }, //PATCH
  delete_feature: (id) => {
    return `/admin/v1/ucaas-features/delete-uc-feature/${id}`;
  }, //DELETE

  // partition
  list_partition: `/admin/v1/partitions/get-partitions`, // GET
  create_partition: `/admin/v1/partitions/add_partition`, // POST
  
  /* get_partition: (id) => {
    return `partition/${id}`;
  }, // GET
  manage_partition: (id) => {
    return `/admin/v1/partitions/update-partition/${id}`;
  }, //PATCH
  delete_partition: (id) => {
    return `/admin/v1/partitions/delete-partition/${id}`;
  }, //DELETE */

  //CustomerUsers
  list_customer_users: `/admin/v1/users/customer-users`,

  create_customer_user: "/admin/v1/users/create-customer-user",

  create_customer_user_invitation: "/admin/v1/users/create-customer-user-invitation",

  update_customer_user: (id) => {
    return `/admin/v1/users/update-customer-user/${id}`
  },
  delete_customer_user: (id) => {
    return `/admin/v1/users/delete-customer-user/${id}`
  },

  update_role_of_customer_user: (id, roleID, isDelete) => {
    return `/admin/v1/customer-user-permissions/customer-roles/${id}?role_id=${roleID}&is_delete=${isDelete}`;
  }, //PUT
  get_current_user_detail: `/api/customer/v1/users/me`,


  //AdminUsers
  list_admin_users: `/admin/v1/admin-users/list-admin-users`,
  add_admin_user: `/admin/v1/admin-users/create_admin_user_invitation`,
  update_admin_user: (id) => {
    return `/admin/v1/admin-users/update-admin-user/${id}`;
  },
  delete_admin_user: (id) => {
    return `/admin/v1/admin-users/delete-admin-user/${id}`;
  },

  // password reset
  customer_password_reset_token:
    "/customer/v1/auth/create_password_reset_token",
  customer_password_reset: "/customer/v1/auth/reset_password",
  admin_password_reset_token: "/admin/v1/auth/create_password_reset_token",
  admin_password_reset: "/admin/v1/auth/reset_password",

  //Engine

  list_engines: "/admin/v1/engine/list-engines",
  add_engine: "/admin/v1/engine/create-engine",
  get_engine: (id) => {
    return `/admin/v1/engine/get-engine/${id}`;
  },
  update_engine: (id) => {
    return `/admin/v1/engine/update-engine/${id}`;
  },
  delete_engine: (id) => {
    //no
    return `/admin/v1/engine/delete-engine/${id}`;
  },

  //Engine Sub Types
  list_engine_sub_types: "/admin/v1/engine/list-engine-sub-types",

  //UC User
  list_uc_users: "/admin/v1/uc-management/get-uc-users",
  add_uc_user: "/admin/v1/uc-management/create-uc-user",
  assign_users_to_service: (id) => {
    return `/admin/v1/uc-management/uc-service-assign-uc-users/${id}`;
  },
  assign_numbers_to_service: (id) => {
    return `/admin/v1/uc-management/uc-service-assign-numbers/${id}`;
  },
  bulk_upload_uc_user: "/admin/v1/uc-management/create-uc-user-bulk",
  update_uc_user: (id) => {
    return `/admin/v1/uc-management/update-uc-user/${id}`;
  },
  delete_uc_user: (id) => {
    return `/admin/v1/uc-management/delete-uc-user/${id}`;
  },
  delete_service_from_user: "/admin/v1/uc-management/delete-service-from-user",

  //UC Services

  get_uc_services : "/admin/v1/uc-management/get-uc-services",
  get_uc_service : (id) => {
    return `/admin/v1/uc-management/get-uc-service/${id}`
  },

  //Containers

  list_containers: "/admin/v1/containers/list-containers",
  add_container: "/admin/v1/containers/create-container",

  get_container: (id) => {
    return `/admin/v1/containers/get-container/${id}`;
  },
  update_container: (id) => {
    return `/admin/v1/containers/update-container/${id}`;
  }, //PATCH
  delete_container: (id) => {
    return `/admin/v1/containers/delete-container/${id}`;
  },

  //CPaaS Tariffs
  list_cpaas_tariffs: "/admin/v1/tariffs/cpaas/list", // GET
  add_cpaas_tariff: "/admin/v1/tariffs/cpaas/create", // POST
  update_cpaas_tariff: (id) => {
    return `/admin/v1/tariffs/cpaas/${id}`;
  }, // PATCH
  delete_cpaas_tariff: (id) => {
    return `/admin/v1/tariffs/cpaas/${id}`;
  }, // DELETE

  // IOT Tariffs

  list_iot_tariffs: "/admin/v1/tariffs/iot/list", // GET
  get_iot_tariff: (id) => {
    return `/admin/v1/tariffs/iot/${id}`;
  }, //GET
  add_iot_tariff: "/admin/v1/tariffs/iot/create", // POST
  update_iot_tariff: (id) => {
    return `/admin/v1/tariffs/iot/${id}`;
  }, // PUT
  delete_iot_tariff: (id) => {
    return `/admin/v1/tariffs/iot/${id}`;
  }, // DELETE

  //Outbound Tariffs

  list_outbound_tariffs:
  "/admin/v1/outbound-tariffs/list", // GET

  // Outbound Tariffs - VOICE

  list_outbound_voice_tariffs:
    "/admin/v1/outbound-tariffs/voice/get-outbound-voice-tariffs", // GET
  get_outbound_voice_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/voice/get-outbound-voice-tariff/${id}`;
  }, //GET
  add_outbound_voice_tariff:
    "/admin/v1/outbound-tariffs/voice/create-outbound-voice-tariff", // POST
  update_outbound_voice_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/voice/update-outbound-voice-tariff/${id}`;
  }, // PUT
  delete_outbound_voice_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/voice/delete-outbound-voice-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - SMS

  list_outbound_sms_tariffs:
    "/admin/v1/outbound-tariffs/sms/get-outbound-sms-tariffs", // GET
  get_outbound_sms_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/sms/get-outbound-sms-tariff/${id}`;
  }, //GET
  add_outbound_sms_tariff:
    "/admin/v1/outbound-tariffs/sms/create-outbound-sms-tariff", // POST
  update_outbound_sms_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/sms/update-outbound-sms-tariff/${id}`;
  }, // PUT
  delete_outbound_sms_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/sms/delete-outbound-sms-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - Whatsapp

  list_outbound_whatsapp_tariffs:
    "/admin/v1/outbound-tariffs/whatsapp/get-outbound-whatsapp-tariffs", // GET
  get_outbound_whatsapp_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/whatsapp/get-outbound-whatsapp-tariff/${id}`;
  }, //GET
  add_outbound_whatsapp_tariff:
    "/admin/v1/outbound-tariffs/whatsapp/create-outbound-whatsapp-tariff", // POST
  update_outbound_whatsapp_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/whatsapp/update-outbound-whatsapp-tariff/${id}`;
  }, // PUT
  delete_outbound_whatsapp_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/whatsapp/delete-outbound-whatsapp-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - Telegram

  list_outbound_telegram_tariffs:
    "/admin/v1/outbound-tariffs/telegram/get-outbound-telegram-tariffs", // GET
  get_outbound_telegram_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/telegram/get-outbound-telegram-tariff/${id}`;
  }, //GET
  add_outbound_telegram_tariff:
    "/admin/v1/outbound-tariffs/telegram/create-outbound-telegram-tariff", // POST
  update_outbound_telegram_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/telegram/update-outbound-telegram-tariff/${id}`;
  }, // PUT
  delete_outbound_telegram_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/telegram/delete-outbound-telegram-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - RCS

  list_outbound_rcs_tariffs:
    "/admin/v1/outbound-tariffs/rcs/get-outbound-rcs-tariff", // GET
  get_outbound_rcs_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/rcs/get-outbound-rcs-tariff/${id}`;
  }, //GET
  add_outbound_rcs_tariff:
    "/admin/v1/outbound-tariffs/rcs/create-outbound-rcs-tariff", // POST
  update_outbound_rcs_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/rcs/update-outbound-rcs-tariff/${id}`;
  }, // PUT
  delete_outbound_rcs_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/rcs/delete-outbound-rcs-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - GBC

  list_outbound_gbc_tariffs:
    "/admin/v1/outbound-tariffs/gbc/get-outbound-google_business_chat-tariffs", // GET
  get_outbound_gbc_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/gbc/get-outbound-google_business_chat-tariff/${id}`;
  }, //GET
  add_outbound_gbc_tariff:
    "/admin/v1/outbound-tariffs/gbc/create-outbound-google_business_chat-tariff", // POST
  update_outbound_gbc_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/gbc/update-outbound-google_business_chat-tariff/${id}`;
  }, // PUT
  delete_outbound_gbc_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/gbc/delete-outbound-google_business_chat-tariff/${id}`;
  }, // DELETE

  // Outbound Tariffs - Apple Message

  list_outbound_apple_message_tariffs:
    "/admin/v1/outbound-tariffs/apple-message/get-outbound-apple_message-tariffs", // GET
  get_outbound_apple_message_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/apple-message/get-outbound-apple_message-tariff/${id}`;
  }, //GET
  add_outbound_apple_message_tariff:
    "/admin/v1/outbound-tariffs/apple-message/create-outbound-apple_message-tariff", // POST
  update_outbound_apple_message_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/apple-message/update-outbound-apple_message-tariff/${id}`;
  }, // PUT
  delete_outbound_apple_message_tariff: (id) => {
    return `/admin/v1/outbound-tariffs/apple-message/delete-outbound-apple_message-tariff/${id}`;
  }, // DELETE

  //Outbound Rates - VOICE

  list_outbound_voice_rates:
    "/admin/v1/outbound-tariff-rates/voice/get-outbound-voice-tariff-rates", // GET
  get_outbound_voice_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/voice/get-outbound-voice-tariff-rate/${id}`;
  }, //GET
  add_outbound_voice_rate:
    "/admin/v1/outbound-tariff-rates/voice/create-outbound-voice-tariff-rate", // POST
  update_outbound_voice_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/voice/update-outbound-voice-tariff-rate/${id}`;
  }, // PUT
  delete_outbound_voice_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/voice/delete-outbound-voice-tariff-rate/${id}`;
  }, // DELETE

  //Outbound Rates - SMS

  list_outbound_sms_rates:
    "/admin/v1/outbound-tariff-rates/sms/get-outbound-sms-tariff-rates", // GET
  get_outbound_sms_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/sms/get-outbound-sms-tariff-rate/${id}`;
  }, //GET
  add_outbound_sms_rate:
    "/admin/v1/outbound-tariff-rates/sms/create-outbound-sms-tariff-rate", // POST
  update_outbound_sms_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/sms/update-outbound-sms-tariff-rate/${id}`;
  }, // PUT
  delete_outbound_sms_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/sms/delete-outbound-sms-tariff-rate/${id}`;
  }, // DELETE

  //Outbound Rates - WHATSAPP

  list_outbound_whatsapp_rates:
    "/admin/v1/outbound-tariff-rates/whatsapp/get-outbound-whatsapp-tariff-rates", // GET
  get_outbound_whatsapp_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/whatsapp/get-outbound-whatsapp-tariff-rate/${id}`;
  }, //GET
  add_outbound_whatsapp_rate:
    "/admin/v1/outbound-tariff-rates/whatsapp/create-outbound-whatsapp-tariff-rate", // POST
  update_outbound_whatsapp_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/whatsapp/update-outbound-whatsapp-tariff-rate/${id}`;
  }, // PUT
  delete_outbound_whatsapp_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/whatsapp/delete-outbound-whatsapp-tariff-rate/${id}`;
  }, // DELETE

  //Outbound Rates - TELEGRAM

  list_outbound_telegram_rates:
    "/admin/v1/outbound-tariff-rates/telegram/get-outbound-telegram-tariff-rates", // GET
  get_outbound_telegram_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/telegram/get-outbound-telegram-tariff-rate/${id}`;
  }, //GET
  add_outbound_telegram_rate:
    "/admin/v1/outbound-tariff-rates/telegram/create-outbound-telegram-tariff-rate", // POST
  update_outbound_telegram_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/telegram/update-outbound-telegram-tariff-rate/${id}`;
  }, // PUT
  delete_outbound_telegram_rate: (id) => {
    return `/admin/v1/outbound-tariff-rates/telegram/delete-outbound-telegram-tariff-rate/${id}`;
  }, // DELETE

  //Outbound Rates - RCS

  list_outbound_rcs_rates:
    "/admin/v1/outbound-tariff-rates/rcs/get-outbound-rcs-tariff-rates", // GET
  get_outbound_rcs_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/rcs/get-outbound-rcs-tariff-rate/${id}`, // GET
  add_outbound_rcs_rate:
    "/admin/v1/outbound-tariff-rates/rcs/create-outbound-rcs-tariff-rate", // POST
  update_outbound_rcs_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/rcs/update-outbound-rcs-tariff-rate/${id}`, // PUT
  delete_outbound_rcs_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/rcs/delete-outbound-rcs-tariff-rate/${id}`, // DELETE

  //Outbound Rates - Google Business Chat

  list_outbound_gbc_rates:
    "/admin/v1/outbound-tariff-rates/gbc/get-outbound-google_business_chat-tariff-rates", // GET
  get_outbound_gbc_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/gbc/get-outbound-google_business_chat-tariff-rate/${id}`, // GET
  add_outbound_gbc_rate:
    "/admin/v1/outbound-tariff-rates/gbc/create-outbound-google_business_chat-tariff-rate", // POST
  update_outbound_gbc_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/gbc/update-outbound-google_business_chat-tariff-rate/${id}`, // PUT
  delete_outbound_gbc_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/gbc/delete-outbound-google_business_chat-tariff-rate/${id}`, // DELETE

  //Outbound Rates - Apple Message

  list_outbound_apple_message_rates:
    "/admin/v1/outbound-tariff-rates/apple-message/get-outbound-apple_message-tariff-rates", // GET
  get_outbound_apple_message_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/apple-message/get-outbound-apple_message-tariff-rate/${id}`, // GET
  add_outbound_apple_message_rate:
    "/admin/v1/outbound-tariff-rates/apple-message/create-outbound-apple_message-tariff-rate", // POST
  update_outbound_apple_message_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/apple-message/update-outbound-apple_message-tariff-rate/${id}`, // PUT
  delete_outbound_apple_message_rate: (id) =>
    `/admin/v1/outbound-tariff-rates/apple-message/delete-outbound-apple_message-tariff-rate/${id}`, // DELETE

  //Service Engine Mapping

  create_service_engine_map:
    "/admin/v1/services/create-engine-mapping",
  create_service_engine_map_array:
    "/admin/v1/services/create-engine-mappings-list",
  update_service_engine_map:
    "/admin/v1/services/update-engine-mappings",

  //Package Tariff Mapping

  list_package_tariff_mapping: "/admin/v1/containers/package-tariff-mappings",
  add_package_tariff_map: "/admin/v1/containers/create-package-tariff-mapping",
  add_package_tariff_map_array:
    "/admin/v1/containers/create-package-tariff-mapping-list",
  update_package_tariff_map: (id) => {
    return `/admin/v1/containers/update-package-tariff-mapping/${id}`;
  },
  delete_package_tariff_map: (id) => {
    return `/admin/v1/containers/delete-package-tariff-mapping/${id}`;
  },

  //UC ORDERS

  list_uc_orders: "/admin/v1/ucaas-orders/list-ucaas-orders",
  add_uc_order: "/admin/v1/ucaas-orders/create-ucaas-order",
  get_uc_order: (id) => {
    return `/admin/v1/ucaas-orders/get-ucaas-order/${id}`;
  },
  update_uc_order: (id) => {
    return `/admin/v1/ucaas-orders/update-ucaas-order/${id}`;
  },
  delete_uc_order: (id) => {
    return `/admin/v1/ucaas-orders/delete-ucaas-order/${id}`;
  },
  list_orderable_uc_packages_admin: "/admin/v1/ucaas-orders/orderable-uc-packages",

  //Number ORDERS

  list_number_orders: "/admin/v1/numbers/list-number-orders",
  add_number_order: "/admin/v1/numbers/create-number-order",
  get_number_order: (id) => {
    return `/admin/v1/numbers/get-number-order/${id}`;
  },
  update_number_order: (id) => {
    return `/admin/v1/numbers/update-number-order/${id}`;
  },
  delete_number_order: (id) => {
    return `/admin/v1/numbers/delete-number-order/${id}`;
  },
  list_orderable_products: "/admin/v1/numbers/orderable-number-products",

  //Subscription Reports

  list_subscription_reports:
    "/admin/v1/subscription-reports/subscription_reports",
  list_subscription_logs: "/admin/v1/subscription-logs/subscription_logs",

  //DID Types

  list_did_types: "/admin/v1/did-types/list-did-types", // GET
  add_did_type: "/admin/v1/did-types/create-did-type", // POST
  update_did_type: (id) => {
    return `/admin/v1/did-types/update-did-type/${id}`;
  }, // PUT
  delete_did_type: (id) => {
    return `/admin/v1/did-types/delete-did-type/${id}`;
  },
  list_customer_did_types: "/customer/v1/did_types/did_types", // GET
  list_customer_capabilities: "/customer/v1/did_types/capabilities", // GET

  list_telesmart_did_types: "/admin/v1/did-types/list-did-types-telesmart", // GET

  //DID TYPE MAPPINGS

  list_did_type_mappings: "/admin/v1/did-types/list-did-type-mappings", //GET
  create_did_type_mapping: "/admin/v1/did-types/create-did-type-mapping", //POST
  update_did_type_mapping: (id) => {
    return `/admin/v1/did-types/update-did-type-mapping/${id}`;
  },

  delete_did_type_mapping: (id) => {
    return `/admin/v1/did-types/delete-did-type-mapping/${id}`;
  },
  update_did_type_mappings_list: "/admin/v1/did-types/update-did-type-mapping-multiple",

  //Service Groups
  list_service_groups: "/admin/v1/services/list-service-groups", // GET

  //Access Mangement

  list_partition_service_groups: "/access_management/get_service_groups_root",
  list_admin_service_groups: "/access_management/get_service_groups",
  update_partition_service_group:
    "/access_management/change_enabled_service_groups", //PUT
  update_customer_service_group:
    "access_management/change_active_service_groups", //PUT

  update_registration_on_of:"/access_management/set_customer_registration_status", //PUT

  update_default_subscription_package: (id) => {
    return `/access_management/update-subscription-package-is-default/${id}`;
  }, // PUT

  //UC Customer Order
  list_orderable_uc_packages: "/customer/v1/ucaas-orders/get-orderable-packages", // GET
  get_customer_uc_orders: "/customer/v1/ucaas-orders/order-history", // GET
  create_uc_order: "/customer/v1/ucaas-orders/create-order", // POST
  get_customer_uc_order: (id) => {
    return `/customer/v1/orders/ucaas/${id}`;
  }, // GET
  update_customer_uc_order: (id) => {
    return `/customer/v1/orders/ucaas/${id}`;
  }, // PUT
  delete_customer_uc_order: (id) => {
    return `/customer/v1/orders/ucaas/${id}`;
  },

  //Number Product

  list_number_products: "/admin/v1/number-products/get-number-products", // GET
  add_number_product: "/admin/v1/number-products/create-number-product", // POST
  update_number_product: (id) =>
    `/admin/v1/number-products/update-number-product/${id}`, // PUT
  delete_number_product: (id) =>
    `/admin/v1/number-products/delete-number-product/${id}`, // DELETE

  //Telesmart Number Products

  list_telesmart_number_products: "/admin/v1/number-products-ts/get_products", //GET
  update_telesmart_number_product: (id) =>
    `/admin/v1/number-products-ts/update-product/${id}`, //PUT
  add_telesmart_number_product: "/admin/v1/number-products-ts/create-product", // POST

  // Product Package mappings

  list_product_package_mappings: "/admin/v1/packages/number-product-mappings", //GET
  add_product_package_mapping: "/admin/v1/packages/create-number-product-mapping", //POST
  delete_product_package_mapping: (id) => {
    return `/admin/v1/packages/delete-number-product-mapping/${id}`;
  }, //DELETE

  //FILTER CUSTOMER
  list_filter_did_types: "/customer/v1/filter/did_types", //GET
  list_filter_capabilities: "/customer/v1/filter/capabilities", //GET
  list_filter_inbound_countries: "/customer/v1/filter/get-inbound-countries", //GET
  list_filter_outbound_countries: "/customer/v1/filter/get-inbound-countries", //GET

  //AUDIT LOGS

  list_audit_logs: "/admin/v1/audit_logs/list", //GET

  //Product Tariff Mapping

  list_product_tariff_mappings:
    "/admin/v1/number-products/list-tariff-mappings", // GET
  add_product_tariff_mapping:
    "/admin/v1/number-products/create-tariff-mapping", // POST
  get_product_tariff_mapping: (id) =>
      `/admin/v1/number-products/get-tariff-mapping/${id}`, // GET
  update_product_tariff_mapping: (id) =>
    `/admin/v1/number-products/update-tariff-mapping/${id}`, // PUT
  delete_product_tariff_mapping: (id) =>
    `/admin/v1/number-products/delete-tariff-mapping/${id}`, // DELETE


  list_service_tariff_mappings:
    "/admin/v1/services/list-outbound-tariff-mappings", // GET
  add_service_tariff_mapping:
    "/admin/v1/services/create-outbound-tariff-mapping", // POST
  update_service_tariff_mapping: (id) =>
    `/admin/v1/services/update-outbound-tariff-mapping/${id}`, // PUT
  delete_service_tariff_mapping: (id) =>
    `/admin/v1/services/delete-outbound-tariff-mapping/${id}`, // DELETE

};

export default urls;
