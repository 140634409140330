import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  InputLabel,
  Select,
  Autocomplete,
  Box,
  Typography,
  Tooltip,
  IconButton,
  MenuItem,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import BaseModal from "src/components/BaseModal";
import { formatDate, rowArrayToObject } from "src/utils/Util";
import {
  Capability,
  DefaultPaginationData,
  getLabelByValue,
} from "src/constants/index";
import BaseSnackbar from "src/components/BaseSnackbar";
import {
  CountriesService,
  DidService,
  FilterService,
  NumberService,
} from "src/api/services";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStore } from "src/store/Store";
import CancelOrderForm from "./forms/CancelOrderForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ShoppingCart() {
  const { t } = useTranslation();
  const [store] = useStore();

  const TABLE_HEAD = [
    { key: "did", label: t("number") },
    { key: "capability", label: t("capability") },
    { key: "did_type", label: t("type") },
    { key: "mrc", label: t("mrc") },
    { key: "nrc", label: t("nrc") },
    { key: "sms_price", label: t("sms-price") },
    { key: "reserved_until", label: t("reserved-until") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    did: { key: "did", index: 0 },
    capability: { key: "capability", index: 1 },
    did_type: { key: "did_type", index: 2 },
    mrc: { key: "mrc", index: 3 },
    nrc: { key: "nrc", index: 4 },
    sms_price: { key: "sms_price", index: 5 },
    reserved_until: { key: "reserved_until", index: 6 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterCapability, setFilterCapability] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [selectNumberModal, setSelectNumberModal] = useState(false);
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [capabilities, setCapabilities] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchNumbers = () => {
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      country_id: filterCountry ? filterCountry : undefined,
      capability_id: filterCapability ? filterCapability : undefined,
      type_id: filterType ? filterType : undefined,
      phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
    };
    setLoadingData(true);

    NumberService.listAvailableNumbers(params)
      .then((response) => {
        if (response.status === 200) {
          let items = [];
          setTotalCount(response.data.data.count);

          for (const idx in response.data.data.items) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});
            const itemData = response.data.data.items[idx];

            Object.entries(itemData).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            items.push(item);
          }
          setData(items);
        }
      })
      .catch((err) => {
        console.log(err.message);
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    FilterService.listFilterInboundCountries({ size: 100 })
      .then((response) => {
        const options = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });

    FilterService.listFilterDidTypes()
      .then((response) => {
        const options = response.data.map((did) => ({
          value: did.id,
          label: did.name,
        }));
        setDidTypes(options);
      })
      .catch((err) => {
        console.log(err);
      });

    FilterService.listFilterCapabilities({ size: 100 })
      .then((response) => {
        const options = Object.entries(response.data).map(
          ([capability, id]) => ({
            value: id,
            label: capability,
          })
        );

        setCapabilities(options);
      })
      .catch((err) => {
        console.log(err);
      });
    //getRuleTypesFunction like countries when api ready FTODO
  }, []);

  useEffect(() => {
    if (filterType && filterCapability && filterType) {
      fetchNumbers();
    }
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "reserved_date") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "cancel") {
      setOpenCancelModal(!openCancelModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("remove")}>
          <IconButton
            sx={{
              backgroundColor: store.palette.secondary.contrastText,
            }}
            size="large"
            aria-label="minimize"
            onClick={() => modalHandler("cancel", index)}
          >
            <DeleteIcon sx={{ fontSize: "1.8rem" }} />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        title={t("cancel-number-order")}
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        children={
          <CancelOrderForm
            successCallback={fetchNumbers}
            formData={selectedRow}
            setModalStatus={setOpenCancelModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
                value={
                  countries.find((option) => option.value === filterCountry) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterCountry(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth sx={{ mt: "0.5em" }}>
              <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
              <Select
                label={t("capability")}
                labelId="filter-capability-label"
                name="capability"
                color="secondary"
                value={filterCapability}
                onChange={(event) => {
                  setFilterCapability(event.target.value);
                }}
              >
                {capabilities.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={didTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("type")} />
                )}
                value={
                  didTypes.find((option) => option.value === filterType) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterType(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={2} xs={12} sx={{ mt: "0.5em" }}>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">{t("status")}</InputLabel>
              <Select
                label={t("status")}
                labelId="filter-status-label"
                name="status"
                color="secondary"
                value={filterStatus}
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
              >
                {getSelectOptions(NumberStatus())}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("phone-number")}
                labelId="filter-number-label"
                name="number"
                color="secondary"
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "." &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                value={filterPhoneNumber}
                onChange={(event) => {
                  setFilterPhoneNumber(event.target.value);
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchNumbers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
