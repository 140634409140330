import { Navigate, useRoutes, useNavigate } from "react-router-dom";

import { isTokenExpired } from "./utils/Util";
// layouts
import MainLayout from "./layouts/main";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Auth from "./pages/Auth";
import NotFound from "./pages/Page404";
import ProgrammableSms from "./pages/app/ProgrammableSms";
import TwoFactorAuthentication from "./pages/app/TwoFactorAuthentication";
import ContactManagement from "./pages/app/ContactManagement";
import CampaignManagement from "./pages/app/CampaignManagement";
import NumberMasking from "./pages/app/NumberMasking";
import CallForwarding from "./pages/app/CallForwarding";
import LogViewer from "./pages/app/LogViewer";
import ChargingLog from "./pages/app/ChargingLog";
import Transaction from "./pages/app/Transaction";
import Profile from "./pages/Profile";
import LandingViewSelection from "./pages/LandingViewSelection";
import DtmfMenu from "./pages/app/DtmfMenu";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------

import Config from "./pages/app/Config";
import NumberMapping from "./pages/app/uccServices/configUcc/numberMapping/NumberMapping";
import UCaaSUsers from "./pages/app/UCaaSUsers";
import WentWrong from "./pages/WentWrong";
import ManageNumbers from "./pages/app/ManageNumbers";
import PurchaseNumbers from "./pages/app/PurchaseNumbers";
import PurchaseUc from "./pages/app/PurchaseUc";
import IotServices from "./pages/app/IotServices";
import ManageSimDatas from "./pages/app/ManageSimDatas";
import PurchaseIots from "./pages/app/PurchaseIots";
import YourNumbers from "./pages/app/YourNumbers";
import VoiceNetworking from "./pages/app/VoiceNetworking";
import SmsNetworking from "./pages/app/SmsNetworking";
import ShoppingCarts from "./pages/app/ShoppingCarts";
// ----------------------------------------------------------------------

export default function PortalRouter() {
  const [store, dispatch] = useStore();
  const portal = JSON.parse(localStorage.getItem("portal"))

  const navigate = useNavigate();

  const handleAppRedirect = () => {
    if (isTokenExpired(portal?.expires_at)) {
      (async () => {
        await dispatch({ type: "PORTAL_LOGOUT" });
      })(); 
    }

    if (portal !== null && portal?.token !== null) {
        return <MainLayout />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  console.log("store: ", store)

  return useRoutes([
    {
      path: "/",
      element: handleAppRedirect(),
      children: [
        { path: "/", element: <Navigate to="/campaign-management" /> },
        {
          path: "/programmable-sms",
          element:  <ProgrammableSms />
        },
        {
          path: "/two-factor-authentication",
          element:<TwoFactorAuthentication />
        },
        { path: "/contacts", element: <ContactManagement /> },
        { path: "/ucc/purchase", element: <PurchaseUc /> },
        { path: "/ucc/config", element: <Config /> },
        { path: "/ucc/uc-users", element: <UCaaSUsers /> },
        { path: "/uc-users-upload", element: <UCaaSUsers /> },
        { path: "/number-mapping/:id", element: <NumberMapping /> },
        {
          path: "/campaign-management",
          element: <CampaignManagement />    
        },
        {
          path: "/number-masking",
          element:<NumberMasking />
        },
        { path: "/forwarding", element: <CallForwarding /> },
        { path: "/numbers/your-numbers", element: <YourNumbers /> },
        { path: "/log-viewer", element: <LogViewer /> },
        { path: "/charging-log", element: <ChargingLog /> },
        { path: "/transactions", element: <Transaction /> },
        { path: "/shopping-cart", element: <ShoppingCarts /> },
        { path: "/profile", element: <Profile /> },
        { path: "/landing-view-selection", element: <LandingViewSelection /> },
        { path: "/dtmf-menu", element: <DtmfMenu /> },
        { path: "/numbers/manage-numbers", element: <ManageNumbers /> },
        { path: "/numbers/purchase", element: <PurchaseNumbers /> },

        { path: "/iot/your-services", element: <IotServices /> },
        { path: "/iot/manage-sim-data", element: <ManageSimDatas /> },
        { path: "/iot/purchase", element: <PurchaseIots /> },
        { path: "/networks/voice", element: <VoiceNetworking /> },
        { path: "/networks/sms", element: <SmsNetworking /> },
      ],
    },
    {
      path: "/",
      element: (portal === null || portal?.token === null ) ? <LogoOnlyLayout /> : <Navigate to="/" />,
      children: [
        { path: "login", element: <Auth type="login" /> },
        { path: "register", element: <Auth type="register" /> },
        { path: "forgot-password", element: <Auth type="forgot-password" /> },
        { path: "new-password", element: <Auth type="new-password" /> },
        { path: "/", element: <Navigate to="/profile" /> },
      ],
    },
    { path: "*", element: <NotFound /> },
    { path: "oops", element: <WentWrong /> },
  ]);
}
