import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { DefaultPaginationData } from "src/constants/index";
import { rowArrayToObject } from "src/utils/Util";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import { CustomerService, OrdersService, PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import OrderForm from "./forms/OrderForm";
import { useParams } from "react-router-dom";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function AddUcOrder() {
  const { t } = useTranslation();
  const { package_id, customer_id } = useParams();
  console.log("package_id:", package_id);
  console.log("customer_id:", customer_id);

  const TABLE_HEAD = [
    { key: "package_name", label: t("package-name") },
    { key: "min_seat_count", label: t("min-seat-count") },
    { key: "max_seat_count", label: t("max-seat-count") },
    { key: "action", label: t("actions"), align: "right", padding: "1.5em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: {
      key: "package_id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    name: { key: "package_name", index: 1 },
    min_seat_count: { key: "min_seat_count", index: 2 },
    max_seat_count: { key: "max_seat_count", index: 3 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openAddModal, setOpenAddModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(customer_id || null);

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  const fetchPackages = () => {
    const params = {
      customer_id: selectedCustomer && selectedCustomer.id,
      name: filterName ? filterName : undefined,
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    OrdersService.getOrderableUcPackages(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          const minSeatCount = itemData.properties.min_seat_count ?? 0;
          const maxSeatCount = itemData.properties.max_seat_count ?? 0;

          item[TABLE_FIELD_MAPPING["min_seat_count"].index] = {
            ...TABLE_FIELD_MAPPING["min_seat_count"],
            value: minSeatCount,
          };
          item[TABLE_FIELD_MAPPING["max_seat_count"].index] = {
            ...TABLE_FIELD_MAPPING["max_seat_count"],
            value: maxSeatCount,
          };
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomerNames();
  }, []);

  useEffect(() => {
    fetchPackages();
    return () => {
      setData([]);
    };
  }, [paginationData, selectedCustomer]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setOpenAddModalStatus(!openAddModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };
  useEffect(() => {
    if (package_id && customer_id) {
      // Filter the data to find the selected package_id and customer_id
      const selectedPackage = data.find(
        (item) => item.package_id === parseInt(package_id)
      );
      const selectedCustomer = customerOptions.find(
        (option) => option.value === parseInt(customer_id)
      );

      if (selectedPackage && selectedCustomer) {
        setSelectedRow(rowArrayToObject(selectedPackage));
        setSelectedCustomer({
          id: selectedCustomer.value,
          label: selectedCustomer.label,
        });
        setOpenAddModalStatus(true);
      }
    }
  }, [package_id, customer_id, data, customerOptions]);
  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("create-uc-order")} sx={{ mr: 1.5 }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-forwarding"
            onClick={() => handleAddOrder(index)}
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleAddOrder = (index) => {
    if (selectedCustomer && selectedCustomer.id) {
      modalHandler("add", index);
    } else {
      setMessage(t("please-select-customer-first"));
      setSnackbarStatus(true);
    }
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("create-new-order")}
        open={openAddModal}
        setOpen={setOpenAddModalStatus}
        children={
          <OrderForm
            successCallback={fetchPackages}
            selectedCustomer={selectedCustomer}
            formData={selectedRow}
            setModalStatus={setOpenAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchPackages();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("customer")} />
              )}
              value={
                selectedCustomer
                  ? customerOptions.find(
                      (option) => option.value === selectedCustomer.id
                    )
                  : null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer({
                  id: newValue?.value || null,
                  name: newValue?.label || null,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
