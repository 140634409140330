import { useState, useEffect } from "react";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { DefaultPaginationData } from "src/constants/index";
import {
  CustomerOrderService,
} from "src/api/services";
import { fDateTime } from "src/utils/formatTime";
import { useTranslation } from "react-i18next";
import { formatDate } from "src/utils/Util";
import { FormControl, Grid, Typography, TextField } from "@mui/material";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function OrderHistory() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { key: "name", label: t("package-name") },
    { key: "orderedSeat", label: t("seat-count") },
    { key: "payment", label: t("payment") },

    { key: "subscriptionStart", label: t("processed-at") },

    { key: "subscriptionEnd", label: t("subs-end") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    seat_count: { key: "orderedSeat", index: 2 },
    payment: { key: "payment", index: 3 },
    processed_at: { key: "subscriptionStart", index: 4 },
    subscription_end: { key: "subscriptionEnd", index: 5 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [subscribedPackages, setSubscribedPackages] = useState([]);
  const [filterName, setFilterName] = useState(null);

  const fetchOrders = () => {
    const params = {
      name: filterName,
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    CustomerOrderService.getCustomerUcOrders(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          let itemData = response.data.items[idx];

          if (itemData.package) {
            const packageName = itemData.package.name;

            item[TABLE_FIELD_MAPPING["name"].index] = {
              ...TABLE_FIELD_MAPPING["name"],
              value: packageName,
            };
          }

          let payment = itemData.monthly
            ? itemData.initial_price_monthly + "  " + t("monthly-0")
            : itemData.initial_price_annual + "  " + t("annual-0");

          item[TABLE_FIELD_MAPPING["payment"].index] = {
            ...TABLE_FIELD_MAPPING["payment"],
            value: (
              <>
                <Typography
                  color={
                    itemData.monthly ? "secondary.dark" : "secondary.light"
                  }
                >
                  {payment}
                </Typography>
              </>
            ),
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "name") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };
  const fetchSubscribedPackages = () => {
    //changeFurkan ask how to get subscribed package. For now return an id(package id)
    setSubscribedPackages([27, 28]);
  };

  useEffect(() => {
    fetchOrders();
    return () => {
      setData([]);
    };
  }, [paginationData]);
  useEffect(() => {
    fetchSubscribedPackages();
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "subscriptionStart") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "subscriptionStart") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  return (
    <>
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("package-name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchOrders();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
        subscribedPackageIds={subscribedPackages}
      />
    </>
  );
}
