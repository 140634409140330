import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { ContainerService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { rowArrayToObject } from "src/utils/Util";
import SaveIcon from "@mui/icons-material/Save";
import { useStore } from "src/store/Store";
import BaseModal from "src/components/BaseModal";
import ConfigOutboundServices from "./ConfigOutboundServices";
import { LoadingButton } from "@mui/lab";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumberPackagesConfig({ formData }) {
  const [store, dispatch] = useStore();
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [openServiceMapping, setOpenServiceMapping] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [gotIt, setGotIt] = useState(store.outboundGotIt || false);

  const TABLE_HEAD = [
    { key: "enabled", label: t("enabled") },
    { key: "outbound_package_name", label: t("outbound-package") },
    { key: "config_services", label: t("config-services") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    is_enabled: { key: "enabled", index: 1 },
    outbound_package_name: { key: "outbound_package_name", index: 2 },
    config_services: { key: "config_services", index: 3 },
    outbound_package_id: {
      key: "outbound_package_id",
      index: 4,
      noRender: true,
    },
    package: { key: "package", index: 5, noRender: true },
  };

  const makeTable = () => {
    let filteredPackages = [];
    ContainerService.getContainer(formData.id)
      .then((response) => {
        if (response.status === 200) {
          response.data.container_package_tariff_mappings.map((mapping) => {
            if (
              mapping.package.name
                .toLowerCase()
                .includes(filterName.toLowerCase())
            ) {
              filteredPackages.push(mapping);
            }
          });

          let extractedData = [];
          for (const mapping of filteredPackages.sort((a, b) => a.id - b.id)) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            let packageID = null;
            let packageName = "";
            if (mapping.package) {
              packageName = mapping.package.name;
              packageID = mapping.package.id;
            }

            if (mapping.package) {
              item[TABLE_FIELD_MAPPING["outbound_package_name"].index] = {
                ...TABLE_FIELD_MAPPING["outbound_package_name"],
                value: packageName,
              };
              item[TABLE_FIELD_MAPPING["outbound_package_id"].index] = {
                ...TABLE_FIELD_MAPPING["outbound_package_id"],
                value: packageID,
              };
              item[TABLE_FIELD_MAPPING["config_services"].index] = {
                ...TABLE_FIELD_MAPPING["config_services"],
                value: (
                  <Button
                    sx={{
                      color: store.palette.info.contrastText,
                      backgroundColor: store.palette.info.main,
                    }}
                    variant="contained"
                    size="small"
                    disabled= {!gotIt}
                    aria-label="view-contact"
                    onClick={() => {
                      setSelectedRow({
                        packageID: packageID,
                        packageName: packageName,
                        containerID: formData.id,
                        service_engine_mappings:
                          mapping.package?.service_engine_mappings,
                      });
                      setOpenServiceMapping(true);
                    }}
                  >
                    {t("config-services")}
                  </Button>
                ),
              };
            }

            Object.entries(mapping).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING && mapping.package) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });

            extractedData.push(item);
          }

          setData(extractedData);

          const deepCopy = JSON.parse(JSON.stringify(extractedData));
          setInitialData(deepCopy);
        } else {
          throw "error on getting container";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (store.outboundGotIt) {
      setGotIt(store.outboundGotIt);
    }
    makeTable();
  }, []);
  useEffect(() => {
    makeTable();
  }, [gotIt]);

  const handleOnChange = (index) => {
    //implement succes message according to data[index][i].value => FTODO
    const newData = data.map((item, i) => {
      if (i === parseInt(index)) {
        item[1].value = !item[1].value;
      } else {
        item[1].value = false;
      }
      return item;
    });
    setData(newData);
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][1].value}
        disabled={!gotIt}
        onChange={() => handleOnChange(index)}
      />
    );
  };

  const isEqual = () => {
    for (const [index, item] of data.entries()) {
      const initialItem = initialData[index];
      if (item[1].value !== initialItem[1].value) {
        return false;
      }
    }
    return true;
  };

  const saveChanges = async () => {
    setSubmitting(true);
    try {
      for (const [index, item] of data.entries()) {
        const initialItem = initialData[index];

        if (item[1].value !== initialItem[1].value) {
          const payload = {
            container_id: formData.id,
            package_id: item[4].value,
            outbound_tariff_id: null,
            is_enabled: item[1].value,
          };
          await ContainerService.updatePackageTariffMapping(
            item[0].value,
            payload
          );
        }
      }

      const newData = JSON.parse(JSON.stringify(data));
      setInitialData(newData);
      setMessage(t("changes-saved-successfully"));
      setSnackbarStatus(true);
      setSubmitting(false);
    } catch (err) {
      setMessage(t("failed-to-save-changes"));
      setSnackbarStatus(true);
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxHeight: "80vh",
      }}
    >
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{ minWidth: 500, width: "70%" }}
        title={t("config-outbound-services")}
        open={openServiceMapping}
        setOpen={setOpenServiceMapping}
        children={
          <ConfigOutboundServices
            successCallback={console.log("success")}
            formData={selectedRow}
            setModalStatus={setOpenServiceMapping}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                makeTable();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      {!gotIt && (
        <Box>
          <Typography mt={2} variant="body2" color={store.palette.error.main}>
            {t("outbound-select-package-warning")}
          </Typography>
          <Tooltip title={t("got-it")}>
            <IconButton
              color="secondary"
              size="small"
              aria-label="got-it"
              onClick={() => {
                setGotIt(true);
                dispatch({
                  type: "UPDATE_OUTBOUND_GOT_IT",
                  payload: { outboundGotIt: true },
                });
              }}
            >
              <ThumbUpAltIcon />
              <span style={{ marginLeft: "0.5rem" }}>{t("got-it")}</span>
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        loadingData={loadingData}
        enableSwitch={enableSwitch}
      />
      <Box mt={"2vh"}>
        <LoadingButton
          disabled={initialData.length > 0 && isEqual()}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={() => saveChanges()}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      </Box>
    </Box>
  );
}
