import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { CustomerService, OrdersService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import { rowArrayToObject, formatDate, canUserWriteIt } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import DeleteNumberOrderForm from "./forms/DeleteNumberOrderForm";
import NumberOrderForm from "./forms/NumberOrderForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ListNumberOrders() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "product_name", label: t("product") },
    { key: "customer_name", label: t("customer") },
    { key: "count", label: t("count") },
    { key: "processed_at", label: t("processed-at") },
    canUserWriteIt("numbers") ? { key: "action", label: t("actions"), align: "right", padding: "1.5vw" } : null,
  ].filter(Boolean);

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    product_name: { key: "product_name", index: 1 },
    customer_name: { key: "customer_name", index: 2 },
    count: { key: "count", index: 3 },
    processed_at: { key: "processed_at", index: 4 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [editOrderModalStatus, setEditOrderModalStatus] = useState(false);
  const [deleteOrderModalStatus, setDeleteOrderModalStatus] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  const fetchOrders = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      customer: selectedCustomer ? selectedCustomer.id : undefined,
      /* package: filterPackage ? filterPackage : undefined, */
    };
    OrdersService.listNumberOrders(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchOrders();
    return () => {
      setData([]);
    };
  }, [paginationData, selectedCustomer]);

  useEffect(() => {
    fetchCustomerNames();
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "processed_at") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "edit") {
      setEditOrderModalStatus(!editOrderModalStatus);
    } else if (modalType === "delete") {
      setDeleteOrderModalStatus(!deleteOrderModalStatus);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-order")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-order"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-order")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-order"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        title={t("edit-order")}
        open={editOrderModalStatus}
        setOpen={setEditOrderModalStatus}
        children={
          <NumberOrderForm
            formData={selectedRow}
            formType={"edit"}
            successCallback={fetchOrders}
            setModalStatus={setEditOrderModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-order")}
        open={deleteOrderModalStatus}
        setOpen={setDeleteOrderModalStatus}
        children={
          <DeleteNumberOrderForm
            formData={selectedRow}
            successCallback={fetchOrders}
            setModalStatus={setDeleteOrderModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("customer")} />
              )}
              value={
                selectedCustomer
                  ? customerOptions.find(
                      (option) => option.value === selectedCustomer.id
                    )
                  : null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer({
                  id: newValue?.value || null,
                  name: newValue?.label || null,
                });
              }}
            />
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchOrders();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={canUserWriteIt("numbers") && getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
