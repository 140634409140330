import api from "src/api/Api";
import urls from "src/api/Urls";


export const listUcUsers = (params) => {
    return api.get(urls.list_uc_users, { params: params });
}

export const addUcUser = (payload) => {
    return api.post(urls.add_uc_user, payload);
}

export const assignUsersToService = (id, payload) => {
    return api.put(urls.assign_users_to_service(id), payload);
}

export const assignNumbersToService = (id, payload) => {
    return api.put(urls.assign_numbers_to_service(id), payload);
}
export const bulkUploadUcUser = (params, payload) => {
    return api.post(urls.bulk_upload_uc_user, payload, {params: params});
}

export const updateUcUser = (id, payload) => {
    return api.patch(urls.update_uc_user(id), payload);
}

export const deleteUcUser = (id, params) => {
    params = params ? params : {proceed : false};
    return api.delete(urls.delete_uc_user(id), {params});
}

export const deleteServiceFromUser = (params) => {
    return api.delete(urls.delete_service_from_user, {params : params});
}

export const listUcServices = (params) => {
    return api.get(urls.get_uc_services , {params : params})
}

export const getUcService = (id) => {
    return api.get(urls.get_uc_service(id))
}
