import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, Checkbox, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { DidService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { useState } from "react";
// ----------------------------------------------------------------------

export default function DidTypeForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(
    formData ? (formData.is_mobile === "True" ? true : false) : false
  );
  const [isFixed, setIsFixed] = useState(
    formData ? (formData.is_fixed === "True" ? true : false) : false
  );
  const PackageSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
    },
    validationSchema: PackageSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        is_mobile: isMobile,
        is_fixed: isFixed,
      };

      let successMessage =
        formType === "add"
          ? t("new-did-added-succesfully")
          : t("did-has-been-succesfully-updated");
      let failMessage =
        formType === "add"
          ? t("new-did-could-not-be-added")
          : t("did-could-not-be-updated");

      const apiService =
        formType === "add"
          ? DidService.addDidType(payload)
          : DidService.updateDidType(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback();
            }
            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <TextField
          fullWidth
          label={t("name")}
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>{getFieldByName("name")}</Stack>
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1%",
                  width: "30%",
                  marginLeft: 2,
                }}
              >
                <Typography>{t("is-mobile")}</Typography>
                <Checkbox
                  checked={isMobile}
                  onChange={(e) => {
                    setIsMobile(e.target.checked);
                  }}
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: "1%",
                  width: "30%",
                }}
              >
                <Typography>{t("is-fixed")}</Typography>
                <Checkbox
                  checked={isFixed}
                  onChange={(e) => {
                    setIsFixed(e.target.checked);
                  }}
                />
              </Box>
            </Box>
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
