import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ManageVoiceNetwork from "./networkings/voice/ManageVoiceNetwork";
import VoiceRouting from "./networkings/voice/VoiceRouting";

// ----------------------------------------------------------------------

export default function PurchaseIots() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("manage"), component: <ManageVoiceNetwork /> },
    { id: 1, label: t("routing"), component: <VoiceRouting /> },
  ];

  const TITLE = t("voice-networking");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
