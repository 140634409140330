import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useStore } from "src/store/Store";
import { ProfileService, SiteConfigService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { LandingOptions } from "src/constants";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t("user-not-found"));
  const [store, dispatch] = useStore();
  const navigate = useNavigate();

  const fetchSettings = async (loginResponse) => {
/*     const settingsResponse = {
      data: {
        id: 3,
        organization: 12,
        globals: [
          {
            id: "show_sms_content",
            label: "Show sent SMS content to customers",
            status: true,
          },
          {
            id: "signup_bonus",
            label: "Signup Bonus",
            status: 5,
          },
          {
            id: "minimum_service_balance",
            label: "Minimum Service Balance",
            status: 2,
          },
        ],
        account_service_mappings: [
          {
            id: "2fa_sms",
            label: "2FA SMS",
            account: 76,
            enabled: true,
            channelType: 1,
          },
          {
            id: "2fa_voice",
            label: "2FA Voice",
            account: 58,
            enabled: true,
            channelType: 2,
          },
          {
            id: "number_masking",
            label: "Deallocations Masking",
            account: 49,
            enabled: true,
            channelType: 2,
          },
          {
            id: "programmable_sms",
            label: "Programmable SMS",
            account: 76,
            enabled: true,
            channelType: 1,
          },
          {
            id: "campaign_manager_sms",
            label: "Campaign Manager SMS",
            account: 76,
            enabled: true,
            channelType: 1,
          },
          {
            id: "campaign_manager_voice",
            label: "Campaign Manager Voice",
            account: null,
            enabled: true,
            channelType: 2,
          },
          {
            id: "dtmf_menu",
            label: "DTMF Menu",
            account: null,
            enabled: true,
            channelType: 2,
          },
        ],
        payout: [
          {
            id: "paypal_client_id",
            label: "Enter Paypal Client ID",
            value: null,
          },
          {
            id: "paypal_secret",
            label: "Enter Paypal Secret",
            value: null,
          },
        ],
        voice_configuration: {
          primary: {
            host: "1.1.1.2",
            port: "5060",
          },
          secondary: {
            host: "1.2.2.2.2",
            port: "5067",
          },
        },
        sms_configuration: {
          primary: "12.12.12.12",
        },
        created: "1653637460",
        modified: "1663682783",
      },
    };
    let servicesData = [...settingsResponse.data.account_service_mappings];
    servicesData.forEach((item) => {
      if (item.enabled === undefined) {
        item.enabled = true;
      }
    }); */
/*     const serviceStatuses = {};
    servicesData.forEach(
      (service) => (serviceStatuses[service.id] = service.enabled)
    );
    dispatch({
      type: "SET_SERVICE_STATUSES",
      payload: {
        services: serviceStatuses,
      },
    }); */

    dispatch({
      type: "LOGIN",
      payload: {
        portal: {
          token: loginResponse.data.access_token,
          expires_at: loginResponse.data.expires_at,
          refresh_token: loginResponse.data.refresh_token,
          refresh_token_expires_at: loginResponse.data.refresh_token_expires_at,
          refresh_token_issued_at: loginResponse.data.refresh_token_issued_at,
          user: {
            id: loginResponse.data.user_info.id,
            first_name: loginResponse.data.first_name,
            last_name: loginResponse.data.last_name,
            email: loginResponse.data.user_info.email,
            contactsInformation : {
              address1 : loginResponse.data.user_info?.properties?.address1,
              address2 : loginResponse.data.user_info?.properties?.address2,
              country_id : loginResponse.data.user_info?.properties?.country_id,
              telephone : loginResponse.data.user_info?.properties?.telephone,
            }
          }
        }
      },
    });
    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        //language: loginResponse.data.user.selected_language_code,
        language: "en-US",
      },
    });
  };

  const getFormData = (values) => {
    const formData = new FormData();
    formData.append("username", values.email);
    formData.append("password", values.password);
    return formData;
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
    password: Yup.string()
      .required(t("password-is-required"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      const payload = getFormData(values);

      let apiService = ProfileService.login(payload);
      apiService
        .then((response) => {
          if (response.status !== 200) {
            throw { message: "login failed", response: response };
          }

          fetchSettings(response);
        })
        .catch((err) => {
          setLoginMessage(err.response.data.detail);
          setOpenSnackbar(true);
          dispatch({ type: "LOGIN_FAIL" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={loginMessage}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t("email-address")}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={t("password")}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("login")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
