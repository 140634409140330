import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import PurchaseIot from "./iotServices/purchaseIot/PurchaseIot";
// ----------------------------------------------------------------------

export default function PurchaseIots() {
  const { t } = useTranslation();

  const TABS = [{ id: 0, label: t("purchase"), component: <PurchaseIot /> }];

  const TITLE = t("iot");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
