import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  InputLabel,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { NetworksService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function VoiceEndpointForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType,
  successCallback,
}) {
  const { t } = useTranslation();

  const VoiceSipTrunkSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    hostAddress: Yup.string().required(t("host-address-required")),
    ib_port: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
    ib_priority: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
    ib_weight: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
    ob_port: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
    ob_priority: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
    ob_weight: Yup.number()
      /* .min(0.1, t("pricing-is-must-be-greater-than-0")) */
      .required(t("required")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      hostAddress: formData.host_address || "",
      ib_port: formData.ib_bort,
      ib_priority: formData.ib_priority,
      ib_weight: formData.weight,
      ob_port: formData.outbound_port,
      ob_priority: formData.ob_priority,
      ob_weight: formData.weight,
    },

    validationSchema: VoiceSipTrunkSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        host_address: values.hostAddress,
        ib_bort: values.ib_bort,
        ib_priority: values.ib_priority,
        weight: values.weight,
        outbound_port: values.ob_port,
      };

      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = NetworksService.addVoiceEndpoint(payload);
        successMessage = t("new-voice-sip-trunk");
        failMessage = t("new-voice-sip-trunk-could-not-be-added");
      } else {
        apiService = NetworksService.updateVoiceEndpoint(formData.id, payload);
        successMessage = t("sip-trunk-has-been-successfully-updated");
        failMessage = t("sip-trunk-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw "sip trunk operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "hostAddress") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="hostAddress"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("host-address")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="hostAddress"
            label={isMobile() ? t("host-address") : undefined}
            placeholder={t("type-host-address-here")}
            {...getFieldProps("hostAddress")}
            error={Boolean(touched.hostAddress && errors.hostAddress)}
            helperText={touched.hostAddress && errors.hostAddress}
          />
        </Box>
      );
    }
    if (fieldName === "ibPort") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="ibPort"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ib-port")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="ibPort"
            label={isMobile() ? t("ib-port") : undefined}
            placeholder="5060"
            {...getFieldProps("ib_port")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== ":" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ib_port && errors.ib_port)}
            helperText={touched.ib_port && errors.ib_port}
          />
        </Box>
      );
    }
    if (fieldName === "ibPriority") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="ibPriority"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ib-priority")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="ibPriority"
            label={isMobile() ? t("ib-priority") : undefined}
            placeholder="1"
            {...getFieldProps("ib_priority")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ib_priority && errors.ib_priority)}
            helperText={touched.ib_priority && errors.ib_priority}
          />
        </Box>
      );
    }

    if (fieldName === "ibWeight") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="weight"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ib-weight")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="ibPriority"
            label={isMobile() ? t("ib-weight") : undefined}
            placeholder="1"
            {...getFieldProps("ib_priority")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ib_weight && errors.ib_weight)}
            helperText={touched.ib_weight && errors.ib_weight}
          />
        </Box>
      );
    }

    if (fieldName === "obPort") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="obPort"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ob-port")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="obPort"
            label={isMobile() ? t("ob-port") : undefined}
            placeholder="5060"
            {...getFieldProps("ob_port")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== ":" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ob_port && errors.ob_port)}
            helperText={touched.ob_port && errors.ob_port}
          />
        </Box>
      );
    }
    if (fieldName === "obPriority") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="obPriority"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ob-priority")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="obPriority"
            label={isMobile() ? t("ob-priority") : undefined}
            placeholder="1"
            {...getFieldProps("ob_priority")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ob_priority && errors.ob_priority)}
            helperText={touched.ob_priority && errors.ob_priority}
          />
        </Box>
      );
    }

    if (fieldName === "obWeight") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="weight"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("ob-weight")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="obPriority"
            label={isMobile() ? t("ob-weight") : undefined}
            placeholder="1"
            {...getFieldProps("ob_priority")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.ob_weight && errors.ob_weight)}
            helperText={touched.ob_weight && errors.ob_weight}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {getFieldByName("name")}
            {getFieldByName("hostAddress")}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2%",
              }}
            >
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <Typography>{t("inbound-h")}</Typography>
                <Divider />
                {getFieldByName("ibPort")}
                {getFieldByName("ibPriority")}
                {getFieldByName("ibWeight")}
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <Typography>{t("outbound-h")}</Typography>
                <Divider />
                {getFieldByName("obPort")}
                {getFieldByName("obPriority")}
                {getFieldByName("obWeight")}
              </Box>
            </Box>
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
