import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import SubscriptionReport from './subscriptionManage/subscriptionReports/SubscriptionReport';

// ----------------------------------------------------------------------


export default function SubscriptionReports() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('subscription-report'), component: <SubscriptionReport /> },      
    ];

    const TITLE = t('subscription-management');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
