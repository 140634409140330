import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  Autocomplete,
  Tooltip,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { DidService } from "src/api/services";
import { rowArrayToObject } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ConfigMapForm({
  formData,
  setModalStatus,
  successCallback,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [didTypeOptions, setDidTypeOptions] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [isDataSame, setIsDataSame] = useState(true);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState(null);
  const TABLE_HEAD = [
    { key: "enabled", label: t("Enabled") },
    { key: "name", label: t("local-did-type") },
    { key: "provider_dids", label: t("mapped-did-type") },
    { key: "actions", label: t("actions"), align: "left" },
  ];

  const TABLE_FIELD_MAPPING = {
    is_enabled: { key: "enabled", index: 0 },
    local_did_name: { key: "name", index: 1 },
    remote_did_type_id: { key: "provider_dids", index: 2 },
    local_did_type_id: { key: "local_did_id", index: 3, noRender: true },
    id: { key: "id", index: 4, noRender: true },
    type: { key: "type", index: 5, noRender: true },
    remote_name: { key: "remote_name", index: 6, noRender: true },
  };
  const fetchMappings = () => {
    let filteredDidTypes = [];
    DidService.listDidTypeMappings({ engine_id: formData.id })
      .then((response) => {
        if (response.status === 200) {
          let items = [];
          for (const mapping of response.data) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            let localDidName = mapping.local_did_type?.name;
            item[TABLE_FIELD_MAPPING["local_did_name"].index] = {
              ...TABLE_FIELD_MAPPING["local_did_name"],
              value: localDidName,
            };

            Object.entries(mapping).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            items.push(item);
          }

          setData(items);
          if (initialData.length === 0) {
            const deepCopy = JSON.parse(JSON.stringify(items));
            setInitialData(deepCopy);
          }
        } else {
          throw "error at listing mappings";
        }
      })
      .catch((err) => {});

    let extractedData = [];
    for (const mapping of filteredDidTypes) {
      let item = new Array(TABLE_HEAD.length - 1).fill({});

      let localDidName = mapping.local_did.name;
      let localdidID = mapping.local_did.id;
      let providerDidID = mapping.provider_did ? mapping.provider_did.id : "";
      let providerDid = mapping.provider_did ?? "";

      if (mapping.local_did) {
        item[TABLE_FIELD_MAPPING["local_did_name"].index] = {
          ...TABLE_FIELD_MAPPING["local_did_name"],
          value: localDidName,
        };
        item[TABLE_FIELD_MAPPING["local_did_id"].index] = {
          ...TABLE_FIELD_MAPPING["local_did_id"],
          value: localdidID,
        };
      }

      if (mapping) {
        item[TABLE_FIELD_MAPPING["mapped_did_id"].index] = {
          ...TABLE_FIELD_MAPPING["mapped_did_id"],
          value: providerDidID,
        };
        item[TABLE_FIELD_MAPPING["provider_did"].index] = {
          ...TABLE_FIELD_MAPPING["provider_did"],
          value: providerDid,
        };
      }

      Object.entries(mapping).forEach(([key, value]) => {
        if (key in TABLE_FIELD_MAPPING) {
          item[TABLE_FIELD_MAPPING[key].index] = {
            ...TABLE_FIELD_MAPPING[key],
            value: value,
          };
        }
      });
      extractedData.push(item);
    }

    setData(extractedData);
  };
  const proividerDidOptions = () => {
    DidService.listTelesmartDidTypes()
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setDidTypeOptions(options);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    proividerDidOptions();
    fetchMappings();
  }, []);

  useEffect(() => {
    checkIsDataSame();
  }, [data]);

  const didTypeSelect = (index) => {
    let selectedValue = rowArrayToObject(data[index]).provider_dids;
    return (
      <FormControl sx={{ width: "100%" }}>
        <Autocomplete
          id="combo-box-demo"
          options={didTypeOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedValue ? t("did-type") : t("select-did-type")}
            />
          )}
          value={
            didTypeOptions.find((option) => option.value === selectedValue) ||
            null
          }
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              handleDidChange(index, newValue);
            } else {
              handleDidChange(index, null);
            }
          }}
        />
      </FormControl>
    );
  };

  const handleDidChange = (index, selectedDid) => {
    let copiedData = [...data];
    copiedData[index][2].value = selectedDid?.value || null;
    copiedData[index][6].value = selectedDid?.label || null;
    if (!selectedDid) {
      copiedData[index][0].value = false;
    }
    setData(copiedData);
  };

  const handleOnChangeEnabled = (index) => {
    let copiedData = [...data];
    if (!copiedData[index][0].value && !copiedData[index][2].value) {
      setMessage(t("you-must-select-did-type-first"));
      setSnackbarStatus(true);
    } else {
      copiedData[index][0].value = !copiedData[index][0].value;
      setData(copiedData);
    }
  };
  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][0].value}
        onChange={() => handleOnChangeEnabled(index)}
      />
    );
  };

  const handleSaveAll = () => {
    setIsSubmiting(true);
    let payload = [];

    data.map((item) => {
      payload.push({
        type: item[5].value,
        local_did_type_id: item[3].value,
        remote_did_type_id: item[2].value,
        remote_name: item[6].value,
        is_enabled: item[0].value,
      });
    });

    let successMessage = t("mappings-save-success");
    let failMessage = t("mappings-save-fail");
    DidService.updateDidTypeMappingList({ engine_id: formData.id }, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setIsSubmiting(false);
          const newData = [...data];
          setInitialData(newData);
          
        } else {
          throw "err at updating wıth array";
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
        setTimeout(() => {
          setIsSubmiting(false);
        }, [1200]);
      });
  };

  const handleSave = (index) => {
    let payload = {
      type: data[index][5].value,
      local_did_type_id: data[index][3].value,
      remote_did_type_id: data[index][2].value,
      engine_id: formData.id,
      remote_name: data[index][6].value,
      is_enabled: data[index][0].value,
    };

    let successMessage = t("changes-are-applied-successfully");
    let failMessage = t("failed-to-apply-chaanges");

    let mappingID = data[index][4].value;
    DidService.updateDidTypeMapping(mappingID, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i].value) !== JSON.stringify(arr2[i].value)) {
        return false;
      }
    }
    return true;
  };

  const checkIsDataSame = () => {
    if (data.length > 0 && initialData.length > 0) {
      if (data.length !== initialData.length) {
        setIsDataSame(false);
        return;
      }
      for (let i = 0; i < data.length; i++) {
        if (!arraysEqual(data[i], initialData[i])) {
          setIsDataSame(false);
          return;
        }
      }
    }
    setIsDataSame(true);
  };

  const getActionItems = (index) => {
    return (
      <Stack width={"1%"} ml={"1vw"}>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                && arraysEqual(data[index], initialData[index])
                
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <Box sx={{maxHeight : "80vh"}}>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchMappings();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        loadingData={loadingData}
        enableSwitch={enableSwitch}
        didTypesSelect={didTypeSelect}
        actionItemPrep={getActionItems}
      />
      <LoadingButton
        disabled={isDataSame}
        sx={{ mt: 2, mb: 2 }}
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmiting}
        startIcon={<SaveIcon />}
        onClick={() => handleSaveAll()}
      >
        {t("common.__i18n_ally_root__.save")}
      </LoadingButton>
    </Box>
  );
}
