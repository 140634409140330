import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import InboundCountries from './settings/countrySettings/inboundCountries/InboundCountries';
import DidType from './settings/didTypes/DidType';
// ----------------------------------------------------------------------


export default function NumbersSettings() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('inbound-countries'), component: <InboundCountries /> },
        { id: 1, label: t('did-types'), component: <DidType /> },
    ];

    const TITLE = t('numbers-settings');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
