import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import NumbersPackage from "./numbersConfig/numbersPackage/NumbersPackage";
// ----------------------------------------------------------------------

export default function NumbersPackages() {
  const { t } = useTranslation();

  const TABS = [
    {
      id: 0,
      label: t("manage-numbers-package"),
      component: <NumbersPackage />,
    }
  ];

  const TITLE = t("numbers-package");

  return (
    <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
  );
}
