import * as React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";

import { NumberMaskingService } from "src/api/services";
import { useTranslation } from "react-i18next";

export default function NumberForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
  isProgrammable = false,
}) {
  const { t } = useTranslation();
  const MaskingNumberSchema = Yup.object().shape({
    formType: Yup.string(),
    name: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    masking_number: Yup.string()
      .required(t("masking-number-must-be-selected"))
      .matches(/^[a-zA-Z0-9]*$/, t("masking-number-must-be-alphanumeric")),
    uid: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      formType: formType,
      name: formData.name || "",
      masking_number: formData.masking_number || "",
      uid: formData.uid || "",
    },
    validationSchema: MaskingNumberSchema,
    onSubmit: (values, actions) => {
      let payload = {
        name: values.name,
        masking_number: values.masking_number,
      };
      console.log("payload", payload);
      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = NumberMaskingService.addMaskingNumber(payload);
        successMessage = t("new-masking-number-has-been-successfully-added");
        failMessage = t("new-masking-number-could-not-be-added");
      } else {
        apiService = NumberMaskingService.updateMaskingNumber(
          formData.id,
          payload
        );
        successMessage = t("has-been-successfully-updated", {
          name: formData.name,
        });
        failMessage = t("could-not-be-updated", { name: formData.name });
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback(response);
            }
            actions.setSubmitting(false);
          } else {
            throw "masking number operation failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("name")}
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      );
    }
    if (fieldName === "masking_number") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("masking-number")}
          {...getFieldProps("masking_number")}
          error={Boolean(touched.masking_number && errors.masking_number)}
          helperText={touched.masking_number && errors.masking_number}
        />
      );
    }
    if (fieldName === "uid") {
      return (
        <TextField
          fullWidth
          disabled
          label="UID"
          {...getFieldProps("uid")}
          error={Boolean(touched.uid && errors.uid)}
          helperText={touched.uid && errors.uid}
        />
      );
    }
    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          disabled={formType === "view" ? true : false}
          loading={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("masking_number")}
            {getFieldByName("uid")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
