import { useState } from "react";
// material
import { Stack, Typography, Divider, Card, CardContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { OrdersService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteNumberOrderForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const deleteTariff = () => {
    setLoading(true);

    OrdersService.deleteNumberOrder(formData.id)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("number-order-delete-success"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "container delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          setMessage(t("number-order-delete-fail"));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">
          {t("are-you-sure-delete-this-number-order")}
        </Typography>
        <Card>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              {t("customer")}
            </Typography>
            <Typography variant="h5">
              {formData.customer_name}
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {t("product")}
            </Typography>
            <Typography variant="h5">{formData.product_name}</Typography>
            
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {t("count")}
            </Typography>
            <Typography variant="h5">{formData.count}</Typography>

            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {t("processed-at")}
            </Typography>
            <Typography variant="h5">{formData.processed_at}</Typography>

          </CardContent>
        </Card>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteTariff}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
