import { useState, useEffect } from "react";
import { Stack, IconButton, Tooltip, Grid, FormControl, TextField } from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import PreviewIcon from "@mui/icons-material/Preview";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import {
  CustomerOrderService
} from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import ManageUcForm from "./form/ManageUcForm";
import { Link } from "react-router-dom";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));


export default function ManageUc({ changeTab }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openUnsubscribeModal, setUnsubscribeModalStatus] = useState(false);
  const [openViewModal, setViewModalStatus] = useState(false);
  const [openViewUsageModal, setViewUsageModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState(null);

  const TABLE_HEAD = [
    { key: "name", label: t("package-name") },
    { key: "total_seat_count", label: t("total-seat-count") },
    { key: "used_seat_count", label: t("used-seat-count") },
    { key: "monthly_pricing", label: t("monthly-pricing") },
    { key: "annual_pricing", label: t("annual-pricing") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    total_seat_count: { key: "total_seat_count", index: 2 },
    used_seat_count: { key: "used_seat_count", index: 3 },
    initial_price_monthly: { key: "monthly_pricing", index: 4 },
    initial_price_annual: { key: "annual_pricing", index: 5 },
    pricing: { key: "pricing", index: 6 },
  };

  const fetchPackages = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterName ? filterName : undefined,
    };
    CustomerOrderService.listOrderableUcPackages(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.in_use) {
            const totalSeatCount = itemData?.service_usage_info?.total_seat_count;
            const usedSeatCount = itemData?.service_usage_info?.used_seat_count;
            const monthlyPrice = itemData?.tariff?.seat_monthly_price;
            const annualPrice = itemData?.tariff?.seat_annual_price;
  
            item[TABLE_FIELD_MAPPING["initial_price_monthly"].index] = {
              ...TABLE_FIELD_MAPPING["initial_price_monthly"],
              value: monthlyPrice,
            };
  
            item[TABLE_FIELD_MAPPING["initial_price_annual"].index] = {
              ...TABLE_FIELD_MAPPING["initial_price_annual"],
              value: annualPrice,
            };

            item[TABLE_FIELD_MAPPING["total_seat_count"].index] = {
              ...TABLE_FIELD_MAPPING["total_seat_count"],
              value: totalSeatCount,
            };

            item[TABLE_FIELD_MAPPING["used_seat_count"].index] = {
              ...TABLE_FIELD_MAPPING["used_seat_count"],
              value: usedSeatCount,
            };

            Object.entries(itemData).forEach(([key, value]) => {
              if (
                key in TABLE_FIELD_MAPPING &&
                key !== "monthly_pricing" &&
                key !== "annual_pricing"
              ) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            values.push(item);
          }
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    changeTab(0, null);
  }, []);

  useEffect(() => {
    fetchPackages();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    //changeFurkan  -ask for any necessary format
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "view") {
      setViewModalStatus(!openViewModal);
    } else if (modalType === "unsubscribe") {
      setUnsubscribeModalStatus(!openUnsubscribeModal);
    } else if (modalType === "viewUsage") {
      setViewUsageModalStatus(!openViewUsageModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("view-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="view-package"
            onClick={() => modalHandler("viewUsage", index)}
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("unsubscribe-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="unsubscribe-package"
            onClick={() => modalHandler("unsubscribe", index)}
          >
            <UnsubscribeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("link-mapp")}>
          <IconButton
            color="warning"
            size="small"
            onClick={() => changeTab(1, rowArrayToObject(data[index]).id)}
          >
            <PhonelinkSetupIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  const onClickName = (index) => {
    modalHandler("view", index);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={"#" + selectedRow.name}
        open={openViewModal}
        setOpen={setViewModalStatus}
        children={
          <ManageUcForm
            formType="view"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setViewModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={"#" + selectedRow.name}
        open={openViewUsageModal}
        setOpen={setViewUsageModalStatus}
        sx={{ width: "50%" }}
        children={
          <ManageUcForm
            formType="viewUsage"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setViewUsageModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={"#" + selectedRow.name}
        open={openUnsubscribeModal}
        setOpen={setUnsubscribeModalStatus}
        children={
          <ManageUcForm
            formType="unsubscribe"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setUnsubscribeModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
        <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("package-name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchPackages();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        /* onClickName={onClickName} */
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}

/* 
import React from 'react'

export default function ManageUc() {
  return (
    <div>ManageUc</div>
  )
} */
