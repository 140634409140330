import * as React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, Button, Box, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { ConfigurationService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";

export default function VoiceConfigurationForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
}) {
  const { t } = useTranslation();
  const VoiceConfigurationSchema = Yup.object().shape({
    formType: Yup.string(),
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    priority: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("priority-is-required")),
    host_address: Yup.number()
      .typeError(t("must-be-number"))
      .required(t("host-address-is-required")),
    port: Yup.string().required(t("port-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      formType: formType,
      name: formData.name || "",
      priority: formData.priority || "",
      host_address: formData.host_address || "",
      port: formData.port || "",
    },
    validationSchema: VoiceConfigurationSchema,
    onSubmit: (values, actions) => {
      let payload = {
        name: values.name,
        priority: values.priority,
        host_address: values.host_address,
        port: values.port,
        properties: {},
      };
      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = ConfigurationService.addVoiceConfiguration(payload);
        successMessage = t(
          "new-voice-configuration-has-been-successfully-added"
        );
        failMessage = t("new-voice-configuration-could-not-be-added");
      } else {
        apiService = ConfigurationService.updateVoiceConfiguration(
          formData.id,
          payload
        );
        successMessage = t("has-been-successfully-updated", {
          name: formData.name,
        });
        failMessage = t("could-not-be-updated", { name: formData.name });
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback(response);
            }
            actions.setSubmitting(false);
          } else {
            throw "Add voice configuration failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message[0] || failMessage
                : failMessage
            );
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "priority") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="priority"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("priority")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="priority"
            label={isMobile() ? t("priority") : undefined}
            placeholder={t("type-priority-here")}
            {...getFieldProps("priority")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            error={Boolean(touched.priority && errors.priority)}
            helperText={touched.priority && errors.priority}
          />
        </Box>
      );
    }
    if (fieldName === "host_address") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="host_address"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("host-address")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="host_address"
            label={isMobile() ? t("host-address") : undefined}
            placeholder={t("type-host-address-here")}
            {...getFieldProps("host_address")}
            error={Boolean(touched.host_address && errors.host_address)}
            helperText={touched.host_address && errors.host_address}
          />
        </Box>
      );
    }
    if (fieldName === "port") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="port"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("port")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled={formType === "view"}
            id="port"
            label={isMobile() ? t("port") : undefined}
            placeholder={t("type-port-here")}
            {...getFieldProps("port")}
            error={Boolean(touched.port && errors.port)}
            helperText={touched.port && errors.port}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          sx={{ mx: 1 }}
          size="large"
          type="submit"
          variant="contained"
          disabled={formType === "view" ? true : false}
          loading={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
    if (fieldName === "cancelButton") {
      return (
        <Button
          sx={{ mx: 1 }}
          className="btn btn-default"
          size="large"
          type="button"
          variant="outlined"
          disabled={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <CancelIcon /> : null}
          onClick={() => setModalStatus(false)}
        >
          {t("cancel")}
        </Button>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {getFieldByName("name")}
            {getFieldByName("priority")}
            {getFieldByName("host_address")}
            {getFieldByName("port")}
          </Stack>
          <br />
          {getFieldByName("cancelButton")}
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
