import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";

// ----------------------------------------------------------------------

export default function UcassPackageForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();

  const PackageSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    minSeatCount: Yup.number()
      .min(1, t("seat-count-must-be-greater-than-0"))
      .integer(t("seat-count-must-be-an-integer"))
      .required(t("seat-count-is-required")),
    maxSeatCount: Yup.number()
      .min(1, t("seat-count-must-be-greater-than-0"))
      .test(
        "is-greater-than-minSeatCount",
        t("max-seat-count-must-be-greater-than-min"),
        function (value) {
          const { minSeatCount } = this.parent;
          return value > minSeatCount;
        }
      )
      .integer(t("seat-count-must-be-an-integer"))
      .required(t("seat-count-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      minSeatCount: formData.min_seat_count || "",
      maxSeatCount: formData.max_seat_count || "",
      description: formData.description || "",
      features: formData.features || [],
      isEnabled: formData.is_enabled || false,
    },
    validationSchema: PackageSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        package_type: 2,
        properties: {
          min_seat_count: values.minSeatCount,
          max_seat_count: values.maxSeatCount,
          description: values.description,
          features: values.features,
        },
        is_enabled: values.isEnabled,
        status: 1,
      };
      let successMessage =
        formType === "add"
          ? t("ucass-package-add-success")
          : t("ucass-package-edit-success");
      let failMessage =
        formType === "add"
          ? t("ucass-package-add-fail")
          : t("ucass-package-edit-fail");
      const apiService =
        formType === "add"
          ? PackagesService.addPackage(payload)
          : PackagesService.updatePackage(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback(response.data);
            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }

    if (fieldName === "minSeatCount") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="minSeatCount"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("min-seat-count")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="minSeatCount"
            label={isMobile() ? t("min-seat-count") : undefined}
            placeholder="0"
            {...getFieldProps("minSeatCount")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.minSeatCount && errors.minSeatCount)}
            helperText={touched.minSeatCount && errors.minSeatCount}
          />
        </Box>
      );
    }
    if (fieldName === "maxSeatCount") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="maxSeatCount"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("max-seat-count")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="number"
            id="maxSeatCount"
            label={isMobile() ? t("max-seat-count") : undefined}
            {...getFieldProps("maxSeatCount")}
            placeholder="0"
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.maxSeatCount && errors.maxSeatCount)}
            helperText={touched.maxSeatCount && errors.maxSeatCount}
          />
        </Box>
      );
    }
    if (fieldName === "description") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="description"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("description")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="description"
            multiline
            rows={5}
            label={isMobile() ? t("description") : undefined}
            placeholder={t("type-description-here")}
            {...getFieldProps("description")}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("minSeatCount")}
            {getFieldByName("maxSeatCount")}
            {getFieldByName("description")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
