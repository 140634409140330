import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { VoiceConfigurationForm, DeleteVoiceConfigurationForm } from "./Forms";
import BaseSnackbar from "src/components/BaseSnackbar";
import { ConfigurationService } from "src/api/services";
import { useTranslation } from "react-i18next";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function VoiceConfiguration() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "name", label: t("name") },
    { key: "priority", label: t("priority") },
    { key: "host_address", label: t("host-address") },
    { key: "port", label: t("port") },
    { key: "action", label: t("actions"), align: "right", padding: "2em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
    priority: { key: "priority", index: 2 },
    host_address: { key: "host_address", index: 3 },
    port: { key: "port", index: 4 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterName, setFilterName] = useState("");
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const loadData = () => {
    const params = {
      filterName: filterName ? filterName : undefined,
    };
    setLoadingData(true);
    ConfigurationService.listVoiceConfiguration(params)
      .then((response) => {
        let items = [];
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "add":
        setAddModalStatus(!openAddModal);
        break;
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-voice-configuration")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-voice-configuration"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-voice-configuration")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-voice-configuration"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("add-new-voice-configuration")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <VoiceConfigurationForm
            formType="add"
            successCallback={loadData}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("edit-voice-configuration")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <VoiceConfigurationForm
            formType="edit"
            successCallback={loadData}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-voice-configuration")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteVoiceConfigurationForm
            successCallback={loadData}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                loadData();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-voice-configuration")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        actionItemPrep={getActionItems}
        loadingData={loadingData}
      />
    </>
  );
}
