import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  IconButton,
  Tooltip,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import {
  Capability,
  DefaultPaginationData,
  NumberStatus,
  getSelectOptions,
} from "src/constants/index";
import BaseSnackbar from "src/components/BaseSnackbar";
import { CountriesService, DidService, NumberService } from "src/api/services";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ManageNumberForm from "./forms/ManageNumberForm";
import RemoveService from "./forms/RemoveService";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ManageNumber() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "did", label: t("number") },
    { key: "voice_routing", label: t("voice-routing") },
    { key: "sms_routing", label: t("sms-routing") },
    { key: "service", label: t("assigned-service") },
    { key: "action", label: t("actions"), align: "right", padding: "1.5rem" },
  ];

  const TABLE_FIELD_MAPPING = {
    did_id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    did: { key: "did", index: 1 },
    voice_routing: { key: "voice_routing", index: 2 },
    sms_routing: { key: "sms_routing", index: 3 },
    service: { key: "service", index: 4 },
    voice_routing_id: { key: "voiceRoutingID", index: 5, noRender: true },
    sms_routing_id: { key: "smsRoutingID", index: 6, noRender: true },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterCapability, setFilterCapability] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openRemoveModal, setRemoveModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [countries, setCountries] = useState([]);

  const fetchNumbers = async () => {
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      country: filterCountry ? filterCountry : undefined,
      capability: filterCapability ? filterCapability : undefined,
      type: filterType ? filterType : undefined,
      phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
    };
    setLoadingData(true);
    NumberService.listNumbers(params)
      .then((response) => {
        let items = [];

        let mockResponse = {
          data: {
            count: 2,
            items: [
              {
                capability: 1,
                country: "Germany(+49)",
                country_code: "DE",
                voice_routing: {
                  id: 1,
                  name: "VoiceRouting1",
                },
                sms_routing: {
                  id: 1,
                  name: "SmsRouting1",
                },
                did: "491000007",
                did_id: 25,
                service: "ucc service",
              },
              {
                capability: 1,
                country: "Germany(+49)",
                country_code: "DE",
                voice_routing: {
                  id: 2,
                  name: "VoiceRouting2",
                },
                sms_routing: {
                  id: 2,
                  name: "SmsRouting2",
                },
                did: "491000009",
                did_id: 27,
                service: "ucc service",
              },
            ],
          },
        };

        for (const idx in mockResponse.data.items) {
          setTotalCount(mockResponse.data.count);
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = mockResponse.data.items[idx];

          let voiceRoutingName = itemData?.voice_routing?.name;
          let voiceRoutingID = itemData?.voice_routing?.id;
          item[TABLE_FIELD_MAPPING["voice_routing"].index] = {
            ...TABLE_FIELD_MAPPING["voice_routing"],
            value: voiceRoutingName,
          };

          item[TABLE_FIELD_MAPPING["voice_routing_id"].index] = {
            ...TABLE_FIELD_MAPPING["voice_routing_id"],
            value: voiceRoutingID,
          };

          let smsRoutingName = itemData?.sms_routing?.name;
          let smsRoutingID = itemData?.sms_routing?.id;
          item[TABLE_FIELD_MAPPING["sms_routing"].index] = {
            ...TABLE_FIELD_MAPPING["sms_routing"],
            value: smsRoutingName,
          };

          item[TABLE_FIELD_MAPPING["sms_routing_id"].index] = {
            ...TABLE_FIELD_MAPPING["sms_routing_id"],
            value: smsRoutingID,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "voice_routing" &&
              key !== "sms_routing"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    CountriesService.getCountries({ size: 100 })
      .then((response) => {
        const options = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchNumbers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "remove") {
      setRemoveModalStatus(!openRemoveModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-routings")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-tariff"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("remove-service")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="remove-service"
            onClick={() => modalHandler("remove", index)}
          >
            <CancelPresentationIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        title={t("edit-routings")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <ManageNumberForm
            successCallback={fetchNumbers}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("remove-service")}
        open={openRemoveModal}
        setOpen={setRemoveModalStatus}
        children={
          <RemoveService
            successCallback={fetchNumbers}
            formData={selectedRow}
            setModalStatus={setRemoveModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
                value={
                  countries.find((option) => option.value === filterCountry) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterCountry(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth sx={{ mt: "0.5em" }}>
              <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
              <Select
                label={t("capability")}
                labelId="filter-capability-label"
                name="capability"
                color="secondary"
                value={filterCapability}
                onChange={(event) => {
                  setFilterCapability(event.target.value);
                }}
              >
                {getSelectOptions(Capability())}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("phone-number")}
                labelId="filter-number-label"
                name="number"
                color="secondary"
                value={filterPhoneNumber}
                onChange={(event) => {
                  setFilterCountry(event.target.value);
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchNumbers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
