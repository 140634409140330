import { Box, Typography, Paper, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "src/store/Store";
import { LoadingButton } from "@mui/lab";
import { NumberService, UserService } from "src/api/services";

export default function BuyNumberForm({
  formData,
  setModalStatus,
  setMessage,
  setSnackbarStatus,
  country
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [identity, setIdentity] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {

    setIdentity(store.portal.user.first_name + " " + store.portal.user.last_name);
    setAddress(store.portal.user?.contactsInformation?.address1 + " / " + store.portal.user?.contactsInformation?.address2 + " / " + country)

  }, [store])


  const handleSumbit = () => {
    setIsSubmitting(true);
    let payload = {
      number: formData.did,
      sell_product_id: formData.sell_product_id
    };

    let succesMessage = t("number-buy-request-success");
    let failMessage = t("number-buy-request-fail");
    NumberService.buyNumber(payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsSubmitting(false);
          setMessage(succesMessage);
          setModalStatus(false);
          setSnackbarStatus(true);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setIsSubmitting(false);
          setMessage(failMessage);
          setSnackbarStatus(true);
        }, [1000]);
      });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, margin: 2 }}>
      <Box sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
            marginBottom: 1,
          }}
        >
          <Typography>{t("selected-number")}:</Typography>
          <Typography sx={{ fontWeight: "bold" }}>{formData?.did}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            textAlign: "left",
            marginBottom: 1,
          }}
        >
          <Typography>{t("identity")}:</Typography>
          <Typography sx={{ fontWeight: "bold" }}>{identity}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography>{t("address")}:</Typography>
          <Typography sx={{ fontWeight: "bold" }}>{address}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.5em",
        }}
      >
        <Button
          sx={{
            backgroundColor: store.palette.error.light,
          }}
          variant="contained"
          onClick={() => setModalStatus(false)}
        >
          <Typography
            fontWeight={1000}
            color={store.palette.success.contrastText}
          >
            {t("cancel")}
          </Typography>
        </Button>
        <LoadingButton
          sx={{
            backgroundColor: store.palette.success.main,
          }}
          loading={isSubmiting}
          variant="contained"
          onClick={() => {
            handleSumbit();
          }}
        >
          <Typography
            fontWeight={1000}
            color={store.palette.success.contrastText}
          >
            {t("buy")}
          </Typography>
        </LoadingButton>
      </Box>
    </Paper>
  );
}
