  import { useState, useEffect } from "react";
  import { styled } from "@mui/system";
  import {
    Grid,
    TextField,
    FormControl,
    Stack,
    Button,
    InputLabel,
    Select,
    Autocomplete,
    Box,
    Typography,
    Tooltip,
    IconButton,
    MenuItem,
  } from "@mui/material";

  import { BaseTable, TableFilterContainer } from "src/components/table";
  import SearchButton from "src/components/buttons/SearchButton";
  import BaseModal from "src/components/BaseModal";
  import { rowArrayToObject } from "src/utils/Util";
  import {
    Capability,
    DefaultPaginationData,
    NumberStatus,
    getLabelByValue,
    getSelectOptions,
  } from "src/constants/index";
  import BaseSnackbar from "src/components/BaseSnackbar";
  import { FilterService, NumberService } from "src/api/services";
  import { useTranslation } from "react-i18next";
  import PurchaseNumberForm from "./forms/PurchaseNumberForm";
  import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
  import { useStore } from "src/store/Store";
  import SelectNumberForm from "./forms/SelectNumberForm";
  import InfoNumberForm from "./forms/InfoNumberForm";
  import BuyNumberForm from "./forms/BuyNumberForm";

  const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
    },
  }));

  export default function PurchaseNumber() {
    const { t } = useTranslation();
    const [store] = useStore();

    const TABLE_HEAD = [
      { key: "did", label: t("number") },
      { key: "capability", label: t("capability") },
      { key: "did_type", label: t("type") },
      { key: "mrc", label: t("mrc") },
      { key: "nrc", label: t("nrc") },
      { key: "sms_price", label: t("sms-price") },
      {
        key: "voice_price",
        label: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>{t("voice-price")}</Box>
            <Box sx={{ width: "12rem" }}>{t("peak-offpeak-weekend")}</Box>
          </Box>
        ),
      },
      {
        key: "mobile_price",
        label: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "8rem" }}>{t("mobile-price")}</Box>
            <Box>{t("origin-price")}</Box>
          </Box>
        ),
      },
      { key: "action", label: t("actions") },
    ];

    const TABLE_FIELD_MAPPING = {
      did: { key: "did", index: 0 },
      capability: { key: "capability", index: 1 },
      did_type: { key: "did_type", index: 2 },
      mrc: { key: "mrc", index: 3 },
      nrc: { key: "nrc", index: 4 },
      sms_price: { key: "sms_price", index: 5 },
      voice_price: {
        key: "voice_price",
        index: 6,
        align: "center",
      },
      mobile_price: { key: "mobile_price", index: 7, align: "center" },
      currency: { key: "currency", index: 8, noRender: "true" },
      address_required: { key: "address_required", index: 9, noRender: "true" },
      comp_address_required: {
        key: "comp_address_required",
        index: 10,
        noRender: "true",
      },
      comp_cond_explanation: {
        key: "comp_cond_explanation",
        index: 11,
        noRender: "true",
      },
      comp_country_required: {
        key: "comp_country_required",
        index: 12,
        noRender: "true",
      },
      comp_doc_required: {
        key: "comp_doc_required",
        index: 13,
        noRender: "true",
      },
      comp_email_required: {
        key: "comp_email_required",
        index: 14,
        noRender: "true",
      },
      comp_legal_restrictions: {
        key: "comp_legal_restrictions",
        index: 15,
        noRender: "true",
      },
      comp_required_doc_labels: {
        key: "comp_required_doc_labels",
        index: 16,
        noRender: "true",
      },
      comp_service_restrictions: {
        key: "comp_service_restrictions",
        index: 17,
        noRender: "true",
      },
      comp_tax_id_required: {
        key: "comp_tax_id_required",
        index: 18,
        noRender: "true",
      },
      cond_explanation: { key: "cond_explanation", index: 19, noRender: "true" },
      country_required: { key: "country_required", index: 20, noRender: "true" },
      doc_required: { key: "doc_required", index: 21, noRender: "true" },
      email_required: { key: "email_required", index: 22, noRender: "true" },
      legal_restrictions: {
        key: "legal_restrictions",
        index: 23,
        noRender: "true",
      },
      required_doc_labels: {
        key: "required_doc_labels",
        index: 24,
        noRender: "true",
      },
      service_restrictions: {
        key: "service_restrictions",
        index: 25,
        noRender: "true",
      },
      tax_id_required: { key: "tax_id_required", index: 26, noRender: "true" },
      sell_product_id: { key: "sell_product_id", index: 27, noRender: "true" },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterCapability, setFilterCapability] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
    const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openBuyNumberModal, setOpenBuyNumberModal] = useState(false);
    const [selectNumberModal, setSelectNumberModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [countries, setCountries] = useState([]);
    const [didTypes, setDidTypes] = useState([]);
    const [capabilities, setCapabilities] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [purchaseType, setPurchaseType] = useState(null);
    const [userCountry, setUserCountry] = useState(null);

    const fetchNumbers = () => {
      const params = {
        page: paginationData.page + 1,
        size: paginationData.rowsPerPage,
        country_id: filterCountry ? filterCountry : undefined,
        capability_id: filterCapability ? filterCapability : undefined,
        type_id: filterType ? filterType : undefined,
        phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
      };

      if (!params.country_id || !params.capability_id || !params.type_id) {
        setMessage(t("country-capability-type-cannot-be-empty"));
        setSnackbarStatus(true);
        return;
      }
      setLoadingData(true);

      NumberService.listAvailableNumbers(params)
        .then((response) => {
          if (response.status === 200) {
            let items = [];
            setTotalCount(response.data.count);

            for (const idx in response.data.items) {
              let item = new Array(TABLE_HEAD.length - 1).fill({});
              const itemData = response.data.items[idx];

              let peakPrice = itemData.voice_price;
              let offPeakPrice = itemData.voice_offpeak_price;
              let weekendPrice = itemData.voice_weekend_price;

              item[TABLE_FIELD_MAPPING["voice_price"].index] = {
                ...TABLE_FIELD_MAPPING["voice_price"],
                value: peakPrice + "/ " + offPeakPrice + "/ " + weekendPrice,
              };

              let mobilePrice = itemData.voice_mobile_price;
              let payPhonePrice = itemData.voice_payphone_price;

              item[TABLE_FIELD_MAPPING["mobile_price"].index] = {
                ...TABLE_FIELD_MAPPING["mobile_price"],
                value: mobilePrice + "/ " + payPhonePrice,
              };

              Object.entries(itemData).forEach(([key, value]) => {
                if (key in TABLE_FIELD_MAPPING && key !== "voice_price") {
                  item[TABLE_FIELD_MAPPING[key].index] = {
                    ...TABLE_FIELD_MAPPING[key],
                    value: value,
                  };
                }
              });
              items.push(item);
            }
            setData(items);
          }
        })
        .catch((err) => {
          setData([]);
          if (err.response.status === 404) {
            setMessage(err.response.data.detail);
            setSnackbarStatus(true);
          }
          console.log(err);
        })
        .finally(() => {
          setLoadingData(false);
        });
    };

    useEffect(() => {
      FilterService.listFilterInboundCountries({ size: 100 })
        .then((response) => {
          const options = response.data.map((country) => ({
            value: country.id,
            label: country.name,
          }));
          setCountries(options);
        })
        .catch((err) => {
          console.log(err);
        });

      FilterService.listFilterDidTypes()
        .then((response) => {
          const options = response.data.map((did) => ({
            value: did.id,
            label: did.name,
          }));
          setDidTypes(options);
        })
        .catch((err) => {
          console.log(err);
        });

      FilterService.listFilterCapabilities({ size: 100 })
        .then((response) => {
          const options = Object.entries(response.data).map(
            ([capability, id]) => ({
              value: id,
              label: capability,
            })
          );

          setCapabilities(options);
        })
        .catch((err) => {
          console.log(err);
        });
      //getRuleTypesFunction like countries when api ready FTODO
    }, []);

    useEffect(() => {
      if (filterType && filterCapability && filterType) {
        fetchNumbers();
      }
      return () => {
        setData([]);
      };
    }, [paginationData]);

    useEffect(() => {
      if (countries.length > 0 && userCountry) {
        setUserCountry(
          countries.find(
            (country) =>
              country.value === store.portal.user?.contactsInformation?.country_id
          ).label
        );
      }
    }, [countries]);

    const formatRowData = (rowData) => {
      let formatted = [];
      let currencySign = "";
      rowData.forEach((d) => {
        if (d.key === "currency") {
          currencySign = d.value;
        }
      });
      rowData.map((d, idx) => {
        if (d.key === "capability") {
          formatted.push({
            ...d,
            value: getLabelByValue(Capability(), d.value.toString()),
          });
        } else if (
          d.key === "mrc" ||
          d.key === "nrc" ||
          d.key === "voice_price" ||
          d.key === "mobile_price" ||
          d.key === "sms_price"
        ) {
          formatted.push({
            ...d,
            value: <Typography>{currencySign + d.value}</Typography>,
          });
        } else {
          formatted.push(d);
        }
      });
      return formatted;
    };

    const modalHandler = (modalType, index = undefined) => {
      if (modalType === "select") {
        setSelectNumberModal(!selectNumberModal);
      }
      if (index) {
        setSelectedRow(rowArrayToObject(data[index]));
      }
    };

    const getActionItems = (index) => {
      return (
        <Stack direction="row" spacing={2}>
          <Tooltip title={t("buy-number")}>
            <IconButton
              sx={{
                backgroundColor: store.palette.secondary.contrastText,
              }}
              size="large"
              aria-label="minimize"
              onClick={() => modalHandler("select", index)}
            >
              <AddShoppingCartIcon sx={{ fontSize: "1.8rem" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    };

    return (
      <>
        <BaseSnackbar
          open={openSnackbar}
          message={message}
          setOpen={setSnackbarStatus}
        />
        <BaseModal
          title={t("buy-number")}
          open={selectNumberModal}
          setOpen={setSelectNumberModal}
          children={
            <SelectNumberForm
              successCallback={setOpenBuyNumberModal}
              number={selectedRow.did}
              setModalStatus={setSelectNumberModal}
              setSnackbarStatus={setSnackbarStatus}
              setMessage={setMessage}
            />
          }
        />
        <BaseModal
          sx={{
            width: "30vw",
          }}
          title={t("buy-number")}
          open={openBuyNumberModal}
          setOpen={setOpenBuyNumberModal}
          children={
            <BuyNumberForm
              successCallback={setOpenBuyNumberModal}
              formData={selectedRow}
              setModalStatus={setOpenBuyNumberModal}
              setSnackbarStatus={setSnackbarStatus}
              setMessage={setMessage}
              country={userCountry}
            />
          }
        />
        <BaseModal
          sx={{
            maxHeight: "100%",
            minHeight: "20%",
            overflowY: "auto",
            width: "45%",
          }}
          title={t("buy-number")}
          open={openInfoModal}
          setOpen={setOpenInfoModal}
          children={
            <InfoNumberForm
              successCallback={setOpenPurchaseModal}
              setType={setPurchaseType}
              formData={selectedRow}
              setModalStatus={setOpenInfoModal}
              setSnackbarStatus={setSnackbarStatus}
              setMessage={setMessage}
            />
          }
        />

        <BaseModal
          sx={{
            maxHeight: "100%",
            minHeight: "20%",
            overflowY: "auto",
            width: "45%",
          }}
          title={t("buy-number")}
          open={openPurchaseModal}
          setOpen={setOpenPurchaseModal}
          children={
            <PurchaseNumberForm
              successCallback={fetchNumbers}
              formData={selectedRow}
              purchaseType={purchaseType}
              setModalStatus={setOpenPurchaseModal}
              setSnackbarStatus={setSnackbarStatus}
              setMessage={setMessage}
            />
          }
        />

        <TableFilterContainer style={{ marginBottom: 25 }}>
          <Grid sx={{ alignItems: "center" }} container spacing={4}>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label={t("country")} />
                  )}
                  value={
                    countries.find((option) => option.value === filterCountry) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    if (newValue && newValue.value) {
                      setFilterCountry(newValue.value);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth sx={{ mt: "0.5em" }}>
                <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
                <Select
                  label={t("capability")}
                  labelId="filter-capability-label"
                  name="capability"
                  color="secondary"
                  value={filterCapability}
                  onChange={(event) => {
                    setFilterCapability(event.target.value);
                  }}
                >
                  {capabilities.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  options={didTypes}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label={t("type")} />
                  )}
                  value={
                    didTypes.find((option) => option.value === filterType) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue && newValue.value) {
                      setFilterType(newValue.value);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12} sx={{ mt: "0.5em" }}>
              <FormControl fullWidth>
                <InputLabel id="filter-type-label">{t("status")}</InputLabel>
                <Select
                  label={t("status")}
                  labelId="filter-status-label"
                  name="status"
                  color="secondary"
                  value={filterStatus}
                  onChange={(event) => {
                    setFilterStatus(event.target.value);
                  }}
                >
                  {getSelectOptions(NumberStatus())}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={t("phone-number")}
                  labelId="filter-number-label"
                  name="number"
                  color="secondary"
                  onKeyDown={(event) => {
                    if (
                      event.key !== "Backspace" &&
                      event.key !== "Tab" &&
                      event.key !== "." &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight" &&
                      !/[0-9]/.test(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onWheel={(event) => event.preventDefault()}
                  inputProps={{ min: 0 }}
                  value={filterPhoneNumber}
                  onChange={(event) => {
                    setFilterPhoneNumber(event.target.value);
                  }}
                ></TextField>
              </FormControl>
            </Grid>
            <SearchButtonContainer item md={2} xs={12}>
              <SearchButton
                onClick={() => {
                  fetchNumbers();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </TableFilterContainer>

        <BaseTable
          head={TABLE_HEAD}
          data={[...data].map((d, idx) => {
            return formatRowData(d);
          })}
          actionItemPrep={getActionItems}
          pagination={{
            paginationData: { ...paginationData, totalCount: totalCount },
            setPaginationData: setPaginationData,
          }}
          loadingData={loadingData}
        />
      </>
    );
  }
