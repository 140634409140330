import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ManageNumber from "./numberServices/manageNumbers/ManageNumber";
// ----------------------------------------------------------------------

export default function ManageNumbers() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("manage-number"), component: <ManageNumber /> },
  ];

  const TITLE = t("number");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
