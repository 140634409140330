import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  textAlign: "center",
  color: theme.palette.common.black,
  backgroundColor: theme.palette.background.dark,
  width: "100%",
  flexGrow: 1,
  borderRadius: 10,
  flexDirection: { xs: "column", md: "row" },
  alignItems: "center",
  overflow: "hidden",
  marginTop: "10px",
}));

ReportDateFilter.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  dateRange: PropTypes.array.isRequired,
};

export default function ReportDateFilter({ dateRange, setDateRange }) {
  const { t } = useTranslation();
  return (
    <BoxStyle>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs()}
          onChange={() => {}}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} variant="filled" />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} variant="filled" />
            </>
          )}
        />
      </LocalizationProvider>
    </BoxStyle>
  );
}
