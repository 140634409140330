import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";

// material
import {
  TextField,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { CustomerService, PasswordResetService, UserService } from "src/api/services";
// ----------------------------------------------------------------------

export default function ForgotPasswordForm({type}) {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
  });
  const getFormData = (values) => {
    return {
      email: values.email,
    };
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const payload = getFormData(values);

      let apiService = type == "customer" ? PasswordResetService.customerPasswordResetToken(payload) : PasswordResetService.adminPasswordResetToken(payload);

      apiService
        .then((response) => {
          if (response.data.meta.code === 200) {
            setMessage(t("reset-password-mail-successfully-sended"));
            setOpenSnackbar(true);
          } else {
            throw {
              message: "failed",
              response: response,
            };
          }
        })
        .catch((err) => {
          setMessage(err.response.data.meta.msg);
          setOpenSnackbar(true);
          console.log(err);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
      />
                <Grid item mb={1}>
                  
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        autoComplete="email"
                        type="email"
                        label={t("email-address")}
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ marginBottom: 2 }}
                      />

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{ marginBottom: 1 }}
                      >
                        {t("submit")}
                      </LoadingButton>
                    </Form>
                  </FormikProvider>
                </Grid>


    </>
  );
}
