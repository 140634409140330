import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import PurchaseNumber from "./numberServices/purchaseNumber/PurchaseNumber";
// ----------------------------------------------------------------------

export default function PurchaseNumbers() {
  const { t } = useTranslation();

  const TABS = [{ id: 0, label: t("purchase"), component: <PurchaseNumber /> }];

  const TITLE = t("number");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
