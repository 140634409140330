import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import IotService from "./iotServices/yourServices/IotService";
// ----------------------------------------------------------------------

export default function IotServices() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("your-services"), component: <IotService /> },
  ];

  const TITLE = t("iot");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
