import { useParams } from "react-router-dom";
import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';


import {Users, Roles} from './userRoles/customerUser';
import { canUserReadIt } from "src/utils/Util";

// ----------------------------------------------------------------------


export default function PortalUsers() {
    const { t } = useTranslation();
    const { id } = useParams();

    const TABS = [
        canUserReadIt("customers_and_users") ? { id: 0, label: t('users'), component: <Users /> } : null,
        canUserReadIt("customer_permissions") ? { id: 1, label: t('roles'), component: <Roles /> } : null,        
    ].filter(Boolean);

    const TITLE = t('customer-portal');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
