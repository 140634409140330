import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { AddPartition } from './addPartition';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function AddPartitions() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('add-partition'), component: <AddPartition /> },
    ];

    const TITLE = t('add-partitions');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
