import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { OrdersService } from "src/api/services";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function NumberOrderForm({
  formType = "add",
  selectedCustomer,
  formData,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();

  const OrderSchema = Yup.object().shape({
    count: Yup.number()
      .typeError(t("must-be-number"))
      .min(1, t("count-must-be-greater-than-0"))
      .required(t("count-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      product_id: formData.product_id || "",
      customer_id: selectedCustomer.id || formData.customer_id,
      count: formData.count || 0,
    },
    validationSchema: OrderSchema,
    onSubmit: (values, actions) => {
      const payload = {
        product_id: values.product_id,
        customer_id: values.customer_id,
        count: values.count,
      };

      let successMessage = formType === "add" ? t("new-order-created-successfully"): t("number-order-updated-successfully");
      let failMessage =  formType === "add" ? t("new-order-could-not-be-created") : t("number-order-could-not-be-updated");;
      const apiService = formType === "add" ? OrdersService.addNumberOrder(payload) : OrdersService.updateNumberOrder(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
              setMessage(successMessage);
              setSnackbarStatus(true);
              successCallback();

            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
            setMessage(failMessage);
            setSnackbarStatus(true);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "count") {
      return (
        <TextField
          fullWidth
          type="number"
          label={t("count")}
          {...getFieldProps("count")}
          error={Boolean(touched.count && errors.count)}
          helperText={touched.count && errors.count}
        />
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("create-order")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("customer")}
                </Typography>
                <Typography variant="h5">{selectedCustomer ? selectedCustomer.name : "Example Customer"}</Typography>

                <Divider sx={{ my: 2 }}/>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("product")}
                </Typography>
                <Typography variant="h5">{formData.product_name}</Typography>
              </CardContent>
            </Card>
          </Stack>
          <Stack spacing={3} marginTop={3}>{getFieldByName("count")}</Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
