import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { PackagesService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import SubscriptionPackageForm from "./forms/SubscriptionPackageForm";
import DeletePackageForm from "./forms/DeletePackageForm";
import { useStore } from "src/store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function SubscriptionPackage() {
  const [store] = useStore();
  const { t } = useTranslation();
  const [serviceGroups] = useState(
    store.services.serviceGroups.sort((a, b) => a - b) ?? []
  );

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    /* { key: "uc_container", label: t("uc-container") },
    { key: "cpaas_container", label: t("cpaas-container") },
    { key: "numbers_container", label: t("numbers-container") },
    { key: "outbound_container", label: t("outbound-container") },
    { key: "iot_container", label: t("iot-container") }, */
    { key: "action", label: t("actions"), align: "right", padding: "2em" },
  ];

  const tableColumns = {
    1: { key: "cpaas_container", label: t("cpaas-container") },
    2: { key: "uc_container", label: t("uc-container") },
    3: { key: "number_container", label: t("numbers-container") },
    4: { key: "outbound_container", label: t("outbound-container") },
    5: { key: "iot_container", label: t("iot-container") },
  };

  serviceGroups.forEach((group) => {
    const column = tableColumns[group];
    if (column) {
      TABLE_HEAD.splice(-1, 0, column); // Insert the column just before the last one
    }
  });

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    cpaas_container: {
      key: "cpaas_container",
      index: 2,
      noRender: !serviceGroups.includes(1),
    },
    uc_container: {
      key: "uc_container",
      index: 3,
      noRender: !serviceGroups.includes(2),
    },
    number_container: {
      key: "numbers_container",
      index: 4,
      noRender: !serviceGroups.includes(3),
    },
    outbound_container: {
      key: "outbound_container",
      index: 5,
      noRender: !serviceGroups.includes(4),
    },
    iot_container: {
      key: "iot_container",
      index: 6,
      noRender: !serviceGroups.includes(5),
    },
    uc_container_id: { key: "uc_container_id", index: 7, noRender: true },
    cpaas_container_id: { key: "cpaas_container_id", index: 8, noRender: true },
    number_container_id: {
      key: "number_container_id",
      index: 9,
      noRender: true,
    },
    outbound_container_id: {
      key: "outbound_container_id",
      index: 10,
      noRender: true,
    },
    iot_container_id: { key: "iot_container_id", index: 11, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterPackage, setPackage] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchPackages = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
      name: filterPackage ? filterPackage : undefined,
    };
    PackagesService.listSubscriptionPackages(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          function setContainerFields(item, field) {
            const containerName = itemData[field] ? itemData[field].name : "";
            const containerID = itemData[field] ? itemData[field].id : "";

            item[TABLE_FIELD_MAPPING[field].index] = {
              ...TABLE_FIELD_MAPPING[field],
              value: containerName,
            };

            item[TABLE_FIELD_MAPPING[`${field}_id`].index] = {
              ...TABLE_FIELD_MAPPING[`${field}_id`],
              value: containerID,
            };
          }

          setContainerFields(item, "uc_container");
          setContainerFields(item, "cpaas_container");
          setContainerFields(item, "number_container");
          setContainerFields(item, "outbound_container");
          setContainerFields(item, "iot_container");

          Object.entries(itemData).forEach(([key, value]) => {
            if (key === "id" || key === "name") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPackages();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={0.1}>
        <Tooltip title={t("edit-service-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-service-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          width: "60%",
          maxHeight: "95vh",
          overflow: "auto",
        }}
        title={t("add-new-subscription-package")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <SubscriptionPackageForm
            formType="add"
            successCallback={fetchPackages}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          width: "60%",
          maxHeight: "100%",
        }}
        title={t("edit-subscription-package")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <SubscriptionPackageForm
            formType="edit"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-subscription-package")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeletePackageForm
            packageType="service"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPackage}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setPackage(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchPackages();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-subscription-package")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
