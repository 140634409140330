import { useEffect, useState, useLayoutEffect } from "react";
import {
  AccountChannelTypes,
  AccountStatuses,
  AccountTypes,
  DefaultPaginationData,
  getLabelByValue,
  getSelectOptions,
} from "src/constants/index";
import { useStore } from "src/store/Store";
import { ConfigurationService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { isMobile, rowArrayToObject } from "../../../utils/Util";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import BaseModal from "../../../components/BaseModal";
import { SMSConfigurationForm } from "../../admin/accounts/Forms";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseDialogSimple from "src/components/BaseDialogSimple";
import UpdateAccountStatusForm from "./Forms/UpdateAccountStatusForm";
import { fDateTime } from "../../../utils/formatTime";
import SearchButton from "../../../components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import DeleteSmsConfiguration from "./Forms/DeleteSmsConfiguration";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function SMSConfiguration({ reportView = false }) {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "pop", label: t("pop") },
    { key: "config_type", label: t("config-type") },
    {
      key: "status",
      label: t("common.__i18n_ally_root__.status"),
    },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    pop: { key: "pop", cellComponentType: "th", index: 1 },
    type: { key: "config_type", index: 2 },
    channel_type: { key: "channel_type", index: 3, noRender: true },
    account_type: { key: "account_type", index: 4, noRender: true },
    api_url: { key: "api_url", index: 5, noRender: true },
    username: { key: "username", index: 6, noRender: true },
    password: { key: "password", index: 7, noRender: true },
    from_number: { key: "from_number", index: 8, noRender: true },
    to_number: { key: "to_number", index: 9, noRender: true },
    method: { key: "method", index: 10, noRender: true },
    content_type: { key: "content_type", index: 11, noRender: true },
    body: { key: "body", index: 12, noRender: true },
    system_id: { key: "system_id", index: 13, noRender: true },
    remote_ip: { key: "remote_ip", index: 14, noRender: true },
    port: { key: "port", index: 15, noRender: true },
    version: { key: "version", index: 16, noRender: true },
    encoding: { key: "encoding", index: 17, noRender: true },
    properties: { key: "properties", index: 18, noRender: true },
    config_status: { key: "config_status", index: 19, noRender: true },
    status: { key: "status", index: 20 },
  };

  const [store, dispatch] = useStore();
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [filterType, setType] = useState("");
  const [filterStatus, setStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [openSelectNewEngineModal, setSelectNewEngineModalStatus] =
    useState(false);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEnableModal, setEnableModalStatus] = useState(false);
  const [openDisableModal, setDisableModalStatus] = useState(false);
  const [openViewModal, setViewModalStatus] = useState(false);
  const [openConfirmDisableDialog, setConfirmDisableDialog] = useState(false);
  const [lastConfiguredVoiceEngine, setLastConfiguredVoiceEngine] =
    useState(false);
  const [lastConfiguredSmsEngine, setLastConfiguredSmsEngine] = useState(false);
  const [lastConfiguredNumberEngine, setLastConfiguredNumberEngine] =
    useState(false);
  const [confirmDisableMessage, setConfirmDisableMessage] = useState("");

  let discardChannelTypes = ["1"];

  /*     const checkConfiguredEngineCounts = (engineData) => {
        if (
        engineData.filter(
            (engine) => engine.channel_type === 1 && engine.status === 1
        ).length === 1
        ) {
        setLastConfiguredSmsEngine(true);
        } else {
        setLastConfiguredSmsEngine(false);
        }
        if (
        engineData.filter(
            (engine) => engine.channel_type === 2 && engine.status === 1
        ).length === 1
        ) {
        setLastConfiguredVoiceEngine(true);
        } else {
        setLastConfiguredVoiceEngine(false);
        }
        if (
        engineData.filter(
            (engine) => engine.channel_type === 3 && engine.status === 1
        ).length === 1
        ) {
        setLastConfiguredNumberEngine(true);
        } else {
        setLastConfiguredNumberEngine(false);
        }
    }; */

  const getConfirmDisableEngineMessage = (channelType) => {
    return t("cancel-engine-confirmation", { channelType: channelType });
  };

  const fetchAccounts = () => {
    const params = {
      /*         status: filterStatus ? filterStatus : undefined, */
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    ConfigurationService.listSMSConfiguration(params)
      .then((response) => {
        let items = [];
        /* checkConfiguredEngineCounts(response.data.items); */
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          response.data.items[idx].type =
            response.data.items[idx].type === 1 ? "HTTP" : "SMPP";
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    if (filterType === "") {
      fetchAccounts();
    }
  }, [filterType]);

  useEffect(() => {
    fetchAccounts();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(AccountStatuses(), d.value.toString()),
        });
      } else if (d.key === "created") {
        formatted.push({
          ...d,
          value: fDateTime(+d.value * 1000),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const selectNewEngineHandler = () => {
    const baseData = {
      account_type: "7",
      channel_type: "1",
      status: "1",
    };
    setSelectedRow(baseData);
    setAddModalStatus(!openAddModal);
  };

  const modalHandler = (modalType, index = undefined) => {
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
    if (modalType === "view") {
      setViewModalStatus(!openViewModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "enable") {
      setEnableModalStatus(!openEnableModal);
    } else if (modalType === "disable") {
      if (data[index][3].value === 1 && data[index][7].value === false) {
        setConfirmDisableMessage(getConfirmDisableEngineMessage("SMS"));
        setConfirmDisableDialog(true);
      } else if (data[index][3].value === 2 && data[index][7].value === false) {
        setConfirmDisableMessage(getConfirmDisableEngineMessage("voice"));
        setConfirmDisableDialog(true);
      } else if (data[index][3].value === 3 && data[index][7].value === false) {
        setConfirmDisableMessage(
          getConfirmDisableEngineMessage("number provider")
        );
        setConfirmDisableDialog(true);
      } else {
        setDisableModalStatus(!openDisableModal);
      }
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("view-engine")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="view-account"
            onClick={() => modalHandler("view", index)}
          >
            <EyeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("edit-engine")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-account"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-engine")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-account"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        {/*             {data[index][3].value === 1 ? (
            <Tooltip title={t("disable-engine")}>
                <IconButton
                color="secondary"
                size="small"
                aria-label="disable-account"
                onClick={() => modalHandler("disable", index)}
                >
                <PauseIcon />
                </IconButton>
            </Tooltip>
            ) : (
            <Tooltip title={t("enable-engine")}>
                <IconButton
                color="secondary"
                size="small"
                aria-label="enable-account"
                onClick={() => modalHandler("enable", index)}
                >
                <PlayArrowIcon />
                </IconButton>
            </Tooltip>
            )} */}
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("view-engine-0")}
        open={openViewModal}
        setOpen={setViewModalStatus}
        sx={{ width: 600 }}
        children={
          <SMSConfigurationForm
            formType="view"
            formData={selectedRow}
            setModalStatus={setViewModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("edit-engine-0")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        sx={{ width: 600 }}
        children={
          <SMSConfigurationForm
            formType="edit"
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            successCallback={fetchAccounts}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("add-new-engine")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        sx={{ width: !isMobile() ? "50vw" : "80vw" }}
        children={
          <SMSConfigurationForm
            formType="add"
            formData={selectedRow}
            setModalStatus={setAddModalStatus}
            successCallback={fetchAccounts}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("enable-engine-0")}
        open={openEnableModal}
        setOpen={setEnableModalStatus}
        children={
          <UpdateAccountStatusForm
            successCallback={fetchAccounts}
            formData={selectedRow}
            setModalStatus={setEnableModalStatus}
            isEnable={true}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-engine")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        sx={{ width: 600 }}
        children={
          <DeleteSmsConfiguration
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            successCallback={fetchAccounts}
          />
        }
      />
      <BaseModal
        title={t("disable-engine-0")}
        open={openDisableModal}
        setOpen={setDisableModalStatus}
        children={
          <UpdateAccountStatusForm
            successCallback={fetchAccounts}
            formData={selectedRow}
            setModalStatus={setDisableModalStatus}
            isEnable={false}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseDialogSimple
        title="Warning!"
        open={openConfirmDisableDialog}
        message={confirmDisableMessage}
        setOpen={setConfirmDisableDialog}
        callback={setDisableModalStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">
                {t("common.__i18n_ally_root__.status")}
              </InputLabel>
              <Select
                label={t("common.__i18n_ally_root__.status")}
                labelId="filter-type-label"
                name="status"
                color="secondary"
                value={filterStatus}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                {getSelectOptions(AccountStatuses())}
              </Select>
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchAccounts();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-sms-configuration")}
        onClick={() => selectNewEngineHandler(true)}
      />
      <br />
      <BaseTable
        head={
          !reportView
            ? [
                ...TABLE_HEAD,
                {
                  key: "action",
                  label: t("actions"),
                  align: "right",
                  padding: "2em",
                },
              ]
            : TABLE_HEAD
        }
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={!reportView ? getActionItems : null}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
