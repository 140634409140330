import React from "react";
import { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { TariffService, ServiceTariffMappingService } from "src/api/services";
import { isMobile } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ConfigOutboundServices({ formData }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [outboundTariffs, setOutboundTariffs] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const TABLE_HEAD = [
    { key: "service_name", label: t("outbound-service") },
    { key: "tariff_select", label: t("outbound-tariff") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    service_name: { key: "service_name", index: 1 },
    tariff_id: { key: "tariff_select", index: 2 },
    service_id: { key: "service_id", index: 3, noRender: true },
  };

  const makeTable = () => {
    let filteredServices = [];
    let params = {
      container_id: formData.containerID,
      package_id: formData.packageID,
    };
    ServiceTariffMappingService.listServiceTariffMappings(params)
      .then((response) => {
        if (response.status === 200) {
          response.data.map((serviceTariffMap) => {
            if (
              serviceTariffMap.service.name
                .toLowerCase()
                .includes(filterName.toLowerCase())
            ) {
              filteredServices.push(serviceTariffMap);
            }
          });

          let extractedData = [];
          for (const mapping of filteredServices.sort((a, b) => a.id - b.id)) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            let serviceName = mapping.service?.name;
            let serviceID = mapping.service?.id;
            let tariffID = null;

            switch (mapping.service.id) {
              case 3:
                tariffID = mapping.whatsapp_tariff_id;
                break;
              case 4:
                tariffID = mapping.telegram_tariff_id;
                break;
              case 14:
                tariffID = mapping.voice_tariff_id;
                break;
              case 15:
                tariffID = mapping.sms_tariff_id;
                break;
              case 16:
                tariffID = mapping.rcs_tariff_id;
                break;
              case 17:
                tariffID = mapping.gbc_tariff_id;
                break;
              case 18:
                tariffID = mapping.apple_message_tariff_id;
                break;
            }

            if (mapping.service) {
              item[TABLE_FIELD_MAPPING["service_name"].index] = {
                ...TABLE_FIELD_MAPPING["service_name"],
                value: serviceName,
              };
              item[TABLE_FIELD_MAPPING["service_id"].index] = {
                ...TABLE_FIELD_MAPPING["service_id"],
                value: serviceID,
              };
            }

            if (mapping) {
              item[TABLE_FIELD_MAPPING["tariff_id"].index] = {
                ...TABLE_FIELD_MAPPING["tariff_id"],
                value: tariffID,
              };
            }

            Object.entries(mapping).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING && key !== "service_name") {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });

            extractedData.push(item);
          }

          setData(extractedData);
          if (initialData.length === 0) {
            const deepCopy = JSON.parse(JSON.stringify(extractedData));
            setInitialData(deepCopy);
          }
        } else {
          throw "list mappings failed";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOutboundTariffs = () => {
    TariffService.listOutboundTariffs()
      .then((response) => {
        if (response.status === 200) {

            let tariffOptions = response.data.map((item) => ({
                value: item.tariff_id,
                label: item.tariff_name,
                service_id: item.service_id
            }))

          setOutboundTariffs(tariffOptions);
        } else {
          throw "failed to list outbound tariffs";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchOutboundTariffs();
    makeTable();
  }, []);

  const tariffDropdownSelect = (index) => {
    if (outboundTariffs !== null) {
      let tariffOptions = [];
      let tariffName = null;
      switch (data[index][3].value) {
        case 3: //whatsapp
          tariffName = t("select-whatsapp-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 3
          );
          break;
        case 4: //Telegram
          tariffName = t("select-telegram-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 4
          );
          break;

        case 14: //Voice
          tariffName = t("select-voice-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 14
          );
          break;
        case 15: //SMS
          tariffName = t("select-sms-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 15
          );
          break;

        case 16: //RCS/MMS
          tariffName = t("select-rcs-mms-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 16
          );
          break;

        case 17: //GBC
          tariffName = t("select-gbc-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 17
          );
          break;

        case 18: //Apple Message
          tariffName = t("select-apple-message-tariff");
          tariffOptions = outboundTariffs.filter(
            (item) => item.service_id === 18
          );
          break;
      }
      return (
        <FormControl sx={{ width: isMobile() ? "30vw" : "20vw" }}>
          <Autocomplete
            disabled={!formData.service_engine_mappings.filter((item) => item.service.id === data[index][3].value)[0].is_enabled}
            id="combo-box-demo"
            options={tariffOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={tariffName} />
            )}
            value={
              tariffOptions.find(
                (option) => option.value === data[index][2].value
              ) || null
            }
            onChange={(event, newValue) => {
              const newData = [...data];
              newData[index][2].value = newValue?.value || null;
              setData(newData);
              let successMessage = t("outbound-tariff-changed-successfully");
              setMessage(successMessage);
              setSnackbarStatus(true);
            }}
          />
        </FormControl>
      );
    }
  };

  const handleSave = (index) => {
    let payload = {
      service_id: data[index][3].value,
      package_id: formData.packageID,
      container_id: formData.containerID,
    };

    switch (data[index][3].value) {
      case 3:
        payload.whatsapp_tariff_id = data[index][2].value;
        break;
      case 4:
        payload.telegram_tariff_id = data[index][2].value;
        break;
      case 14:
        payload.voice_tariff_id = data[index][2].value;
        break;
      case 15:
        payload.sms_tariff_id = data[index][2].value;
        break;
      case 16:
        payload.rcs_tariff_id = data[index][2].value;
        break;
      case 17:
        payload.gbc_tariff_id = data[index][2].value;
        break;
      case 18:
        payload.apple_message_tariff_id = data[index][2].value;
        break;
    }

    let successMessage = t("outbound-tariff-changed-successfully");
    let failMessage = t("failed-to-change-outbound-tariff");

    ServiceTariffMappingService.updateServiceTariffMapping(
      data[index][0].value,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].value !== arr2[i].value) return false;
    }
    return true;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <Box sx={{
      maxHeight: "80vh",
      overflow: "scroll",
    }}>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  makeTable();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        actionItemPrep={getActionItems}
        pagination={{}}
        loadingData={loadingData}
        tariffSelect={tariffDropdownSelect}
      />
    </Box>
  );
}
