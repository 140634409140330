import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  TextField,
  Grid,
  Paper,
  Divider,
  InputLabel,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { TariffService } from "src/api/services";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDateInput, isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

export default function UcassTariffForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
}) {
  const { t } = useTranslation();
  const [effectiveStartDate, setEffectiveStartDate] = useState(
    formData ? dayjs(formData.effective_start) : dayjs()
  );
  const [effectiveEndDate, setEffectiveEndDate] = useState(
    formData ? dayjs(formData.effective_end) : dayjs()
  );

  const cpaasTariffSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, "Too short")
      .max(48, "Too long")
      .required("Name is required")
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    number_masking: Yup.number().typeError(t("must-be-number")).nullable(true),
    multi_factor_authentication: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
    campaign_management: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
    text_to_speech: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true)
      .min(0, t("pricing-is-must-be-greater-than-0")), //FTODO CHANGE
    speech_to_text: Yup.number().typeError(t("must-be-number")).nullable(true),
    email_outbound: Yup.number().typeError(t("must-be-number")).nullable(true),
    email_inbound: Yup.number().typeError(t("must-be-number")).nullable(true),
    voice_outbound: Yup.number().typeError(t("must-be-number")).nullable(true),
    voice_inbound: Yup.number().typeError(t("must-be-number")).nullable(true),
    webrtc_voice: Yup.number().typeError(t("must-be-number")).nullable(true),
    /* webrtc_video: Yup.number().typeError(t("must-be-number")).nullable(true), */
    whatsapp_outbound: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
    telegram_outbound: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
    viber_outbound: Yup.number().typeError(t("must-be-number")).nullable(true),
    apple_business_outbound: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
    google_business_outbound: Yup.number()
      .typeError(t("must-be-number"))
      .nullable(true),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      effectiveDateStart: formData.effective_start
        ? dayjs(formData.effective_start)
        : dayjs(),
      number_masking: formData.number_masking,
      multi_factor_authentication: formData.multi_factor_authentication,
      campaign_management: formData.campaign_management,
      text_to_speech: formData.text_to_speech,
      speech_to_text: formData.speech_to_text,
      email_outbound: formData.email_outbound,
      email_inbound: formData.email_inbound,
      voice_outbound: formData.voice_outbound,
      voice_inbound: formData.voice_inbound,
      webrtc_voice: formData.webrtc_voice,
      /* webrtc_video: formData.webrtc_video , */
      whatsapp_outbound: formData.whatsapp_outbound,
      telegram_outbound: formData.telegram_outbound,
      viber_outbound: formData.viber_outbound,
      apple_business_outbound: formData.apple_business_outbound,
      google_business_outbound: formData.google_business_outbound,
      effectiveDateEnd: formData.effective_end
        ? dayjs(formData.effective_end)
        : dayjs(),
    },

    validationSchema: cpaasTariffSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        effective_date_start: formatDateInput(values.effectiveDateStart),
        number_masking: Number(values.number_masking),
        multi_factor_authentication: Number(values.multi_factor_authentication),
        campaign_management: Number(values.campaign_management),
        tts: Number(values.text_to_speech),
        stt: Number(values.speech_to_text),
        email_outbound: Number(values.email_outbound),
        email_inbound: Number(values.email_inbound),
        voice_outbound: Number(values.voice_outbound),
        voice_inbound: Number(values.voice_inbound),
        webrtc_voice: Number(values.webrtc_voice),
        webrtc_video: 0, //FTODO remove later. Now on just written for api work
        whatsapp_outbound_message: Number(values.whatsapp_outbound),
        telegram_outbound_message: Number(values.telegram_outbound),
        viber_outbound_message: Number(values.viber_outbound),
        apple_business_outbound_message: Number(values.apple_business_outbound),
        google_business_outbound_message: Number(
          values.google_business_outbound
        ),
        effective_date_end: formatDateInput(values.effectiveDateEnd),
      };

      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = TariffService.addCpaasTariff(payload);
        successMessage = t("new-tariff-has-been-successfully-added");
        failMessage = t("new-tariff-could-not-be-added");
      } else {
        apiService = TariffService.updateCpaasTariff(formData.id, payload);
        successMessage = t("tariff-has-been-successfully-updated");
        failMessage = t("tariff-could-not-be-updated");
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 2500);
          setMessage(failMessage);
          setSnackbarStatus(true);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "numberMasking") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="numberMasking"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("number-masking")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="numberMasking"
            label={isMobile() ? t("number-masking") : undefined}
            placeholder="0.0"
            {...getFieldProps("number_masking")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.number_masking && errors.number_masking)}
            helperText={touched.number_masking && errors.number_masking}
          />
        </Box>
      );
    }

    if (fieldName === "multiFactorAuthentication") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="multiFactorAuthentication"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("multi-factor-authentication")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="multiFactorAuthentication"
            label={isMobile() ? t("multi-factor-authentication") : undefined}
            placeholder="0.0"
            {...getFieldProps("multi_factor_authentication")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.multi_factor_authentication &&
                errors.multi_factor_authentication
            )}
            helperText={
              touched.multi_factor_authentication &&
              errors.multi_factor_authentication
            }
          />
        </Box>
      );
    }
    if (fieldName === "campaignManagement") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="campaignManagement"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("campaign-management")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="campaignManagement"
            label={isMobile() ? t("campaign-management") : undefined}
            placeholder="0.0"
            {...getFieldProps("campaign_management")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.campaign_management && errors.campaign_management
            )}
            helperText={
              touched.campaign_management && errors.campaign_management
            }
          />
        </Box>
      );
    }

    if (fieldName === "textToSpeech") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="textToSpeech"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("text-to-speech")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="textToSpeech"
            label={isMobile() ? t("text-to-speech") : undefined}
            placeholder="0.0"
            {...getFieldProps("text_to_speech")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.text_to_speech && errors.text_to_speech)}
            helperText={touched.text_to_speech && errors.text_to_speech}
          />
        </Box>
      );
    }

    if (fieldName === "speechToText") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="speechToText"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("speech-to-text")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="speechToText"
            label={isMobile() ? t("speech-to-text") : undefined}
            placeholder="0.0"
            {...getFieldProps("speech_to_text")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.speech_to_text && errors.speech_to_text)}
            helperText={touched.speech_to_text && errors.speech_to_text}
          />
        </Box>
      );
    }

    if (fieldName === "emailOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="emailOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("email-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="emailOutbound"
            label={isMobile() ? t("email-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("email_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.email_outbound && errors.email_outbound)}
            helperText={touched.email_outbound && errors.email_outbound}
          />
        </Box>
      );
    }

    if (fieldName === "emailInbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="emailInbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("email-inbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="emailInbound"
            label={isMobile() ? t("email-inbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("email_inbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.email_inbound && errors.email_inbound)}
            helperText={touched.email_inbound && errors.email_inbound}
          />
        </Box>
      );
    }

    if (fieldName === "voiceOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="voiceOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("voice-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="voiceOutbound"
            label={isMobile() ? t("voice-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("voice_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.voice_outbound && errors.voice_outbound)}
            helperText={touched.voice_outbound && errors.voice_outbound}
          />
        </Box>
      );
    }

    if (fieldName === "voiceInbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="voiceInbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("voice-inbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="voiceInbound"
            label={isMobile() ? t("voice-inbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("voice_inbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.voice_inbound && errors.voice_inbound)}
            helperText={touched.voice_inbound && errors.voice_inbound}
          />
        </Box>
      );
    }
    if (fieldName === "webrtcVoice") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="webrtcVoice"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("webrtc-voice")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="webrtcVoice"
            label={isMobile() ? t("webrtc-voice") : undefined}
            placeholder="0.0"
            {...getFieldProps("webrtc_voice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.webrtc_voice && errors.webrtc_voice)}
            helperText={touched.webrtc_voice && errors.webrtc_voice}
          />
        </Box>
      );
    }
    /* 
    if (fieldName === "webrtcVideo") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="webrtcVideo"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("webrtc-video")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="webrtcVideo"
            label={isMobile() ? t("webrtc-video") : undefined}
            placeholder="0.0"
            {...getFieldProps("webrtc_video")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.webrtc_video && errors.webrtc_video)}
            helperText={touched.webrtc_video && errors.webrtc_video}
          />
        </Box>
      );
    } */

    if (fieldName === "whatsappOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="whatsappOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("whatsapp-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="whatsappOutbound"
            label={isMobile() ? t("whatsapp-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("whatsapp_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.whatsapp_outbound && errors.whatsapp_outbound
            )}
            helperText={touched.whatsapp_outbound && errors.whatsapp_outbound}
          />
        </Box>
      );
    }

    if (fieldName === "telegramOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="telegramOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("telegram-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="telegramOutbound"
            label={isMobile() ? t("telegram-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("telegram_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.telegram_outbound && errors.telegram_outbound
            )}
            helperText={touched.telegram_outbound && errors.telegram_outbound}
          />
        </Box>
      );
    }
    if (fieldName === "viberOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="viberOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("viber-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="viberOutbound"
            label={isMobile() ? t("viber-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("viber_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.viber_outbound && errors.viber_outbound)}
            helperText={touched.viber_outbound && errors.viber_outbound}
          />
        </Box>
      );
    }

    if (fieldName === "appleBusinessOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="appleBusinessOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("apple-business-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="appleBusinessOutbound"
            label={isMobile() ? t("apple-business-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("apple_business_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.apple_business_outbound && errors.apple_business_outbound
            )}
            helperText={
              touched.apple_business_outbound && errors.apple_business_outbound
            }
          />
        </Box>
      );
    }

    if (fieldName === "googleBusinessOutbound") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="googleBusinessOutbound"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("google-business-outbound")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="googleBusinessOutbound"
            label={isMobile() ? t("google-business-outbound") : undefined}
            placeholder="0.0"
            {...getFieldProps("google_business_outbound")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.google_business_outbound &&
                errors.google_business_outbound
            )}
            helperText={
              touched.google_business_outbound &&
              errors.google_business_outbound
            }
          />
        </Box>
      );
    }

    if (fieldName === "effectiveStartDate") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-start-date")}
            value={effectiveStartDate}
            onChange={(newValue) => {
              setEffectiveStartDate(newValue);
              setFieldValue("effectiveDateStart", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }
    if (fieldName === "effectiveEndDate") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-end-date")}
            value={effectiveEndDate}
            onChange={(newValue) => {
              setEffectiveEndDate(newValue);
              setFieldValue("effectiveDateEnd", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Paper sx={{ height: "70vh", overflow: "auto", padding: 2 }}>
            <Grid container spacing={3} justifyContent={"center"}>
              <Grid item xs={6} sm={12}>
                {getFieldByName("name")}
              </Grid>

              <Grid item xs={12} sm={6}>
                {getFieldByName("effectiveStartDate")}
              </Grid>
              <Grid item xs={12} sm={6}>
                {getFieldByName("effectiveEndDate")}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
              <Grid
                container
                spacing={3}
                item
                xs={6}
                sm={12}
                justifyContent={"center"}
              >
                <Grid item xs={12} sm={6}>
                  {getFieldByName("numberMasking")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("multiFactorAuthentication")}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {getFieldByName("textToSpeech")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("speechToText")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("emailOutbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("emailInbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("voiceOutbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("voiceInbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("webrtcVoice")}
                </Grid>
                {/*               <Grid item xs={12} sm={6}>
                {getFieldByName("webrtcVideo")}
              </Grid> */}
                <Grid item xs={12} sm={6}>
                  {getFieldByName("whatsappOutbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("telegramOutbound")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("viberOutbound")}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {getFieldByName("appleBusinessOutbound")}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {getFieldByName("googleBusinessOutbound")}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {getFieldByName("campaignManagement")}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
