import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { DefaultPaginationData } from "src/constants/index";
import { rowArrayToObject } from "src/utils/Util";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import { CustomerService, OrdersService, ProductsService } from "src/api/services";
import { useTranslation } from "react-i18next";
import NumberOrderForm from "./forms/NumberOrderForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function AddNumberOrder() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "product_name", label: t("product-name") },
    { key: "action", label: t("actions"), align: "right", padding: "1.5em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: {
      key: "product_id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    name: { key: "product_name", index: 1 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openAddModal, setOpenAddModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  const fetchProducts = () => {
    const params = {
      name: filterName ? filterName : undefined,
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
      package_type: 2,
      /* customerId: selectedCustomer.id */
    };
    setLoadingData(true);
    OrdersService.getOrderableProducts(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomerNames();
  }, []);

  useEffect(() => {
    fetchProducts();
    return () => {
      setData([]);
    };
  }, [paginationData, selectedCustomer]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      if (!selectedCustomer) {
        setMessage(t("please-select-customer-first"));
        setSnackbarStatus(true);
      } else {
        setOpenAddModalStatus(!openAddModal);
      }
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("create-number-order")} sx={{ mr: 2 }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-forwarding"
            onClick={() => modalHandler("add", index)}
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("create-new-order")}
        open={openAddModal}
        setOpen={setOpenAddModalStatus}
        children={
          <NumberOrderForm
            successCallback={fetchProducts}
            selectedCustomer={selectedCustomer}
            formData={selectedRow}
            setModalStatus={setOpenAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchProducts();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("select-customer")} />
              )}
              value={
                selectedCustomer
                  ? customerOptions.find(
                      (option) => option.value === selectedCustomer.id
                    )
                  : null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer({
                  id: newValue?.value || null,
                  name: newValue?.label || null,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
