import api from "src/api/Api";
import urls from "src/api/Urls";


export const listSubscriptionReports = (params) => {
    return api.get(urls.list_subscription_reports, { params: params });
}

export const listSubscriptionLogs = (params) => {
    return api.get(urls.list_subscription_logs, { params: params });
}