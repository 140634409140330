import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Autocomplete,
  InputLabel,
  Checkbox,
  Typography,
  Card,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import {
  CountriesService,
  CustomerService,
  PackagesService,
} from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function AddCustomerForm({
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
  mainPage = false,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [isCreateUser, setIsCreateUser] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .when([], {
        is: () => isCreateUser,
        then: Yup.string().required(t("first-name-is-required")),
        otherwise: Yup.string(),
      })
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),

    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .when([], {
        is: () => isCreateUser,
        then: Yup.string().required(t("last-name-is-required")),
        otherwise: Yup.string(),
      })
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .when([], {
        is: () => isCreateUser,
        then: Yup.string().required(t("email-is-required")),
        otherwise: Yup.string(),
      }),
    telephone: Yup.number().when([], {
      is: () => isCreateUser,
      then: Yup.number().required(t("telephone-is-required")),
      otherwise: Yup.number(),
    }),
    address1: Yup.string().when([], {
      is: () => isCreateUser,
      then: Yup.string().required(t("address-is-required-0")),
      otherwise: Yup.string(),
    }),
    address2: Yup.string().when([], {
      is: () => isCreateUser,
      then: Yup.string().required(t("address-is-required-0")),
      otherwise: Yup.string(),
    }),
    customerName: Yup.string()
      .min(2, t("too-short-2"))
      .max(50, t("too-long-2"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .required(t("customer-name-is-required"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    password: Yup.string()
      .when([], {
        is: () => isCreateUser,
        then: Yup.string().required(t("password-is-required")),
        otherwise: Yup.string(),
      })
      .min(8, t("too-short-3"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
    country: Yup.number().when([], {
      is: () => isCreateUser,
      then: Yup.number().required(t("country-is-required")),
      otherwise: Yup.number(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      customerName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      address1: "",
      address2: "",
      telephone: "",
      country: ""
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      if (
        values.customerName &&
        values.email &&
        values.password &&
        values.firstName &&
        values.lastName
      ) {
        const payload = {
          new_customer: {
            name: values.customerName,
            service_package_id: selectedPackage,
          },
          new_customer_user: {
            customer_name: values.customerName,
            email: values.email,
            password: values.password,
            first_name: values.firstName,
            last_name: values.lastName,
            telephone: values.telephone,
            address1: values.address1,
            address2: values.address2,
            country_id: values.country,
          },
        };

        CustomerService.addCustomer(payload)
          .then((response) => {
            if (response.status === 200) {
              setMessage(
                t("customer-and-its-initial-user-has-been-successfully-created")
              );
              setSnackbarStatus(true);
              setModalStatus(false);
              successCallback();

              actions.setSubmitting(false);
            } else {
              throw t("register-failed");
            }
          })
          .catch((err) => {
            /*   let message;
            if (err.response.data && err.response.data.detail) {
              message = err.response.data.detail;
            } else {
              message = t("register-failed");
            }
 */
            setMessage(t("failed-to-create-customer"));
            /*   setMessage(message); */
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      } else {
        const payload = {
          name: values.customerName,
          service_package_id: selectedPackage,
        };

        CustomerService.addCustomerWithoutUser(payload)
          .then((response) => {
            if (response.status === 200) {
              setMessage(t("customer-has-been-successfully-created"));
              setSnackbarStatus(true);
              setModalStatus(false);
              successCallback();

              actions.setSubmitting(false);
            } else {
              throw t("register-failed");
            }
          })
          .catch((err) => {
            let message;
            if (err.response.data && err.response.data.detail) {
              message = err.response.data.detail;
            } else {
              message = t("register-failed");
            }
            setMessage(message);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }
    },
  });

  useEffect(() => {
    const params = {
      size: 100,
    };
    PackagesService.listSubscriptionPackages(params).then((response) => {
      if (response.status === 200) {
        let options = response.data.items.map((subsPackage) => ({
          value: subsPackage.id,
          label: subsPackage.name,
        }));
        if (!selectedPackage) {
          setSelectedPackage(options[0].value);
        }
        setPackages(options);
      } else {
        throw "list iot containers failed";
      }
    });

    CountriesService.getCountries().then((response) => {
      if (response.status === 200) {
        let countryOptions = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));

        setCountries(countryOptions);
      } else {
        throw "error listing countries";
      }
    });
  }, []);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Box textAlign="start">
                {!isMobile() && (
                  <InputLabel
                    htmlFor="customerName"
                    style={{
                      color: "secondary",
                      marginLeft: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    {mainPage ? t("company-name") : t("customer-name")}
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="customerName"
                  {...getFieldProps("customerName")}
                  placeholder={
                    mainPage
                      ? t("type-company-name-here")
                      : t("type-customer-name-here")
                  }
                  error={Boolean(touched.customerName && errors.customerName)}
                  helperText={touched.customerName && errors.customerName}
                />
              </Box>

              <Autocomplete
                disableClearable
                disablePortal
                id="combo-box-demo"
                options={packages}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("subscription-package")} />
                )}
                value={
                  packages.find((option) => option.value === selectedPackage) ||
                  null
                }
                onChange={(event, newValue) => {
                  setSelectedPackage(newValue?.value || null);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={isCreateUser}
                  onChange={(e) => setIsCreateUser(e.target.checked)}
                />
                <Typography>{t("create-with-customer-user")}</Typography>
              </Box>

              {isCreateUser && (
                <Card
                  sx={{
                    padding: 2,
                  }}
                >
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="firstName"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("first-name-0")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="firstName"
                        {...getFieldProps("firstName")}
                        error={Boolean(touched.firstName && errors.firstName)}
                        placeholder={t("type-first-name-here")}
                        helperText={touched.firstName && errors.firstName}
                        sx={!isMobile() ? { mr: 2 } : undefined}
                      />
                    </Box>

                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="lastName"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("last-name-0")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="lastName"
                        {...getFieldProps("lastName")}
                        placeholder={t("type-last-name-here")}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="email"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("email-address")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="email"
                        type="email"
                        {...getFieldProps("email")}
                        placeholder={t("type-email-here")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Box>

                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="password"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("password")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="password"
                        type={showPassword ? "text" : "password"}
                        {...getFieldProps("password")}
                        placeholder={t("type-password-here")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="address1"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("address-1")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="address1"
                        {...getFieldProps("address1")}
                        placeholder={t("type-address-here")}
                        error={Boolean(touched.address1 && errors.address1)}
                        helperText={touched.address1 && errors.address1}
                      />
                    </Box>

                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="address2"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("address-2")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="address2"
                        {...getFieldProps("address2")}
                        placeholder={t("type-address-here")}
                        error={Boolean(touched.address2 && errors.address2)}
                        helperText={touched.address2 && errors.address2}
                      />
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="telephone"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("telephone")}
                        </InputLabel>
                      )}
                      <TextField
                        disabled={!isCreateUser}
                        fullWidth
                        id="telephone"
                        {...getFieldProps("telephone")}
                        placeholder={t("type-telephone-here")}
                        error={Boolean(touched.telephone && errors.telephone)}
                        helperText={touched.telephone && errors.telephone}
                      />
                    </Box>

                    <Box flex={1} textAlign="start">
                      {!isMobile() && (
                        <InputLabel
                          htmlFor="countries"
                          style={{
                            color: "secondary",
                            marginLeft: "0.2rem",
                            fontWeight: 500,
                          }}
                        >
                          {t("country")}
                        </InputLabel>
                      )}
                      <Autocomplete
                        disabled={!isCreateUser}
                        id="countries"
                        options={countries}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("select-country")}
                            error={Boolean(touched.country && errors.country)}
                            helperText={touched.country && errors.country}
                          />
                        )}
                        value={
                          countries.find(
                            (option) => option.value === selectedCountry
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          const countryValue = newValue
                            ? Number(newValue.value)
                            : null;
                          setSelectedCountry(countryValue);
                          formik.setFieldValue("country", countryValue);
                        }}
                      />
                    </Box>
                  </Stack>
                </Card>
              )}
              <Box
                sx={{
                  width: "50%",
                  alignSelf: "center",
                }}
              >
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {isCreateUser
                    ? t("add-customer-with-customer-user")
                    : t("add-customer")}
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </>
    </>
  );
}
