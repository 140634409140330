import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { UserService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { canUserWriteIt, rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import LoupeIcon from "@mui/icons-material/Loupe";
import { useTranslation } from "react-i18next";
import { UserForm, DeleteUserForm } from "./form";
import AsignRoleForm from "./form/AsignRoleForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function AdminUser() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "firstName", label: t("first-name") },
    { key: "lastName", label: t("last-name") },
    { key: "email", label: t("email") },
    { key: "role", label: t("role") },
    { key: "partitionAdmin", label: t("partition-admin"), align: "center" },
    canUserWriteIt("admin_users")
      ? { key: "action", label: t("actions"), align: "right", padding: "3vw" }
      : null,
  ].filter(Boolean);

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    first_name: { key: "firstName", index: 1 },
    last_name: { key: "lastName", index: 2 },
    email: { key: "email", index: 3 },
    role: { key: "role", index: 4 },
    partition_admin: { key: "partitionAdmin", index: 5, align: "center" },
    role_id: { key: "roleID", index: 6, noRender: true },
    roles: { key: "roles", index: 7, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openPasswordModal, setPasswordModalStatus] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchAdminUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterName ? filterName : undefined,
    };
    UserService.listAdminUsers(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          if (itemData.roles) {
            const roleNames = [];
            itemData.roles
              .sort((a, b) => a?.role?.id - b?.role?.id)
              .forEach((item) => {
                roleNames.push(item?.role?.role_name.toString());
              });

            const roleName = roleNames.join(", ");

            item[TABLE_FIELD_MAPPING["role"].index] = {
              ...TABLE_FIELD_MAPPING["role"],
              value: roleName,
            };
          }
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "role") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchAdminUsers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "partitionAdmin") {
        if (d.value) {
          formatted.push({
            ...d,
            value: "True",
          });
        } else {
          formatted.push({
            ...d,
            value: "False",
          });
        }
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "password") {
      setPasswordModalStatus(!openPasswordModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "role") {
      setOpenRoleModal(!openRoleModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-admin-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-admin-user"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {canUserWriteIt("admin_permissions") && (
          <Tooltip title={t("asign-role")}>
            <IconButton
              color="secondary"
              size="small"
              aria-label="asign-role"
              onClick={() => modalHandler("role", index)}
            >
              <LoupeIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("override-password")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="update-password"
            onClick={() => modalHandler("password", index)}
          >
            <LockResetIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-admin-user")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-admin-user"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("add-admin-user")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <UserForm
            formType={"add"}
            formData={{}}
            successCallback={fetchAdminUsers}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("edit-admin-user")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UserForm
            formType={"edit"}
            formData={selectedRow}
            successCallback={fetchAdminUsers}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("assign-role")}
        open={openRoleModal}
        setOpen={setOpenRoleModal}
        children={
          <AsignRoleForm
            formData={selectedRow}
            successCallback={fetchAdminUsers}
            setModalStatus={setOpenRoleModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("set-password")}
        open={openPasswordModal}
        setOpen={setPasswordModalStatus}
        children={
          <UserForm
            formType={"password"}
            formData={selectedRow}
            successCallback={fetchAdminUsers}
            setModalStatus={setPasswordModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-admin-user")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteUserForm
            packageType="ucass"
            successCallback={fetchAdminUsers}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchAdminUsers();
                }}
              />
            </SearchButtonContainer>
          </Grid>
          {canUserWriteIt("admin_users") && (
            <Grid
              item
              md={7}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AddNewButton
                label={t("add-new-admin-user")}
                onClick={() => modalHandler("add")}
              />
            </Grid>
          )}
        </Grid>
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={canUserWriteIt("admin_users") && getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
