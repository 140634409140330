import { Navigate, useRoutes, useNavigate } from "react-router-dom";

import { isTokenExpired } from "./utils/Util";
// layouts
import MainLayout from "./layouts/main";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Auth from "./pages/Auth";
import NotFound from "./pages/Page404";
import ChargingLog from "./pages/app/ChargingLog";
import Profile from "./pages/Profile";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------
import Accounts from "./pages/admin/Accounts";
import Customers from "./pages/admin/Customers";
import Settings from "./pages/admin/Settings";
import Applications from "./pages/admin/Applications";
import OutboundSidebar from "./pages/admin/OutboundSidebar";
import ApplicationsSidebar from "./pages/admin/ApplicationsSidebar";
import AddPartitions from "./pages/admin/AddPartitions";
import UcPackages from "./pages/admin/UcPackages";
import Features from "./pages/admin/Features";
import ManagePartitions from "./pages/admin/ManagePartitions";
import UcTariffs from "./pages/admin/UcTariffs";
import ManagePartitionServices from "./pages/admin/partitions/ManagePartitionServices";
import AdminUsers from "./pages/admin/AdminUsers";
import PortalUsers from "./pages/admin/PortalUsers";
import UcContainers from "./pages/admin/UcContainers";
import CpaasPackages from "./pages/admin/CpaasPackages";
import CpaasContainers from "./pages/admin/CpaasContainers";
import CpaasTariffs from "./pages/admin/CpaasTariffs";
import NumbersContainers from "./pages/admin/NumbersContainers";
import NumbersPackages from "./pages/admin/NumbersPackages";
import NumbersTariffs from "./pages/admin/NumbersTariffs";
import OutboundContainers from "./pages/admin/OutboundContainers";
import OutboundPackages from "./pages/admin/OutboundPackages";
import OutboundTariffs from "./pages/admin/OutboundTariffs";
import UcOrders from "./pages/admin/UcOrders";
import NumberOrders from "./pages/admin/NumberOrders";
import SubscriptionPackages from "./pages/admin/SubscriptionPackages";
import SubscriptionReports from "./pages/admin/SubscriptionReports";
import SubscriptionLogs from "./pages/admin/SunscriptionLogs";
import UcManagements from "./pages/admin/UcManagements";
import NumbersProducts from "./pages/admin/NumbersProducts";
import IotContainers from "./pages/admin/IotContainers";
import IotPackages from "./pages/admin/IotPackages";
import IotTariffs from "./pages/admin/IotTariffs";
import WentWrong from "./pages/WentWrong";
import NumbersSettings from "./pages/admin/NumbersSettings";
import OutboundSettings from "./pages/admin/OutboundSettings";
import NumberReports from "./pages/admin/NumberReports";
import OutboundReports from "./pages/admin/OutboundReports";
import AdminUserLogs from "./pages/admin/AdminUserLogs";
import PortalUserLogs from "./pages/admin/PortalUserLogs";
import ProvisioningLogs from "./pages/admin/ProvisioningLogs";
import Partitions from "./pages/admin/Partitions";
// ----------------------------------------------------------------------

export default function AdminRouter() {
  const [store, dispatch] = useStore();
  const admin = JSON.parse(localStorage.getItem("admin"))
  const navigate = useNavigate();

  const handleAppRedirect = () => {
    if (isTokenExpired(admin?.expires_at)) {
      (async () => {
        await dispatch({ type: "ADMIN_LOGOUT" });
      })();
    }

    if (admin !== null && store?.admin?.token !== null) {
        return <MainLayout isAdminLayout={true}/>;
    } else {
      return <Navigate to="/admin/login" />;
    }
  };

  

  return useRoutes([
    {
      path: "/admin",
      element: handleAppRedirect(),
      children: [
        { path: "/admin", element: <Navigate to="/admin/engines" /> },
        { path: "/admin/profile", element: <Profile /> },
        { path: "/admin/engines", element: <Accounts /> },
        { path: "/admin/outbounds", element: <OutboundSidebar /> },
        {
          path: "/admin/tariffs/applications",
          element: <ApplicationsSidebar />,
        },
        { path: "/admin/customers", element: <Customers /> },
        { path: "/admin/audit-logs/admin-user", element: <AdminUserLogs /> },
        { path: "/admin/audit-logs/portal-user", element: <PortalUserLogs /> },
        { path: "/admin/audit-logs/provisioning", element: <ProvisioningLogs /> },
        { path: "/admin/charging-logs", element: <ChargingLog /> },
        { path: "/admin/settings/portal-and-labels", element: <Settings /> },
        { path: "/admin/settings/numbers", element: <NumbersSettings /> },
        { path: "/admin/settings/outbound", element: <OutboundSettings /> },

        {
          path: "/admin/subscriptions/packages",
          element: <SubscriptionPackages />,
        },
        {
          path: "/admin/subscriptions/reports",
          element: <SubscriptionReports />,
        },
        {
          path: "/admin/subscriptions/logs",
          element: <SubscriptionLogs />,
        },
        { path: "/admin/cpaas/packages", element: <CpaasPackages /> },
        { path: "/admin/cpaas/containers", element: <CpaasContainers /> },
        { path: "/admin/cpaas/tariffs", element: <CpaasTariffs /> },
        { path: "/admin/ucaas/containers", element: <UcContainers /> },
        { path: "/admin/ucaas/packages", element: <UcPackages /> },
        { path: "/admin/ucaas/tariffs", element: <UcTariffs /> },
        { path: "/admin/ucaas/features", element: <Features /> },
        { path: "/admin/uc-management/uc-users", element: <UcManagements /> },

        { path: "/admin/numbers/containers", element: <NumbersContainers /> },
        { path: "/admin/numbers/packages", element: <NumbersPackages /> },
        { path: "/admin/numbers/products", element: <NumbersProducts /> },
        { path: "/admin/numbers/tariffs", element: <NumbersTariffs /> },

        { path: "/admin/outbound/containers", element: <OutboundContainers /> },
        { path: "/admin/outbound/packages", element: <OutboundPackages /> },
        { path: "/admin/outbound/tariffs", element: <OutboundTariffs /> },

        { path: "/admin/iot/containers", element: <IotContainers /> },
        { path: "/admin/iot/packages", element: <IotPackages /> },
        { path: "/admin/iot/tariffs", element: <IotTariffs /> },

        { path: "/admin/uc-management/uc-orders", element: <UcOrders /> },
        { path: "/admin/number-management/number-orders", element: <NumberOrders /> },
        { path: "/admin/number-management/number-reports", element: <NumberReports /> },


        { path: "/admin/outbound-management/outbound-reports", element: <OutboundReports /> },

        { path: "/admin/applications", element: <Applications /> },
        { path: "/admin/users-roles/admin", element: <AdminUsers /> },
        { path: "/admin/users-roles/portal", element: <PortalUsers /> },
        {
          path: "/admin/users-roles/portal/:companyName",
          element: <PortalUsers />,
        },
        {
          path: "/admin/partitions/add-partition",
          element:
            store.admin?.user && store.admin?.user.root_admin ? (
              <AddPartitions />
            ) : (
              <Navigate to="/admin" />
            ),
        },
        {
          path: "/admin/partitions/list-partitions",
          element:
            store.admin?.user && store.admin?.user.root_admin ? (
              <Partitions />
            ) : (
              <Navigate to="/admin" />
            ),
        },
        {
          path: "/admin/partitions/manage-partitions/:id",
          element:
            store.admin?.user && store.admin?.user.root_admin ? (
              <ManagePartitions />
            ) : (
              <Navigate to="/admin" />
            ),
        },

        {
          path: "/admin/manage-partitions-services/:id",
          element:
            store.admin?.user && store.admin?.user.root_admin ? (
              <ManagePartitionServices />
            ) : (
              <Navigate to="/admin" />
            ),
        },
      ],
    },
    {
        path: "/admin",
       element: (admin === null || admin?.token === null ) ? <LogoOnlyLayout /> : <Navigate to="/admin" />,
        children: [
            { path: "/admin/login", element: <Auth type="admin-login" /> },
            { path: "/admin/forgot-password", element: <Auth type="forgot-password" /> },
            { path: "/admin/accept-invite", element: <Auth type="accept-admin-invitation" /> },
            {
              path: "/admin/new-password-admin",
              element: <Auth type="new-password-admin" />,
            },
          ],
      },
    { path: "*", element: <NotFound /> },
    { path: "oops", element: <WentWrong /> },
  ]);
}
