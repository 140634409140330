import api from "src/api/Api";
import urls from "src/api/Urls";

export const getPartitionServiceGroups = (params) => {
  return api.get(urls.list_partition_service_groups, { params: params });
};

export const getAdminServiceGroups = () => {
  return api.get(urls.list_admin_service_groups);
};

export const updatePartitionServiceGroup = (params) => {
  return api.put(urls.update_partition_service_group, null, { params: params });
};

export const updateCustomerServiceGroup = (params) => {
  return api.put(urls.update_customer_service_group, null, { params: params });
};


export const updateRegistrationOnOff = (params) => {
  return api.put(urls.update_registration_on_of, null, { params: params });
}

export const updateDefaultSubscriptionPackage = (package_id, params) => {
  return api.put(urls.update_default_subscription_package(package_id),null, {params});
}

