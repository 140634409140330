import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { PackagesService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { isMobile, rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { useTranslation } from "react-i18next";
import {
  ConfigFeatureForm,
  UcassPackageForm,
  DeletePackageForm,
} from "./forms";
import ConfigUcaasPackage from "./forms/ConfigUcaasPackage";
import DeleteAnyway from "src/components/DeleteAnyway";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function UcassPackage() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "min_seat_count", label: t("min-seat-count") },
    { key: "max_seat_count", label: t("max-seat-count") },
    { key: "action", label: t("actions"), align: "right", padding: "4em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    min_seat_count: { key: "min_seat_count", index: 2 },
    max_seat_count: { key: "max_seat_count", index: 3 },
    service_engine_mappings: {
      key: "service_engine_mappings",
      index: 4,
      noRender: true,
    },
    is_enabled: { key: "is_enabled", index: 5, noRender: true },
    package_type: { key: "package_type", index: 6, noRender: true },
    features: { key: "features", index: 7, noRender: true },
    description: { key: "description", index: 8, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterPackage, setPackage] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openConfigModal, setConfigModalStatus] = useState(false);
  const [openConfigFeatureModal, setConfigFeatureModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchPackages = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterPackage ? filterPackage : undefined,
      package_type: 2,
    };
    PackagesService.listPackages(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.properties) {
            const minSeatCount = itemData.properties.min_seat_count ?? 0;
            const maxSeatCount = itemData.properties.max_seat_count ?? 0;
            const description = itemData.properties.description || "";
            const features = itemData.properties.features ?? [];

            item[TABLE_FIELD_MAPPING["min_seat_count"].index] = {
              ...TABLE_FIELD_MAPPING["min_seat_count"],
              value: minSeatCount,
            };
            item[TABLE_FIELD_MAPPING["max_seat_count"].index] = {
              ...TABLE_FIELD_MAPPING["max_seat_count"],
              value: maxSeatCount,
            };
            item[TABLE_FIELD_MAPPING["description"].index] = {
              ...TABLE_FIELD_MAPPING["description"],
              value: description,
            };
            item[TABLE_FIELD_MAPPING["features"].index] = {
              ...TABLE_FIELD_MAPPING["features"],
              value: features,
            };
          }
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPackages();
    return () => {
      setData([]);
    };
  }, [paginationData, openConfigModal]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "config") {
      setConfigModalStatus(!openConfigModal);
    } else if (modalType === "configFeatures") {
      setConfigFeatureModalStatus(!openConfigFeatureModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-uc-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("configure-features")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="configure-features"
            onClick={() => modalHandler("configFeatures", index)}
          >
            <SettingsInputComponentIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("config-uc-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="configure-package"
            onClick={() => modalHandler("config", index)}
          >
            <DisplaySettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-uc-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleFirstAdd = (data) => {
    setSelectedRow(data);
    setConfigModalStatus(!openConfigModal);
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchPackages();
    }
  };

  const informativeMessage = t("uc-package-config-info");

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{ width: !isMobile() ? "30vw" : "60vw" }}
        title={t("add-ucass-package")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <UcassPackageForm
            formType="add"
            successCallback={handleFirstAdd}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("edit-ucass-package")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <UcassPackageForm
            formType="edit"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-uc-package")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeletePackageForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-uc-package")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchPackages}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PackagesService.deletePackage(selectedRow.id, { proceed: true })
            }
            name={t("uc-package")}
            usedIn={t("uc-container")}
          />
        }
      />
      <BaseModal
        sx={{ width: !isMobile() ? "70vw" : "100vw" }}
        title={t("config-package")}
        open={openConfigModal}
        setOpen={setConfigModalStatus}
        informativeMessage={informativeMessage}
        children={
          <ConfigUcaasPackage
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setConfigModalStatus}
            setOuterSnackbarStatus={setSnackbarStatus}
            setOuterMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{ minWidth: "30vw", width: "30%" }}
        title={t("config-features")}
        open={openConfigFeatureModal}
        setOpen={setConfigFeatureModalStatus}
        children={
          <ConfigFeatureForm
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setConfigFeatureModalStatus}
            setMessage={setMessage}
            setSnackbarStatus={setSnackbarStatus}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPackage}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setPackage(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchPackages();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-ucass-package")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
