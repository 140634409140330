import api from "src/api/Api";
import urls from "src/api/Urls";

export const getOutboundCountries = (params) => {
  return api.get(urls.outbound_countries, { params: params });
};

export const setOutboundCountries = (payload) => {
  return api.put(urls.set_outbound_countries, payload);
};

export const getInboundCountries = (params) => {
  return api.get(urls.inbound_countries, { params: params });
};

export const setInboundCountries = (payload) => {
  return api.put(urls.set_inbound_countries, payload);
};

export const getCountries = (params) => {
  return api.get(urls.list_countries, { params: params });
};

export const listPartitionCountries = () => {
  return api.get(urls.list_partition_countries );
};
