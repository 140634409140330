import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import AddUcOrder from './ucOrders/AddUcOrder';
import ListUcOrders from './ucOrders/ListUcOrders';
import { canUserWriteIt } from 'src/utils/Util';

// ----------------------------------------------------------------------


export default function UcOrders() {
    const { t } = useTranslation();

    const TABS = [
        canUserWriteIt("uc_order") ? { id: 0, label: t('new-uc-order'), component: <AddUcOrder /> } : null,
        { id: 1, label: t('uc-orders-list'), component: <ListUcOrders /> },
    ].filter(Boolean);

    const TITLE = t('uc-orders');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
