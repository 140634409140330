import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import NumberTariff from './numbersConfig/numbersTariff/NumberTariff';


// ----------------------------------------------------------------------


export default function NumbersTariffs() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('numbers-tariff'), component: <NumberTariff/> },
    ];
    
    const TITLE = t("numbers-tariff");
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
