import api from "src/api/Api";
import urls from "src/api/Urls";


export const customerPasswordResetToken = (payload) => {
    return api.post(urls.customer_password_reset_token, payload);
}

export const customerPasswordReset = (payload) => {
    return api.post(urls.customer_password_reset, payload);
}

export const adminPasswordResetToken = (payload) => {
    return api.post(urls.admin_password_reset_token, payload);
}

export const adminPasswordReset = (payload) => {
    return api.post(urls.admin_password_reset, payload);
}
