import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  Autocomplete,
  Tooltip,
  IconButton
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { ContainerService, TariffService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { isMobile } from "src/utils/Util";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function IotPackageConfig({ formData }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [iotTariffs, setIotTariffs] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const TABLE_HEAD = [
    { key: "enabled", label: t("enabled") },
    { key: "iot_package_name", label: t("iot-package") },
    { key: "engine_name", label: t("engine") },
    { key: "tariff_select", label: t("iot-tariff") },
    { key: "actions", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    is_enabled: { key: "enabled", index: 1 },
    iot_package_name: { key: "iot_package_name", index: 2 },
    engine_name: { key: "engine_name", index: 3 },
    iot_tariff_id: { key: "tariff_select", index: 4 },
    iot_package_id: { key: "iot_package_id", index: 5, noRender: true },
    package: { key: "package", index: 6, noRender: true },
  };

  useEffect(() => {
    fetchIotTariffs();
    makeTable();
  }, []);

  const makeTable = () => {
    let filteredPackages = [];
    ContainerService.getContainer(formData.id)
      .then((response) => {
        if (response.status === 200) {
          response.data.container_package_tariff_mappings.map((mapping) => {
            if (
              mapping.package.name
                .toLowerCase()
                .includes(filterName.toLowerCase())
            ) {
              filteredPackages.push(mapping);
            }
          });

          let extractedData = [];

          for (const mapping of filteredPackages.sort((a, b) => a.id - b.id)) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});
            let packageID = null;
            let packageName = "";
            let tariffID = null;
            let engineName = "";
            if (mapping.package) {
              packageName = mapping.package.name;
              packageID = mapping.package.id;
              console.log(mapping);
              tariffID = mapping.iot_tariff ? mapping.iot_tariff : null;

              if (mapping.package.service_engine_mappings[0].engine) {
                if (
                  mapping.package.service_engine_mappings[0].engine.properties
                ) {
                  let pop =
                    mapping.package.service_engine_mappings[0].engine.properties
                      .pop;
                  let engineSubtype =
                    mapping.package.service_engine_mappings[0].engine
                      .engine_subtype.name;

                  engineName = pop + "/" + engineSubtype;
                } else {
                  engineName =
                    mapping.package.service_engine_mappings[0].engine.name;
                }
              }
            }

            if (mapping.package) {
              item[TABLE_FIELD_MAPPING["iot_package_name"].index] = {
                ...TABLE_FIELD_MAPPING["iot_package_name"],
                value: packageName,
              };
              item[TABLE_FIELD_MAPPING["iot_package_id"].index] = {
                ...TABLE_FIELD_MAPPING["iot_package_id"],
                value: packageID,
              };
              item[TABLE_FIELD_MAPPING["engine_name"].index] = {
                ...TABLE_FIELD_MAPPING["engine_name"],
                value: engineName,
              };
              item[TABLE_FIELD_MAPPING["iot_tariff_id"].index] = {
                ...TABLE_FIELD_MAPPING["iot_tariff_id"],
                value: tariffID,
              };
            }

            Object.entries(mapping).forEach(([key, value]) => {
              if (
                key in TABLE_FIELD_MAPPING &&
                key !== "iot_tariff_id" &&
                key !== "iot_package_name" &&
                key !== "engine_name" &&
                key !== "iot_package_id" &&
                mapping.package
              ) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            extractedData.push(item);
          }
          setData(extractedData);

          if (initialData.length === 0) {
            const deepCopy = JSON.parse(JSON.stringify(extractedData));
            setInitialData(deepCopy);
          }
        } else {
          throw "err to get mappings";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchIotTariffs = () => {
    TariffService.listIotTariffs({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.items.map((tariff) => ({
            value: tariff.id,
            label: tariff.name,
          }));
          setIotTariffs(options);
        }
      })
      .catch((err) => {
        console.error("failed to get tariffs");
      });
  };

  const handleOnChange = (index) => {
    let successMessage = data[index][1].value
      ? t("iot-package-disabled-successfully")
      : t("iot-package-enabled-successfully");

    if (!data[index][6].value.is_enabled) {
      setMessage(t("iot-package-is-disable"));
      setSnackbarStatus(true);
      return;
    }

    data[index][1].value = !data[index][1].value;
    setMessage(successMessage);
    setSnackbarStatus(true);
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][1].value}
        onChange={() => handleOnChange(index)}
      />
    );
  };

  const tariffDropdownSelect = (index) => {
    return (
      <FormControl sx={{ width: isMobile() ? "40vw" : "15vw" }}>
        <Autocomplete
          id="combo-box-demo"
          options={iotTariffs}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-iot-tariff")} />
          )}
          value={
            iotTariffs.find(
              (option) => option.value === data[index][4].value
            ) || null
          }
          onChange={(event, newValue) => {
            data[index][4].value = newValue?.value || null;
            let successMessage = t("iot-tariff-changed-successfully");
            setMessage(successMessage);
            setSnackbarStatus(true);
          }}
        />
      </FormControl>
    );
  };

  const handleSave = (index) => {
    let payload = {
      container_id: formData.id,
      package_id: data[index][5].value,
      iot_tariff_id: data[index][4].value,
      is_enabled: data[index][1].value,
    };

    let successMessage = t("changes-are-applied-successfully");
    let failMessage = t("failed-to-apply-chaanges");

    ContainerService.updatePackageTariffMapping(data[index][0].value, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        } else {
          throw "err at update";
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                makeTable();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        loadingData={loadingData}
        enableSwitch={enableSwitch}
        tariffSelect={tariffDropdownSelect}
        actionItemPrep={getActionItems}
      />
    </>
  );
}
