import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  styled,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import BaseSnackbar from "src/components/BaseSnackbar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { CustomerService, UcMana, UcManagementService } from "src/api/services";
import { useStore } from "src/store/Store";

const FileInput = styled("input")({
  display: "none",
});

export default function BulkUploadUcUsers() {
  const [store, dispatch] = useStore();
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState(",");
  const [fileName, setFileName] = useState("Choose a file!");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const fetchCustomerNames = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };

  useEffect(() => {
    fetchCustomerNames();
    if (store.customerID) {
      setSelectedCustomer(store.customerID);
      dispatch({ type: "UPDATE_CUSTOMER_ID", payload: { customerID: null } });
    }
  }, []);

  const PackageSchema = Yup.object().shape({
    customerName: Yup.string()
      .required(t("customer-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    file: Yup.mixed().required(t("file-is-required")),
  });

  let sampleData =
    "Email,First Name,Last Name,uc_username,uc_password\n" +
    "john.doe@example.com,John,Doe,johndoe,password123\n" +
    "jane.smith@example.com,Jane,Smith,janesmith,securepass456\n" +
    "bob.johnson@example.com,Bob,Johnson,bobjohnson,letmein789\n" +
    "alice.williams@example.com,Alice,Williams,alicew,pass1234\n" +
    "david.miller@example.com,David,Miller,davidm,securepass789\n" +
    "susan.jones@example.com,Susan,Jones,susanj,letmein567\n" +
    "michael.brown@example.com,Michael,Brown,michaelb,password5678\n" +
    "linda.white@example.com,Linda,White,lindaw,securepass890\n" +
    "tom.davis@example.com,Tom,Davis,tomd,letmein890\n" +
    "emily.wilson@example.com,Emily,Wilson,emilyw,password8901\n" +
    "ryan.anderson@example.com,Ryan,Anderson,ryana,securepass901\n" +
    "olivia.moore@example.com,Olivia,Moore,oliviam,letmein9012\n" +
    "henry.clark@example.com,Henry,Clark,hc123,securepass012\n" +
    "grace.hill@example.com,Grace,Hill,graceh,letmein0123";

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      const uploadedFileName = uploadedFile.name;
      setFile(uploadedFile);
      setFileName(uploadedFileName);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage(t("please-select-file-to-uplaod"));
      setSnackbarStatus(true);
      return;
    }

    const params = {
      delimiter: delimiter,
    };
    const payload = new FormData();
    payload.append("csv_file", file);

    UcManagementService.bulkUploadUcUser(params, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("file-uploaded-successfully"));
          setSnackbarStatus(true);
        } else {
          setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
          setSnackbarStatus(true);
        }
      })
      .catch((err) => {
        setMessage(t("file-uploaded-error")); //Error occurred while uploading the file
        setSnackbarStatus(true);
      });
  };

  const handleDelimiterChange = (event) => {
    setDelimiter(event.target.value);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          border: "0.2px solid white",
          borderRadius: 5,
          boxShadow: 10,
          width: "100%",
        }}
      >
        <Box
          className="functionalities"
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mb: 5 }}>
            <InputLabel id="delimiter-label">{t("select-customer")}</InputLabel>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("choose-customer")} />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Box>
          <Box
            className="DelimiterPart"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="delimiter-label">{t("delimiter")}</InputLabel>
            <Select
              labelId="delimiter-label"
              id="delimiter"
              value={delimiter}
              onChange={handleDelimiterChange}
            >
              <MenuItem value={","}>{t("comma")} ( , )</MenuItem>
              <MenuItem value={";"}>{t("semicolon")} ( ; )</MenuItem>
            </Select>
          </Box>
          <Box
            className="FileUpload"
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 5,
            }}
          >
            <InputLabel id="upload-label">{t("file-upload")}</InputLabel>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <TextField fullWidth value={fileName} sx={{ height: "100%" }} />
              </Grid>
              <Grid item xs={5} md={6}>
                <label htmlFor="file" style={{ height: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{ ml: 1, mt: 1, height: "85%", width: "6vw" }}
                  >
                    <SearchIcon /> {t("browse")}
                  </Button>
                  <FileInput
                    id="file"
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileUpload}
                  />
                </label>
              </Grid>
              <Grid item xs={5} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleSubmit}
                  sx={{ mt: 1, height: "85%", width: "6vw" }}
                >
                  <UploadFileIcon />
                  {t("upload")}
                </Button>
              </Grid>
            </Grid>
            {!file ? (
              <Typography
                mt={2}
                variant="body2"
                color={store.palette.error.main}
              >
                {t("csv-warning")}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box
          className="sampleDataFile"
          sx={{
            width: "60%",
            ml: 5,
          }}
        >
          <InputLabel id="sampleData-label" sx={{ fontSize: 20 }}>
            {t("example-data-file")}
          </InputLabel>

          <textarea
            readOnly
            defaultValue={sampleData}
            style={{ width: "100%", height: "100%", fontSize: 14 }}
          />
        </Box>
      </Box>
    </>
  );
}
