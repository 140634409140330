import api from "src/api/Api";
import urls from "src/api/Urls";

//UC Orders

export const listUcOrders = (params) => {
    return api.get(urls.list_uc_orders, { params: params });
}

export const getUcOrder = (id) => {
    return api.get(urls.get_uc_order(id));   
        } 

export const addUcOrder = (payload) => {
    return api.post(urls.add_uc_order, payload);
}

export const deleteUcOrder = (id) => {
    return api.delete(urls.delete_uc_order(id));
}

export const updateUcOrder = (id, payload) => {
    return api.put(urls.update_uc_order(id), payload);
}

export const getOrderableUcPackages = (params) => {
    return api.get(urls.list_orderable_uc_packages_admin, { params: params });
}


//Number Orders:

export const listNumberOrders = (params) => {
    return api.get(urls.list_number_orders, { params: params });
}

export const getNumberOrder = (id) => {
    return api.get(urls.get_number_order(id));   
        } 

export const addNumberOrder = (payload) => {
    return api.post(urls.add_number_order, payload);
}

export const deleteNumberOrder = (id) => {
    return api.delete(urls.delete_number_order(id));
}

export const updateNumberOrder = (id, payload) => {
    return api.put(urls.update_number_order(id), payload);
}

export const getOrderableProducts = (params) => {
    return api.get(urls.list_orderable_products, { params: params });
}
