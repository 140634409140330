import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.lightmost,
}));

// ----------------------------------------------------------------------

export default function WentWrong() {
  const { t } = useTranslation();

  return (
    <RootStyle title={t("error")}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {t("went-wrong")}
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              {t("sorry-went-wrong")}
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_wentwrong.jpg"
                sx={{
                  height: 260,
                  ml: "8em",
                  my: { xs: 5, sm: 5 },
                }}
              />
            </motion.div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "2em",
              }}
            >
              <Button
                to="/"
                size="large"
                variant="contained"
                component={RouterLink}
                startIcon={<HomeIcon />}
              >
                {t("go-to-home")}
              </Button>
              <Button
                size="large"
                variant="contained"
                href="mailto:furkan.unal@smarttel.co"
                startIcon={<MailIcon />}
              >
                {t("contact-support")}
              </Button>
            </Box>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
