import api from "src/api/Api";
import urls from "src/api/Urls";



export const listOrganizationUsers = (id, params) => {
    return api.get(urls.list_organization_users(id), { params: params });
}

export const addOrganizationUser = (id, payload) => {
    return api.post(urls.add_organization_user(id), payload);
}

export const deleteOrganizationUser = (id, params) => {
    return api.delete(urls.delete_organization_user(id), { params: params });
}

export const updateOrganizationUser = (id, params, payload) => {
    return api.post(urls.update_organization_user(id), payload, { params: params });
}

export const updateOrganizationUserPassword = (id, payload) => {
    return api.put(urls.update_password(id), payload);
}

export const updateSelectedLanguage = (id, selectedLanguageId) => {
    return api.post(urls.update_selected_language(id), selectedLanguageId);
}

export const listCustomerUsers = (params) => {
    return api.get(urls.list_customer_users, { params: params });
}


//Admin User

export const listAdminUsers = (params) => {
    return api.get(urls.list_admin_users, { params: params });
}

export const addAdminuser = (payload) => {
    return api.post(urls.add_admin_user, payload);
}

export const updateAdminUser = (id, payload) => {
    return api.patch(urls.update_admin_user(id), payload);
}

export const deleteAdminUser = (id) => {
    return api.delete(urls.delete_admin_user(id));
}

export const updateRoleOfAdmin = (id, roleID, isDelete) => {
    return api.put(urls.assign_role_to_admin(id, roleID, isDelete));
}

//Customer User

export const createCustomerUser = (payload) => {
    return api.post(urls.create_customer_user, payload);
}

export const createCustomerUserInvitation = (payload) => {
    return api.post(urls.create_customer_user_invitation, payload);
}

export const updateCustomerUser = (id, payload) => {
    return api.put(urls.update_customer_user(id), payload);
}

export const deleteCustomerUser = (id, payload) => {
    return api.put(urls.delete_customer_user(id), payload);
}


export const updateRoleOfCustomerUser = (id, roleID, isDelete) => {
    return api.put(urls.update_role_of_customer_user(id, roleID, isDelete));
}

export const getCurrentUserDetail = () => {
    return api.get(urls.get_current_user_detail)
}