import api from "src/api/Api";
import urls from "src/api/Urls";

//Admin Roles
export const addAdminRole = (payload) => {
    return api.post(urls.add_admin_role, payload);
}

export const listAdminRoles = (params) => {
    return api.get(urls.list_admin_roles, { params: params });
}

export const updateAdminRole = (id, payload) => {
    return api.patch(urls.update_admin_role(id), payload);
}

export const deleteAdminRole = (id, params) => {
    params = params ? params : { proceed: false };
    return api.delete(urls.delete_admin_role(id), {params});
}

export const listAdminFunctionGroups = (params) => {
    return api.get(urls.list_admin_function_groups, { params: params });
}

export const getFunctionGroupsOfAdminRole = (id) => {
    return api.get(urls.get_function_groups_of_admin_role(id));
}

export const getAdminUserRoles = (id) => {
    return api.get(urls.get_admin_user_roles(id));
}

export const assignFunctionGroupToRole = (id, functioon_group_id, is_delete, read_allowed, write_allowed) => {
    return api.put(urls.assign_function_group_to_role(id, functioon_group_id, is_delete, read_allowed, write_allowed));
}


//Customer Roles   -Later
export const listCustomerRoles = (params) => {           //TODO! list customer roles not ready. Change here when done
    return api.get(urls.list_customer_roles, { params: params });
}

export const listCustomerFunctionGroups = (params) => {
    return api.get(urls.list_customer_function_groups, { params: params });
}

export const getFunctionGroupsOfCustomerRole = (id) => {
    return api.get(urls.get_function_groups_of_customer_role(id));
}

export const getCustomerUserRoles = (id) => {
    return api.get(urls.get_customer_user_roles(id));
}
