import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import IotContainer from "./iotConfig/iotContainers/IotContainer";

// ----------------------------------------------------------------------

export default function IotContainers() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("iot-container"), component: <IotContainer /> },
  ];

  const TITLE = t("iot-container");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
