import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountService } from "src/api/services";
import {
  Card,
  CardContent,
  Tooltip,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from "react-router-dom";
import { useStore } from "src/store/Store";

export default function GeneralPartitionInfo({ id }) {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [store] = useStore();


  const fetchPartitions = () => {
    const params = {
      size: 100,
    };
    AccountService.listPartitions(params)
      .then((response) => {
        response.data.items.map((partition) => {
          if (partition.id === parseInt(id)) {
            setData(partition);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchPartitions();
    return () => {
      setData({});
    };
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card sx={{ padding: 5 }}>
        <Box className="backward-button-container" sx={{ mb: 2 }}>
            <Tooltip title={t("go-back-to-partition-list")}>
              <Link to="/admin/partitions/list-partitions" style={{ textDecoration: "none" }}>
                <IconButton sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: store.palette.secondary.main,
                  '&:hover': {
                    backgroundColor: "gray",
                  },
                  borderRadius: "50%",
                }}>
                  <KeyboardBackspaceIcon sx={{ width: "40px", height: "40px", color: store.palette.secondary.contrastText }} />
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
          <Typography variant="h5" component="div">
            {t("general-info")}:
            <hr
              style={{
                border: "0.5px solid #000",
                width: "150px",
                margin: "5px 0",
              }}
            />
          </Typography>
          <CardContent>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gap: "10px",
              }}
            >
              <div>
                <Typography variant="h5" component="div">
                  {t("id")}:
                </Typography>
                <Typography variant="h5" component="div">
                  {t("name")}:
                </Typography>
                {data.fqdn && (
                  <>
                    <Typography variant="h5" component="div">
                      {t("fqdn")}:
                    </Typography>
                    <Typography variant="h5" component="div">
                      &nbsp;
                    </Typography>
                  </>
                )}
                <Typography variant="h5" component="div">
                  {t("status")}:
                </Typography>
              </div>
              <div>
                <Typography variant="h6" color={"secondary"} component="div">
                  {data.id}
                </Typography>
                <Typography variant="h6" color={"secondary"} component="div">
                  {data.name}
                </Typography>
                {data.fqdn && (
                  <>
                    <Typography
                      variant="h6"
                      color={"secondary"}
                      component="div"
                    >
                      {data.fqdn[0] ?? <br />}
                    </Typography>
                    <Typography
                      variant="h6"
                      color={"secondary"}
                      component="div"
                    >
                      {data.fqdn[1] ?? <br />}
                    </Typography>
                  </>
                )}
                <Typography
                  variant="h6"
                  color={"secondary"}
                  component="div"
                  sx={{ mt: 1.5 }}
                >
                  {data.status === 1 ? t("active") : t("inactive")}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
