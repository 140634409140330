import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
// app
import * as Constants from "src/constants";
import {
  TwoFactorAuthenticationService,
  CommonService,
} from "src/api/services";
import { VerifyOTPForm } from ".";
import { useTranslation } from "react-i18next";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------

export default function ServiceForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  let disabledChannelTypes = [];
  if (!store.portalSettings.services["2fa_sms"]) {
    disabledChannelTypes.push("1");
  }
  if (!store.portalSettings.services["2fa_voice"]) {
    disabledChannelTypes.push("2");
  }
  const [languages, setLanguages] = useState([]);
  const [testMessageSent, setTestMessageSent] = useState(false);
  const [otpDetails, setOtpDetails] = useState({});

  useEffect(() => {
    CommonService.getTTSLanguages({})
      .then((response) => {
        setLanguages(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ServiceSchema = Yup.object().shape({
    formType: Yup.string(),
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    serviceId: Yup.string().when("formType", {
      is: "test",
      then: Yup.string().required(t("service-id-is-required")),
      otherwise: Yup.string(),
    }),
    codeLength: Yup.string().required(t("code-length-must-be-selected")),
    type: Yup.string().required(t("type-must-be-selected")),
    timeout: Yup.number()
      .min(0, t("timeout-must-be-at-least-0"))
      .required(t("timeout-is-required")),
    guardTime: Yup.number()
      .min(0, t("guard-time-must-be-at-least-0"))
      .required(t("guard-time-is-required")),
    body: Yup.string().matches(/(\{code\})/, t("body-must-include-code-in-it")),
    status: Yup.string().when("formType", {
      is: "test",
      then: Yup.string(),
      otherwise: Yup.string().required(t("status-must-be-selected-0")),
    }),
    ttsLanguageId: Yup.number().when("type", {
      is: "2",
      then: Yup.number().required(t("language-must-be-selected")),
      otherwise: Yup.number(),
    }),
    vendor: Yup.string().when("type", {
      is: "2",
      then: Yup.string().required(t("vendor-must-be-selected")),
      otherwise: Yup.string(),
    }),
    fromNumber: Yup.string().when("formType", {
      is: "test",
      then: Yup.string()
        .matches(/^[a-zA-Z0-9]*$/, t("from-number-must-be-alphanumeric"))
        .required(t("from-number-is-required")),
      otherwise: Yup.string(),
    }),
    toNumber: Yup.string().when("formType", {
      is: "test",
      then: Yup.string()
        .matches(/^[a-zA-Z0-9]*$/, t("to-number-must-be-alphanumeric"))
        .required(t("to-number-is-required")),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      formType: formType,
      name: formData.name || "",
      serviceId: formData.serviceId || "",
      codeLength: formData.codeLength || "4",
      type: formData.type
        ? formData.type.toString()
        : disabledChannelTypes.includes("1")
        ? "2"
        : "1",
      timeout: formData.timeout || 180,
      guardTime: formData.guardTime || 60,
      body: formData.body || t("your-verification-code-is") + " {code}",
      ttsLanguageId: formData.ttsLanguageId || "1",
      vendor: formData.vendor || "1",
      status: formData.status ? formData.status.toString() : "1",
      fromNumber: "",
      toNumber: "",
    },
    validationSchema: ServiceSchema,
    onSubmit: (values, actions) => {
      const payload =
        formType === "test"
          ? {
              service: formData.serviceId || values.serviceId,
              from_number: values.fromNumber,
              to_number: values.toNumber,
              timeout: values.timeout,
              code_length: values.codeLength,
              body: values.body,
            }
          : {
              name: values.name,
              type: values.type,
              code_length: values.codeLength,
              status: values.status,
              default_body: values.body,
              default_guard_time: values.guardTime,
              default_timeout: values.timeout,
              tts_language:
                values.type === "2" ? values.ttsLanguageId : undefined,
              vendor: values.type === "2" ? values.vendor : undefined,
            };
      let apiService, successMessage, failMessage;
      if (formType === "test") {
        apiService = TwoFactorAuthenticationService.sendOTPMessage(payload);
        successMessage = t("test-message-has-been-successfully-sent");
        failMessage = t("test-message-could-not-be-sent");
      } else if (formType === "add") {
        apiService = TwoFactorAuthenticationService.addTwoFAService(payload);
        successMessage = t("new-service-has-been-successfully-added");
        failMessage = t("new-service-could-not-be-added");
      } else {
        apiService = TwoFactorAuthenticationService.updateTwoFAService(
          formData.id,
          payload
        );
        successMessage = t("has-been-successfully-updated", {
          name: formData.name,
        });
        failMessage = t("could-not-be-updated", { name: formData.name });
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            const otpData = {
              ...values,
              code: response.data.code,
              requestId: response.data.request_id,
            };
            if (formType === "test") {
              setOtpDetails(otpData);
              setTestMessageSent(true);
            }
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus && formType !== "test") {
              setModalStatus(false);
            }
            if (successCallback) {
              formType === "test"
                ? successCallback(otpData)
                : successCallback();
            }
            actions.setSubmitting(false);
          } else {
            throw "two fa operation failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return formType === "test" ? null : (
        <TextField
          fullWidth
          label={t("service-name")}
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      );
    }
    if (fieldName === "serviceId") {
      return formType === "add" ? null : (
        <TextField
          fullWidth
          {...getFieldProps("serviceId")}
          label={t("service-id")}
          disabled={formData.serviceId ? true : false}
          error={Boolean(touched.serviceId && errors.serviceId)}
          helperText={touched.serviceId && errors.serviceId}
        />
      );
    }
    if (fieldName === "codeLength") {
      return formType === "test" && formData.codeLength
        ? null
        : Constants.getRadioButtonComponent(
            Constants.TwoFAServiceCodeLengths(),
            getFieldProps("codeLength"),
            t("code-length")
          );
    }
    if (fieldName === "type") {
      return formType === "test" && formData.type
        ? null
        : Constants.getRadioButtonComponent(
            Constants.TwoFAServiceTypes(),
            getFieldProps("type"),
            t("type"),
            "row",
            disabledChannelTypes
          );
    }
    if (fieldName === "timeout") {
      return formType === "test" && formData.timeout ? null : (
        <TextField
          fullWidth
          type="number"
          label={t("timeout-s")}
          {...getFieldProps("timeout")}
          error={Boolean(touched.timeout && errors.timeout)}
          helperText={touched.timeout && errors.timeout}
        />
      );
    }
    if (fieldName === "guardTime") {
      return formType === "test" && formData.guardTime ? null : (
        <TextField
          fullWidth
          type="number"
          label={t("guard-time-s")}
          {...getFieldProps("guardTime")}
          error={Boolean(touched.guardTime && errors.guardTime)}
          helperText={touched.guardTime && errors.guardTime}
        />
      );
    }
    if (fieldName === "body") {
      return formType === "test" && formData.body ? null : (
        <TextField
          fullWidth
          multiline
          label={t("body")}
          {...getFieldProps("body")}
          error={Boolean(touched.body && errors.body)}
          helperText={touched.body && errors.body}
        />
      );
    }
    if (fieldName === "status") {
      return formType === "test"
        ? null
        : Constants.getRadioButtonComponent(
            Constants.TwoFAServiceStatuses(),
            getFieldProps("status"),
            t("common.__i18n_ally_root__.status")
          );
    }
    if (fieldName === "ttsLanguageId") {
      return values.type === "1" ? null : formData.serviceId &&
        formType === "test" ? null : (
        <FormControl fullWidth>
          <InputLabel id="tts-language-label">{t("language-0")}</InputLabel>
          <Select
            label={t("language-0")}
            labelId="tts-language-label"
            color="secondary"
            {...getFieldProps("ttsLanguageId")}
            error={Boolean(touched.ttsLanguageId && errors.ttsLanguageId)}
            helperText={touched.ttsLanguageId && errors.ttsLanguageId}
          >
            {languages.map((lang, idx) => {
              return (
                <MenuItem key={lang.id} value={lang.id}>
                  {lang.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
    if (fieldName === "vendor") {
      return values.type === "1" ? null : formData.serviceId &&
        formType === "test" ? null : (
        <FormControl fullWidth>
          <InputLabel id="vendor-label">{t("vendor-1")}</InputLabel>
          <Select
            label={t("vendor-1")}
            labelId="vendor-label"
            color="secondary"
            disabled={formType === "view"}
            {...getFieldProps("vendor")}
            error={Boolean(touched.vendor && errors.vendor)}
            helperText={touched.vendor && errors.vendor}
          >
            {Constants.getSelectOptions(
              Constants.TextSpeechVendorType(),
              [],
              null
            )}
          </Select>
        </FormControl>
      );
    }
    if (fieldName === "fromNumber") {
      return formType !== "test" ? null : (
        <TextField
          fullWidth
          label={t("common.fromNumber")}
          {...getFieldProps("fromNumber")}
          error={Boolean(touched.fromNumber && errors.fromNumber)}
          helperText={touched.fromNumber && errors.fromNumber}
        />
      );
    }
    if (fieldName === "toNumber") {
      return formType !== "test" ? null : (
        <TextField
          fullWidth
          label={t("common.__i18n_ally_root__.toNumber")}
          {...getFieldProps("toNumber")}
          error={Boolean(touched.toNumber && errors.toNumber)}
          helperText={touched.toNumber && errors.toNumber}
        />
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          disabled={testMessageSent}
          loading={isSubmitting}
          loadingPosition={formType === "test" ? "end" : "start"}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          {formType === "test"
            ? t("send")
            : t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("serviceId")}
            {getFieldByName("codeLength")}
            {getFieldByName("type")}
            {getFieldByName("vendor")}
            {getFieldByName("ttsLanguageId")}
            {getFieldByName("timeout")}
            {getFieldByName("guardTime")}
            {getFieldByName("body")}
            {getFieldByName("status")}
            {getFieldByName("fromNumber")}
            {getFieldByName("toNumber")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
      {formType === "test" && formData.serviceId && testMessageSent ? (
        <VerifyOTPForm formData={otpDetails} />
      ) : null}
    </>
  );
}
