import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import SubscriptionPackage from './subscriptionManage/subscriptionPackage/SubscriptionPackage';
// ----------------------------------------------------------------------


export default function SubscriptionPackages() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('subscription-package'), component: <SubscriptionPackage /> },      
    ];

    const TITLE = t('subscription-management');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
