import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import IotTariff from "./iotConfig/iotTariffs/IotTariff";

// ----------------------------------------------------------------------

export default function IotTariffs() {
  const { t } = useTranslation();

  const TABS = [{ id: 0, label: t("iot-tariff"), component: <IotTariff /> }];

  const TITLE = t("iot-tariff");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
