import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import UcaasUser from "./uccServices/ucaasUser/UcaasUser";
import UploadUcUsers from "./uccServices/ucaasUser/UploadUcUsers";
// ----------------------------------------------------------------------

export default function UCaaSUsers() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("ucaas-user"), component: <UcaasUser /> },
    { id: 1, label: t("upload-ucaas-user"), component: <UploadUcUsers /> },
  ];

  const TITLE = t("uc-users");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
