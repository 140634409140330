import React from "react";
import { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import {
  ProductsService,
  TariffService,
} from "src/api/services";
import { isMobile } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function ConfigProducts({ formData }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [tariffOptions, setTariffOptions] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const TABLE_HEAD = [
    /*  { key: "enabled", label: t("Enabled") }, */
    { key: "product_name", label: t("numbers-product") },
    { key: "tariff", label: t("numbers-tariff") },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    /* is_enabled: { key: "enabled", index: 1 }, */
    product_name: { key: "product_name", index: 1 },
    tariff_select: { key: "tariff_select", index: 2 },
    product_id: { key: "product_id", index: 3, noRender: true },
  };

  const makeTable = () => {
    let filteredProducts = [];
    let params = {
      container_id : formData.containerID,
      package_id: formData.packageID
    }
    console.log(params)
    ProductsService.listProductTariffMappings(params)
      .then((response) => {
        if (response.status === 200) {
          response.data.map((ProductTariffMap) => {
            if (
              ProductTariffMap.product.name
                .toLowerCase()
                .includes(filterName.toLowerCase())
            ) {
              filteredProducts.push(ProductTariffMap);
            }
          });

          let extractedData = [];
          for (const mapping of filteredProducts.sort((a, b) => a.id - b.id)) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            let productName = mapping.product.name;
            let productID = mapping.product.id;
            let tariffID = mapping.tariff
              ? mapping.tariff.id
              : null;

            if (mapping.product) {
              item[TABLE_FIELD_MAPPING["product_name"].index] = {
                ...TABLE_FIELD_MAPPING["product_name"],
                value: productName,
              };
              item[TABLE_FIELD_MAPPING["product_id"].index] = {
                ...TABLE_FIELD_MAPPING["product_id"],
                value: productID,
              };
            }

            if (mapping) {
              item[TABLE_FIELD_MAPPING["tariff_select"].index] = {
                ...TABLE_FIELD_MAPPING["tariff_select"],
                value: tariffID,
              };
            }

            Object.entries(mapping).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING && key !== "product_name") {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });

            extractedData.push(item);
          }

          setData(extractedData);
          if (initialData.length === 0) {
            const deepCopy = JSON.parse(JSON.stringify(extractedData));
            setInitialData(deepCopy);
          }
        } else {
          throw "list packages failed";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchTariffOptions = () => {
    TariffService.listNumberTariffs({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let options = [];
         
          response.data.items.forEach((item) => {
            let option = {
              value: item.id,
              label: item.name,
            };
            options.push(option);
          });

          setTariffOptions(options);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchTariffOptions();
    makeTable();
  }, []);

  const tariffDropdownSelect = (index) => {
    return (
      <FormControl sx={{ width: isMobile() ? "30vw" : "20vw" }}>
        <Autocomplete
          id="combo-box-demo"
          options={tariffOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-numbers-tariff")} />
          )}
          value={
            tariffOptions.find(
              (option) => option.value === data[index][2].value
            ) || null
          }
          onChange={(event, newValue) => {
            const newData = [...data];
            newData[index][2].value = newValue?.value || null;
            setData(newData);
            let successMessage = t("numbers-tariff-changed-successfully");
            setMessage(successMessage);
            setSnackbarStatus(true);
          }}
        />

      </FormControl>
    );
  };

  const handleSave = (index) => {
    let payload = {
      product_id: data[index][3].value,
      tariff_id: data[index][2].value,
      package_id: formData.packageID,
      container_id: formData.containerID
    };

    let successMessage = t("product-tariff-changed-successfully");
    let failMessage = t("failed-to-change-product-tariff");

    ProductsService.updateProductTariffMapping(data[index][0].value, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].value !== arr2[i].value) return false;
    }
    return true;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  makeTable();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        actionItemPrep={getActionItems}
        pagination={{}}
        loadingData={loadingData}
        tariffSelect={tariffDropdownSelect}
      />
    </>
  );
}
