import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import OutboundPackage from './outboundConfig/outboundPackage/OutboundPackage';

// ----------------------------------------------------------------------


export default function OutboundPackages() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('outbound-package'), component: <OutboundPackage /> },
    ];

    const TITLE = t('outbound-package');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
