import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  Autocomplete,
  Box,
  InputLabel,
  Switch,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import {
  ContainerService,
  PackagesService,
  ProductsService,
} from "src/api/services";
import { useTranslation } from "react-i18next";
import { useStore } from "src/store/Store";
import { isMobile } from "src/utils/Util";

// ----------------------------------------------------------------------

export default function SubscriptionPackageForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const [store] = useStore();
  const { t } = useTranslation();
  const [serviceGroups] = useState(store.services.serviceGroups ?? []);

  const [ucContainers, setUcContainers] = useState([]);
  const [cpaasContainers, setCpaasContainers] = useState([]);
  const [numberContainers, setNumberContainers] = useState([]);
  const [outboundContainers, setOutboundContainers] = useState([]);
  const [iotContainers, setIotContainers] = useState([]);

  const [selectedUc, setSelectedUc] = useState(
    formData.uc_container_id || null
  );
  const [selectedCpaas, setSelectedCpaas] = useState(
    formData.cpaas_container_id || null
  );
  const [selectedNumService, setSelectedNumService] = useState(
    formData.number_container_id || null
  );
  const [selectedOutbound, setSelectedOutbound] = useState(
    formData.outbound_container_id || null
  );

  const [selectedIot, setSelectedIot] = useState(
    formData.iot_container_id || null
  );
  const [chargingEnabled, setChargingEnabled] = useState(store.services.creditControl);

  const PackageSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const checkTariffMapping = async (packageTariffMappings, type) => {
    let tariffMissing = null;

    switch (type) {
      case 1:
        tariffMissing = packageTariffMappings.some(
          (mapping) => mapping.cpaas_tariff === null && mapping.is_enabled
        );
        break;
      case 2:
        tariffMissing = packageTariffMappings.some(
          (mapping) => mapping.uc_tariff === null && mapping.is_enabled
        );
        break;
      case 3:
        const responses = await Promise.all(
          packageTariffMappings.map((mapping) => {
            if (mapping.is_enabled) {
              return ProductsService.listProductTariffMappings({
                container_id: mapping.container_id,
                package_id: mapping.package ? mapping.package.id : undefined,
              });
            }
            return null;
          })
        );

        tariffMissing = responses.some((response) => {
          if (response && response.status === 200) {
            return response.data.some((item) => !item.tariff);
          }
          return false;
        });
        break;
      case 4:
        tariffMissing = packageTariffMappings.some(
          (mapping) => mapping.outbound_tariff === null && mapping.is_enabled
        );
        break;
      case 5:
        tariffMissing = packageTariffMappings.some(
          (mapping) => mapping.iot_tariff === null && mapping.is_enabled
        );
        break;
      default:
        break;
    }

    return tariffMissing ? "/ " + t("missing-tariff") : "";
  };

  useEffect(() => {
    const fetchContainers = async () => {
      try {
        const response = await ContainerService.listContainers({
          page_size: 100,
          container_type: 0,
        });

        if (response.status === 200) {
          const cpaasOptions = [];
          const ucOptions = [];
          const numbersOptions = [];
          const outboundOptions = [];
          const iotOptions = [];

          for (const item of response.data.items) {
            const labelSuffix = chargingEnabled
              ? await checkTariffMapping(
                  item.container_package_tariff_mappings,
                  item.container_type
                )
              : "";

            const option = {
              value: item.id,
              label: item.name + labelSuffix,
            };

            switch (item.container_type) {
              case 1:
                cpaasOptions.push(option);
                break;
              case 2:
                ucOptions.push(option);
                break;
              case 3:
                numbersOptions.push(option);
                break;
              case 4:
                outboundOptions.push(option);
                break;
              case 5:
                iotOptions.push(option);
                break;
              default:
                break;
            }
          }

          setCpaasContainers(cpaasOptions);
          setUcContainers(ucOptions);
          setNumberContainers(numbersOptions);
          setOutboundContainers(outboundOptions);
          setIotContainers(iotOptions);
        } else {
          throw new Error("list containers error");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchContainers();
  }, [chargingEnabled]);

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      ucContainer: formData.uc_container_id || null,
      cpaasContainer: formData.cpaas_container_id || null,
      numberServiceContainer: formData.number_service_container_id || null,
      outboundServiceContainer: formData.outbound_service_container_id || null,
      iotContainer: formData.iot_container_id || null,
    },
    validationSchema: PackageSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        uc_container_id: selectedUc,
        cpaas_container_id: selectedCpaas,
        number_container_id: selectedNumService,
        outbound_container_id: selectedOutbound,
        iot_container_id: selectedIot,
        status: 1,
      };
      let successMessage =
        formType === "add"
          ? t("subscripton-package-add-success")
          : t("subscription-package-edit-success");
      let failMessage =
        formType === "add"
          ? t("subscription-package-add-fail")
          : t("subscription-package-edit-fail");
      const apiService =
        formType === "add"
          ? PackagesService.addSubscriptionPackage(payload)
          : PackagesService.updateSubscriptionPackage(
              { subscription_package_id: formData.id },
              payload
            );
      apiService
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw "package operation failed";
          }
        })
        .catch((err) => {
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "cpaasContainer" && serviceGroups.includes(1)) {
      return (
        <Autocomplete
          disablePortal
          getOptionDisabled={(option) =>
            option.label.includes(t("missing-tariff"))
          }
          id="combo-box-demo"
          options={cpaasContainers}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-cpaas-container")} />
          )}
          value={
            cpaasContainers.find((option) => option.value === selectedCpaas) ||
            null
          }
          onChange={(event, newValue) => {
            setSelectedCpaas(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "ucContainer" && serviceGroups.includes(2)) {
      return (
        <Autocomplete
          disablePortal
          getOptionDisabled={(option) =>
            option.label.includes(t("missing-tariff"))
          }
          id="combo-box-demo"
          options={ucContainers}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-uc-container")} />
          )}
          value={
            ucContainers.find((option) => option.value === selectedUc) || null
          }
          onChange={(event, newValue) => {
            setSelectedUc(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "numberServiceContainer" && serviceGroups.includes(3)) {
      return (
        <Autocomplete
          disablePortal
          getOptionDisabled={(option) =>
            option.label.includes(t("missing-tariff"))
          }
          id="combo-box-demo"
          options={numberContainers}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-numbers-container")} />
          )}
          value={
            numberContainers.find(
              (option) => option.value === selectedNumService
            ) || null
          }
          onChange={(event, newValue) => {
            setSelectedNumService(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "outboundServiceContainer" && serviceGroups.includes(4)) {
      return (
        <Autocomplete
          disablePortal
          getOptionDisabled={(option) =>
            option.label.includes(t("missing-tariff"))
          }
          id="combo-box-demo"
          options={outboundContainers}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-outbound-container")} />
          )}
          value={
            outboundContainers.find(
              (option) => option.value === selectedOutbound
            ) || null
          }
          onChange={(event, newValue) => {
            setSelectedOutbound(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "iotContainer" && serviceGroups.includes(5)) {
      return (
        <Autocomplete
          disablePortal
          getOptionDisabled={(option) =>
            option.label.includes(t("missing-tariff"))
          }
          id="combo-box-demo"
          options={iotContainers}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-iot-container")} />
          )}
          value={
            iotContainers.find((option) => option.value === selectedIot) || null
          }
          onChange={(event, newValue) => {
            setSelectedIot(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "chargingEnabled") {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span>{t("charging-enabled")}</span>
          <Switch
            disabled={true}
            checked={chargingEnabled}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography color={chargingEnabled ? "secondary" : "gray"}>
            {" "}
            {chargingEnabled ? t("charging-enabled-info") : t("charging-info")}
          </Typography>
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("chargingEnabled")}
            {getFieldByName("cpaasContainer")}
            {getFieldByName("ucContainer")}
            {getFieldByName("numberServiceContainer")}
            {getFieldByName("outboundServiceContainer")}
            {getFieldByName("iotContainer")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
