import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { TariffService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function OutboundTariffForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  tariffType,
  successCallback,
  countries,
}) {
  const { t } = useTranslation();

  const OutboundTariffSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
    },
    validationSchema: OutboundTariffSchema,
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
      };

      let apiService, successMessage, failMessage, name;
      let commonName;
      switch (tariffType) {
        case "voice":
          name = t("outbound-voice-tariff");
          if (formType == "add") {
            apiService = TariffService.addOutboundVoiceTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundVoiceTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;

        case "sms":
          name = t("outbound-sms-tariff");
          if (formType == "add") {
            apiService = TariffService.addOutboundSmsTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundSmsTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;

        case "whatsapp":
          name = t("outbound-whatsapp-tariff");
          if (formType == "add") {
            apiService = TariffService.addOutboundWhatsappTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundWhatsappTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;

        case "telegram":
          name = t("outbound-telegram-tariff");
          if (formType == "add") {
            apiService = TariffService.addOutboundTelegramTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundTelegramTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;

        case "rcs":
          name = t("outbound-rcs-tariff");
          if (formType == "add") {
            apiService = TariffService.addOutboundRcsTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundRcsTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;

        case "gbc":
          commonName = "Google Business Chat";
          name = t("outbound-gbc-tariff", { commonName });
          if (formType == "add") {
            apiService = TariffService.addOutboundGbcTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundGbcTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;
        case "appleMessage":
          commonName = "Apple Message";
          name = t("outbound-gbc-tariff", { commonName });
          if (formType == "add") {
            apiService = TariffService.addOutboundAppleMessageTariff(payload);
            successMessage = t("outbound-tariff-add-success", { name });
            failMessage = t("outbound-tariff-add-fail", { name });
          } else {
            apiService = TariffService.updateOutboundAppleMessageTariff(
              formData.id,
              payload
            );
            successMessage = t("outbound-tariff-update-success", { name });
            failMessage = t("outbound-tariff-update-fail", { name });
          }

          break;
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  const getFieldByName = (fieldName) => {
    if (fieldName === "outboundTariff") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }
    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>{getFieldByName("outboundTariff")}</Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
