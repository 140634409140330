import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import NumberMapping from "./uccServices/configUcc/numberMapping/NumberMapping";
import ManageUc from "./uccServices/configUcc/manage/ManageUc";
import { useState } from "react";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------

export default function Config() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [store, dispatch] = useStore();

  const changeTab = (number, id) => {
    dispatch({
      type: "UPDATE_CUSTOMER_ID",
      payload: { customerID: id ? id.toString() : null },
    });
    setActiveTab(number);
  };

  const TABS = [
    {
      id: 0,
      label: t("manage"),
      component: <ManageUc changeTab={changeTab} />,
    },
    { id: 1, label: t("number-mapping"), component: <NumberMapping /> },
  ];

  const TITLE = t("config");

  return (
    <SmartcpaasAppLayout tabs={TABS} title={TITLE} activeTab={activeTab} />
  );
}
