import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import {
    Stack,
    TextField,
    Select,
    Grid,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
    Autocomplete,
    CircularProgress,
    FormControlLabel
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as Constants from 'src/constants';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { fDateTime } from 'src/utils/formatTime';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import { CampaignManagementService, ContactService, NumberService } from 'src/api/services';
import AnnouncementForm from './AnnouncementForm';
import BaseModal from 'src/components/BaseModal';
import NumbersFileUpload from 'src/components/NumbersFileUpload';
import ThemeConfig from 'src/theme/palette';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { useStore } from 'src/store/Store';


export default function CampaignForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, isProgrammable = false }) {
    const { t } = useTranslation();
    const [store] = useStore();
    let disabledChannelTypes = [];
    if (!(store.portalSettings.services.campaign_manager_sms)) {
        disabledChannelTypes.push("1");
    }
    if (!(store.portalSettings.services.campaign_manager_voice)) {
        disabledChannelTypes.push("2");
    }
    const ADD_STEPS = [t('to-numbers'), t('from-numbers'), t('content'), t('schedule'), t('summary')];
    const UPDATE_STEPS = [t('to-numbers-0'), t('content-0'), t('schedule-0'), t('summary-0')];

    const RECURRING_DURATION = {
        1: t('1-day'),
        3: t('3-days'),
        7: t('1-week'),
    }

    const RECURRING_FREQUENCY = {
        60: t('1-hour'),
        360: t('6-hours'),
        720: t('12-hours'),
    }

    const CampaignSchema = Yup.object().shape({
        formType: Yup.string(),
        name: Yup.string().min(4, t('too-short')).max(48, t('too-long')).required(t('campaign-name-is-required')).matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
        toNumbers: Yup.array().when('formType', {
            is: "add",
            then: Yup.array().min(1, t('a-to-number-is-required')).of(Yup.string().required("Required!").matches(/^[a-zA-Z0-9]*$/, t('to-number-must-be-alphanumeric'))),
        }),
        fromNumbers: Yup.array().when('formType', {
            is: "add",
            then: Yup.array().min(1, t('a-from-number-is-required')).of(Yup.string().required("Required!").matches(/^[a-zA-Z0-9]*$/, t('from-number-must-be-alphanumeric'))),
        }),
        type: Yup.string().required(t('content-type-must-be-selected')),
        encoding: Yup.string().when("type", {
            is: "1",
            then: Yup.string().required(t('encoding-must-be-selected')),
        }),
        messageBody: Yup.string().when("type", {
            is: "1",
            then: Yup.string().min(20, t('message-body-must-contain-at-least-20-characters')).required(t('message-body-is-required')),
        }),
        announcementId: Yup.number().when("type", {
            is: "2",
            then: Yup.number().required(t('must-select-an-announcement'))
        }),
        dialTimeout: Yup.number().when("type", {
            is: "2",
            then: Yup.number().required(t('must-enter-dial-timeout'))
        }),
        scheduledDate: Yup.date().nullable(true),
        callbackUrl: Yup.string(),
        isRecurring: Yup.bool(),
        recurringDurationInDays: Yup.number().nullable(true),
        recurringFrequencyInMinutes: Yup.number().nullable(true),
    });

    const formik = useFormik({
        initialValues: {
            formType: formType,
            name: formData.name || '',
            toNumbers: formData.toNumbers || [],
            fromNumbers: formData.fromNumbers || [],
            type: (formData.type !== null && formData.type !== undefined ? formData.type.toString() : formData.type) || disabledChannelTypes.includes("1") ? "2" : "1",
            encoding: (formData.encoding !== null && formData.encoding !== undefined ? formData.encoding.toString() : formData.encoding) || "1",
            messageBody: formData.messageBody || '',
            announcementId: formData.announcementId || 0,
            dialTimeout: formData.dialTimeout || 10,
            scheduledDate: (formType === "add" ? null : new Date(+formData.startDate * 1000)) || null,
            callbackUrl: formData.callbackUrl || '',
            isRecurring: formData.isRecurring || false,
            recurringDurationInDays: formData.recurringDurationInDays || 1,
            recurringFrequencyInMinutes: formData.recurringFrequencyInMinutes || 60,
            numbers: formData.numbers || [],
        },
        validationSchema: CampaignSchema,
        onSubmit: (values, actions) => {
            let payload = {
                name: values.name,
                campaign_status: 1,
                channel_type: values.type,
                message_body: values.type === "1" ? values.messageBody : undefined,
                encoding: values.type === "1" ? values.encoding : undefined,
                dial_timeout: values.dialTimeout,
                announcement: values.type === "2" ? values.announcementId : undefined,
                scheduled_at: values.scheduledDate === null ? new Date() : values.scheduledDate,
                callback_url: values.callbackUrl === "" ? null : values.callbackUrl,
                is_recurring: values.isRecurring,
            };
            if (formType === "add") {
                payload = {
                    ...payload,
                    to_number_list: values.toNumbers,
                    from_number_list: values.fromNumbers,
                    message_body_props: programmableBodyProps,
                }
                if (values.isRecurring) {
                    payload = {
                        ...payload,
                        duration_in_days: values.recurringDurationInDays,
                        frequency_in_minutes: values.recurringFrequencyInMinutes,
                    }
                }
            }
            console.log("payload", payload);
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = CampaignManagementService.addCampaign(payload);
                successMessage = t('new-campaign-has-been-successfully-added');
                failMessage = t('new-campaign-could-not-be-added');
            } else {
                apiService = CampaignManagementService.updateCampaign(formData.id, payload);
                successMessage = t('has-been-successfully-updated', { name: formData.name });
                failMessage = t('could-not-be-updated', { name: formData.name });
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback(response); };
                        actions.setSubmitting(false);
                    } else { throw "campaign operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        },
        validateOnMount: true,
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [announcements, setAnnouncements] = React.useState([]);
    const [contactGroups, setContactGroups] = React.useState([]);
    const [announcementName, setAnnouncementName] = React.useState("");
    const [contactGroupName, setContactGroupName] = React.useState("");
    const [openAnouncementAutoComplete, setOpenAnouncementAutoComplete] = React.useState(false);
    const [openContactGroupAutoComplete, setOpenContactGroupAutoComplete] = React.useState(false);
    const [anouncementOptions, setAnouncementOptions] = React.useState([]);
    const [contactGroupOptions, setContactGroupOptions] = React.useState([]);
    const anouncementAutoCompleteLoading = openAnouncementAutoComplete && anouncementOptions.length === 0;
    const contactGroupAutoCompleteLoading = openContactGroupAutoComplete && contactGroupOptions.length === 0;
    const [openAddAnnouncementModal, setAddAnnouncementModalStatus] = React.useState(false);
    const [displayedAnnouncement, setDisplayedAnnouncement] = React.useState(null);
    const [selectedContactGroup, setSelectedContactGroup] = React.useState(null);
    const [uploading, setUploading] = React.useState(false);
    const [uploadToNumbers, setUploadToNumbers] = React.useState(false);
    const [selectContactGroup, setSelectContactGroup] = React.useState(false);
    const [enterToNumbers, setEnterToNumbers] = React.useState(false);
    const [programmableBodyProps, setProgrammableBodyProps] = React.useState({});
    const [uploadFromNumbers, setUploadFromNumbers] = React.useState(undefined);
    const [steps, setSteps] = React.useState(ADD_STEPS);
    // const [recurringCampaign, setRecurringCampaign] = React.useState(false);
    const [numbers, setNumbers] = React.useState([]);
    const [optionsEdited, setOptionsEdited] = React.useState(false);

    const formatAutocompleteFieldValues = (values) => {
        setOptionsEdited(true);
        let selected = [];
        values.forEach(option => { selected.push(option.id) });
        return selected;
    }

    React.useEffect(() => {
        CampaignManagementService.listAnnouncements({ name: announcementName })
            .then((response) => {
                setAnnouncements(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [announcementName]);

    React.useEffect(() => {
        ContactService.listContactGroups({ group_name: contactGroupName })
            .then((response) => {
                setContactGroups(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [contactGroupName]);

    React.useEffect(() => {
        if (!openAnouncementAutoComplete) {
            setAnouncementOptions([]);
        }
    }, [openAnouncementAutoComplete]);

    React.useEffect(() => {
        if (!openContactGroupAutoComplete) {
            setContactGroupOptions([]);
        }
    }, [openContactGroupAutoComplete]);

    React.useEffect(() => {
        setSteps(formType === "add" ? ADD_STEPS : UPDATE_STEPS);
    }, [formType]);

    React.useEffect(() => {
        if (formData.announcementId !== undefined && formData.announcementId !== null) {
            CampaignManagementService.getAnnouncement(formData.announcementId)
                .then((response) => {
                    setAnnouncementName(response.data.name);
                    setDisplayedAnnouncement(response.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, []);

    React.useEffect(() => {
        const params = {
            search: undefined,
            status: undefined,
            direction_type: 1,
            set_type: undefined,
            // page: paginationData.page + 1,
            page_size: 100,
        };

        NumberService.listNumbers(params)
            .then((response) => {
                for (const idx in response.data.results) {
                    let items = [];
                    response.data.results.forEach(number => {
                        if (number["is_magic"])
                            items.unshift({ id: number.id, label: number.number_value, group: t('shared-number') });
                        else if (number["set_type"] == 2) //External
                            items.push({ id: number.id, label: number.number_value, group: t('telesmart') });
                        else
                            items.push({ id: number.id, label: number.number_value, group: t('unknown') });
                    })
                    setNumbers(items);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const isStepOptional = (step) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        let passStep = true;

        if (activeStep === 0) {
            formik.setFieldTouched("name")
            formik.setFieldTouched("toNumbers")
            passStep = errors["name"] === undefined && errors["toNumbers"] === undefined;
        } else if (activeStep === 1 && formType === "add") {
            formik.setFieldTouched("fromNumbers");
            passStep = errors["fromNumbers"] === undefined;
        } else if ((activeStep === 1 && formType === "edit") || (activeStep === 2 && formType === "add")) {
            formik.setFieldTouched("type");
            formik.setFieldTouched("encoding");
            formik.setFieldTouched("messageBody");
            passStep = errors["type"] === undefined && errors["encoding"] === undefined && errors["messageBody"] === undefined;
        }

        if (passStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error(t('you-cant-skip-a-step-that-isnt-optional'));
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onAnouncementNameChangeHandle = async value => {
        setAnnouncementName(value);
    };

    const onContactGroupNameChangeHandle = async value => {
        setContactGroupName(value);
    };

    const handleAnnouncementsSelectChange = (event, newValue) => {
        formik.setFieldValue("announcementId", newValue === null ? 0 : newValue.id)
        setDisplayedAnnouncement(newValue);
    };

    const handleContactGroupSelectChange = (event, newValue) => {
        formik.setFieldValue("toNumbers", newValue === null ? [] : newValue.contacts.map(i => i.phone_number))
        setSelectedContactGroup(newValue);
    };

    const infoContainer = {
        backgroundColor: "#D5DDB9",
        color: "#0A1845",
        borderRadius: "4px",
        marginTop: "30px",
        padding: "20px 30px",
        fontSize: "12px",
        alignItems: "center",
    }

    const smsEncodingDetailCard = {
        borderRadius: "4px",
        marginLeft: "10px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        backgroundColor: "#FFFFFF",
    }

    function calculateSmsCredits(smsEncoding, messageLength) {
        let limit = 0;
        if (smsEncoding === Constants.CampaignSmsEncodings()[0]["value"]) {
            limit = 160;
        } else {
            limit = 70;
        }
        return Math.ceil(messageLength / limit);
    }

    const toNumbersTab = () => {
        return (
            <Stack spacing={3} sx={{ mt: 5 }}>
                <TextField
                    fullWidth
                    label={t('campaign-name-0')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                />
                {formType === "add" ?
                    (<>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                                {t('to-numbers-1')}
                            </Grid>
                            {uploadToNumbers === false && enterToNumbers === false && selectContactGroup === false &&
                                (<>
                                    <Grid item xs={3}>
                                        <Button variant="outlined" onClick={
                                            () => {
                                                setUploadToNumbers(true);
                                                setEnterToNumbers(false);
                                                setSelectContactGroup(false);
                                            }
                                        }>
                                            {t('upload-numbers')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="outlined" onClick={() => {
                                            setUploadToNumbers(false);
                                            setEnterToNumbers(true);
                                            setSelectContactGroup(false);
                                            let toNumbersArray = new Array();
                                            toNumbersArray.push("");
                                            formik.setFieldValue("toNumbers", toNumbersArray)
                                        }
                                        }>
                                            {t('enter-numbers')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="outlined" onClick={
                                            () => {
                                                setUploadToNumbers(false);
                                                setEnterToNumbers(false);
                                                setSelectContactGroup(true);
                                            }
                                        }>
                                            {t('select-contact-group')}
                                        </Button>
                                    </Grid>
                                </>)
                            }
                            {
                                selectContactGroup &&
                                (<Grid item xs={9}>
                                    <Autocomplete
                                        open={openContactGroupAutoComplete}
                                        onOpen={() => {
                                            setOpenContactGroupAutoComplete(true);
                                        }}
                                        onClose={() => {
                                            setOpenContactGroupAutoComplete(false);
                                        }}
                                        onChange={handleContactGroupSelectChange}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.group_name}
                                        options={contactGroups}
                                        loading={contactGroupAutoCompleteLoading}
                                        value={selectedContactGroup}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={Boolean(touched.toNumbers && errors.toNumbers)}
                                                helperText={touched.toNumbers && errors.toNumbers}
                                                label={t('contact-group')}
                                                variant="outlined"
                                                onChange={ev => {
                                                    // dont fire API if the user delete or not entered anything
                                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                                        onContactGroupNameChangeHandle(ev.target.value);
                                                    }
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {anouncementAutoCompleteLoading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                )
                            }
                            {uploadToNumbers === true &&
                                ((<Grid item xs={3}>
                                    <Field
                                        name="toNumbers"
                                        component={NumbersFileUpload}
                                        title={t('upload')}
                                        setFieldValue={setFieldValue}
                                        uploading={uploading}
                                        errorMessage={errors.toNumbers ? errors.toNumbers : ""}
                                        touched={touched.toNumbers}
                                        isProgrammable={isProgrammable}
                                        setProgrammableBodyProps={setProgrammableBodyProps}
                                    />
                                </Grid>))
                            }
                            {enterToNumbers &&
                                (<>
                                    {formik.values.toNumbers.map((toNumber, index) => (
                                        <>
                                            {index !== 0 ?
                                                (<Grid item xs={3} ></Grid>)
                                                :
                                                <></>}
                                            <Grid item xs={7}>
                                                <TextField
                                                    key={index}
                                                    fullWidth
                                                    value={toNumber}
                                                    autoFocus
                                                    onChange={
                                                        (e) => {
                                                            let toNumbersArray = formik.values.toNumbers;
                                                            toNumbersArray[index] = e.target.value;
                                                            formik.setFieldValue("toNumbers", toNumbersArray)
                                                        }
                                                    }
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            let toNumbersArray = formik.values.toNumbers;
                                                            toNumbersArray.push("");
                                                            formik.setFieldValue("toNumbers", toNumbersArray)
                                                        }
                                                    }}
                                                    error={Boolean(touched.toNumbers && errors.toNumbers && errors.toNumbers[index])}
                                                    helperText={touched.toNumbers && errors.toNumbers && errors.toNumbers[index]}
                                                />
                                            </Grid>
                                            {
                                                formik.values.toNumbers.length !== 1 &&
                                                <Grid item xs={1}>
                                                    <Button onClick={() => {
                                                        let toNumbersArray = formik.values.toNumbers;
                                                        toNumbersArray.splice(index, 1);
                                                        formik.setFieldValue("toNumbers", toNumbersArray)
                                                    }}>
                                                        -
                                                    </Button>
                                                </Grid>
                                            }
                                            {
                                                formik.values.toNumbers.length - 1 === index &&
                                                <Grid item xs={1} >
                                                    <Button alignSelf="flex-end" onClick={() => {
                                                        let toNumbersArray = formik.values.toNumbers;
                                                        toNumbersArray.push("");
                                                        formik.setFieldValue("toNumbers", toNumbersArray)
                                                    }
                                                    }>
                                                        +
                                                    </Button>
                                                </Grid>
                                            }
                                        </>
                                    ))}
                                </>)
                            }
                        </Grid>
                        <Grid style={infoContainer}>
                            <h2>
                                <NotificationsIcon /> {t('uploading-numbers')}
                            </h2>
                            {isProgrammable ? <>
                                <p style={{ height: "100" }}>
                                    {t('put-your-numbers-list-into-a-text-file-txt-csv-please-refer-to-example-file-structure-and-message-body-to-customize-the-message-per-number-after-you-fill-in-the-number-list-click-the-button')} <b>{t('import-file')}</b> {t('above-then-click-the-button')}
                                    <b>{t('click-to-upload')}</b> {t('to-upload-the-file')}
                                </p>
                                <br />
                                <div style={{ textAlign: "left" }}>
                                    <b>{t('example-file-structure')}</b>
                                    <p>{t('number-customer-product-price')}</p>
                                    <p>{t('440001-liam-salty-snacks-1-99')}</p>
                                    <p>{t('440002-olivia-laundry-detergent-4-49')}</p>
                                    <p>{t('440003-emma-peanut-butter-2-39')}</p>
                                    <br />
                                    <b>{t('example-message-body')}</b>
                                    <p>{t('hi-and-123-customer-and-125-and-123-product-and-125-is-only-and-123-price-and-125-for-you')}</p>
                                </div>
                            </>
                                :
                                <p style={{ height: "100" }}>
                                    {t('put-your-numbers-list-into-a-text-file-txt-csv-one-number-per-line-after-you-fill-in-the-number-list-click-the-button')} <b>{t('import-file-0')}</b> {t('above-then-click-the-button-0')}
                                    <b>{t('click-to-upload-0')}</b> {t('to-upload-the-file-0')}
                                </p>
                            }
                        </Grid>
                    </>)
                    :
                    <></>
                }
            </Stack >
        )
    }

    const fromNumbersTab = () => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;

        return (
            <Autocomplete
                multiple
                value={formType === "edit" && !optionsEdited ? numbers.filter(option => formData.fromNumbers.includes(option.id)) : numbers.filter(option => values.fromNumbers.includes(option.id))}
                options={numbers}
                disableCloseOnSelect
                disableListWrap
                getOptionLabel={(option) => option.label}
                groupBy={(option) => option.group}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params}
                        label={t('numbers-0')}
                        error={Boolean(touched.fromNumbers && errors.fromNumbers)}
                        helperText={touched.fromNumbers && errors.fromNumbers}
                    />
                )}
                onChange={(event, values) => setFieldValue("fromNumbers", formatAutocompleteFieldValues(values))}
            />
        );
    }

    const contentTab = () => {
        return (
            <Stack spacing={3} sx={{ mt: 5 }}>
                {Constants.getRadioButtonComponent(
                    Constants.CampaignChannelTypes(),
                    getFieldProps('type'),
                    t('content-type'),
                    "row",
                    disabledChannelTypes
                )}
                {
                    formik.values.type === "1" ?
                        (
                            <>
                                {Constants.getRadioButtonComponent(
                                    Constants.CampaignSmsEncodings(),
                                    getFieldProps('encoding'),
                                    t('encoding')
                                )}
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label={t('common.form.messageBody')}
                                    {...getFieldProps('messageBody')}
                                    error={Boolean(touched.messageBody && errors.messageBody)}
                                    helperText={touched.messageBody && errors.messageBody}
                                />
                            </>
                        )
                        :
                        (
                            <>
                                <Grid container columnSpacing={1} justifyContent="center" alignItems="center">
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            error={Boolean(touched.announcementId && errors.announcementId)}
                                            helperText={touched.announcementId && errors.announcementId}
                                            style={{ width: 300 }}
                                            open={openAnouncementAutoComplete}
                                            onOpen={() => {
                                                setOpenAnouncementAutoComplete(true);
                                            }}
                                            onClose={() => {
                                                setOpenAnouncementAutoComplete(false);
                                            }}
                                            onChange={handleAnnouncementsSelectChange}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            getOptionLabel={option => option.name}
                                            options={announcements}
                                            loading={anouncementAutoCompleteLoading}
                                            value={displayedAnnouncement}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t('announcement')}
                                                    variant="outlined"
                                                    onChange={ev => {
                                                        // dont fire API if the user delete or not entered anything
                                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                                            onAnouncementNameChangeHandle(ev.target.value);
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {anouncementAutoCompleteLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={() => setAddAnnouncementModalStatus(!openAddAnnouncementModal)}>
                                            {t('create-announcement')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <TextField
                                    fullWidth
                                    label={t('dial-timeout-s')}
                                    {...getFieldProps('dialTimeout')}
                                    error={Boolean(touched.dialTimeout && errors.dialTimeout)}
                                    helperText={touched.dialTimeout && errors.dialTimeout}
                                />
                            </>
                        )
                }


                <Grid container style={infoContainer}>
                    <Grid item xs={7}>
                        <h2>
                            <NotificationsIcon /> {t('writing-message')}
                        </h2>
                        <p style={{ height: "100" }}>
                            {t('one-thing-to-consider-seriously-when-writing-the-message-is-the-encoding-to-use')} <b>GSM7</b> {t('supports-standard-alphabet')} <b>UCS2</b> {t('supports-almost-all-traditional-characters-like-simplified-chinese-and-so-on')}
                            <b>{t('encoding-will-affect-on-the-message-length')}</b>
                            {t('if-message-length-is')}
                            <b
                            >{t('more-than-160-characters-for-gsm-7-and-70-characters-for-ucs2')}</b
                            >
                            {t('it-will-become')} <b>{t('multipart-message')}</b>.
                        </p>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid style={smsEncodingDetailCard} container spacing={1}>
                            <Grid item xs={3} />
                            <Grid item xs={4}><b>{t('regular-sms')}</b></Grid>
                            <Grid item xs={4}><b>{t('multipart-sms')}</b></Grid>
                            <Grid item xs={3}><b>7-bit</b></Grid>
                            <Grid item xs={4}>{t('160-characters')}</Grid>
                            <Grid item xs={4}>{t('152-characters')}</Grid>
                            <Grid item xs={3}><b>{t('unicode')}</b></Grid>
                            <Grid item xs={4}>{t('70-characters')}</Grid>
                            <Grid item xs={4}>{t('66-characters')}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        )
    }

    const scheduleTab = () => {
        return (
            <Stack spacing={3} sx={{ mt: 5 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('scheduled-date')}
                        {...getFieldProps('scheduledDate')}
                        onChange={(newValue) => {
                            formik.setFieldValue("scheduledDate", newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={t('leave-blank-to-schedule-immediately')} />}
                    />
                </LocalizationProvider>
                <TextField
                    fullWidth
                    label={t('status-callback-url')}
                    {...getFieldProps('callbackUrl')}
                    helperText={t('this-endpoint-must-allow-post-methods')}
                />
                <FormControlLabel control={<Checkbox {...getFieldProps('isRecurring')} />} label={t('recurring-campaign')} />
                {
                    formik.values.isRecurring && formType === "add" &&
                    (<>
                        <FormControl fullWidth>
                            <InputLabel id="duration-select-label">{t('recurring-duration-0')}</InputLabel>
                            <Select
                                labelId="duration-select-label"
                                {...getFieldProps('recurringDurationInDays')}
                                error={Boolean(touched.recurringDurationInDays && errors.recurringDurationInDays)}
                                helperText={touched.recurringDurationInDays && errors.recurringDurationInDays}
                                label={t('recurring-duration-0')}
                            >
                                <MenuItem value={1}>{t('1-day')}</MenuItem>
                                <MenuItem value={3}>{t('3-days')}</MenuItem>
                                <MenuItem value={7}>{t('1-week')}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="frequency-select-label">{t('recurring-frequency-0')}</InputLabel>
                            <Select
                                labelId="frequency-select-label"
                                {...getFieldProps('recurringFrequencyInMinutes')}
                                error={Boolean(touched.recurringFrequencyInMinutes && errors.recurringFrequencyInMinutes)}
                                helperText={touched.recurringFrequencyInMinutes && errors.recurringFrequencyInMinutes}
                                label={t('recurring-frequency-0')}
                            >
                                <MenuItem value={60}>{t('1-hour')}</MenuItem>
                                <MenuItem value={360}>{t('6-hours')}</MenuItem>
                                <MenuItem value={720}>{t('12-hours')}</MenuItem>
                            </Select>
                        </FormControl>
                    </>)
                }
                <Grid style={infoContainer}>
                    <h2>
                        <NotificationsIcon /> {t('scheduling-the-campaign')}
                    </h2>
                    <p style={{ height: "100" }}>
                        {t('choosing-a-date-and-time-at-which-the-campaign-has-to-take-place-1')} <b>Note:</b> {t('to-schedule-the-campaign-immediately-leave-1')} <b>{t('schedule-date')}</b> {t('as-blank-and-click-on')}
                        <b>"Next"</b>.
                    </p>
                </Grid>
            </Stack>
        )
    }

    const summaryTab = () => {
        return (
            <Stack spacing={3} sx={{ mt: 5 }}>
                <TextField
                    fullWidth
                    label={t('campaign-name-0')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                />
                <Grid container spacing={2}>
                    {formType === "add" &&
                        (<>
                            <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                                {t('to-numbers-2')}
                            </Grid>
                            <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                                {selectedContactGroup !== null ? selectedContactGroup.group_name :
                                    formik.values.toNumbers.join(", ").length < 50 ?
                                        formik.values.toNumbers.join(", ") :
                                        formik.values.toNumbers.join(", ").substring(0, 50) + "..."}
                            </Grid>
                            <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                                {t('from-numbers-0')}
                            </Grid>
                            <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                                {numbers.filter(item => formik.values.fromNumbers.includes(item.id)).map(item => item.label).join(", ").length < 50 ?
                                    numbers.filter(item => formik.values.fromNumbers.includes(item.id)).map(item => item.label).join(", ") :
                                    numbers.filter(item => formik.values.fromNumbers.includes(item.id)).map(item => item.label).join(", ").substring(0, 50) + "..."}
                            </Grid>
                        </>)
                    }
                    <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                        Type:
                    </Grid>
                    <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                        {Constants.getLabelByValue(Constants.CampaignChannelTypes(), formik.values.type.toString())}
                    </Grid>
                    <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                        Details:
                    </Grid>
                    {formik.values.type === "1" ?
                        (<Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                            <p>{t('message-length')} <b>{formik.values.messageBody.length}</b></p>
                            <p>{t('message-segments')} <b>{calculateSmsCredits(formik.values.encoding, formik.values.messageBody.length)}</b></p>
                            <p>{t('total-credits')} <b>{formik.values.toNumbers.length * calculateSmsCredits(formik.values.encoding, formik.values.messageBody.length)}</b></p>
                        </Grid>)
                        :
                        (<Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                            <p>{t('number-of-calls')} <b>{formik.values.toNumbers.length}</b></p>
                            <p>{t('total-credits-0')} <b>{formik.values.toNumbers.length}</b></p>
                        </Grid>)
                    }
                    <Grid item xs={3} style={{ color: ThemeConfig.primary.dark, textAlign: "left" }}>
                        {t('start-time')}
                    </Grid>
                    <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                        {formik.values.scheduledDate === null ? t('immediate') : fDateTime(formik.values.scheduledDate)}
                    </Grid>
                    {
                        formik.values.isRecurring &&
                        (
                            <>
                                <Grid item xs={3} style={{ color: ThemeConfig.secondary.dark, textAlign: "left" }}>
                                    {t('recurring-duration-1')}
                                </Grid>
                                <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                                    {RECURRING_DURATION[formik.values.recurringDurationInDays]}
                                </Grid>
                                <Grid item xs={3} style={{ color: ThemeConfig.secondary.dark, textAlign: "left" }}>
                                    {t('recurring-frequency-1')}
                                </Grid>
                                <Grid item xs={9} style={{ color: ThemeConfig.primary.main, textAlign: "left" }}>
                                    {RECURRING_FREQUENCY[formik.values.recurringFrequencyInMinutes]}
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Stack>
        )
    }

    const renderStep = activeStep => {
        switch (activeStep) {
            case 0:
                return toNumbersTab();
            case 1:
                return formType === "add" ? fromNumbersTab() : contentTab();
            case 2:
                return formType === "add" ? contentTab() : scheduleTab();
            case 3:
                return formType === "add" ? scheduleTab() : summaryTab();
            case 4:
                return summaryTab();
            default:
            // code block
        }
    }

    const getSelectedAnnouncementId = (response) => {
        setDisplayedAnnouncement(response.data);
        formik.setFieldValue("announcementId", response.data.id)
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">{t('optional')}</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    {t('all-steps-completed-you-and-apos-re-finished')}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>{t('reset')}</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {renderStep(activeStep)}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        {t('back')}
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            {t('skip')}
                                        </Button>
                                    )}
                                    {
                                        activeStep === steps.length - 1 ?
                                            <LoadingButton
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                // disabled={false}
                                                loading={isSubmitting}
                                                loadingPosition={formType === "test" ? "end" : "start"}
                                                endIcon={formType === "test" ? <SendIcon /> : null}
                                                startIcon={formType !== "test" ? <SaveIcon /> : null}
                                            >
                                                {formType === "test" ? t('send') : t('common.__i18n_ally_root__.save')}
                                            </LoadingButton>
                                            :
                                            (<Button onClick={handleNext}>
                                                {t('next')}
                                            </Button>)
                                    }
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Form>
            </FormikProvider>
            <BaseModal title={t('add-new-announcement-0')} open={openAddAnnouncementModal} setOpen={setAddAnnouncementModalStatus} children={<AnnouncementForm successCallback={getSelectedAnnouncementId} formType="add" formData={{}} setModalStatus={setAddAnnouncementModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
        </>
    );
}
