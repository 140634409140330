import * as Yup from "yup";
import { useFormik, Form, FormikProvider, Field } from "formik";
import { useState, useEffect } from "react";

// material
import {
  TextField,
  Stack,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";

import { ConfigurationService } from "src/api/services";
// app
import {
  AccountStatuses,
  AccountChannelTypes,
  getLabelByValue,
  SmsConfigurationMethods,
  SmsConfigurationContenTypes,
  getSelectOptions,
} from "src/constants/index";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function SMSConfigurationForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();

  const [configurationType, setConfigurationType] = useState(
    formData.config_type || "HTTP"
  );

  const getAccountSchema = () => {
    let shape = {
      status: Yup.string(),
      accountType: Yup.string(),
      pop: Yup.string(),
      channelType: Yup.string(),

      api_url: Yup.string(),
      username: Yup.string()
        .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
        .test(
          "has-more-letters-than-commas-and-periods",
          t("name-must-have-more-letters-than-commas-and-periods"),
          (value) => {
            if (!value) {
              return true;
            }
            const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
            const specialCharsCount = (value.match(/[,./]/g) || []).length;
            return lettersCount > specialCharsCount;
          }
        ),
      password: Yup.string(),
      from_number: Yup.number()
        .typeError(t("must-be-number"))
        .when(configurationType, {
          is: "HTTTP",
          then: Yup.number().required(t("field-required")),
          otherwise: Yup.number(),
        }),
      to_number: Yup.number()
        .typeError(t("must-be-number"))
        .when(configurationType, {
          is: "HTTTP",
          then: Yup.number().required(t("field-required")),
          otherwise: Yup.number(),
        }),
      body: Yup.string(),
      method: Yup.string(),
      conten_type: Yup.string(),

      system_id: Yup.string(),
      password: Yup.string(),
      version: Yup.string(),
      remote_ip_address: Yup.string(),
      port: Yup.number().typeError(t("must-be-number")).nullable(true),
      encoding: Yup.string(),
    };

    return Yup.object().shape(shape);
  };

  const getInitialValues = () => {
    let values = {
      status: getLabelByValue(AccountStatuses(), formData.status),
      accountType: "Custom",
      channelType: getLabelByValue(AccountChannelTypes(), 1),
      pop: formData.pop || "",

      api_url: formData.api_url || "",
      username: formData.username || "",
      password: formData.password || "",

      from_number: formData.from_number || "",
      to_number: formData.to_number || "",
      body: formData.body || "",
      method: formData.method || SmsConfigurationMethods()[0].value,
      content_type: formData.content_type || "",

      system_id: formData.system_id || "",
      password: formData.password || "",
      version: formData.version || "",
      remote_ip_address: formData.remote_ip_address || "",
      port: formData.port || "",
      encoding: formData.encoding || "",
    };

    return values;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getAccountSchema(),
    onSubmit: (values, actions) => {
      console.log("Form Values:", values);

      let apiService, payload, successMessage, failMessage;

      if (configurationType === "HTTP") {
        payload = {
          type: 1,
          pop: values.pop,
          account_type: 7,
          channel_type: 1,
          api_url: values.api_url,
          username: values.username,
          password: values.password,
          from_number: values.from_number,
          to_number: values.to_number,
          body: values.body,
          method: values.method,
          content_type: values.conten_type,
          system_id: null,
          version: null,
          remote_ip: null,
          port: null,
          encoding: null,
          properties: {},
        };
      } else {
        payload = {
          type: 2,
          pop: null,
          account_type: 7,
          channel_type: 1,
          api_url: null,
          username: null,
          password: null,
          from_number: null,
          to_number: null,
          body: null,
          method: null,
          content_type: null,
          system_id: values.system_id,
          version: values.version,
          remote_ip: values.remote_ip_address,
          port: values.port,
          encoding: values.encoding,
          properties: {},
        };
      }

      if (formType === "edit") {
        apiService = ConfigurationService.updateSMSConfiguration(
          formData.id,
          payload
        );
        successMessage = t("has-been-successfully-updated", {
          name: values.accountType,
        });
        failMessage = t("could-not-be-updated", { name: values.accountType });
      } else if (formType === "add") {
        apiService = ConfigurationService.addSMSConfiguration(payload);
        successMessage = t("has-been-successfully-added", {
          name: values.accountType,
        });
        failMessage = t("could-not-be-added", { name: values.accountType });
      }
      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback();
            }
            actions.setSubmitting(false);
          } else {
            throw "account operation failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const onChangeMethod = (e, field) => {
    const value = e.target.value;
    if (field === "method") {
      setFieldValue("method", value);
      if (value === "GET") {
        setFieldValue("conten_type", "");
      }
    } else {
      setFieldValue("conten_type", value);
    }
  };

  const getFieldByName = (fieldName) => {
    if (fieldName === "configType") {
      return (
        <Box>
          <RadioGroup
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10vw",
            }}
            row
            onChange={(event) => setConfigurationType(event.target.value)}
            defaultValue={configurationType}
          >
            <FormControlLabel
              disabled={formType === "view"}
              key="HTTP"
              value="HTTP"
              control={<Radio />}
              label="HTTP"
            />
            <FormControlLabel
              disabled={formType === "view"}
              key="SMPP"
              value="SMPP"
              control={<Radio />}
              label="SMPP"
            />
          </RadioGroup>
        </Box>
      );
    }

    if (fieldName === "status" && formType === "view") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <TextField
            fullWidth
            disabled
            label={t("common.__i18n_ally_root__.status")}
            {...getFieldProps("status")}
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && errors.status}
          />
        </Grid>
      );
    }

    if (fieldName === "accountType") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <TextField
            fullWidth
            disabled
            label={t("account-type")}
            {...getFieldProps("accountType")}
            error={Boolean(touched.accountType && errors.accountType)}
            helperText={touched.accountType && errors.accountType}
          />
        </Grid>
      );
    }

    if (fieldName === "pop") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <Box textAlign={"start"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="pop"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("pop")}:
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled={formType === "view"}
              id="pop"
              label={isMobile() ? "POP" : undefined}
              placeholder="Type POP here"
              {...getFieldProps("pop")}
              error={Boolean(touched.pop && errors.pop)}
              helperText={touched.pop && errors.pop}
            />
          </Box>
        </Grid>
      );
    }

    if (fieldName === "channelType") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <TextField
            fullWidth
            disabled
            label={t("channel-type")}
            {...getFieldProps("channelType")}
            error={Boolean(touched.channelType && errors.channelType)}
            helperText={touched.channelType && errors.channelType}
          />
        </Grid>
      );
    }

    if (fieldName === "api_url") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <Box textAlign={"start"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="api_url"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("api-url")}:
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled={formType === "view"}
              id="api_url"
              label={isMobile() ? t("api-url") : undefined}
              placeholder={t("type-api-url-here")}
              {...getFieldProps("api_url")}
              error={Boolean(touched.api_url && errors.api_url)}
              helperText={touched.api_url && errors.api_url}
            />
          </Box>
        </Grid>
      );
    }
    if (fieldName === "username") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <Box textAlign={"start"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="username"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("username")}:
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled={formType === "view"}
              id="username"
              label={isMobile() ? t("username") : undefined}
              placeholder={t("type-username-here")}
              {...getFieldProps("username")}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Box>
        </Grid>
      );
    }
    if (fieldName === "password") {
      return (
        <Grid xs={12} sm={6} sx={{ p: 1 }}>
          <Box textAlign={"start"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="password"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("password")}:
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled={formType === "view"}
              id="password"
              label={isMobile() ? t("password") : undefined}
              placeholder={t("type-password-here")}
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Box>
        </Grid>
      );
    }

    /*   if (fieldName === "credentials") {
                let fields = [];
                formData.account_properties.forEach((field, idx) => {
                    if (field.type === "text") {
                        fields.push(<Grid xs={6} sx={{ p: 1 }}>
                            <TextField
                                key={`${field.id}-${idx}`}
                                fullWidth
                                label={field.label}
                                disabled={formType === "view" ? true : false}
                                {...getFieldProps(field.id)}
                            /></Grid>
                        )
                    }
                })
                
                return fields;
            } */

    if (fieldName === "httpConfig") {
      let fields = [];
      fields.push(
        <Grid xs={12} sx={{ mt: 2 }}>
          <p>{t("payload-mapping")}</p>
        </Grid>
      );
      const inputNames = [
        "from_number",
        "to_number",
        "method",
        "conten_type",
        "body",
      ];
      inputNames.forEach((field, idx) => {
        let placeholderText = "";
        if (field === "from_number") {
          placeholderText = t("type-from-number-here");
        } else if (field === "to_number") {
          placeholderText = t("type-to-number-here");
        } else if (field === "body") {
          placeholderText = t("type-body-here");
        }

        fields.push(
          <Grid xs={12} sm={6} sx={{ p: 1 }}>
            {field !== "method" && field !== "conten_type" ? (
              <Box textAlign={"start"}>
                {!isMobile() && (
                  <InputLabel
                    htmlFor={field}
                    style={{
                      color: "secondary",
                      marginLeft: "0.2rem",
                      fontWeight: 500,
                    }}
                  >
                    {t(field.replace("_", "-"))}:
                  </InputLabel>
                )}
                <TextField
                  key={`${field}-${idx}`}
                  fullWidth
                  id={field}
                  label={isMobile() ? t(field.replace("_", "-")) : undefined}
                  placeholder={placeholderText}
                  disabled={formType === "view"}
                  onKeyDown={(event) => {
                    if (
                      (field === "from_number" || field === "to_number") &&
                      event.key !== "Backspace" &&
                      event.key !== "Tab" &&
                      event.key !== "ArrowLeft" &&
                      event.key !== "ArrowRight" &&
                      !/[0-9]/.test(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  {...getFieldProps(field)}
                  error={Boolean(touched[field] && errors[field])}
                  helperText={touched[field] && errors[field]}
                />
              </Box>
            ) : (
              <FormControl fullWidth>
                <InputLabel
                  disabled={
                    formType === "view" ||
                    (field === "conten_type" && values.method === "GET")
                  }
                >
                  {t(field.replace("_", "-"))}
                </InputLabel>
                <Select
                  key={`${field}-${idx}`}
                  label={t(field.replace("_", "-"))}
                  color="secondary"
                  disabled={
                    formType === "view" ||
                    (field === "conten_type" && values.method === "GET")
                  }
                  {...getFieldProps(field)}
                  onChange={(e) => onChangeMethod(e, field)}
                >
                  {getSelectOptions(
                    field === "method"
                      ? SmsConfigurationMethods()
                      : SmsConfigurationContenTypes(),
                    [],
                    null
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>
        );
      });

      return <Grid container>{fields}</Grid>;
    }

    if (fieldName === "smppConfig") {
      let fields = [];
      const inputNames = [
        "system_id",
        "password",
        "remote_ip_address",
        "port",
        "version",
        "encoding",
      ];
      inputNames.forEach((field, idx) => {
        let placeholderText = "";
        if (field === "system_id") {
          placeholderText = t("type-system-id-here");
        } else if (field === "password") {
          placeholderText = t("type-password-here");
        } else if (field === "remote_ip_address") {
          placeholderText = t("type-ip-adress-here");
        } else if (field === "port") {
          placeholderText = t("type-port-here");
        } else if (field === "version") {
          placeholderText = t("type-version-here");
        } else if (field === "encoding") {
          placeholderText = t("type-encoding-here");
        }
        fields.push(
          <Grid xs={12} sm={6} sx={{ p: 1 }}>
            <Box textAlign={"start"}>
              {!isMobile() && (
                <InputLabel
                  htmlFor={field}
                  style={{
                    color: "secondary",
                    marginLeft: "0.2rem",
                    fontWeight: 500,
                  }}
                >
                  {t(field.replace(/_/g, "-"))}:
                </InputLabel>
              )}
              <TextField
                key={`${field}-${idx}`}
                fullWidth
                placeholder={placeholderText}
                id={field}
                label={isMobile() ? t(field.replace(/_/g, "-")) : undefined}
                disabled={formType === "view"}
                {...getFieldProps(field)}
              />
            </Box>
          </Grid>
        );
      });

      return <Grid container>{fields}</Grid>;
    }

    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          disabled={formType === "view" ? true : false}
          loading={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }

    if (fieldName === "cancelButton") {
      return (
        <Button
          sx={{ mx: 1 }}
          className="btn btn-default"
          size="large"
          type="button"
          variant="outlined"
          disabled={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <CancelIcon /> : null}
          onClick={() => setModalStatus(false)}
        >
          {t("cancel")}
        </Button>
      );
    }
  };

  return (
    <Box sx={{ overflow: "auto", height: "80vh" }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {getFieldByName("configType")}
          <Stack>
            <Grid container sx={{ width: "100%", m: 0 }}>
              {getFieldByName("status")}
              {getFieldByName("accountType")}
              {getFieldByName("channelType")}

              {configurationType === "HTTP" && (
                <>
                  {getFieldByName("pop")}
                  {getFieldByName("api_url")}
                  {getFieldByName("username")}
                  {getFieldByName("password")}
                </>
              )}
            </Grid>
            {configurationType === "HTTP"
              ? getFieldByName("httpConfig")
              : getFieldByName("smppConfig")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </Box>
  );
}
