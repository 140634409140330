import api from "src/api/Api";
import urls from "src/api/Urls";


export const listPartitions = (params) => {
    return api.get(urls.list_partitions, { params: params });
}

export const createPartition = (params, payload) => {
    return api.post(urls.create_partition, payload, { params: params });
}

export const managePartitionService = (id, payload) => {
    return api.post(urls.manage_partition(id), payload);
}
