import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Grid, TextField, FormControl, Typography } from "@mui/material";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { DefaultPaginationData } from "src/constants";
import SearchButton from "src/components/buttons/SearchButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { LogViewerService } from "src/api/services";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { formatDateInput } from "src/utils/Util";
import { fCurrency } from "src/utils/formatNumber";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function VoiceReport() {
  const { t } = useTranslation();

  let apiService = LogViewerService.listLogsVoice;

  const tableFieldMapping = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    created: { key: "createdDate", index: 1 },
    from_number: { key: "fromNumber", index: 2 },
    to_number: { key: "toNumber", index: 3 },
    duration: { key: "duration", index: 4, align: "center" },
    call_status: { key: "status", index: 5, align: "center" },
    amount: { key: "amount", index: 6, align: "right" },
    sip_status: { key: "sipCode", index: 7, noRender: true },
    account: { key: "account", index: 8, noRender: true },
    call_sid: { key: "callSid", index: 9, noRender: true },
    direction: { key: "direction", index: 10, noRender: true },
    originating_sip_ip: { key: "ip", index: 11, noRender: true },
    originating_sip_trunk_name: { key: "trunk", index: 12, noRender: true },
  };
  const tableHead = [
    { key: "id", label: t("common.id") },
    { key: "createdDate", label: t("common.createdDate") },
    { key: "fromNumber", label: t("common.fromNumber") },
    { key: "toNumber", label: t("common.toNumber") },
    { key: "duration", label: t("common.duration"), align: "center" },
    { key: "status", label: t("common.status"), align: "center" },
    { key: "amount", label: t("amount") },
  ];

  const [filterFromNumber, setFromNumber] = useState("");
  const [filterToNumber, setToNumber] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [effectiveStartDate, setEffectiveStartDate] = useState(dayjs());
  const [effectiveEndDate, setEffectiveEndDate] = useState(dayjs());

  const fetchCdr = () => {
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
      date_after: formatDateInput(effectiveStartDate),
      date_before: formatDateInput(effectiveEndDate),
      from_number: filterFromNumber || undefined,
      to_number: filterToNumber || undefined,
    };
    setLoadingData(true);
    apiService(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.count);
        for (const idx in response.data.results) {
          let item = new Array(tableHead.length - 1).fill({});
          Object.entries(response.data.results[idx]).forEach(([key, value]) => {
            if (key in tableFieldMapping) {
              item[tableFieldMapping[key].index] = {
                ...tableFieldMapping[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCdr();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "createdDate") {
        formatted.push({
          ...d,
          value: formatDateInput(d.value),
        });
      } else if (d.key === "status") {
        let statusText = d.value;
        statusText =
          typeof statusText === "string"
            ? statusText[0].toUpperCase() + statusText.slice(1)
            : "";
        const sipCode = rowData.filter((cell) => cell.key === "sipCode");
        formatted.push({
          ...d,
          key: d.key,
          value: statusText,
          tooltipText:
            sipCode.length === 1
              ? rowData.filter((cell) => cell.key === "sipCode")[0].value
              : "",
        });
      } else if (d.key === "amount") {
        formatted.push({
          ...d,
          value: fCurrency(d),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  return (
    <>
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4} padding={2}>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("start-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveStartDate(newValue);
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={3} xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography>{t("end-date-time")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={effectiveStartDate}
                  onChange={(newValue) => {
                    setEffectiveEndDate(newValue);
                  }}
                  minDate={effectiveStartDate}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth sx={{ textAlign: "left", mt: 1}}>
              <Typography>{t("from-number")}</Typography>
              <TextField
                value={filterFromNumber}
                placeholder={t("from-number-here")}
                name="fromNumber"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFromNumber(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth sx={{ textAlign: "left", mt: 1 }}>
              <Typography>{t("to-number")}</Typography>
              <TextField
                value={filterToNumber}
                placeholder={t("to-number-here")}
                name="toNumber"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setToNumber(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={3} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCdr();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={tableHead}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
