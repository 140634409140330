import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useStore } from "src/store/Store";
import { ProfileService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function AdminLoginForm() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t("user-not-found"));
  const [store, dispatch] = useStore();

  const getFormData = (values) => {
    const formData = new FormData();
    formData.append("username", values.email);
    formData.append("password", values.password);
    return formData;
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("form.__i18n_ally_root__.validation.email"))
      .required(t("email-is-required")),
    password: Yup.string()
      .required(t("password-is-required"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      const payload = getFormData(values);

      ProfileService.adminLogin(payload)
        .then((response) => {
          if (response.status !== 200) {
            throw { message: "login failed", response: response };
          }
          
          dispatch({
            type: "LOGIN",
            payload: {
              admin: {
                token: response.data.access_token,
                expires_at: response.data.expires_at,
                refresh_token: response.data.refresh_token,
                refresh_token_expires_at: response.data.refresh_token_expires_at,
                refresh_token_issued_at: response.data.refresh_token_issued_at,
                user: {
                  id: response.data.user_info.id,
                  first_name: response.data.user_info.first_name,
                  last_name: response.data.user_info.last_name,
                  email: response.data.user_info.email,
                  root_admin: response.data.user_info.root_admin,
                  partition_admin: response.data.user_info.partition_admin,
                  accesses : response.data.roles.map((role) => (
                    role.function_groups.map((item) => ({
                      function_group_name: item.function_group_name,
                      read_allowed: item.read_allowed,
                      write_allowed: item.write_allowed
                    }))
                  )),
                },
              },
            },
          });
          dispatch({
            type: "UPDATE_SELECTED_LANGUAGE",
            payload: {
              //language: loginResponse.data.user.selected_language_code,
              language: "en-US",
            },
          });
        })
        .catch((err) => {
          setLoginMessage(err.response.data.detail);
          setOpenSnackbar(true);
          dispatch({ type: "LOGIN_FAIL" });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={loginMessage}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t("email-address")}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label={t("password")}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("login")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
