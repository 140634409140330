import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// ----------------------------------------------------------------------
import TRANSLATIONS_EN from "src/translations/en/translation.json";
import TRANSLATIONS_DE from "src/translations/de/translation.json";
import TRANSLATIONS_FR from "src/translations/fr/translation.json";
import TRANSLATIONS_IT from "src/translations/it/translation.json";
import TRANSLATIONS_TR from "src/translations/tr/translation.json";
import TRANSLATIONS_AR from "src/translations/ar/translation.json";
import TRANSLATIONS_ES from "src/translations/es/translation.json";
import TRANSLATIONS_HI from "src/translations/hi/translation.json";
import TRANSLATIONS_ID from "src/translations/id/translation.json";
import TRANSLATIONS_JA from "src/translations/ja/translation.json";
import TRANSLATIONS_PT from "src/translations/pt/translation.json";
import TRANSLATIONS_ZH from "src/translations/zh/translation.json";
import TRANSLATIONS_DA from "src/translations/da/translation.json";
import TRANSLATIONS_FI from "src/translations/fi/translation.json";
import TRANSLATIONS_SV from "src/translations/sv/translation.json";
// ----------------------------------------------------------------------

const resources = {
    "en-US": { translation: TRANSLATIONS_EN },
    "de-DE": { translation: TRANSLATIONS_DE },
    "fr-FR": { translation: TRANSLATIONS_FR },
    "it-IT": { translation: TRANSLATIONS_IT },
    "tr-TR": { translation: TRANSLATIONS_TR },
    "ar-AR": { translation: TRANSLATIONS_AR },
    "es-ES": { translation: TRANSLATIONS_ES },
    "hi-HI": { translation: TRANSLATIONS_HI },
    "id-ID": { translation: TRANSLATIONS_ID },
    "ja-JA": { translation: TRANSLATIONS_JA },
    "pt-PT": { translation: TRANSLATIONS_PT },
    "zh-ZH": { translation: TRANSLATIONS_ZH },
    "da-DA": { translation: TRANSLATIONS_DA },
    "fi-FI": { translation: TRANSLATIONS_FI },
    "sv-SV": { translation: TRANSLATIONS_SV },
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: sessionStorage.getItem("language") || "en-US",
        fallbackLng: "en-US",
    });

export default i18n;
