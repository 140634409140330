import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { ReactSortable } from "react-sortablejs";
// material
import {
  Stack,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Typography,
  Box,
  FormLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { DidService, EngineService, ProductsService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { CountriesService } from "src/api/services";
import { Capability, SetType, getSelectOptions } from "src/constants";
import { isMobile } from "src/utils/Util";
import { useStore } from "src/store/Store";

// ----------------------------------------------------------------------

export default function NumbersProductForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [rules, setRules] = useState([]);
  const [active, setActive] = useState(formData ? formData.active : false); //FTODO
  const [capability, setCapability] = useState(formData.capability_id ?? 1);

  const [selectedCountry, setSelectedCountry] = useState(
    formData.country_id ?? null
  );
  const [selectedDidType, setSelectedDidType] = useState(
    formData.type_id ?? null
  );
  const [selectedRule, setSelectedRule] = useState(formData.rule_id ?? null);

  const [rangeType, setrangeType] = useState(
    formData.range === t("individual") ? "individual" : "block"
  );
  const [providerOptions, setProviderOptions] = useState([]);

  const ProductSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name-is-required"))
      .max(32, t("name-is-too-long"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    /*     country: Yup.string().required(t("country-must-be-selected")),
    capability: Yup.string().required(t("capabilty-must-be-selected")),
    did_type: Yup.string().required(t("did-type-must-be-selected")),
    complianceRule: Yup.string().required(t("rule-must-be-selected")), */
  });

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      /*       country: formData.country_id || null,
      capability: formData.capabiliuty || "1",
      rule_id: formData.rule_id || null,
      did_id: formData.did_id || null,
      status: formData.status || false, */
    },
    validationSchema: ProductSchema,
    onSubmit: (values, actions) => {
      let providers = providerOptions.map((option, index) => ({
        id: option.id || null,
        provider_id: option.value,
        filter_enable: option.filter_enable,
        filter_order: index + 1,
        product_id: formData ? formData.id : null,
      }));

      let payload = {
        name: values.name,
        country_id: selectedCountry,
        capability_id: capability,
        /* rule_id: selectedRule, */
        type_id: selectedDidType,
        filter_providers: providers,
        properties: {
          individual: rangeType === "individual",
          block: rangeType === "block",
          active: active,
        },
        /* status: active, */
      };

      let successMessage =
        formType === "add"
          ? t("numbers-product-add-success")
          : t("numbers-product-edit-success");
      let failMessage =
        formType === "add"
          ? t("numbers-product-add-fail")
          : t("numbers-product-edit-fail");

      let apiService =
        formType === "add"
          ? ProductsService.addNumberProduct(payload)
          : ProductsService.updateNumberProduct(formData.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  useEffect(() => {
    CountriesService.getInboundCountries({
      size: 100,
    })
      .then((response) => {
        let active_countries = [];
        response.data.forEach((item) => {
          if (item.is_active) {
            active_countries.push(item);
          }
        });
        const options = active_countries.map((country) => ({
          value: country.country_id,
          label: country.name,
        }));
        if (!selectedCountry && options.length > 0) {
          setSelectedCountry(options[0].value);
        }
        setCountries(options);
      })
      .catch((err) => {
        console.log("Error fetching countries:", err);
        throw err;
      });

    DidService.listDidTypes({ size: 100 })
      .then((response) => {
        let options = response.data.items.map((did) => ({
          value: did.id,
          label: did.name,
        }));
        if (!selectedDidType && options.length > 0) {
          setSelectedDidType(options[0].value);
        }
        setDidTypes(options);
      })
      .catch((err) => {
        console.log("Error fetching DID types:", err);
        throw err;
      });

    if (formType === "add") {
      EngineService.listEngines({
        size: 100,
        engine_type: 3,
        is_running: true
      }).then((response) => {
        let options = response.data.items
          .map((provider) => ({
            value: provider.id,
            label:
              provider.properties.pop + " / " + provider.engine_subtype.name,
            filter_enable: false,
          }));

        /* options.unshift({
          value: -1,
          label: t("local"),
          filter_enable: false,
        }); */

        setProviderOptions(options);
      });
    } else {
      let options = formData.filter_providers
        .sort((a, b) => a.filter_order - b.filter_order)
        .map((item) => ({
          id: item.id,
          value: item.provider.id,
          label:
            item.provider.properties.pop +
            " / " +
            item.provider.engine_subtype.name,
          filter_enable: item.filter_enable,
        }));
      setProviderOptions(options);
    }
  }, []);

  const handleCheck = (value, provider) => {
    let options = [...providerOptions];
    let foundOption = options.find((item) => item.value === provider.value);
    foundOption.filter_enable = !foundOption.filter_enable;
    setProviderOptions(options);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="name"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="name"
            label={isMobile() ? t("name") : undefined}
            placeholder={t("type-name-here")}
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
      );
    }

    if (fieldName === "country") {
      return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={countries}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={selectedCountry ? t("country") : t("select-country")}
              />
            )}
            value={
              countries.find((option) => option.value === selectedCountry) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedCountry(newValue.value);
              }
            }}
          />
        </FormControl>
      );
    }

    if (fieldName === "didType") {
      return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={didTypes}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={t("did-type")} />
            )}
            value={
              didTypes.find((option) => option.value === selectedDidType) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedDidType(newValue.value);
              }
            }}
          />
        </FormControl>
      );
    }
    if (fieldName === "rule") {
      return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={rules}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={t("compliance-rule")} />
            )}
            value={
              rules.find((option) => option.value === selectedRule) || null
            }
            onChange={(event, newValue) => {
              if (newValue && newValue.value) {
                setSelectedRule(newValue.value);
              }
            }}
          />
        </FormControl>
      );
    }
    if (fieldName === "capability") {
      return (
        <FormControl fullWidth>
          <InputLabel id="capability-type-label">{t("capability")}</InputLabel>
          <Select
            label={t("capability")}
            labelId="capability-type-label"
            color="secondary"
            value={capability}
            onChange={(event) => {
              setCapability(parseInt(event.target.value));
            }}
          >
            {getSelectOptions(Capability(), "", "")}
          </Select>
        </FormControl>
      );
    }
    if (fieldName === "active") {
      return (
        <FormControl fullWidth>
          <Box sx={{ display: "flex", alignItems: "center", ml: "2%" }}>
            <Typography color="main">{t("active")}</Typography>
            <Checkbox
              checked={active}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
          </Box>
        </FormControl>
      );
    }

    if (fieldName === "rangeType") {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={rangeType === "individual"}
              onChange={(e) => {
                e.target.checked && setrangeType("individual");
              }}
            />
            <Typography component="h6">{t("individual")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={rangeType === "block"}
              onChange={(e) => {
                e.target.checked && setrangeType("block");
              }}
            />
            <Typography component="h6">{t("block")}</Typography>
          </Box>
        </Box>
      );
    }

    if (fieldName === "inboundProvider") {
      return (
        <>
          <FormControl fullWidth>
            <Box>
              <Typography variant="h5" color={"secondary"}>
                {t("inbound-providers")}
              </Typography>
              <Typography>{t("drag-drop")}</Typography>
              <ReactSortable
                list={providerOptions}
                setList={setProviderOptions}
              >
                {providerOptions.map((provider, index) => (
                  <Box
                    key={index}
                    sx={{
                      cursor: "move",
                      display: "flex",
                      alignItems: "center",
                      m: 1.2,
                      backgroundColor: store.palette.background.dark,
                      borderRadius: "0.5rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={provider.filter_enable}
                      onChange={(e) => handleCheck(e.target.checked, provider)}
                    />
                    <Typography>
                      {index + 1} -{provider.label}
                    </Typography>
                  </Box>
                ))}
              </ReactSortable>
            </Box>
          </FormControl>
        </>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={1}>
            {getFieldByName("name")}
            {getFieldByName("country")}
            {getFieldByName("didType")}
            {getFieldByName("capability")}
            {/*  {getFieldByName("rule")} */}
            {getFieldByName("inboundProvider")}
            {/* {getFieldByName("rangeType")} */}
            {getFieldByName("active")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
