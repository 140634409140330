import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useStore } from "src/store/Store";

export default function InfoNumberForm({
  successCallback,
  setType,
  setModalStatus,
  formData,
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  const [purchaseType, setPurchaseType] = useState("individual");

  if (formData.did === "490000003") {
    formData = {
      address_required: true,
      capability: 3,
      comp_address_required: true,
      comp_cond_explanation: [
        "Stock Order ETA (in working days) (1 - 5)",
        "Back Order ETA (in working days) (10 - 15)",
      ],
      comp_country_required: true,
      comp_doc_required: true,
      comp_email_required: true,
      comp_legal_restrictions: [
        "comp legal restricition example 1",
        "comp legal restricition example 1",
      ],
      comp_required_doc_labels: [
        " Company Registration",
        " ID of person in charge of order",
        " Notification to NRAs",
        " Order Form JPN End-User",
        " Order Form JPN Service Provider",
        "ID of person owning 25%+ of shares",
      ],
      comp_service_restrictions: ["service restriction example sss"],
      comp_tax_id_required: true,
      cond_explanation: [
        "Stock Order ETA (in working days) (1 - 5)",
        "Back Order ETA (in working days) (10 - 15)",
      ],
      connect_fee: 1,
      country_code: "ID",
      country_required: true,
      currency: "$",
      did: "6200000045",
      did_type: "Mobile",
      doc_required: true,
      email_required: true,
      initial_time: 1,
      legal_restrictions: [
        "For 0120 numbers the end user (corporate) must be located in the country (mainland).",
        "For 0800 numbers the end user (corporate) can be located worldwide.",
        "For 053 numbers the end user (corporate) name is requested before activation.",
        "Service Providers can be",
      ],
      mrc: 1,
      nrc: 1,
      provider_code: null,
      required_doc_labels: ["Proof of Address"],
      rounding: 1,
      service_restrictions: [
        "Dialing format: 0120-XXX-XXX; 0800-YYY-YYYY..",
        "National reachability: ALL expect minor IP Phones and Cable TV providers.",
        "International reachability: no.",
        "Concurrent calls: No limitation.",
        "Max call duration (min): 360.",
        "CLI format: no info.",
      ],
      sms_price: 1,
      tax_id_required: true,
      translated_did: null,
      voice_mobile_price: 0.1,
      voice_offpeak_price: 1,
      voice_payphone_price: 0.1,
      voice_price: 1,
      voice_weekend_price: 1,
    };
  }

  const getFieldByName = (fieldName) => {
    if (fieldName === "selectedNumber") {
      return (
        <Box mb={3}>
          <Typography>
            {t("selected-number")}: <strong>{formData.did} </strong>
          </Typography>
        </Box>
      );
    }
    if (fieldName === "purchaseType") {
      return (
        <>
          <Typography variant="h5">{t("purchase-for")}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={purchaseType === "individual"}
                onChange={(e) =>
                  e.target.checked && setPurchaseType("individual")
                }
              />
              <Typography>{t("individual")}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={purchaseType === "company"}
                onChange={(e) => e.target.checked && setPurchaseType("company")}
              />
              <Typography>{t("company")}</Typography>
            </Box>
          </Box>
        </>
      );
    }

    if (fieldName === "expConditions") {
      let conditions =
        purchaseType === "individual"
          ? formData.cond_explanation
          : formData.comp_cond_explanation;

      if (conditions[0] !== "" || conditions.length > 1) {
        return (
          <>
            <Typography variant="h6">{t("exp-conditions")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              {conditions.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="h6">{t("exp-conditions")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              <li>{t("no-condition")}</li>
            </ul>
          </>
        );
      }
    }

    if (fieldName === "legalRestrictions") {
      let restrictions =
        purchaseType === "individual"
          ? formData.legal_restrictions
          : formData.comp_legal_restrictions;

      if (restrictions[0] !== "" || restrictions.length > 1) {
        return (
          <>
            <Typography variant="h6">{t("legal-restrict")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              {restrictions.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="h6">{t("legal-restrict")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              <li>{t("no-legal-restrict")}</li>
            </ul>
          </>
        );
      }
    }

    if (fieldName === "serviceRestrictions") {
      let restrictions =
        purchaseType === "individual"
          ? formData.service_restrictions
          : formData.comp_service_restrictions;

      if (restrictions[0] !== "" || restrictions.length > 1) {
        return (
          <>
            <Typography variant="h6">{t("service-restricts")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              {restrictions.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="h6">{t("service-restricts")}:</Typography>
            <ul
              style={{
                textAlign: "start",
                padding: "0.5rem",
              }}
            >
              <li>{t("no-service-restrict")}</li>
            </ul>
          </>
        );
      }
    }
    if (fieldName === "submit") {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: store.palette.error.light,
              }}
              onClick={() => setModalStatus(false)}
            >
              <Typography fontWeight={1000}>{t("cancel")}</Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: store.palette.primary.main,
              }}
              variant="contained"
              onClick={() => {
                setModalStatus(false);
                setType(purchaseType);
                successCallback(true);
              }}
            >
              <Typography fontWeight={1000}>{t("next")}</Typography>
            </Button>
          </Box>
        </>
      );
    }
  };

  return (
    <>
      {getFieldByName("selectedNumber")}
      <Stack spacing={1}>{getFieldByName("purchaseType")}</Stack>
      <br />
      {getFieldByName("expConditions")}
      {getFieldByName("legalRestrictions")}
      {getFieldByName("serviceRestrictions")}
      <br />
      {getFieldByName("submit")}
    </>
  );
}
