import React from "react";
import {
  Typography,
  Card,
  CardContent,
  ListItemText,
  ListItem,
  List,
  Checkbox,
  Stack,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FeatureService, PackagesService } from "src/api/services";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import BaseSnackbar from "src/components/BaseSnackbar";
import SaveIcon from "@mui/icons-material/Save";

import { DefaultPaginationData } from "src/constants";

export default function ConfigFeatureForm({
  formData,
  setModalStatus,
  successCallback,
  setMessage,
  setSnackbarStatus,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState(
    formData.features || []
  );

  const handleFeatureToggle = (selectedFeature) => {
    const updatedSelectedFeatures = selectedFeatures
      .map((f) => f.id)
      .includes(selectedFeature.id)
      ? selectedFeatures.filter((feature) => feature.id !== selectedFeature.id)
      : [...selectedFeatures, selectedFeature];
    setSelectedFeatures(updatedSelectedFeatures);
  };

  const fetchFeatures = () => {
    setLoading(true);
    const params = {
      page: DefaultPaginationData.page + 1,
      page_size: DefaultPaginationData.rowsPerPage,
    };
    FeatureService.listFeatures(params)
      .then((response) => {
        setFeatures(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: formData.id,
      name: formData.name || "",
      minSeatCount: formData.min_seat_count,
      maxSeatCount: formData.max_seat_count,
      description: formData.description,
      isEnabled: formData.is_enabled,
      features: selectedFeatures,
    },
    onSubmit: (values, actions) => {
      const payload = {
        name: values.name,
        package_type: 2,
        properties: {
          min_seat_count: values.minSeatCount,
          max_seat_count: values.maxSeatCount,
          description: values.description,
          features: selectedFeatures,
        },
        is_enabled: values.isEnabled,
        status: 1,
      };

      let successMessage = t("uc-package-feature-configured-successfully");
      let failMessage = t("failed-to-configure-uc-package");

      PackagesService.updatePackage(formData.id, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={1} alignItems={"center"}>
            <Card
              sx={{
                width: "75%",
                mb: "2vh",
                minHeight: "22vh",
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("features")}
                </Typography>
                <List
                  sx={{
                    alignItems: "center",
                    maxHeight: "500px",
                    overflowY: "scroll",
                  }}
                >
                  {features.map((feature) => (
                    <ListItem key={feature.id} sx={{ textAlign: "center" }}>
                      <Checkbox
                        checked={selectedFeatures
                          .map((f) => f.id)
                          .includes(feature.id)}
                        onChange={() => handleFeatureToggle(feature)}
                      />
                      <ListItemText primary={feature.name} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Box>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {t("common.__i18n_ally_root__.save")}
              </LoadingButton>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
