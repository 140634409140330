import React, { useState } from "react";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function BaseSnackbar({
  vertical = "top",
  horizontal = "center",
  open = false,
  message = "",
  setOpen,
  permanent = false,
  fontSize = "default",
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const messages = Array.isArray(message) ? message : [message];

  const handleNextMessage = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousMessage = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={open}
      autoHideDuration={permanent ? null : 8000}
      onClose={() => setOpen(false)}
      message={<span style={{ fontSize }}>{messages[currentIndex]}</span>}
      action={
        <>
          {Array.isArray(message) && (
            <>
              <IconButton
                size="small"
                aria-label="previous"
                color="inherit"
                onClick={handlePreviousMessage}
                disabled={currentIndex === 0}
              >
                <ArrowBackIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNextMessage}
                disabled={currentIndex === messages.length - 1}
              >
                <ArrowForwardIcon fontSize="small" />
              </IconButton>
            </>
          )}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
}
