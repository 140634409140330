import { useEffect, useState } from "react";
import {
  CustomerStatuses,
  DefaultPaginationData,
  getLabelByValue,
} from "src/constants/index";
import { CustomerService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";
import ForwardIcon from '@mui/icons-material/Forward';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));
const TableDataCellStyle = styled(TableCell)(({ theme }) => ({
  color: theme.palette.background.contrastText,
}));
export default function UcCustomer({ changeTab }) {
  const { t } = useTranslation();

  useEffect(() => {
    changeTab(0, null, null);
  }, []);
  const TABLE_HEAD = [
    { key: "customerName", label: t("customer-name") },
    { key: "ucServices", label: t("uc-services"), align: "center" },
/*     { key: "status", label: t("common.__i18n_ally_root__.status") }, */
    { key: "action", label: t("actions"), align: "right", padding: "1.8em" },
  ];

  const TABLE_FIELD_MAPPING = {
    name: { key: "customerName", index: 0 },
    uc_services: { key: "ucServices", index: 1 },
   /*  status: { key: "status", index: 2 }, */
    id: { key: "id", cellComponentType: "th", index: 3, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterCustomer, setCustomer] = useState("");

  const fetchCustomers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterCustomer ? filterCustomer : undefined,
    };

    CustomerService.listCustomerWithDetails(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.items.filter((item)=> item.uc_services.length > 0).length);
        for (const idx in response.data.items.filter((item)=> item.uc_services.length > 0)) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.uc_services) {
            let serviceTable = (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <TableDataCellStyle key={`nested-table-${itemData.id}`}>
                  <Table style={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("service-name")}</TableCell>
                        <TableCell>{t("total-seat")}</TableCell>
                        <TableCell>{t("used-seat")}</TableCell>
                        <TableCell>{t("uc-users")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.uc_services.sort((a, b) => b.used_seat_count - a.used_seat_count).map((serviceData, serviceIndex) => (
                        <TableRow key={`nested-row-${serviceIndex}`}>
                          <TableCell>{serviceData.name}</TableCell>
                          <TableCell>{serviceData.seat_count}</TableCell>
                          <TableCell>{serviceData.used_seat_count}</TableCell>
                          <TableCell>
                            <>
                              <Stack direction="row" spacing={2}>
                                <Tooltip title={t("manage-uc-users")}>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      changeTab(
                                        3,
                                        serviceData.id,
                                        itemData.id
                                      )
                                    }
                                  >
                                    <GroupIcon sx={{ ml: 1 }} />
                                  </IconButton>
                                </Tooltip>

                                {/* <Tooltip title={t("add-uc-users")}>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      changeTab(
                                        4,
                                        serviceData.id,
                                        itemData.id
                                      )
                                    }
                                  >
                                    <GroupAddIcon sx={{ ml: 1 }} />
                                  </IconButton>
                                </Tooltip> */}
                              </Stack>
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableDataCellStyle>
              </Box>
            );
            item[TABLE_FIELD_MAPPING["uc_services"].index] = {
              ...TABLE_FIELD_MAPPING["uc_services"],
              value: serviceTable,
            };

          }

          Object.entries(itemData).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "uc_services"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });

          items.push(item);
        }

        setData(items);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCustomers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(CustomerStatuses(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("manage-uc-services")} sx={{ mr: 1 }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="manage-services"
            onClick={() => changeTab(1, null, rowArrayToObject(data[index]).id)}
          >
            <ForwardIcon />
          </IconButton>
        </Tooltip>

      </>
    );
  };

  return (
    <>

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterCustomer}
                label={t("customer")}
                name="customer"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setCustomer(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchCustomers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <Box sx={{ mb: "4vh" }} />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
