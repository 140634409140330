import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ProvisioningLog from "./auditLogs/ProvisioningLog";

// ----------------------------------------------------------------------

export default function ProvisioningLogs() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("provisioning-logs"), component: <ProvisioningLog /> }
];

  const TITLE = t("audit-logs");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
