import { useState, useEffect } from "react";
import { Stack, IconButton, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { BaseTable } from "src/components/table";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import { RoleForm } from "./form";
import { RoleService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";

export default function CustomerRoles() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "name", label: t("role"), align: "center" },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1, align: "center" },
    function_mappings: { key: "function_mappings", index: 2, noRender: true },
    properties: { key: "properties", index: 3, noRender: true },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openShowRoleModal, setShowRoleModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const fetchRoles = () => {
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
    };
    setLoadingData(true);
    RoleService.listCustomerRoles(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchRoles();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "show") {
      setShowRoleModalStatus(!openShowRoleModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("show-role-details")} sx={{ mr: 1 }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="show-role"
            onClick={() => modalHandler("show", index)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <br />
      <BaseModal
        sx={{
          minWidth: "40rem",
          maxHeight: "95vh",
        }}
        title={t("role-details")}
        open={openShowRoleModal}
        setOpen={setShowRoleModalStatus}
        children={
          <RoleForm
            formType={"show"}
            formData={selectedRow}
            successCallback={fetchRoles}
            setModalStatus={setShowRoleModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
