import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import NumbersContainer from "./numbersConfig/numbersContainer/NumbersContainer";

// ----------------------------------------------------------------------

export default function NumbersContainers() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("numbers-container"), component: <NumbersContainer /> },
  ];

  const TITLE = t("numbers-container");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
