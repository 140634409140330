// material
import {
  Stack,
  TextField,
  Autocomplete,
  FormControl,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// app
import { NumberService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

// ----------------------------------------------------------------------

export default function ManageNumbersForm({
  formType,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [voiceRoutingOptions, setVoiceRoutingOptions] = useState([]);
  const [smsRoutingOptions, setSmsRoutingOptions] = useState([]);

  const [selectedVoiceRouting, setSelectedVoiceRouting] = useState(
    formData.voiceRoutingID || null
  );
  const [selectedSmsRouting, setSelectedSmsRouting] = useState(
    formData.smsRoutingID || null
  );

  useEffect(() => {
    NumberService.listVoiceRoutings({ size: 100 })
      .then((response) => {
        response.data = {
          items: [
            {
              id: 1,
              name: "VoiceRouting1",
            },
            {
              id: 2,
              name: "VoiceRouting2",
            },
            {
              id: 3,
              name: "VoiceRouting3",
            },
            {
              id: 4,
              name: "VoiceRouting4",
            },
          ],
        };
        let options = response.data.items.map((routing) => ({
          value: routing.id,
          label: routing.name,
        }));
        setVoiceRoutingOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });

    NumberService.listSmsRotings({ size: 100 })
      .then((response) => {
        response.data = {
          items: [
            {
              id: 1,
              name: "SmsRouting1",
            },
            {
              id: 2,
              name: "SmsRouting2",
            },
            {
              id: 3,
              name: "SmsRouting3",
            },
            {
              id: 4,
              name: "SmsRouting4",
            },
          ],
        };
        let options = response.data.items.map((routing) => ({
          value: routing.id,
          label: routing.name,
        }));
        setSmsRoutingOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSubmit = () => {
    const payload = {
      voice_routing_id: selectedVoiceRouting,
      sms_routing_id: selectedSmsRouting,
    };

    let successMessage = t("number-routings-update-success");
    let failMessage = t("number-routings-update-fail");
    setIsSubmitting(true);
    NumberService.updateNumber(formData.id, payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsSubmitting(false);
          setModalStatus(false);
          setMessage(successMessage);
          setSnackbarStatus(true);
          successCallback();
        } else {
          throw "routing update error";
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        setModalStatus(false);
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const getFieldByName = (fieldName) => {
    if (fieldName === "voiceRoutings") {
      return (
        <FormControl fullWidth>
          <Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={voiceRoutingOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("voice-routing")} />
              )}
              value={
                voiceRoutingOptions.find(
                  (option) => option.value === selectedVoiceRouting
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedVoiceRouting(newValue?.value || null);
              }}
            />
          </Box>
        </FormControl>
      );
    }

    if (fieldName === "smsRoutings") {
      return (
        <FormControl fullWidth>
          <Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={smsRoutingOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("sms-routing")} />
              )}
              value={
                smsRoutingOptions.find(
                  (option) => option.value === selectedSmsRouting
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedSmsRouting(newValue?.value || null);
              }}
            />
          </Box>
        </FormControl>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <Stack spacing={1}>
        {getFieldByName("voiceRoutings")}
        {getFieldByName("smsRoutings")}
      </Stack>
      <br />
      {getFieldByName("submitButton")}
    </>
  );
}
