import { useState } from "react";
// material
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { PackagesService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteAnyway({
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
  apiService,
  name,
  usedIn,
  serviceList = <></>
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const deleteAnyway = () => {
    setLoading(true);

    apiService()
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("delete-success", { name }));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw "package delete failed";
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          setMessage(t("delete-fail", { name }));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="subtitle1" variant="h6">
          {t("is-using-at", { name, usedIn })}
        </Typography>
        <Box
          sx={{
            alignSelf: "center",
          }}
        >
          {serviceList}
          <Typography component="subtitle1" variant="h6">
            {t("yes-I-am-sure")}
          </Typography>
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
        </Box>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            disabled={checked ? false : true}
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteAnyway}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
