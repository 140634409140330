import { useEffect, useState } from "react";
import { canUserReadIt, canUserWriteIt } from "src/utils/Util";
import {
  Grid,
  TextField,
  FormControl,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { rowArrayToObject } from "src/utils/Util";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import { CustomerService, UcManagementService } from "src/api/services";
import { useTranslation } from "react-i18next";
import AddNewButton from "src/components/buttons/AddNewButton";
import ManageForm from "./form/ManageForm";
import { useStore } from "src/store/Store";
import UcUserForm from "./form/UcUserForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddNumbersForm from "./form/AddNumbersForm";
import DeleteUcUserForm from "./form/DeleteUcUserForm";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function UcServiceManage() {
  const { t } = useTranslation();
  const [store, dispatch] = useStore();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openAddUsersModal, setOpenAddUsersModal] = useState(false);
  const [openEditModal, setOpenEditModalStatus] = useState(false);
  const [openAsignPackageModal, setOpenAssignPackageModal] = useState(false);
  const [openAsignNumberModal, setOpenAssignNumberModal] = useState(false);
  const [openAddNumbersModal, setOpenAddNumbersModal] = useState(false);
  const [openEditNumberModal, setOpenEditNumberModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [ucServiceOptions, setUcServiceOptions] = useState([]);
  const [selectedUcService, setSelectedUcService] = useState(null);
  const [assignIndividual, setAssignIndividual] = useState(false);

  const [tableHead, setTableHead] = useState([]);
  const [tableFieldMapping, setTableFieldMapping] = useState({});

  useEffect(() => {
    if (assignIndividual) {
      setTableHead(
        [
          { key: "first_name", label: t("first-name") },
          { key: "last_name", label: t("last-name") },
          { key: "email", label: t("email") },
          { key: "customer_name", label: t("customer") },
          { key: "uc_service", label: t("uc-service") },
          { key: "number", label: t("phone-number") },
          canUserWriteIt("uc_management")
            ? { key: "action", label: t("actions") }
            : null,
        ].filter(Boolean)
      );

      setTableFieldMapping({
        id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
        first_name: { key: "first_name", index: 1 },
        last_name: { key: "last_name", index: 2 },
        email: { key: "email", index: 3 },
        customer_name: { key: "customer_name", index: 4 },
        uc_service: { key: "uc_service", index: 5 },
        number: { key: "number", index: 6 },
        customer: { key: "customer", index: 7, noRender: true },
        uc_username: { key: "uc_username", index: 8, noRender: true },
        customer_id: { key: "customer_id", index: 9, noRender: true },
      });
    } else {
      setTableHead([
        { key: "country", label: t("country") },
        { key: "capability", label: t("capability") },
        { key: "number", label: t("number") },
        { key: "type", label: t("type") },
  /*       { key: "status", label: t("status") }, */
        { key: "action", label: t("actions"), align: "left", padding: "1rem" },
      ]);

      setTableFieldMapping({
        id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
        country: { key: "country", index: 1 },
        capability: { key: "capability", index: 2 },
        number: { key: "number", index: 3 },
        type: { key: "type", index: 4 },
       /*  status: { key: "status", index: 5 }, */
      });
    }
  }, [assignIndividual]);

  const fetchCustomerOptions = () => {
    setLoadingData(true);

    CustomerService.listCustomers()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomerOptions(options);
        } else {
          throw "list customer names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingData(false);
  };
  const fetchServiceOptions = () => {
    if (selectedCustomer) {
      setLoadingData(true);

      let params = {
        customer_id: selectedCustomer,
        page: 1,
        size: 100,
      };
      UcManagementService.listUcServices(params)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.items);

            let services = response.data.items.map((service) => ({
              value: service.id,
              label: service.name,
            }));
            setUcServiceOptions(services);
            if (store.serviceID !== "null") {
              setSelectedUcService(store.serviceID);
              dispatch({
                type: "UPDATE_SERVICE_ID",
                payload: { serviceID: null },
              });
            }
          } else {
            throw "list error";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setUcServiceOptions([]);
    }
  };

  useEffect(() => {
    fetchCustomerOptions();
    fetchServiceOptions();
  }, [selectedCustomer]);

  const fetchData = () => {
    if (selectedUcService) {
      setLoadingData(true);
      UcManagementService.getUcService(selectedUcService)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.total > 0) {
              setAssignIndividual(response.data.items[0].uc_service_type === 0);
              setData(response.data.items[0]);
            }
          } else {
            throw "get error";
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  };

  const makeTable = () => {
    let items = [];

    if (assignIndividual) {
      for (const idx in data.uc_users) {
        let item = new Array(tableHead.length - 1).fill({});
        const itemData = data.uc_users[idx].uc_user;

        item[tableFieldMapping["customer_name"].index] = {
          ...tableFieldMapping["customer_name"],
          value: itemData.customer.name,
        };

        item[tableFieldMapping["uc_service"].index] = {
          ...tableFieldMapping["uc_service"],
          value: data.name,
        };

        let number =
          itemData.number !== null ? (
            itemData.number.number
          ) : canUserReadIt("numbers") ? (
            <Button
              sx={{
                color: store.palette.info.contrastText,
                backgroundColor: store.palette.info.main,
              }}
              variant="contained"
              size="small"
              aria-label="view-contact"
              onClick={() => {
                setSelectedRow({
                  id: itemData.id,
                  first_name: itemData.first_name,
                  last_name: itemData.last_name,
                  email: itemData.email,
                  customer: itemData.customer,
                });
                setOpenAssignNumberModal(true);
              }}
            >
              {t("assign-number")}
            </Button>
          ) : (
            <HorizontalRuleIcon sx={{ ml: "1.3em" }} />
          );

        item[tableFieldMapping["number"].index] = {
          ...tableFieldMapping["number"],
          value: number,
        };

        Object.entries(itemData).forEach(([key, value]) => {
          if (key in tableFieldMapping && key !== "number") {
            item[tableFieldMapping[key].index] = {
              ...tableFieldMapping[key],
              value: value,
            };
          }
        });

        items.push(item);
      }
      setTableData(items);
    } else {
      for (const idx in data.numbers) {
        let item = new Array(tableHead.length - 1).fill({});
        const itemData = data.numbers[idx].number;

        //TODO: Provide that informations with relations - BE

        item[tableFieldMapping["country"].index] = {
          ...tableFieldMapping["country"],
          value: itemData?.product?.country?.name || " ",
        };
        item[tableFieldMapping["capability"].index] = {
          ...tableFieldMapping["capability"],
          value: itemData?.product?.capability?.name || " ",
        };

        item[tableFieldMapping["type"].index] = {
          ...tableFieldMapping["type"],
          value: itemData?.product?.did_type?.name || " ",
        };

        Object.entries(itemData).forEach(([key, value]) => {
          if (key in tableFieldMapping && key !== 'type') {
            item[tableFieldMapping[key].index] = {
              ...tableFieldMapping[key],
              value: value,
            };
          }
        });

        items.push(item);
      }
      setTableData(items);
      
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedUcService]);

  useEffect(() => {
    makeTable();
  }, [data]);

  useEffect(() => {
    if (store.customerID !== "null") {
      setSelectedCustomer(store.customerID);
      dispatch({
        type: "UPDATE_CUSTOMER_ID",
        payload: { customerID: null },
      });
    }
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "addExistingUsers") {
      setOpenAddUsersModal(!openAddUsersModal);
    } else if (modalType === "addUser") {
      setOpenAddUserModal(true);
    } else if (modalType === "editUser") {
      setOpenEditModalStatus(!openEditModal);
    } else if (modalType === "deleteUser") {
      setOpenDeleteUserModal(!openDeleteUserModal);
    } else if (modalType === "assignPackage") {
      setOpenAssignPackageModal(!openAsignPackageModal);
    } else if (modalType === "addNumbers") {
      setOpenAddNumbersModal(!openAddNumbersModal);
    } else if (modalType === "editNumber") {
      setOpenEditNumberModal(!openEditNumberModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(tableData[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit-forwarding")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-forwarding"
            onClick={() =>
              assignIndividual
                ? modalHandler("editUser", index)
                : modalHandler("editNumber", index)
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-forwarding"
            onClick={() =>
              assignIndividual
                ? modalHandler("deleteUser", index)
                : modalHandler("deleteNumber", index)
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <BaseModal
        sx={{
          minWidth: "30rem",
          width: "40%",
          maxHeight: "95vh",
          overflow: "auto",
        }}
        title={t("add-new-uc-user")}
        open={openAddUserModal}
        setOpen={setOpenAddUserModal}
        children={
          <UcUserForm
            successCallback={fetchData}
            formType="addToService"
            formData={data}
            customerOptions={customerOptions}
            setModalStatus={setOpenAddUserModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "20rem",
          width: "80%",
          maxHeight: "97vh",
          overflow: "auto",
        }}
        title={t("add-users-to-service")}
        open={openAddUsersModal}
        setOpen={setOpenAddUsersModal}
        children={
          <ManageForm
            successCallback={fetchData}
            formType="add"
            formData={data}
            setModalStatus={setOpenAddUsersModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          minWidth: "20rem",
          width: "60%",
          height: "90vh",
          overflow: "auto",
        }}
        title={t("add-numbers-to-uc-service")}
        open={openAddNumbersModal}
        setOpen={setOpenAddNumbersModal}
        children={
          <AddNumbersForm
            successCallback={fetchData}
            formType="add"
            formData={data}
            setModalStatus={setOpenAddNumbersModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{
          minWidth: "20rem",
          width: "30%",
          maxHeight: "80vh",
          overflow: "auto",
        }}
        title={t("edit-uc-user")}
        open={openEditModal}
        setOpen={setOpenEditModalStatus}
        children={
          <UcUserForm
            successCallback={fetchData}
            formType="edit"
            formData={selectedRow}
            setModalStatus={setOpenEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        /* sx={{
          minWidth: "20rem",
          width: "30%",
          maxHeight: "80vh",
          overflow: "auto",
        }} */
        title={t("delete-uc-user")}
        open={openDeleteUserModal}
        setOpen={setOpenDeleteUserModal}
        children={
          <DeleteUcUserForm
            successCallback={fetchData}
            formData={selectedRow}
            selectedUcService={selectedUcService}
            setModalStatus={setOpenDeleteUserModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("assign-number")}
        open={openAsignNumberModal}
        setOpen={setOpenAssignNumberModal}
        children={
          <UcUserForm
            successCallback={fetchData}
            formType="assignNumber"
            formData={selectedRow}
            setModalStatus={setOpenAssignNumberModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-customer")}
                  sx={{ backgroundColor: "white" }}
                />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={ucServiceOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-uc-service")}
                  sx={{ backgroundColor: "white" }}
                />
              )}
              value={
                ucServiceOptions.find(
                  (option) => option.value === selectedUcService
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedUcService(newValue?.value || null);
              }}
            />
          </Grid>

          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPhoneNumber}
                label={t("phone-number")}
                name="phonenumber"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterPhoneNumber(event.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                /* makeTable(); */
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "2vw",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {selectedUcService && canUserWriteIt("uc_management") &&
          (assignIndividual ? (
            <>
              <AddNewButton
                sx={{ height: "fit-content" }}
                label={t("add-existing-users")}
                onClick={() => modalHandler("addExistingUsers")}
              />
              <AddNewButton
                sx={{ height: "fit-content" }}
                label={t("add-new-uc-user")}
                onClick={() => modalHandler("addUser")}
              />
            </>
          ) : (
            <>
              <AddNewButton
                sx={{ height: "fit-content" }}
                label={t("assign-numbers")}
                onClick={() => modalHandler("addNumbers")}
              />
            </>
          ))}
      </Box>

      <br />
      {selectedUcService ? (
        <>
          <Typography
            variant="h4"
            component="h1"
            style={{ fontWeight: "bold" }}
            sx={{ mb: 2 }}
          >
            #{data && data.name}
          </Typography>
          <BaseTable
            head={tableHead}
            data={[...tableData].map((d, idx) => {
              return formatRowData(d);
            })}
            actionItemPrep={canUserWriteIt("uc_management") && getActionItems}
            pagination={{}}
            loadingData={loadingData}
          />
        </>
      ) : selectedCustomer ? (
        <StyledBox>{t("select-service-warning")}</StyledBox>
      ) : (
        <StyledBox>{t("select-customer-warning")}</StyledBox>
      )}
    </>
  );
}
