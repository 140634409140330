import { useEffect, useState } from "react";
import {
  Capability,
  DefaultPaginationData,
  getLabelByValue,
  getSelectOptions,
} from "src/constants/index";
import {
  CountriesService,
  NetworksService,
  UserService,
} from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  Grid,
  Stack,
  IconButton,
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Autocomplete,
  MenuItem,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function SmsRouting() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "number", label: t("number") },
    { key: "capability", label: t("capability") },
    { key: "number_type", label: t("type") },
    { key: "route_select", label: t("route") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0, noRender: true },
    number: { key: "number", index: 1 },
    capability: { key: "capability", index: 2 },
    number_type: { key: "number_type", index: 3 },
    trunk_id: { key: "route_select", index: 4 },
    is_enabled: { key: "is_enabled", index: 5 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterCountry, setFilterCountry] = useState("");
  const [filterCapability, setFilterCapability] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [countries, setCountries] = useState([]);
  const [voiceEndpoints, setVoiceEndpoints] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVoiceRoutings = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      /* name: filterName ? filterName : undefined, */
    };
    NetworksService.listNumbersWithRoutings(params)
      .then((response) => {
        response.data = {
          count: 8,
          items: [
            {
              capability: 1,
              country: "Turkey(+90)",
              id: 7,
              number: "905320000025",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 3,
              country: "Turkey(+90)",
              id: 8,
              number: "905320000039",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 3,
              country: "Turkey(+90)",
              id: 9,
              number: "905320000070",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 2,
              country: "Turkey(+90)",
              id: 12,
              number: "905320000054",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 2,
              country: "Turkey(+90)",
              id: 11,
              number: "905320000001",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 3,
              country: "Turkey(+90)",
              id: 40,
              number: "905320000081",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 3,
              country: "Turkey(+90)",
              id: 2,
              number: "905331231239",
              number_type: "Mobile",
              trunk_id: 0,
            },
            {
              capability: 3,
              country: "Turkey(+90)",
              id: 10,
              number: "905320000024",
              number_type: "Mobile",
              trunk_id: 0,
            },
          ],
          page: 1,
          pages: 1,
        };
        let values = [];
        setTotalCount(response.data.count);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchVoiceRoutings();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  useEffect(() => {
    CountriesService.getCountries({ size: 100 })
      .then((response) => {
        const options = response.data.items.map((country) => ({
          value: country.country_id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });

    NetworksService.listSmsEndpoints({
      size: 100,
    }).then((response) => {
      response.data = {
        items: [
          {
            id: 1,
            name: "test",
          },
          {
            id: 2,
            name: "asd",
          },
        ],
      };
      const options = response.data.items.map((endpoint) => ({
        value: endpoint.id,
        label: endpoint.name,
      }));
      setVoiceEndpoints(options);
    });
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "capability") {
        formatted.push({
          ...d,
          value: getLabelByValue(Capability(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Checkbox
          checked={data[index].is_enabled}
          onChange={(e) => {
            data[index].is_enabled = !data[index].is_enabled;
          }}
        />
      </Stack>
    );
  };

  const handleRouteChange = (index, selectedRoute) => {
    const newData = [...data];
    newData[index][4].value = selectedRoute;
    setData(newData);
  };

  const routeDropdownSelect = (index) => {
    return (
      <FormControl sx={{ width: "90%" }}>
        <Select
          color="secondary"
          value={rowArrayToObject(data[index]).route_select}
          onChange={(event) => {
            handleRouteChange(index, event.target.value);
          }}
        >
          {
            <MenuItem key={"none"} value={0}>
              {t("default")}
            </MenuItem>
          }
          {voiceEndpoints.map((voiceEndpoint, idx) => {
            return (
              <MenuItem key={voiceEndpoint.value} value={voiceEndpoint.value}>
                {voiceEndpoint.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const handleSubmit = () => {
    setMessage("Failed to set routings");
    setSnackbarStatus(true);
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("number")}
                labelId="filter-number-label"
                name="number"
                color="secondary"
                value={filterPhoneNumber}
                onChange={(event) => {
                  setFilterPhoneNumber(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
                value={
                  countries.find((option) => option.value === filterCountry) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterCountry(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth sx={{ mt: "0.5em" }}>
              <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
              <Select
                label={t("capability")}
                labelId="filter-capability-label"
                name="capability"
                color="secondary"
                value={filterCapability}
                onChange={(event) => {
                  setFilterCapability(event.target.value);
                }}
              >
                {getSelectOptions(Capability())}
              </Select>
            </FormControl>
          </Grid>

          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchVoiceRoutings();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          onClick={() => handleSubmit()}
          loading={loading}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      </Box>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
        routeSelect={routeDropdownSelect}
      />
    </>
  );
}
