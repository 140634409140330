import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  Tooltip,
  Autocomplete,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { EngineService, PackagesService } from "src/api/services";
import { isMobile } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable } from "src/components/table";
import DisableAnyway from "src/components/DisableAnyway";
import BaseModal from "src/components/BaseModal";
import SaveIcon from "@mui/icons-material/Save";

export default function ConfigIotPackage({ formData, successCallback }) {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [engineOptions, setEngineOptions] = useState([]);
  const [openDisableAnyway, setOpenDisableAnyway] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "engines", label: t("engine") },
    { key: "enabled", label: t("Enabled") },
    { key: "actions", label: t("actions"), align: "right"},
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    engine_id: { key: "engines", index: 2 },
    is_enabled: { key: "enabled", index: 3 },
  };
  const makeTable = () => {
    setLoadingData(true);
    let items = [];
    for (const mapping of formData.service_engine_mappings) {
      let item = new Array(TABLE_HEAD.length - 1).fill({});

      let id = mapping.id;
      let name = formData.name;
      let engineID = mapping.engine ? mapping.engine.id : "";
      let is_enabled = formData.is_enabled;

      item[TABLE_FIELD_MAPPING["id"].index] = {
        ...TABLE_FIELD_MAPPING["id"],
        value: id,
      };
      item[TABLE_FIELD_MAPPING["name"].index] = {
        ...TABLE_FIELD_MAPPING["name"],
        value: name,
      };
      item[TABLE_FIELD_MAPPING["engine_id"].index] = {
        ...TABLE_FIELD_MAPPING["engine_id"],
        value: engineID,
      };

      item[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...TABLE_FIELD_MAPPING["is_enabled"],
        value: is_enabled,
      };

      items.push(item);
    }

    setData(items);
    if (initialData.length === 0) {
      const deepCopy = JSON.parse(JSON.stringify(items));
      setInitialData(deepCopy);
    }
    setLoadingData(false);
  };

  const iotEngineOptions = () => {
    EngineService.listEngines({ size: 100 })
      .then((response) => {
        if (response.status === 200) {
          let options = [];
          response.data.items.forEach((item) => {
            if (item.engine_subtype.service_group.id === 5 /*IOT ID = 5*/) {
              let pop = item.properties.pop;
              let engineSubtype = item.engine_subtype.name;
              let option = {
                value: item.id,
                label: pop + "/" + engineSubtype,
              };
              options.push(option);
            }
          });
          setEngineOptions(options);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    makeTable();
    iotEngineOptions();
  }, []);

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].value !== arr2[i].value) return false;
    }
    return true;
  };

  const enginesSelect = (index) => {
    return (
      <FormControl sx={{ width: isMobile() ? "40vw" : "20vw" }}>
        <Autocomplete
          options={engineOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-iot-engine")} />
          )}
          value={
            engineOptions.find(
              (option) => option.value === data[index][2].value
            ) || null
          }
          onChange={(event, newValue) => {
            const newData = [...data];
            newData[index][2].value = newValue?.value || null;
            setData(newData);
            let successMessage = t("iot-engine-changed-successfully");
            setMessage(successMessage);
            setSnackbarStatus(true);
            if (newValue === null) {
              newData[index][3].value = false;
              setData(newData);
            }
          }}
        />
      </FormControl>
    );
  };

  const handleSave = (index) => {
    let successMessage = t("changes-are-applied-successfully");
    let failMessage = t("failed-to-apply-changes");

    if (data[index][2].value !== initialData[index][2].value) {
      let payload = {
        id: data[index][0].value,
        engine_id: data[index][2].value,
        package_id: formData.id,
        is_enabled: true,
        service_id: null,
      };

      PackagesService.updateServiceEngineMapping(payload)
        .then((response) => {
          if (response.status === 200) {
            let newData = [...initialData];
            newData[index][2].value = data[index][2].value;
            setInitialData(newData);
          } else {
            throw "error update";
          }
        })
        .catch((err) => {});
    }

    if (data[index][3].value !== initialData[index][3].value) {
      let params = {
        is_enabled: data[index][3].value,
        proceed: false,
      };
      if (data[index][3].value) {
        if (data[index][2].value) {
          PackagesService.enableDisablePackage(formData.id, params)
            .then((response) => {
              if (response.status === 200) {
                setMessage(successMessage);
                setSnackbarStatus(true);
                let newData = [...initialData];
                newData[index][3].value = data[index][3].value;
                setInitialData(newData);
              } else {
                throw "errr";
              }
            })
            .catch((error) => {
              setMessage(failMessage);
              setSnackbarStatus(true);
            });
        } else {
          setMessage(t("you-must-choice-an-engine-first"));
          setSnackbarStatus(true);
        }
      } else {
        PackagesService.enableDisablePackage(formData.id, params)
          .then((response) => {
            if (response.status === 200) {
              setMessage(successMessage);
              setSnackbarStatus(true);
              let newData = [...initialData];
              newData[index][3].value = data[index][3].value;
              setInitialData(newData);
            } else {
              throw "errr";
            }
          })
          .catch((err) => {
            setTimeout(function () {
              if (
                err.response.data.detail ===
                "Package is already assigned to a container."
              ) {
                setOpenDisableAnyway(true);
              } else {
                setMessage(failMessage);
                setSnackbarStatus(true);
              }
            }, 800);
          });
      }
    }
  };

  const handleOnChangeEnabled = (index) => {
    if (!data[index][3].value && data[index][2].value === null) {
      setMessage(t("you-must-choice-an-engine-first"));
      setSnackbarStatus(true);
    } else {
      const newData = [...data];
      newData[index][3].value = !newData[index][3].value;
      setData(newData);
    }
  };

  const enableSwitch = (index) => {
    return (
      <>
        <Switch
          checked={data[index][3].value}
          onChange={() => handleOnChangeEnabled(index)}
        />
      </>
    );
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const disableCallback = () => {
    data[0][3].value = !data[0][3].value;
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("disable-iot-package")}
        open={openDisableAnyway}
        setOpen={setOpenDisableAnyway}
        children={
          <DisableAnyway
            successCallback={disableCallback}
            setModalStatus={setOpenDisableAnyway}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PackagesService.enableDisablePackage(formData.id, {
                is_enabled: false,
                proceed: true,
              })
            }
            name={t("iot-package")}
            usedIn={t("iot-container")}
          />
        }
      />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        pagination={{}}
        actionItemPrep={getActionItems}
        loadingData={loadingData}
        enableSwitch={enableSwitch}
        enginesSelect={enginesSelect}
      />
    </>
  );
}
