import api from "src/api/Api";
import urls from "src/api/Urls";

export const listCustomers = (params) => {
  return api.get(urls.list_customers, { params: params });
};

export const listCustomerWithDetails = (params) => {
  return api.get(urls.list_customers_with_details, { params: params });
};

export const createCustomer = (payload) => {
  return api.post(urls.create_customer, payload);
};

export const manageBalance = (id, payload) => {
  return api.post(urls.manage_customer_balance(id), payload);
};

export const setSubscriptionPackage = (id, payload) => {
  return api.patch(urls.set_subscription_package(id), payload);
};

export const addCustomer = (payload) => {
  return api.post(urls.add_customer, payload);
};
export const addCustomerWithoutUser = (payload) => {
  return api.post(urls.add_customer_without_user, payload);
};

export const deleteCustomer = (id) => {
  return api.delete(urls.delete_customer(id));
};


export const registerCustomerWithUser = (payload) => {
  return api.post(urls.register_customer_with_user, payload);
};