import dayjs, { Dayjs } from "dayjs";

export const rowArrayToObject = (row) => {
  let obj = {};
  row.map((d, idx) => {
    obj[d.key] = d.value;
  });
  return obj;
};

export const setFavIcon = (favicon) => {
  if (favicon) {
    const faviconComponent = document.getElementById("favicon");
    faviconComponent.href = "data:;base64," + favicon;
  }
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export function isTokenExpired(expiresAt) {

    const currentTime = Date.now() / 1000;
    return currentTime > parseInt(expiresAt);
  
}

export const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const dateFormatted = date.toLocaleDateString(undefined, options);
  const timeFormatted = date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${dateFormatted} ${timeFormatted}`;
};

export const formatDateInput = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS") : "";
};

export const isMobile = () => {
  return window.innerWidth <= 768;
};


export const canUserReadIt = (functionName) => {
  const adminData = JSON.parse(localStorage.getItem("admin"));
  const accesses = adminData?.user?.accesses;

  if (accesses) {
    for (let accessGroup of accesses) {
      const accessItem = accessGroup.find((item) => item.function_group_name === functionName);

      if (accessItem && accessItem.read_allowed) {
        return true;
      }
    }
  }
  return false;
}


export const canUserWriteIt = (functionName) => {
  const adminData = JSON.parse(localStorage.getItem("admin"));
  const accesses = adminData?.user?.accesses;

  if(accesses){
    for(let accessGroup of accesses){
      const accessItem = accessGroup.find((item) => item.function_group_name === functionName);
      if(accessItem && accessItem.write_allowed){
        return true;
      }
    }
  }
  return false;
}

export const isUserPartitionAdmin = () => {
  const adminData = JSON.parse(localStorage.getItem("admin"));

  return adminData?.user?.partition_admin;

}