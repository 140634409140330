import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Checkbox,
  Stack,
  Box,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  TextField,
  Autocomplete,
  Grid,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CountriesService,
  DidService,
  NumberService,
  UcManagementService,
} from "src/api/services";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";

const SearchButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function AddNumbersForm({
  formData,
  setModalStatus,
  successCallback,
  setMessage,
  setSnackbarStatus,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState(
    formData.numbers.map((item) => item.number.id)
  );

  const [filterNumber, setFilterNumber] = useState("");
  const [onlyOwneds, setOnlyOwneds] = useState(false);

  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [filterCountry, setFilterCountry] = useState(null);
  const [filterType, setFilterType] = useState("");

  const handleNumberToggle = (selectedNumberID) => {
    const updatedSelectedNumbers = selectedNumbers.includes(selectedNumberID)
      ? selectedNumbers.filter((item) => item !== selectedNumberID)
      : [...selectedNumbers, selectedNumberID];

    setSelectedNumbers(updatedSelectedNumbers);
  };

  const fetchNumbers = () => {
    setLoading(true);
    const params = {
      page: 1,
      size: 100,
    };
    NumberService.listNumbersAdmin(params)
      .then((response) => {
        setNumbers(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCountries = () => {
    CountriesService.getInboundCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchDidTypes = () => {
    DidService.listDidTypes({ size: 100 })
      .then((response) => {
        let didOptions = response.data.items.map((option) => ({
          label: option.name,
          value: option.id,
        }));
        setDidTypes(didOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchNumbers();
    fetchCountries();
    fetchDidTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedUcUsers: selectedNumbers,
    },

    onSubmit: (values, actions) => {
      let payload = selectedNumbers;

      let successMessage = t("numbers-add-to-uc-service-succes");
      let failMessage = t("numbers-add-to-uc-service-fail");

      UcManagementService.assignNumbersToService(formData.id, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();

            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={1} alignItems={"center"}>
            <Typography variant="h6" color="textSecondary">
              {t("numbers-contains")}
            </Typography>
            <Divider sx={{ mt: "1vh" }} />
            <Grid sx={{ alignItems: "center" }} container spacing={4}>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    value={filterNumber}
                    label={t("name")}
                    name="name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={(event) => {
                      setFilterNumber(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-filter"
                    options={countries.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label={t("country")} />
                    )}
                    value={
                      filterCountry !== null
                        ? { label: filterCountry.name, value: filterCountry.id }
                        : null
                    }
                    onChange={(event, newValue) => {
                      if (newValue && newValue.value) {
                        const selectedCountry = countries.find(
                          (country) => country.id === newValue.value
                        );
                        setFilterCountry(selectedCountry);
                      } else {
                        setFilterCountry(null);
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    options={didTypes}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField {...params} label={t("type")} />
                    )}
                    value={
                      didTypes.find((option) => option.value === filterType) ||
                      null
                    }
                    onChange={(event, newValue) => {
                      if (newValue && newValue.value) {
                        setFilterType(newValue.value);
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("assigned-numbers")}
                  </Typography>
                  <Switch
                    checked={onlyOwneds}
                    onChange={(e) => setOnlyOwneds(e.target.checked)}
                  />
                </Box>
              </Grid>
            </Grid>
            <Card sx={{ width: "100%", height: "40vh", overflow: "auto" }}>
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("number")}</TableCell>
                        <TableCell>{t("country")}</TableCell>
                        <TableCell>{t("type")}</TableCell>
                        <TableCell>{t("select")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {numbers
                        .filter((number) => {
                          const nameMatchedNumbers = number.number
                            .toLowerCase()
                            .includes(filterNumber.toLowerCase());
                          return onlyOwneds
                            ? selectedNumbers.includes(number.id) &&
                                nameMatchedNumbers
                            : nameMatchedNumbers;
                        })
                        .map((number) => (
                          <TableRow key={number.id}>
                            <TableCell>{number.number}</TableCell>
                            <TableCell>{number?.product?.country?.name}</TableCell>
                            <TableCell>{number?.product?.did_type?.name}</TableCell>
                            <TableCell>
                              <Checkbox
                                checked={selectedNumbers.includes(number.id)}
                                onChange={() => handleNumberToggle(number.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <Box>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {t("add-numbers")}
              </LoadingButton>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
