import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import copyOutline from "@iconify/icons-eva/copy-outline";
// app
import { useStore } from "src/store/Store";
import { ProfileService } from "src/api/services";
import BaseSnackbar from "../BaseSnackbar";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function ProfileDetailsForm() {
  const { t } = useTranslation();
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [showApiAccountId, setShowApiAccountId] = useState(false);
  const [store, dispatch] = useStore();
  const ProfileDetailsSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    last_name: Yup.string()
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
  });

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const formik = useFormik({
    initialValues: {
      first_name: store.user_type === "admin" ? store.admin.user.first_name : store.portal.user.first_name,
      last_name: store.user_type === "admin" ? store.admin.user.last_name : store.portal.user.last_name,
    },
    validationSchema: ProfileDetailsSchema,
    onSubmit: (values, actions) => {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
      };

      let apiService =
        store.user_type === "admin"
          ? ProfileService.updateAdminUserProfile(store.admin.user.id, payload)
          : ProfileService.updateCustomerUserProfile(store.portal.user.id, payload);

      apiService
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setMessage(t("profile-updated-successfully"));
              setShowNotification(true);
              actions.setSubmitting(false);
            }, 500);
            let updatedUser =  store.user_type === "admin" ? store.admin.user : store.portal.user;
            updatedUser.first_name = values.first_name;
            updatedUser.last_name = values.last_name;
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: updatedUser,
              },
            });
          } else {
            throw "profile could not be updated";
          }
        })
        .catch((err) => {
          setMessage(t("profile-could-not-be-updated"));
          setShowNotification(true);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={showNotification}
        message={message}
        setOpen={setShowNotification}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                required
                color="secondary"
                label={t("first-name")}
                {...getFieldProps("first_name")}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
            </FormControl>

            <FormControl>
              <TextField
                required
                color="secondary"
                label={t("last-name")}
                {...getFieldProps("last_name")}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </FormControl>

            <FormControl>
              <TextField
                color="secondary"
                type="email"
                label={t("email")}
                defaultValue={store.userType === "admin" ? store.admin.user.email : store.portal.user.email}
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField
                color="secondary"
                label={t("organization")}
                defaultValue={store.userType === "admin" ? store.admin.user.organization : store.portal.user.organization}
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField
                color="secondary"
                label={t("api-account-id")}
                /* defaultValue={store.user.api_account_id} */
                defaultValue={store.userType === "admin" ? store.admin.user.api_account_id : store.portal.user.api_account_id}
                type={showApiAccountId ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleCopyText(store.user.api_account_id)
                        }
                        edge="end"
                      >
                        <Icon icon={copyOutline} />
                      </IconButton>
                      <IconButton
                        onClick={() => setShowApiAccountId(!showApiAccountId)}
                        edge="end"
                      >
                        <Icon icon={showApiAccountId ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField
                color="secondary"
                label={t("api-key")}
               /*  defaultValue={store.user.api_key} */
                defaultValue={store.userType === "admin" ? store.admin.user.api_key : store.portal.user.api_key}
                type={showApiKey ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleCopyText(store.userType === "admin" ? store.admin.user.api_key : store.portal.user.api_key)}
                        edge="end"
                      >
                        <Icon icon={copyOutline} />
                      </IconButton>
                      <IconButton
                        onClick={() => setShowApiKey(!showApiKey)}
                        edge="end"
                      >
                        <Icon icon={showApiKey ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </FormControl>
          </Stack>
          <br />

          <Box style={{ textAlign: "right" }}>
            <LoadingButton
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t("update-profile")}
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
