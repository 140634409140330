import api from "src/api/Api";
import urls from "src/api/Urls";

export const addSmsTariff = (payload) => {
  return api.post(urls.add_sms_tariff, payload);
};

export const listSmsTariffs = (params) => {
  return api.get(urls.list_sms_tariffs, { params: params });
};

export const deleteSmsTariff = (id) => {
  return api.delete(urls.delete_sms_tariff(id));
};

export const updateSmsTariff = (id, payload) => {
  return api.put(urls.update_sms_tariff(id), payload);
};

export const addVoiceTariff = (payload) => {
  return api.post(urls.add_voice_tariff, payload);
};

export const listVoiceTariffs = (params) => {
  return api.get(urls.list_voice_tariffs, { params: params });
};

export const deleteVoiceTariff = (id) => {
  return api.delete(urls.delete_voice_tariff(id));
};

export const updateVoiceTariff = (id, payload) => {
  return api.put(urls.update_voice_tariff(id), payload);
};

export const addTTSTariff = (payload) => {
  return api.post(urls.add_tts_tariff, payload);
};

export const listTTSTariffs = (params) => {
  return api.get(urls.list_tts_tariffs, { params: params });
};

export const deleteTTSTariff = (id) => {
  return api.delete(urls.delete_tts_tariff(id));
};

export const updateTTSTariff = (id, payload) => {
  return api.put(urls.update_tts_tariff(id), payload);
};

export const addSTTTariff = (payload) => {
  return api.post(urls.add_stt_tariff, payload);
};

export const listSTTTariffs = (params) => {
  return api.get(urls.list_stt_tariffs, { params: params });
};

export const deleteSTTTariff = (id) => {
  return api.delete(urls.delete_stt_tariff(id));
};

export const updateSTTTariff = (id, payload) => {
  return api.put(urls.update_stt_tariff(id), payload);
};

//Numbers Tariff

export const addNumberTariff = (payload) => {
  return api.post(urls.add_number_tariff, payload);
};

export const listNumberTariffs = (params) => {
  return api.get(urls.list_number_tariffs, { params: params });
};

export const deleteNumberTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_number_tariff(id), { params });
};

export const updateNumberTariff = (id, payload) => {
  return api.patch(urls.update_number_tariff(id), payload);
};

export const addTFATariff = (payload) => {
  return api.post(urls.add_tfa_tariff, payload);
};

export const listTFATariffs = (params) => {
  return api.get(urls.list_tfa_tariffs, { params: params });
};

export const deleteTFATariff = (id) => {
  return api.delete(urls.delete_tfa_tariff(id));
};

export const updateTFATariff = (id, payload) => {
  return api.put(urls.update_tfa_tariff(id), payload);
};

export const addNumberMaskingTariff = (payload) => {
  return api.post(urls.add_number_masking_tariff, payload);
};

export const listNumberMaskingTariffs = (params) => {
  return api.get(urls.list_number_masking_tariffs, { params: params });
};

export const deleteNumberMaskingTariff = (id) => {
  return api.delete(urls.delete_number_masking_tariff(id));
};

export const updateNumberMaskingTariff = (id, payload) => {
  return api.put(urls.update_number_masking_tariff(id), payload);
};

//UCaaS Tariff

export const listUcassTariff = (params) => {
  return api.get(urls.list_ucass_tariffs, { params: params });
};

export const addUcassTariff = (payload) => {
  return api.post(urls.add_ucass_tariff, payload);
};

export const deleteUcassTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_ucass_tariff(id), { params });
};

export const updateUcassTariff = (id, payload) => {
  return api.patch(urls.update_ucass_tariff(id), payload);
};

//CPaaS Tariff

export const listCpaasTariff = (params) => {
  return api.get(urls.list_cpaas_tariffs, { params: params });
};

export const addCpaasTariff = (payload) => {
  return api.post(urls.add_cpaas_tariff, payload);
};

export const deleteCpaasTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_cpaas_tariff(id), { params });
};

export const updateCpaasTariff = (id, payload) => {
  return api.put(urls.update_cpaas_tariff(id), payload);
};

//Outbound Tariff Voice

export const listOutboundVoiceTariff = (params) => {
  return api.get(urls.list_outbound_voice_tariffs, { params: params });
};

export const addOutboundVoiceTariff = (payload) => {
  return api.post(urls.add_outbound_voice_tariff, payload);
};

export const deleteOutboundVoiceTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_voice_tariff(id), { params });
};

export const updateOutboundVoiceTariff = (id, payload) => {
  return api.put(urls.update_outbound_voice_tariff(id), payload);
};

//Outbound Tariff SMS

export const listOutboundSmsTariff = (params) => {
  return api.get(urls.list_outbound_sms_tariffs, { params: params });
};

export const addOutboundSmsTariff = (payload) => {
  return api.post(urls.add_outbound_sms_tariff, payload);
};

export const deleteOutboundSmsTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_sms_tariff(id), { params });
};

export const updateOutboundSmsTariff = (id, payload) => {
  return api.put(urls.update_outbound_sms_tariff(id), payload);
};

//Outbound Tariff Whatsapp

export const listOutboundWhatsappTariff = (params) => {
  return api.get(urls.list_outbound_whatsapp_tariffs, { params: params });
};

export const addOutboundWhatsappTariff = (payload) => {
  return api.post(urls.add_outbound_whatsapp_tariff, payload);
};

export const deleteOutboundWhatsappTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_whatsapp_tariff(id), { params });
};

export const updateOutboundWhatsappTariff = (id, payload) => {
  return api.put(urls.update_outbound_whatsapp_tariff(id), payload);
};

//Outbound Tariff Telegram

export const listOutboundTelegramTariff = (params) => {
  return api.get(urls.list_outbound_telegram_tariffs, { params: params });
};

export const addOutboundTelegramTariff = (payload) => {
  return api.post(urls.add_outbound_telegram_tariff, payload);
};

export const deleteOutboundTelegramTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_telegram_tariff(id), { params });
};

export const updateOutboundTelegramTariff = (id, payload) => {
  return api.put(urls.update_outbound_telegram_tariff(id), payload);
};

//Outbound Tariff Rcs

export const listOutboundRcsTariff = (params) => {
  return api.get(urls.list_outbound_rcs_tariffs, { params: params });
};

export const addOutboundRcsTariff = (payload) => {
  return api.post(urls.add_outbound_rcs_tariff, payload);
};

export const deleteOutboundRcsTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_rcs_tariff(id), { params });
};

export const updateOutboundRcsTariff = (id, payload) => {
  return api.put(urls.update_outbound_rcs_tariff(id), payload);
};

//Outbound Tariff GBC

export const listOutboundGbcTariff = (params) => {
  return api.get(urls.list_outbound_gbc_tariffs, { params: params });
};

export const addOutboundGbcTariff = (payload) => {
  return api.post(urls.add_outbound_gbc_tariff, payload);
};

export const deleteOutboundGbcTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_gbc_tariff(id), { params });
};

export const updateOutboundGbcTariff = (id, payload) => {
  return api.put(urls.update_outbound_gbc_tariff(id), payload);
};

//Outbound Tariff AppleMessage

export const listOutboundAppleMessageTariff = (params) => {
  return api.get(urls.list_outbound_apple_message_tariffs, { params: params });
};

export const addOutboundAppleMessageTariff = (payload) => {
  return api.post(urls.add_outbound_apple_message_tariff, payload);
};

export const deleteOutboundAppleMessageTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_outbound_apple_message_tariff(id), { params });
};

export const updateOutboundAppleMessageTariff = (id, payload) => {
  return api.put(urls.update_outbound_apple_message_tariff(id), payload);
};

//IOT Tariffs

export const listIotTariffs = (params) => {
  return api.get(urls.list_iot_tariffs, { params: params });
};

export const addIotTariff = (payload) => {
  return api.post(urls.add_iot_tariff, payload);
};

export const deleteOIotTariff = (id, params) => {
  params = params ? params : { proceed: false };
  return api.delete(urls.delete_iot_tariff(id), { params });
};

export const updateIotTariff = (id, payload) => {
  return api.put(urls.update_iot_tariff(id), payload);
};

//Outbound General Listing

export const listOutboundTariffs = () => {
  return api.get(urls.list_outbound_tariffs);
};
