import React from "react";
import { useState, useEffect } from "react";
import {
  Switch,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchButton from "src/components/buttons/SearchButton";
import { useTranslation } from "react-i18next";
import { ContainerService } from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "src/components/table";
import { rowArrayToObject } from "src/utils/Util";
import SaveIcon from "@mui/icons-material/Save";
import { useStore } from "src/store/Store";
import ConfigProducts from "./ConfigProducts";
import BaseModal from "src/components/BaseModal";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumberPackagesConfig({ formData }) {

  const [store] = useStore();
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [openProductMappingModal, setOpenProductMappingModal] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});

  const TABLE_HEAD = [
    { key: "enabled", label: t("enabled") },
    { key: "numbers_package_name", label: t("numbers-package") },
    { key: "map_products", label: t("config-products") },
    { key: "actions", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    is_enabled: { key: "enabled", index: 1 },
    numbers_package_name: { key: "numbers_package_name", index: 2 },
    map_products: { key: "map_products", index: 3 },
    numbers_package_id: { key: "numbers_package_id", index: 4, noRender: true },
    package: { key: "package", index: 5, noRender: true },

  };

  const makeTable = () => {
    let filteredPackages = [];
    ContainerService.getContainer(formData.id)
      .then((response) => {
        if (response.status === 200) {
          response.data.container_package_tariff_mappings.map((mapping) => {
            if (
              mapping.package.name
                .toLowerCase()
                .includes(filterName.toLowerCase())
            ) {
              filteredPackages.push(mapping);
            }
          });

          let extractedData = [];
          for (const mapping of filteredPackages.sort((a, b) => a.id - b.id)) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});


            let packageID = null;
            let packageName = "";
            if (mapping.package) {
              packageName = mapping.package.name;
              packageID = mapping.package.id;
            }

            if (mapping.package) {
              item[TABLE_FIELD_MAPPING["numbers_package_name"].index] = {
                ...TABLE_FIELD_MAPPING["numbers_package_name"],
                value: packageName,
              };
              item[TABLE_FIELD_MAPPING["numbers_package_id"].index] = {
                ...TABLE_FIELD_MAPPING["numbers_package_id"],
                value: packageID,
              };
              item[TABLE_FIELD_MAPPING["map_products"].index] = {
                ...TABLE_FIELD_MAPPING["map_products"],
                value: (
                  <Button
                    sx={{
                      color: store.palette.info.contrastText,
                      backgroundColor: store.palette.info.main,
                    }}
                    variant="contained"
                    size="small"
                    aria-label="view-contact"
                    onClick={() => {
                      setSelectedRow({
                        packageID: packageID,
                        packageName: packageName,
                        containerID: formData.id
                      });
                      setOpenProductMappingModal(true);
                    }}
                  >
                    {t("config-products")}
                  </Button>
                ),
              };
            }

            Object.entries(mapping).forEach(([key, value]) => {
              if (
                key in TABLE_FIELD_MAPPING &&
                key !== "numbers_package_name" &&
                key !== "numbers_package_id" &&
                mapping.package
              ) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });

            extractedData.push(item);
          }

          setData(extractedData);

          const deepCopy = JSON.parse(JSON.stringify(extractedData));
          setInitialData(deepCopy);
        } else {
          throw "error on getting container";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    makeTable();
  }, []);
  const handleOnChange = (index) => {
    let successMessage = data[index][1].value
      ? t("numbers-package-disabled-successfully")
      : t("numbers-package-enabled-successfully");

    data[index][1].value = !data[index][1].value;
    setMessage(successMessage);
    setSnackbarStatus(true);
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][1].value}
        onChange={() => handleOnChange(index)}
      />
    );
  };

  const handleSave = (index) => {
    let payload = {
      container_id: formData.id,
      package_id: data[index][4].value,
      number_tariff_id: null,
      is_enabled: data[index][1].value,
    };

    let successMessage = t("changes-are-applied-successfully");
    let failMessage = t("failed-to-apply-chaanges");

    ContainerService.updatePackageTariffMapping(data[index][0].value, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);

          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        } else {
          throw "err at update";
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("save-changes")}>
          <IconButton
            disabled={
              initialData.length === data.length
                ? arraysEqual(data[index], initialData[index])
                : ""
            }
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
       <BaseModal
       sx={{ minWidth: 500, width: "70%" }}
        title={t("config-products")}
        open={openProductMappingModal}
        setOpen={setOpenProductMappingModal}
        children={
          <ConfigProducts
            successCallback={console.log("success")}
            formData={selectedRow}
            setModalStatus={setOpenProductMappingModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterName}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                makeTable();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        actionItemPrep={getActionItems}
        pagination={{}}
        loadingData={loadingData}
        enableSwitch={enableSwitch}
      />
    </>
  );
}
