import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import AddNumberOrder from './numberManagement/numberOrders/AddNumberOrder';
import ListNumberOrders from './numberManagement/numberOrders/ListNumberOrders';
import { canUserWriteIt } from 'src/utils/Util';

// ----------------------------------------------------------------------


export default function NumberOrders() {
    const { t } = useTranslation();

    const TABS = [
       canUserWriteIt("numbers") ? { id: 0, label: t('new-number-order'), component: <AddNumberOrder /> } : null,
        { id: 1, label: t('list-number-orders'), component: <ListNumberOrders /> },
    ].filter(Boolean);

    const TITLE = t('number-orders');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
