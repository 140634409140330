import * as React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import * as Constants from "src/constants";

import { NumberMaskingService } from "src/api/services";
import { useTranslation } from "react-i18next";

export default function ServiceForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
  isProgrammable = false,
}) {
  const { t } = useTranslation();
  const ServiceSchema = Yup.object().shape({
    formType: Yup.string(),
    name: Yup.string()
      .min(4, t("too-short"))
      .max(48, t("too-long"))
      .required(t("name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    status: Yup.string().required(t("status-must-be-selected")),
    default_time_to_live: Yup.number().required(t("gurad-time-is-required")),
    callback_url: Yup.string(),
    selection_behaviour: Yup.string().required(
      t("selection-behaviour-must-be-selected")
    ),
    intercept_callback_url: Yup.string(),
    out_of_session_callback_url: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      formType: formType,
      name: formData.name || "",
      status:
        (formData.status !== null && formData.status !== undefined
          ? formData.status.toString()
          : formData.status) || "1",
      default_time_to_live: formData.default_time_to_live || 180,
      callback_url: formData.callback_url || "",
      selection_behaviour:
        (formData.selection_behaviour !== null &&
        formData.selection_behaviour !== undefined
          ? formData.selection_behaviour.toString()
          : formData.selection_behaviour) || "1",
      intercept_callback_url: formData.intercept_callback_url || "",
      out_of_session_callback_url: formData.out_of_session_callback_url || "",
    },
    validationSchema: ServiceSchema,
    onSubmit: (values, actions) => {
      let payload = {
        name: values.name,
        status: values.status,
        selection_behaviour: values.selection_behaviour,
        default_time_to_live: values.default_time_to_live,
        callback_url: values.callback_url,
        intercept_callback_url: values.intercept_callback_url,
        out_of_session_callback_url: values.out_of_session_callback_url,
      };
      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = NumberMaskingService.addService(payload);
        successMessage = t("new-service-has-been-successfully-added");
        failMessage = t("new-service-could-not-be-added");
      } else {
        apiService = NumberMaskingService.updateService(formData.id, payload);
        successMessage = t("has-been-successfully-updated", {
          name: formData.name,
        });
        failMessage = t("could-not-be-updated", { name: formData.name });
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback(response);
            }
            actions.setSubmitting(false);
          } else {
            throw "number masking service operation failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("name")}
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      );
    }
    if (fieldName === "status") {
      return Constants.getRadioButtonComponent(
        Constants.StatusActiveInactive(),
        {
          ...getFieldProps("status"),
          disabled: formType === "view" ? true : false,
        },
        t("common.__i18n_ally_root__.status")
      );
    }
    if (fieldName === "default_time_to_live") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("default-time-to-live")}
          {...getFieldProps("default_time_to_live")}
          error={Boolean(
            touched.default_time_to_live && errors.default_time_to_live
          )}
          helperText={
            touched.default_time_to_live && errors.default_time_to_live
          }
        />
      );
    }
    if (fieldName === "callback_url") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("callback-url")}
          {...getFieldProps("callback_url")}
          error={Boolean(touched.callback_url && errors.callback_url)}
          helperText={touched.callback_url && errors.callback_url}
        />
      );
    }
    if (fieldName === "selection_behaviour") {
      return Constants.getRadioButtonComponent(
        Constants.NumberSelectionBehaviour(),
        {
          ...getFieldProps("selection_behaviour"),
          disabled: formType === "view" ? true : false,
        },
        t("selection-behaviuor")
      );
    }
    if (fieldName === "intercept_callback_url") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("intercept-callback-url")}
          {...getFieldProps("intercept_callback_url")}
          error={Boolean(
            touched.intercept_callback_url && errors.intercept_callback_url
          )}
          helperText={
            touched.intercept_callback_url && errors.intercept_callback_url
          }
        />
      );
    }
    if (fieldName === "out_of_session_callback_url") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label={t("out-of-session-callback-url")}
          {...getFieldProps("out_of_session_callback_url")}
          error={Boolean(
            touched.out_of_session_callback_url &&
              errors.out_of_session_callback_url
          )}
          helperText={
            touched.out_of_session_callback_url &&
            errors.out_of_session_callback_url
          }
        />
      );
    }
    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          disabled={formType === "view" ? true : false}
          loading={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("status")}
            {getFieldByName("default_time_to_live")}
            {getFieldByName("callback_url")}
            {getFieldByName("selection_behaviour")}
            {getFieldByName("intercept_callback_url")}
            {getFieldByName("out_of_session_callback_url")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
