import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import { Account, Custom } from "./accounts/";
import CpassEngine from "./engines/CpassEngine";
import UcassEngine from "./engines/UcassEngine";
import InboundProviders from "./engines/InboundProviders";
import IotEngine from "./engines/IotEngines";
import { useState, useEffect } from "react";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------

export default function Accounts() {
  const [store] = useStore();
  const { t } = useTranslation();
  const [serviceGroups] = useState(store.services.serviceGroups ?? []);

  console.log(serviceGroups);
  const TABS = [];

  if (serviceGroups.includes(1)) {
    TABS.push({ id: 0, label: t("cpaas-engines"), component: <CpassEngine /> });
  }
  if (serviceGroups.includes(2)) {
    TABS.push({ id: 1, label: t("ucaas-engines"), component: <UcassEngine /> });
  }
  if (serviceGroups.includes(3)) {
    TABS.push({
      id: 2,
      label: t("inbound-providers"),
      component: <InboundProviders />,
    });
  }
  if (serviceGroups.includes(5)) {
    TABS.push({ id: 4, label: t("iot-engines"), component: <IotEngine /> });
  }

  TABS.push({
    id: 3,
    label: t("operator-network-node"),
    component: <Custom />,
  });

  /*   if (TABS.length === 0) {
    return null;
  } */

  const TITLE = t("common.engines.title");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
