import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import { appSidebarConfig, adminSidebarConfig } from "./SidebarConfig";
import i18n from "src/i18n";
import { useStore } from "src/store/Store";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

AppSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function AppSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isAdminLayout = false,
}) {
  const { pathname } = useLocation();
  const [store] = useStore();
  const [logo, setLogo] = useState("");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (store.services?.style?.logo) {
      setLogo(store.services.style.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.services.style]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        component={RouterLink}
        to= {store.userType === "admin" ? "/admin" : "/" }
        sx={{
          /* width: "85%", */
          /* marginTop: 2, */
          marginBottom: -2,

        }}
      >
        <Logo src={logo} />
      </Box>
      <NavSection
        navConfig={isAdminLayout ? adminSidebarConfig() : appSidebarConfig()}
      />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: "secondary.main" },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "secondary.main",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
