import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import ManageSmsNetwork from "./networkings/sms/ManageSmsNetwork";
import SmsRouting from "./networkings/sms/SmsRouting";

// ----------------------------------------------------------------------

export default function PurchaseIots() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("manage"), component: <ManageSmsNetwork /> },
    { id: 1, label: t("routing"), component: <SmsRouting /> },
  ];

  const TITLE = t("sms-network");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
