import api from "src/api/Api";
import urls from "src/api/Urls";

export const login = (payload) => {
  return api.post(urls.login, payload);
};

export const adminLogin = (payload) => {
  return api.post(urls.admin_login, payload);
};

export const getProfile = (id) => {
  return api.get(urls.get_profile(id));
};

export const updateAdminUserProfile = (id, payload) => {
  return api.patch(urls.update_admin_user_profile(id), payload);
};

export const changeAdminUserPassword = (id, payload) => {
  return api.put(urls.change_admin_user_password(id), payload);
};


export const updatePreferredLandingView = (id, payload) => {
  return api.put(urls.update_preferred_landing_view(id), payload);
};

export const updateAlwaysAskLandingPage = (id, payload) => {
  return api.put(urls.update_always_ask_landing_page(id), payload);
};

export const updateServiceGroups = (payload) => {
  return api.put(urls.update_service_groups, payload);
};


export const adminAcceptInvitation = (payload) => {
  return api.post(urls.admin_accept_invitation, payload);
};


//Customer

export const updateCustomerUserProfile = (id, payload) => {
  return api.put(urls.update_customer_user_profile(id), payload);
};

export const changeCustomerUserPassword = (id, payload) => {
  return api.put(urls.change_customer_user_password(id), payload);
};
