import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject, formatDate } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import {
  TariffService,
  CountriesService,
  OutboundRatesService,
} from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import OutboundRateForm from "../forms/OutboundRateForm";
import DeleteOutboundRateForm from "../forms/DeleteOutboundRateForm";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function OutboundSmsRates({ tariffID }) {
  const { t } = useTranslation();
  const rateType = "sms";
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [countries, setCountries] = useState([]);
  /* const [filterCountry, setCountry] = useState(null); */
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [tariffOptions, setTariffOptions] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState(
    tariffID ? tariffID : null
  );

  const TABLE_HEAD = [
    { key: "prefix", label: t("prefix") },
    { key: "currency", label: t("currency") },
    { key: "price", label: t("price") },
    { key: "effective_start", label: t("effective-start") },
    { key: "effective_end", label: t("effective-end") },
    { key: "action", label: t("actions"), align: "right", padding: "2.5%" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    destination_prefix: { key: "prefix", index: 1 },
    currency: { key: "currency", index: 2 },
    price: { key: "price", index: 3 },
    effective_start: { key: "effectiveStart", index: 4 },
    effective_end: { key: "effectiveEnd", index: 5 },
    currency_id: { key: "currency_id", index: 6, noRender: true },
  };

  const fetchRates = () => {
    if (selectedTariff) {
      setLoadingData(true);
      OutboundRatesService.getOutboundSmsRate(selectedTariff)
        .then((response) => {
          let items = [];
          for (const idx in response.data.rates) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});
            const itemData = response.data.rates[idx];
            if (itemData.currency) {
              let currencyName = itemData.currency.short_code;
              let currencyID = itemData.currency.id;
              item[TABLE_FIELD_MAPPING["currency"].index] = {
                ...TABLE_FIELD_MAPPING["currency"],
                value: currencyName,
              };
              item[TABLE_FIELD_MAPPING["currency_id"].index] = {
                ...TABLE_FIELD_MAPPING["currency_id"],
                value: currencyID,
              };
            }
            let notMaps = ["country", "currency", "country_id", "currency_id"];
            Object.entries(response.data.rates[idx]).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING && !notMaps.includes(key)) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            items.push(item);
          }
          setData(items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  };

  useEffect(() => {
    fetchRates();

    return () => {
      setData([]);
    };
  }, [paginationData, selectedTariff]);

  useEffect(() => {
    CountriesService.getOutboundCountries({ size: 100 })
      .then((response) => {
        let active_countries = [];
        response.data.map((item) => {
          if (item.is_active) {
            active_countries.push(item);
          }
        });
        setCountries(active_countries);
      })
      .catch((err) => {
        console.log(err);
      });

    TariffService.listOutboundSmsTariff()
      .then((response) => {
        if (response.status === 200) {
          const options = response.data.items.map((tariff) => ({
            value: tariff.id,
            label: tariff.name,
          }));
          setTariffOptions(options);
        } else {
          throw "list tariff names failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "effectiveStart") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "effectiveEnd") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      if (!selectedTariff) {
        setMessage(t("please-select-tariff"));
        setSnackbarStatus(true);
      } else {
        setAddTariffModalStatus(!openAddTariffModal);
      }
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-rate")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-tariff"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-rate")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-sms-tariff"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          minHeight: "20%",
          maxHeight: "98%",
          width: "35%",
          overflowY: "auto",
        }}
        title={t("add-outbound-sms-rate")}
        open={openAddTariffModal}
        setOpen={setAddTariffModalStatus}
        children={
          <OutboundRateForm
            selectedTariff={selectedTariff}
            rateType={rateType}
            successCallback={fetchRates}
            formType="add"
            formData={{}}
            setModalStatus={setAddTariffModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        sx={{
          minHeight: "20%",
          maxHeight: "98%",
          width: "35%",
          overflowY: "auto",
        }}
        title={t("edit-outbound-sms-rate")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <OutboundRateForm
            selectedTariff={selectedTariff}
            rateType={rateType}
            formType="edit"
            successCallback={fetchRates}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            countries={countries}
          />
        }
      />
      <BaseModal
        title={t("delete-outbound-sms-rate")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteOutboundRateForm
            rateType={rateType}
            successCallback={fetchRates}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={4} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tariffOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label={t("outbound-sms-tariff")} />
              )}
              value={
                tariffOptions.find(
                  (option) => option.value === selectedTariff
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedTariff(newValue?.value || null);
              }}
            />
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchRates();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-outbound-rate-0")}
        onClick={() => modalHandler("add")}
      />
      <br />
      {selectedTariff ? (
        <BaseTable
          head={TABLE_HEAD}
          data={[...data].map((d, idx) => {
            return formatRowData(d);
          })}
          actionItemPrep={getActionItems}
          pagination={{}}
          loadingData={loadingData}
        />
      ) : (
        <StyledBox>{t("select-tariff")}</StyledBox>
      )}
    </>
  );
}
