import React from "react";
import { useState, useEffect } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { FeatureService } from "src/api/services";
import { DefaultPaginationData } from "src/constants";
import BaseModal from "src/components/BaseModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { rowArrayToObject } from "src/utils/Util";
import BaseSnackbar from "src/components/BaseSnackbar";
import { BaseTable } from "src/components/table";
import { FeatureForm, DeleteFeatureForm } from "./Forms";

export default function Feature() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openAddModal, setAddModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const TABLE_HEAD = [
    { key: "name", label: t("feature") },
    { key: "action", label: t("actions"), align: "right", padding: "1.8em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
  };

  const fetchFeatures = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    FeatureService.listFeatures(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.entries(response.data.items[idx]).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchFeatures();
  }, [paginationData]);

  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => (
    <>
      <Tooltip title={t("edit-feature")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="edit-feature"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-feature")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="delete-feature"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );  

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("edit-feature")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <FeatureForm
            successCallback={fetchFeatures}
            formType="edit"
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("add-ucass-feature")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <FeatureForm
            formType="add"
            successCallback={fetchFeatures}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-feature")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteFeatureForm
            successCallback={fetchFeatures}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          sx={{
            width: "20%",
          }}
          size="large"
          type="submit"
          variant="contained"
          onClick={() => setAddModalStatus(true)}
        >
          {t("add-new-feature")}
        </LoadingButton>
      </Box>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={data}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
