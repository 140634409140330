import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Box,
  InputLabel,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import { NumberService, UcManagementService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { canUserReadIt, isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

export default function UcUserForm({
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
  formType,
  formData,
  customerOptions,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [numberOptions, setNumberOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    formType === "add" ? formData.customer : formData.customer.id || null
  );
  const [selectedUcService, setSelectedUcService] = useState(formData.id);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    email:
      formType === "add"
        ? Yup.string()
            .email(t("form.__i18n_ally_root__.validation.email"))
            .required(t("email-is-required"))
        : Yup.string().email(t("form.__i18n_ally_root__.validation.email")),
    ucUsername: Yup.string()
      .nullable()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      /*  .required(t("last-name-is-required")) */
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    password: Yup.string()
      .nullable()
      .min(8, t("too-short-3"))
      .max(32, t("password-long-error")),
  });

  useEffect(() => {
    NumberService.listNumbersAdmin({
      customer_id: selectedCustomer,
      page: 1,
      size: 100,
    })
      .then((response) => {
        if (response.status === 200) {
          const numberOptions = response.data.items.filter((item) => !item.is_assigned).map((item) => ({
            value: item.id,
            label: item.number,
          }));
          setNumberOptions(numberOptions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCustomer]);

  useEffect(() => {
    if (formType === "add") {
      let params = {
        customer_id: selectedCustomer,
        page: 1,
        size: 100,
      };
      UcManagementService.listUcServices(params)
        .then((response) => {
          if (response.status === 200) {
            let services = response.data.items.map((service) => ({
              value: service.id,
              label: service.name,
            }));
            setServiceOptions(services);
          } else {
            throw "list error";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedCustomer]);

  const formik = useFormik({
    initialValues: {
      firstName: formData.first_name || "",
      lastName: formData.last_name || "",
      email: formData.email || "",
      ucUsername: formData.uc_username || null,
      password: null,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      let payload;

      payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        customer_id: selectedCustomer,
      };

      if (values.ucUsername) {
        payload["uc_username"] = values.ucUsername;
      }
      if (values.ucUsername) {
        payload["uc_password"] = values.password;
      }
      if (selectedUcService) {
        payload["uc_service_id"] = selectedUcService;
      }
      if (selectedNumber) {
        payload["number_id"] = selectedNumber;
      }

      let apiService, successMessage, failMessage;

      if (formType === "add" || formType === "addToService") {
        apiService = UcManagementService.addUcUser(payload);
        successMessage = t("uc-user-added-successfully");
        failMessage = t("uc-user-add-fail");
      } else if (formType === "edit") {
        apiService = UcManagementService.updateUcUser(formData.id, payload);
        successMessage = t("uc-user-updated-successfully");
        failMessage = t("uc-user-update-fail");
      } else if (formType === "addCredentials") {
        apiService = UcManagementService.updateUcUser(formData.id, payload);
        successMessage = t("credentials-added-successfully");
        failMessage = t("uc-user-credentials-fail");
      } else if (formType === "assignNumber") {
        apiService = UcManagementService.updateUcUser(formData.id, payload);
        successMessage = t("number-assign-succes");
        failMessage = t("number-assign-fail");
      }
      apiService
        .then((response) => {
          if (response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw t("register-failed");
          }
        })
        .catch((err) => {

          if (
            err.response?.status === 400 &&
            err.response?.data?.detail === "Not enough seats available"
          ) {
            setMessage(t("not-enough-seat-available"));
            setTimeout(() => {
              actions.setSubmitting(false);
            }, [1000])
            
          } else {
            setMessage(failMessage);
          }
          setSnackbarStatus(true);
        })
        .finally(() => {
            actions.setSubmitting(false);
          });
    },
  });

  const getFieldByName = (fieldName) => {
    if (
      fieldName === "customers" && (formType === "add" || formType === "addToService")
    ) {
      const content =
        formType === "addToService" ? (
          <Box textAlign={"start"}>
            {!isMobile() && (
              <InputLabel
                htmlFor="customerName"
                style={{
                  color: "secondary",
                  marginLeft: "0.2rem",
                  fontWeight: 500,
                }}
              >
                {t("selected-customer")}:
              </InputLabel>
            )}
            <TextField
              fullWidth
              disabled
              id="customerName"
              value={formData.customer.name}
              sx={{ mr: 2 }}
            />
          </Box>
        ) : (
          <Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-customer")}
                  sx={{ backgroundColor: "white" }}
                />
              )}
              value={
                customerOptions.find(
                  (option) => option.value === selectedCustomer
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue?.value || null);
              }}
            />
          </Box>
        );
      return content;
    }

    if (fieldName === "services" && formType === "addToService") {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="serviceName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("selected-uc-service")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            disabled
            id="serviceName"
            value={formData.name}
            sx={{ mr: 2 }}
          />
        </Box>
      );
    }

    if (fieldName === "serviceDropdown" && formType === "add") {
      return (
        <>
          <Autocomplete
            disablePortal
            disabled={selectedCustomer === null}
            id="combo-box-demo"
            options={serviceOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={t("select-uc-service")} />
            )}
            value={
              serviceOptions.find(
                (option) => option.value === selectedUcService
              ) || null
            }
            onChange={(event, newValue) => {
              setSelectedUcService(newValue?.value || null);
            }}
          />
        </>
      );
    }

    if (
      fieldName === "firstName" &&
      formType !== "addCredentials" &&
      formType !== "assignNumber"
    ) {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="firstName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("first-name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="firstName"
            label={isMobile() ? t("first-name") : undefined}
            placeholder={t("type-first-name-here")}
            {...getFieldProps("firstName")}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            sx={{ mr: 2 }}
          />
        </Box>
      );
    }
    if (
      fieldName === "lastName" &&
      formType !== "addCredentials" &&
      formType !== "assignNumber"
    ) {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="lastName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("last-name")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="lastName"
            label={isMobile() ? t("last-name-0") : undefined}
            placeholder={t("type-last-name-here")}
            {...getFieldProps("lastName")}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Box>
      );
    }
    if (
      fieldName === "email" &&
      formType !== "edit" &&
      formType !== "addCredentials" &&
      formType !== "assignNumber"
    ) {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="email"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("email")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            type="email"
            id="email"
            label={isMobile() ? t("email-address") : undefined}
            placeholder={t("type-email-here")}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Box>
      );
    }
    if (
      fieldName === "ucUserName" &&
      formType !== "edit" &&
      formType !== "assignNumber"
    ) {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="ucUserName"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("uc-username")}:
            </InputLabel>
          )}

          <TextField
            fullWidth
            id="ucUserName"
            label={isMobile() ? t("uc-username") : undefined}
            placeholder={t("type-uc-username-here")}
            {...getFieldProps("ucUsername")}
            error={Boolean(touched.ucUsername && errors.ucUsername)}
            helperText={touched.ucUsername && errors.ucUsername}
          />
        </Box>
      );
    }
    if (
      fieldName === "ucPassword" &&
      formType !== "edit" &&
      formType !== "assignNumber"
    ) {
      return (
        <Box textAlign={"start"}>
          {!isMobile() && (
            <InputLabel
              htmlFor="ucPassword"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("uc-password")}:
            </InputLabel>
          )}

          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            id="ucPassword"
            label={isMobile() ? t("uc-password") : undefined}
            placeholder={t("type-uc-password-here")}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
      );
    }

    if (
      fieldName === "numbers" &&
      formType !== "edit" &&
      formType !== "addCredentials"
    ) {
      return (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={numberOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("select-number")} />
          )}
          value={
            numberOptions.find((option) => option.value === selectedNumber) ||
            null
          }
          onChange={(event, newValue) => {
            setSelectedNumber(newValue?.value || null);
          }}
        />
      );
    }

    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {formType !== "addCredentials"
            ? formType !== "edit"
              ? formType !== "assignNumber"
                ? t("add-uc-user")
                : t("assign-number")
              : t("update")
            : t("add-credentials")}
        </LoadingButton>
      );
    }
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("customers")}
            {getFieldByName("services")}
            {getFieldByName("firstName")}
            {getFieldByName("lastName")}
            {getFieldByName("email")}

            {formType === "add" && (
              <>
                <Divider />
                <Typography>{t("optionals")}</Typography>
                <Divider />
              </>
            )}
            {getFieldByName("ucUserName")}
            {getFieldByName("ucPassword")}
            {canUserReadIt("numbers") && getFieldByName("numbers")}
            {getFieldByName("serviceDropdown")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
