import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import IotPackage from "./iotConfig/iotPackages/IotPackage";

// ----------------------------------------------------------------------

export default function IotPackages() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("manage-iot-package"), component: <IotPackage /> },
  ];

  const TITLE = t("iot-package");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
