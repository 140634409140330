import api from "src/api/Api";
import urls from "src/api/Urls";

export const listVoiceConfiguration = (params) => {
    return api.get(urls.list_voice_configuration, { params: params });
}

export const addVoiceConfiguration = (payload) => {
    return api.post(urls.add_voice_configuration, payload);
}

export const getVoiceConfiguration = (id) => {
    return api.get(urls.get_voice_configuration(id));
}

export const updateVoiceConfiguration = (id, payload) => {
    return api.put(urls.update_voice_configuration(id), payload);
}

export const deleteVoiceConfiguration = (id) => {
    return api.delete(urls.delete_voice_configuration(id));
}

export const listSMSConfiguration = (params) => {
    return api.get(urls.list_sms_configuration, { params: params });
}

export const addSMSConfiguration = (payload) => {
    return api.post(urls.add_sms_configuration, payload);
}
export const getSmsConfiguration = (id) => {
    return api.get(urls.get_sms_configuration(id));
}

export const updateSMSConfiguration = (id, payload) => {
    return api.put(urls.update_sms_configuration(id), payload);
}

export const deleteSMSConfiguration = (id) => {
    return api.delete(urls.delete_sms_configuration(id));
}
