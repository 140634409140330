import { useState } from 'react';
import { styled, Tabs, Tab, Box } from '@mui/material';
import {useTranslation} from 'react-i18next';
import OutboundAppleMsgTariffs from './OutboundAppleMsgTariffs';
import OutboundAppleMsgRates from './OutboundAppleMsgRates';

// ----------------------------------------------------------------------

const TabStyle = styled(Tab)(({ theme }) => ({
    color: theme.palette.secondary.main,
    padding: "0 10px",
    minHeight: "25px",
    '&:hover': {
        color: theme.palette.secondary.lightmost,
    },
    marginLeft: 8,
}));

export default function OutboundAppleMessage() {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [tariffID, setTariffID] = useState(null);

    const changeTab = (number, id) => {
        setTariffID(id);
        setTabValue(number);
        
    }

    let name = "Apple";

    const tabs = [
        {id: 0, label: t('outbound-apple-message-tariff', {name}), component: <OutboundAppleMsgTariffs changeTab={changeTab}/>},
        {id: 1, label: t('outbound-apple-rates-0', {name}), component: <OutboundAppleMsgRates tariffID={tariffID} />},
        
    ];

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    }

    return (
        <div sx={{ width: "100%" }}>
            <Tabs
                sx={{ ml: 3, '& .MuiTabs-indicator': {bottom: "15px"} }}
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons={false}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="nav tabs">
                {tabs.map(item => {
                    return <TabStyle label={item.label} selected={tabValue === item.id} />
                })}
            </Tabs>
            <div role="tabpanel" id={`tabpanel-${tabValue}`} aria-labelledby={`tab-${tabValue}`} >
                <Box sx={{ pl: 3, flexGrow: 1 }}>
                    {tabs[tabValue].component}
                </Box>
            </div>
        </div>
    );
}
