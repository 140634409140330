import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import {
  CustomerService,
  PasswordResetService,
  ProfileService,
  SiteConfigService,
} from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ type }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t("user-not-found"));
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("password-is-required"))
      .min(8, t("too-short-3"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
  });

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const token = urlParams.get("token");

  const formik = useFormik({
    initialValues: {
      email: email || "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        email: values.email,
        password: values.password,
        token: token || "",
      };

      if (values.password !== values.confirmPassword) {
        setLoginMessage(t("passwords-do-not-match"));
        setOpenSnackbar(true);
        setSubmitting(false);
        return;
      }

      let apiService =
        type == "customer"
          ? PasswordResetService.customerPasswordReset(payload)
          : PasswordResetService.adminPasswordReset(payload);

      apiService
        .then((response) => {
          if (response.status !== 200) {
            throw { message: "reset password failed", response: response };
          }

          navigate("/login");
        })
        .catch((err) => {
          setLoginMessage(err.response.data.detail);
          setOpenSnackbar(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={loginMessage}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label={t("password")}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label={t("confirm-password")}
              {...getFieldProps("confirmPassword")}
              error={Boolean(
                touched.confirmPassword &&
                  (errors.confirmPassword ||
                    formik.values.password !== formik.values.confirmPassword)
              )}
              helperText={
                (touched.confirmPassword && errors.confirmPassword) ||
                (formik.values.password !== formik.values.confirmPassword &&
                  t("passwords-do-not-match"))
              }
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("reset-password")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
