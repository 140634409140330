import api from "src/api/Api";
import urls from "src/api/Urls";

/* export const addCurrency = (payload) => {
  return api.post(urls.addcurrency, payload);
};

export const deleteCurrency = (id) => {
  return api.delete(urls.deletecurrency(id));
};
 */

export const listCurrencies = (params) => {
  return api.get(urls.partitionCurrencies, { params: params });
};

export const setCurrencies = (payload) => {
  return api.put(urls.set_partition_currencies, payload);
};
