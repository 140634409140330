import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  InputLabel,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import SearchButton from "src/components/buttons/SearchButton";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import {
  Capability,
  DefaultPaginationData,
  DidType,
  NumberStatus,
  getLabelByValue,
  getSelectOptions,
} from "src/constants/index";
import BaseSnackbar from "src/components/BaseSnackbar";
import {
  CountriesService,
  DidService,
  FilterService,
  NumberService,
} from "src/api/services";
import { useTranslation } from "react-i18next";
import DeallocateForm from "./forms/DeallocateForm";
import PortOutForm from "./forms/PortOutForm";
import { useStore } from "src/store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function YourNumber() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "country", label: t("country") },
    { key: "capability", label: t("capability") },
    { key: "did", label: t("number") },
    { key: "type", label: t("type") },
    { key: "status", label: t("status") },
    { key: "action", label: t("actions"), align: "center", padding: "1rem" },
  ];

  const TABLE_FIELD_MAPPING = {
    did_id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    country: { key: "country", index: 1 },
    capability: { key: "capability", index: 2 },
    did: { key: "did", index: 3 },
    type: { key: "type", index: 4 },
    status: { key: "status", index: 5 },
  };

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterCapability, setFilterCapability] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
  const [openPortOutModal, setPortOutModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [countries, setCountries] = useState([]);
  const [didTypes, setDidTypes] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [store] = useStore();

  const fetchNumbers = () => {
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      country: filterCountry ? filterCountry : undefined,
      capability: filterCapability ? filterCapability : undefined,
      type: filterType ? filterType : undefined,
      phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
    };
    setLoadingData(true);

    NumberService.listNumbers(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.count);

        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.translated_did) {
            let translatedDid = itemData.translated_did;
            item[TABLE_FIELD_MAPPING["did"].index] = {
              ...TABLE_FIELD_MAPPING["did"],
              value: translatedDid,
            };
          } else {
            let did = itemData.did;
            item[TABLE_FIELD_MAPPING["did"].index] = {
              ...TABLE_FIELD_MAPPING["did"],
              value: did,
            };
          }

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "did") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    FilterService.listFilterInboundCountries()
      .then((response) => {
        const options = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });

    FilterService.listFilterDidTypes()
      .then((response) => {
        const options = response.data.map((did) => ({
          value: did.id,
          label: did.name,
        }));
        setDidTypes(options);
      })
      .catch((err) => {
        console.log(err);
      });

      FilterService.listFilterInboundCountries()
      .then((response) => {
        const options = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchNumbers();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "capability") {
        formatted.push({
          ...d,
          value: getLabelByValue(Capability(), d.value.toString()),
        });
      } else if (d.key === "type") {
        formatted.push({
          ...d,
          value: getLabelByValue(DidType(), d.value.toString()),
        });
      } else if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(NumberStatus(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "dealllocate") {
      setDeallocateModalStatus(!openDeallocateModal);
    } else if (modalType === "portOut") {
      setPortOutModalStatus(!openPortOutModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2%",
          }}
        >
          <Button
            sx={{
              color: store.palette.warning.contrastText,
              backgroundColor: store.palette.warning.main,
            }}
            variant="contained"
            size="small"
            aria-label="view-contact"
            onClick={() => modalHandler("dealllocate", index)}
          >
            {t("deallocate")}
          </Button>

          <Button
            sx={{
              color: store.palette.info.contrastText,
              backgroundColor: store.palette.info.main,
            }}
            variant="contained"
            size="small"
            aria-label="view-contact"
            onClick={() => modalHandler("portOut", index)}
          >
            {t("port-out")}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("deallocate-request")}
        open={openDeallocateModal}
        setOpen={setDeallocateModalStatus}
        children={
          <DeallocateForm
            formType="deallocate"
            successCallback={fetchNumbers}
            formData={selectedRow}
            setModalStatus={setDeallocateModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("port-out-request")}
        open={openPortOutModal}
        setOpen={setPortOutModalStatus}
        children={
          <PortOutForm
            formType="portOut"
            successCallback={fetchNumbers}
            formData={selectedRow}
            setModalStatus={setPortOutModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer style={{ marginBottom: 25 }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("country")} />
                )}
                value={
                  countries.find((option) => option.value === filterCountry) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterCountry(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth sx={{ mt: "0.5em" }}>
              <InputLabel id="filter-type-label">{t("capability")}</InputLabel>
              <Select
                label={t("capability")}
                labelId="filter-capability-label"
                name="capability"
                color="secondary"
                value={filterCapability}
                onChange={(event) => {
                  setFilterCapability(event.target.value);
                }}
              >
                {getSelectOptions(Capability())}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={didTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={t("type")} />
                )}
                value={
                  didTypes.find((option) => option.value === filterType) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    setFilterType(newValue.value);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12} sx={{ mt: "0.5em" }}>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">{t("status")}</InputLabel>
              <Select
                label={t("status")}
                labelId="filter-status-label"
                name="status"
                color="secondary"
                value={filterStatus}
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
              >
                {getSelectOptions(NumberStatus())}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("phone-number")}
                labelId="filter-number-label"
                name="number"
                color="secondary"
                onKeyDown={(event) => {
                  if (
                    event.key !== "Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "." &&
                    event.key !== "ArrowLeft" &&
                    event.key !== "ArrowRight" &&
                    !/[0-9]/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => event.preventDefault()}
                inputProps={{ min: 0 }}
                value={filterPhoneNumber}
                onChange={(event) => {
                  setFilterPhoneNumber(event.target.value);
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchNumbers();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
