import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";

import YourNumber from "./numberServices/yourNumbers/YourNumber";
import SharedNumber from "./numberServices/yourNumbers/SharedNumber";
// ----------------------------------------------------------------------

export default function YourNumbers() {
  const { t } = useTranslation();

  const TABS = [
    { id: 0, label: t("your-numbers"), component: <YourNumber /> },
    { id: 1, label: t("shared-number"), component: <SharedNumber /> },
  ];

  const TITLE = t("number");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
