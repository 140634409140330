import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  TextField,
  InputLabel,
  Typography,
  Box,
  Grid,
  Divider,
  Paper,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  OutboundRatesService,
  CurrencyService,
} from "src/api/services";
import { useTranslation } from "react-i18next";
import { formatDateInput, isMobile } from "src/utils/Util";
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

/* const StyledLabel = styled(Inp) */

export default function OutboundWpRateForm({
  selectedTariff,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
  countries,
}) {

  const { t } = useTranslation();
  const [effectiveStartDate, setEffectiveStartDate] = useState(
    formData ? dayjs(formData.effectiveStart) : dayjs()
  );
  const [effectiveEndDate, setEffectiveEndDate] = useState(
    formData ? dayjs(formData.effectiveEnd) : dayjs()
  );

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    formData.currency_id || null
  );
  const [selectedCountry, setSelectedCountry] = useState(
    formData.country_id || null
  );
  const OutboundWpTariffSchema = Yup.object().shape({
    prefix: Yup.number()
      .test("digits-only", t("only-digits-allowed"), (val) => /^\d+$/.test(val))
      /* .max(5, t("prefix-must-be-less-than-5")) */
      .required(t("prefix-is-required")),
    marketingPrice: Yup.number()
      .moreThan(0, t("price-must-be-greater-than-zero"))
      .required(t("price-is-required")),
    utilityPrice: Yup.number()
      .moreThan(0, t("price-must-be-greater-than-zero"))
      .required(t("price-is-required")),
    servicePrice: Yup.number()
      .moreThan(0, t("price-must-be-greater-than-zero"))
      .required(t("price-is-required")),
    authenticationPrice: Yup.number()
      .moreThan(0, t("price-must-be-greater-than-zero"))
      .required(t("price-is-required")),
  });

  const fetchCurrencies = () => {
    CurrencyService.listCurrencies({ size: 100 })
      .then((response) => {
        let currencyOptions = Object.values(response.data)
          .filter((currency) => currency.is_active)
          .map((currency) => ({
            value: currency.currency_id,
            label: currency.name,
          }));
        setCurrencies(currencyOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const formik = useFormik({
    initialValues: {
      currency: formData.currency_id || null,
      country: formData.country_id || null,
      prefix: formData.prefix || "",
      marketingPrice: formData.marketting_price,
      utilityPrice: formData.utility_price,
      servicePrice: formData.service_price,
      authenticationPrice: formData.authentication_price,

      effectiveStart: formData.effectiveStart
        ? dayjs(formData.effectiveStart)
        : dayjs(),
      effectiveEnd: formData.effectiveEnd
        ? dayjs(formData.effectiveEnd)
        : dayjs(),
    },
    validationSchema: OutboundWpTariffSchema,
    onSubmit: (values, actions) => {
      const payload = {
        currency_id: selectedCurrency,
        country_id: selectedCountry,
        destination_prefix: values.prefix,
        marketing_price: values.marketingPrice,
        utility_price: values.utilityPrice,
        service_price: values.servicePrice,
        authentication_price: values.authenticationPrice,
        effective_start: formatDateInput(values.effectiveStart),
        effective_end: formatDateInput(values.effectiveEnd),
        tariff_id: selectedTariff,
      };

      let apiService, successMessage, failMessage;
      let name = "WhatsApp";
      successMessage = formType === "add" ? t("outbound-tariff-rate-add-success", { name }) : t("outbound-tariff-rate-update-success", { name });
      failMessage = formType === "add" ? t("outbound-tariff-rate-add-fail", { name }) : t("outbound-tariff-rate-update-fail", { name });
      apiService =
        formType === "add"
          ? OutboundRatesService.addOutboundWhatsappRate(payload)
          : OutboundRatesService.updateOutboundWhatsappRate(
              formData.id,
              payload
            );

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw "tariff operation failed";
          }
        })
        .catch((err) => {
          
          setMessage(failMessage);
          setSnackbarStatus(true);
          setModalStatus(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const getFieldByName = (fieldName) => {
    if (fieldName === "currency") {
      return (
        <Autocomplete
          disablePortal
          id="currencies"
          options={currencies}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("currency")} />
          )}
          value={
            currencies.find((option) => option.value === selectedCurrency) ||
            null
          }
          onChange={(event, newValue) => {
            setSelectedCurrency(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "country") {
      return (
        <Autocomplete
          disablePortal
          id="countries"
          options={countries}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={t("country")} />
          )}
          value={
            countries.find((option) => option.value === selectedCountry) || null
          }
          onChange={(event, newValue) => {
            setSelectedCountry(newValue?.value || null);
          }}
        />
      );
    }
    if (fieldName === "prefix") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="prefix"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("prefix")}:
            </InputLabel>
          )}
          <TextField
            id="prefix"
            fullWidth
            type="number"
            {...getFieldProps("prefix")}
            placeholder={t("type-prefix-here")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.prefix && errors.prefix)}
            helperText={touched.prefix && errors.prefix}
          />
        </Box>
      );
    }
    if (fieldName === "marketingPrice") {
      return (
        <Box>
          <InputLabel
            htmlFor="marketingPrice"
            style={{ marginRight: "10px", colo: "secondary" }}
          >
            {t("marketing-price")}:
          </InputLabel>
          <TextField
            placeholder="0.0"
            fullWidth
            type="number"
            id="marketingPrice"
            inputMode="numeric"
            {...getFieldProps("marketingPrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Tab" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.marketingPrice && errors.marketingPrice)}
            helperText={touched.marketingPrice && errors.marketingPrice}
          />
        </Box>
      );
    }

    if (fieldName === "utilityPrice") {
      return (
        <Box>
          <InputLabel
            htmlFor="utilityPrice"
            style={{ marginRight: "10px", color: "secondary" }}
          >
            {t("utility-price")}:
          </InputLabel>
          <TextField
            placeholder="0.0"
            fullWidth
            id="utilityPrice"
            type="number"
            {...getFieldProps("utilityPrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Tab" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.utilityPrice && errors.utilityPrice)}
            helperText={touched.utilityPrice && errors.utilityPrice}
          />
        </Box>
      );
    }
    if (fieldName === "servicePrice") {
      return (
        <Box>
          <InputLabel
            htmlFor="servicePrice"
            style={{ marginRight: "10px", color: "secondary" }}
          >
            {t("service-price")}:
          </InputLabel>
          <TextField
            fullWidth
            id="servicePrice"
            type="number"
            placeholder="0.0"
            {...getFieldProps("servicePrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                event.key !== "Tab" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(touched.servicePrice && errors.servicePrice)}
            helperText={touched.servicePrice && errors.servicePrice}
          />
        </Box>
      );
    }
    if (fieldName === "authenticationPrice") {
      return (
        <Box>
          <InputLabel
            htmlFor="authenticationPrice"
            style={{ marginRight: "10px", color: "secondary" }}
          >
            {t("authentication-price")}:
          </InputLabel>
          <TextField
            fullWidth
            type="number"
            id="authenticationPrice"
            placeholder="0.0"
            {...getFieldProps("authenticationPrice")}
            onKeyDown={(event) => {
              if (
                event.key !== "Backspace" &&
                event.key !== "Tab" &&
                event.key !== "." &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight" &&
                !/[0-9]/.test(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(event) => event.preventDefault()}
            inputProps={{ min: 0 }}
            error={Boolean(
              touched.authenticationPrice && errors.authenticationPrice
            )}
            helperText={
              touched.authenticationPrice && errors.authenticationPrice
            }
          />
        </Box>
      );
    }
    if (fieldName === "effectiveStart") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-start-date")}
            value={effectiveStartDate}
            onChange={(newValue) => {
              setEffectiveStartDate(newValue);
              setFieldValue("effectiveStart", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }
    if (fieldName === "effectiveEnd") {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("effective-end-date")}
            value={effectiveEndDate}
            onChange={(newValue) => {
              setEffectiveEndDate(newValue);
              setFieldValue("effectiveEnd", newValue.toISOString());
            }}
            minDate={effectiveStartDate}
          />
        </LocalizationProvider>
      );
    }

    if (fieldName === "submitButton") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          startIcon={<SaveIcon />}
        >
          {t("common.__i18n_ally_root__.save")}
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Paper sx={{ maxHeight: "80vh", overflow: "auto", padding: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {getFieldByName("prefix")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("country")}
              </Grid>
              <Grid item xs={12} sm={12}>
                {getFieldByName("currency")}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("effectiveStart")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("effectiveEnd")}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography color={"secondary"}>
                  {t("pricing-plans")}
                </Typography>
                <Divider sx={{ mt: 1.5 }} />
              </Grid>
              <Grid container spacing={3} item xs={12} sm={12}>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("marketingPrice")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("utilityPrice")}
                </Grid>
              </Grid>
              <Grid container item spacing={3} xs={12} sm={12}>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("servicePrice")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getFieldByName("authenticationPrice")}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <br />
          {getFieldByName("submitButton")}
        </Form>
      </FormikProvider>
    </>
  );
}
