import * as React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, Autocomplete } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { NumberMaskingService } from "src/api/services";

export default function SessionForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  formType = "add",
  successCallback,
  isProgrammable = false,
}) {
  const { t } = useTranslation();

  const SessionSchema = Yup.object().shape({
    formType: Yup.string(),
    name: Yup.string()
      .min(4, "Too short!")
      .max(48, "Too long!")
      .required("Name is required")
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    default_time_to_live: Yup.number().required("Gurad time is required!"),
    masking_number: Yup.string()
      .required("Masking number must be selected!")
      .matches(/^[a-zA-Z0-9]*$/, "Masking number must be alphanumeric"),
    first_phone_number: Yup.string()
      .required("First phone number must be selected!")
      .matches(/^[a-zA-Z0-9]*$/, "First phone must be alphanumeric"),
    second_phone_number: Yup.string()
      .required("Second phone number must be selected!")
      .matches(/^[a-zA-Z0-9]*$/, "Second phone must be alphanumeric"),
    // masking_number: Yup.string().required('Status must be selected!').matches(/^[a-zA-Z0-9]*$/, "Masking number must be alphanumeric"),
    // uid: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      formType: formType,
      name: formData.name || "",
      default_time_to_live: formData.default_time_to_live || 180,
      service: formData.service || "",
      masking_number: formData.masking_number_value || "",
      first_phone_number: formData.first_phone_number || "",
      second_phone_number: formData.second_phone_number || "",
    },
    validationSchema: SessionSchema,
    onSubmit: (values, actions) => {
      let payload = {
        name: values.name,
        default_time_to_live: values.default_time_to_live,
        service: formData.service,
        masking_number: values.masking_number,
        first_phone_number: values.first_phone_number,
        second_phone_number: values.second_phone_number,
      };
      console.log("payload", payload);
      let apiService, successMessage, failMessage;
      if (formType === "add") {
        apiService = NumberMaskingService.addMaskingNumberSession(payload);
        successMessage =
          "New number masking session has been successfully added";
        failMessage = "New number masking session could not be added";
      } else {
        apiService = NumberMaskingService.updateMaskingNumber(
          formData.id,
          payload
        );
        successMessage = `${formData.name} has been successfully updated`;
        failMessage = `${formData.name} could not be updated`;
      }

      apiService
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
            if (successCallback) {
              successCallback(response);
            }
            actions.setSubmitting(false);
          } else {
            throw "number masking session operation failed";
          }
        })
        .catch((err) => {
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
          if (setModalStatus) {
            setModalStatus(false);
          }
        });
    },
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [numbers, setNumbers] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    let items = [];
    formData.numbers.forEach((number) => {
      items.push(number.masking_number);
    });
    setNumbers(items);
  }, []);

  const getFieldByName = (fieldName) => {
    if (fieldName === "name") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label="Name"
          {...getFieldProps("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      );
    }
    if (fieldName === "default_time_to_live") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label="Default Time to Live"
          {...getFieldProps("default_time_to_live")}
          error={Boolean(
            touched.default_time_to_live && errors.default_time_to_live
          )}
          helperText={
            touched.default_time_to_live && errors.default_time_to_live
          }
        />
      );
    }
    if (fieldName === "masking_number") {
      return (
        <Autocomplete
          disabled={formType === "view" ? true : false}
          value={values.masking_number}
          options={numbers}
          disableListWrap
          renderInput={(params) => (
            <TextField
              {...params}
              label="Masking Numbers"
              error={Boolean(touched.masking_number && errors.masking_number)}
              helperText={touched.masking_number && errors.masking_number}
            />
          )}
          onChange={(event, value) => setFieldValue("masking_number", value)}
        />
      );
    }
    if (fieldName === "first_phone_number") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label="First Phone Number"
          {...getFieldProps("first_phone_number")}
          error={Boolean(
            touched.first_phone_number && errors.first_phone_number
          )}
          helperText={touched.first_phone_number && errors.first_phone_number}
        />
      );
    }
    if (fieldName === "second_phone_number") {
      return (
        <TextField
          fullWidth
          disabled={formType === "view" ? true : false}
          label="Second Phone Number"
          {...getFieldProps("second_phone_number")}
          error={Boolean(
            touched.second_phone_number && errors.second_phone_number
          )}
          helperText={touched.second_phone_number && errors.second_phone_number}
        />
      );
    }
    if (fieldName === "submitButton" && formType !== "view") {
      return (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          disabled={formType === "view" ? true : false}
          loading={isSubmitting}
          endIcon={formType === "test" ? <SendIcon /> : null}
          startIcon={formType !== "test" ? <SaveIcon /> : null}
        >
          Save
        </LoadingButton>
      );
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {getFieldByName("name")}
            {getFieldByName("default_time_to_live")}
            {getFieldByName("masking_number")}
            {getFieldByName("first_phone_number")}
            {getFieldByName("second_phone_number")}
          </Stack>
          <br />
          {getFieldByName("submitButton")}
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
