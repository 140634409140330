import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { NetworksService, UserService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import VoiceEndpointForm from "./forms/VoiceEndpointForm";
import DeleteVoiceEndpoint from "./forms/DeleteVoiceEndpoint";

export default function ManageVoiceNetwork() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "host_address", label: t("host-address") },
    { key: "ib_port", label: t("ib-port") },
    { key: "ib_priority", label: t("ib-priority") },
    { key: "weight", label: t("weight") },
    { key: "outbound_port", label: t("outbound-port") },
    { key: "provisioning_status", label: t("provisioning-status") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    name: { key: "name", index: 0 },
    host_address: { key: "host_address", index: 1 },
    ib_port: { key: "ib_port", index: 2 },
    ib_priority: { key: "ib_priority", index: 3 },
    weight: { key: "weight", index: 4 },
    outbound_port: { key: "outbound_port", index: 5 },
    provisioning_status: { key: "provisioning_status", index: 6 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchVoiceEndpoints = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      /* name: filterName ? filterName : undefined, */
    };
    NetworksService.listVoiceEndpoints(params)
      .then((response) => {
        let values = [];
        response.data = {
          count: 2,
          items: [
            {
              id: 1,
              name: "voiceEndpoint1",
              host_address: "192.168.123.132",
              ib_port: "8090",
              ib_priority: "1",
              weight: "3",
              outbound_port: "7090",
              provisioning_status: "Active",
            },
            {
              id: 2,
              name: "voiceEndpointTest2",
              host_address: "192.168.127.101",
              ib_port: "5090",
              ib_priority: "2",
              weight: "2",
              outbound_port: "1020",
              provisioning_status: "Active",
            },
          ],
        };
        setTotalCount(response.data.count);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "role") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchVoiceEndpoints();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit-voice-endpoint")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-voice-endpoint"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-endpoint"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          maxHeight: "100%",
          minHeight: "20%",
          overflowY: "auto",
          width: "45%",
        }}
        title={t("add-voice-endpoint")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <VoiceEndpointForm
            formType={"add"}
            formData={{}}
            successCallback={fetchVoiceEndpoints}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          maxHeight: "100%",
          minHeight: "20%",
          overflowY: "auto",
          width: "45%",
        }}
        title={t("edit-voice-endpoint")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <VoiceEndpointForm
            formType={"edit"}
            formData={selectedRow}
            successCallback={fetchVoiceEndpoints}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("delete-voice-endpoint")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteVoiceEndpoint
            successCallback={fetchVoiceEndpoints}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container>
          <Grid item md={6} xs={12}>
            <Box textAlign={"left"} padding={2}>
              <Typography>
                <strong>{t("results")} : </strong>
                {t("total-records", { count: totalCount })}
              </Typography>
              <Typography mt={1}>
                <strong>{t("signalling-ip")} :</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <AddNewButton
              label={t("add-sip-trunk")}
              onClick={() => modalHandler("add")}
            />
          </Grid>
        </Grid>
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
