import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import CpaasPackage from './cpaasConfig/cpaasPackages/CpaasPackage';

// ----------------------------------------------------------------------


export default function CpaasPackages() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('manage-cpaas-package'), component: <CpaasPackage /> },
    ];

    const TITLE = t('cpaas-package');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
