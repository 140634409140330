import { useState, useEffect } from "react";
import { Stack, IconButton, Tooltip, Grid, FormControl, TextField } from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LinkIcon from "@mui/icons-material/Link";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseModal from "src/components/BaseModal";
import { rowArrayToObject } from "src/utils/Util";
import { DefaultPaginationData } from "src/constants/index";
import {
  CustomerOrderService,
  OrdersService,
  PackagesService,
  TariffService,
} from "src/api/services";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import UcPurchaseForm from "./form/UcPurchaseForm";
import { Link } from "react-router-dom";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));


export default function UcPurchase() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");

  const [openSubscribeModal, setSubscribeModalStatus] = useState(false);
  const [openViewModal, setViewModalStatus] = useState(false);
  const [openUpgradeModal, setUpgradeModalStatus] = useState(false);
  const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [filterName, setFilterName] = useState(null);
  const [subscribedPackages, setSubscribedPackages] = useState([]);

  const TABLE_HEAD = [
    { key: "name", label: t("package-name") },
    { key: "min_seat_count", label: t("min-seat-count") },
    { key: "max_seat_count", label: t("max-seat-count") },
    { key: "monthly_pricing", label: t("monthly-pricing") },
    { key: "annual_pricing", label: t("annual-pricing") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    min_seat_count: { key: "min_seat_count", index: 2 },
    max_seat_count: { key: "max_seat_count", index: 3 },
    initial_price_monthly: { key: "monthly_pricing", index: 4 },
    initial_price_annual: { key: "annual_pricing", index: 5 },
    description: { key: "description", index: 6, noRender: true },
    features: { key: "features", index: 7, noRender: true },
    service_usage_info: { key: "service_usage_info", index: 8, noRender: true },
  };

  const fetchPackages = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterName ? filterName : undefined,
    };
    CustomerOrderService.listOrderableUcPackages(params)
      .then((response) => {
        let values = [];
        let subscribedPackageIds = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          const monthlyPrice = itemData?.tariff?.seat_monthly_price;
          const annualPrice = itemData?.tariff?.seat_annual_price;

          item[TABLE_FIELD_MAPPING["initial_price_monthly"].index] = {
            ...TABLE_FIELD_MAPPING["initial_price_monthly"],
            value: monthlyPrice,
          };

          item[TABLE_FIELD_MAPPING["initial_price_annual"].index] = {
            ...TABLE_FIELD_MAPPING["initial_price_annual"],
            value: annualPrice,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "monthly_pricing" &&
              key !== "annual_pricing"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);

          if (itemData.in_use) {
            subscribedPackageIds.push(itemData.id);
          }
        }
        setData(values);
        setSubscribedPackages(subscribedPackageIds);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPackages();
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddTariffModalStatus(!openAddTariffModal);
    } else if (modalType === "subscribe") {
      setSubscribeModalStatus(!openSubscribeModal);
    } else if (modalType === "view") {
      setViewModalStatus(!openViewModal);
    } else if (modalType === "upgrade") {
      setUpgradeModalStatus(!openUpgradeModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const subsPackagesContain = (value) => {
    for (let i = 0; i < subscribedPackages.length; i++) {
      if (subscribedPackages[i] === value) return true;
    }
    return false;
  };

  const getActionItems = (index) => {
    let icon;

    if (subsPackagesContain(parseInt(data[index][0].value))) {
      icon = (
        <>
          <Tooltip title={t("upgrade-subscription")}>
            <IconButton
              color="secondary"
              size="small"
              onClick={() => modalHandler("upgrade", index)}
            >
              <UpgradeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("link-config")}>
            <IconButton color="warning" size="small">
              <Link to="/ucc/config">
                <LinkIcon />
              </Link>
            </IconButton>
          </Tooltip>
        </>
      );
    } else {
      icon = (
        <Tooltip title={t("subscribe-package")}>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => modalHandler("subscribe", index)}
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Stack direction="row">
        <Tooltip title={t("view-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="view-package"
            onClick={() => modalHandler("view", index)}
          >
            <EyeIcon />
          </IconButton>
        </Tooltip>
        {icon}
      </Stack>
    );
  };

  const onClickName = (index) => {
    modalHandler("view", index);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={"#" + selectedRow.name}
        open={openViewModal}
        setOpen={setViewModalStatus}
        children={
          <UcPurchaseForm
            formType="view"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setViewModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          minHeight: "20%",
          overflowY: "auto",
          width: "35%",
        }}
        title={t("subscribe")}
        open={openSubscribeModal}
        setOpen={setSubscribeModalStatus}
        children={
          <UcPurchaseForm
            formType="subscribe"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setSubscribeModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          minHeight: "20%",
          height: "100%",
          overflowY: "auto",
          width: "35%",
        }}
        title={t("upgrade-uc")}
        open={openUpgradeModal}
        setOpen={setUpgradeModalStatus}
        children={
          <UcPurchaseForm
            formType="upgrade"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setUpgradeModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                label={t("package-name")}
                value={filterName}
                onChange={(event) => {
                  setFilterName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <SearchButtonContainer>
              <SearchButton
                onClick={() => {
                  fetchPackages();
                }}
              />
            </SearchButtonContainer>
          </Grid>
        </Grid>
      </TableFilterContainer>
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        onClickName={onClickName}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
        subscribedPackageIds={subscribedPackages}
      />
    </>
  );
}
