import { useState, useEffect } from "react";
import { Box, Checkbox, ListItemText, Card, Switch } from "@mui/material";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { AccessManagementService, AccountService, PartitionService } from "src/api/services";
import { useFormik, Form, FormikProvider } from "formik";
import {
  CpassEngineTypes,
  IotEngineTypes,
  NumbersEngineTypes,
  OutboundEngineTypes,
  ServiceGroups,
  UcEngineTypes,
  getLabelByValue,
} from "src/constants";

export default function ManagePartitionServices({ id }) {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [checkedServiceGroups, setCheckedServiceGroups] = useState([]);
  const [checkedEngines, setCheckedEngines] = useState([]);
  const [selectableEngines, setSelectableEngines] = useState([]);
  const [creditControl, setCreditControl] = useState(false);
  const [data, setData] = useState({})

  useEffect(() => {
    let params = {
      partition_id: id,
    };
    AccessManagementService.getPartitionServiceGroups(params)
      .then((response) => {
        const ids = Object.values(response.data).map(
          (serviceGroup) => serviceGroup.id
        );
        setCheckedServiceGroups(ids);
        console.log(ids);
      })
      .catch((err) => {
        console.error(err);
      });

    AccountService.listPartitions({size: 100,})
      .then((response) => {
        response.data.items.map((partition) => {
          if (partition.id === parseInt(id)) {
            setData(partition);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  const engineTypesMap = {
    CPaaS: CpassEngineTypes(),
    UCaaS: UcEngineTypes(),
    Numbers: NumbersEngineTypes(),
    Outbounds: OutboundEngineTypes(),
    IOT: IotEngineTypes(),
  };

  const formik = useFormik({
    initialValues: {
      serviceGroups: checkedServiceGroups,
      engines: checkedEngines,
    },

    onSubmit: (values, actions) => {
      const payload = {
        serviceGroups: values.serviceGroups,
        engines: values.engines,
        credit_control: creditControl,
      };

      let successMessage = t("partition-configured-successfully");
      let failMessage = t("failed-to-configure-partition");

      PartitionService.managePartitionService(id, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
              setMessage(successMessage);
              setSnackbarStatus(true);
            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
            setMessage(failMessage);
            setSnackbarStatus(true);
        });
    },
  });

  const SelectableEngines = () => {
    let serviceGroupsLabels = checkedServiceGroups.map((serviceGroup) =>
      getLabelByValue(ServiceGroups(), serviceGroup)
    );

    let updatedSelectableEngines = [];

    serviceGroupsLabels.forEach((serviceGroupName) => {
      if (engineTypesMap[serviceGroupName]) {
        updatedSelectableEngines = [
          ...updatedSelectableEngines,
          ...engineTypesMap[serviceGroupName].map((engine) => engine.value),
        ];
      }
    });

    setSelectableEngines(updatedSelectableEngines);
  };
  useEffect(() => {
    formik.setValues({
      serviceGroups: checkedServiceGroups,
      engines: checkedEngines,
    });
  }, [checkedServiceGroups, checkedEngines]);
  useEffect(() => {
    SelectableEngines();
  }, [checkedServiceGroups]);

  const handleServiceGroupToggle = (serviceGroupID) => {
    let params = {
      partition_id: parseInt(id),
      service_group_id: serviceGroupID,
      enabled: !checkedServiceGroups.includes(serviceGroupID),
    };

    let successMessage = t("partition-configured-successfully");
    let failMessage = t("failed-to-configure-partition");

    AccessManagementService.updatePartitionServiceGroup(params)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
      });

    const updatedCheckedServiceGroups = checkedServiceGroups.includes(
      serviceGroupID
    )
      ? checkedServiceGroups.filter((id) => id !== serviceGroupID)
      : [...checkedServiceGroups, serviceGroupID];
    setCheckedServiceGroups(updatedCheckedServiceGroups);
  };

  const handleEngineToggle = (EngineID) => {
    const updatedCheckedEngines = checkedEngines.includes(EngineID)
      ? checkedEngines.filter((id) => id !== EngineID)
      : [...checkedEngines, EngineID];
    setCheckedEngines(updatedCheckedEngines);
  };

  const generateEngineContent = (engineTypes) => {
    return engineTypes.map((engine) => (
      <div key={engine.value}>
        {selectableEngines.includes(engine.value) ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={checkedEngines.includes(engine.value)}
              onChange={() => handleEngineToggle(engine.value)}
            />
            <ListItemText primary={engine.label} />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={false} disabled />
            <ListItemText secondary={engine.label} />
          </div>
        )}
      </div>
    ));
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <Box>
        <h1>#{id} {data.name}</h1>
      </Box>

      <Card sx={{ padding: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Switch
            checked={creditControl}
            onChange={(e) => setCreditControl(e.target.checked)} //Coming
            inputProps={{ "aria-label": "controlled" }}
          />
          <span>{t("credit-control-0")}</span>
        </Box>
        <h2 style={{ textAlign: "center" }}>{t("service-groups")}</h2>
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))",
              gridGap: "10px",
              alignSelf: "center",
              p: 4,
              paddingLeft: "6%",
            }}
          >
            {ServiceGroups().map((service) => (
              <div
                key={service.value}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={checkedServiceGroups.includes(
                    parseInt(service.value)
                  )}
                  onChange={() =>
                    handleServiceGroupToggle(parseInt(service.value))
                  }
                />
                <ListItemText primary={service.label} />
              </div>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            padding: 4,
            pl: "4%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gridGap: "10px",
            alignSelf: "center",
            gap: 10,
          }}
        >
          {Object.keys(engineTypesMap).map((serviceGroupLabel, index) => (
            <Box key={serviceGroupLabel}>
              <h2>{serviceGroupLabel}</h2>
              {generateEngineContent(engineTypesMap[serviceGroupLabel])}
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
}
