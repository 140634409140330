import { useEffect, useState } from "react";

import {
  Box,
  Switch,
  Grid,
  Typography,
  Autocomplete,
  Paper,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";

import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from "src/components/buttons/SearchButton";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  AccessManagementService,
  CountriesService,
  PackagesService,
  ServiceGroupsService,
} from "src/api/services";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useStore } from "src/store/Store";
import { canUserWriteIt } from "src/utils/Util";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function PortalSettings() {
  const { t } = useTranslation();

  const [store, dispatch] = useStore();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [registrationRequiredON, setRegistrationRequired] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [initalPackage, setInitialPackage] = useState(null);

  useEffect(() => {
    setRegistrationRequired(store.services.customerRegistration);
  }, [store]);

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "enabled", label: t("Enabled"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: {
      key: "id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    name: { key: "name", index: 1 },
    enabled: { key: "enabled", index: 2 },
  };

  const fetchServiceGroups = () => {
    setLoadingData(true);
    AccessManagementService.getAdminServiceGroups()
      .then((response) => {
        const sortedData = Object.values(response.data)
          .sort((a, b) => a.id - b.id)
          .map((itemData) => {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            Object.entries(itemData).forEach(([key, value]) => {
              if (key in TABLE_FIELD_MAPPING) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            return item;
          });
        setData(sortedData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const fetchSubscriptionPackages = () => {
    PackagesService.listSubscriptionPackagesWithoutPagination()
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.map((subsPackage) => ({
            value: subsPackage.id,
            label: subsPackage.name,
            isDefault: subsPackage.is_default,
          }));
          setPackages(options);
          const defaultPackage = options.find((item) => item.isDefault);
          setSelectedPackage(defaultPackage.value);
          setInitialPackage(defaultPackage.value);
        } else {
          throw new Error("list iot containers failed");
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription packages:", error);
      });
  };
  useEffect(() => {
    fetchServiceGroups();
    fetchSubscriptionPackages();
    return () => {
      setData([]);
    };
  }, []);

  const handleChangeReqistration = () => {
    let succesMessage = !registrationRequiredON
      ? t("registration-on-succes")
      : t("registration-of-succes");
    let failMessage = t("fail-update-customer-registration");

    AccessManagementService.updateRegistrationOnOff({
      registration_on: !registrationRequiredON,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage(succesMessage);
          setSnackbarStatus(true);
          setRegistrationRequired(!registrationRequiredON);
        } else {
          throw "failed to update registration on/of status";
        }
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          setMessage(failMessage);
          setSnackbarStatus(true);
        }, 1000);
      });
  };

  const handleChangeDefaultSubscriptionPackage = () => {
    AccessManagementService.updateDefaultSubscriptionPackage(selectedPackage, {
      is_default: true,
    })
      .then((response) => {
        if (response.status === 200) {
          setMessage(t("default-subscription-package-updated-successfully"));
          setInitialPackage(selectedPackage);
          setSnackbarStatus(true);
        } else {
          throw "err update default subscription package";
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage(t("failed-to-update-default-subscription-package"));
        setSnackbarStatus(true);
      });
  };

  const handleOnChangeEnabled = (index) => {
    let params = {
      service_group_id: data[index][0].value,
      enabled: !data[index][2].value,
    };

    let successMessage = data[index][2].value
      ? t("service-group-disabled-successfully")
      : t("service-group-enabled-successfully");
    let failMessage = data[index][2].value
      ? t("service-group-could-not-be-disabled")
      : t("service-group-could-not-be-enabled");

    AccessManagementService.updateCustomerServiceGroup(params)
      .then((response) => {
        data[index][2].value = !data[index][2].value;
        setMessage(successMessage);
        setSnackbarStatus(true);
      })
      .catch((err) => {
        if (err.response.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };
  const enableSwitch = (index) => {
    return (
      <Switch
        disabled={!canUserWriteIt("access_management")}
        checked={data[index][2].value}
        onChange={() => handleOnChangeEnabled(index)}
      />
    );
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          mt: 3,
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.4em",
          }}
        >
          {t("portal-services")}:
        </Typography>
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          pagination={{}}
          loadingData={loadingData}
          enableSwitch={enableSwitch}
          alignSwitch={"right"}
        />
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          padding: 3,
          mt: 4,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
            mt: 2,
            width: "100%",
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "1.3em",
            }}
          >
            {t("customer-registration")}:
          </Typography>
          <Switch
            disabled={!canUserWriteIt("access_management")}
            checked={registrationRequiredON}
            onChange={() => handleChangeReqistration()}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
            mt: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.3em",
            }}
          >
            {t("default-subscription-package-on-register")}:
          </Typography>
          <Autocomplete
            sx={{
              width: "25vw",
              "& .MuiInputBase-root": {
                borderRadius: 2,
                borderColor: "#e0e0e0",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&:hover fieldset": {
                  borderColor: "#b0b0b0",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#1976d2",
                },
              },
            }}
            disableClearable
            disablePortal
            disabled={!canUserWriteIt("access_management")}
            id="combo-box-demo"
            options={packages}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("subscription-package")}
                variant="outlined"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#b0b0b0",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#1976d2",
                  },
                }}
              />
            )}
            value={
              packages.find((option) => option.value === selectedPackage) ||
              null
            }
            onChange={(event, newValue) => {
              setSelectedPackage(newValue?.value || null);
            }}
          />

          {canUserWriteIt("access_management") && (
            <Tooltip title={t("save-changes")}>
              <IconButton
                disabled={initalPackage === selectedPackage}
                color="secondary"
                size="small"
                onClick={handleChangeDefaultSubscriptionPackage}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Paper>
    </>
  );
}
