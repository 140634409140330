import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline, Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import shape from './shape';
import typography from './typography';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const [store, dispatch] = useStore();

  const themeOptionsWoColors = useMemo(
    () => ({
      shape: shape,
      typography: typography,
      shadows: shadows,
      customShadows: customShadows,
    }),
    []
  );

/*   console.log("store.services: ",store.services.style.palettes[store.services.style.selectedPalette]) */
  // !X! Theme is setting here
  const themeOptionsWithColors = useMemo(
    () => ({
      ...themeOptionsWoColors,
      palette: store.palette,
      /*  palette: store.services.palettes[store.services.selectedPalette], */
    }),
    [store.palette]
  );

  const theme = createTheme(themeOptionsWithColors);
  theme.components = componentsOverride(theme);

  return (
    <>
    {store.manualUpdateTriggeredForPalette ? 
        <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
      :
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>}
    </>
  );
}
