import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';

import {Tariff} from './tariff';
// ----------------------------------------------------------------------


export default function OutboundSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('outbound-sms-tariffs'), component: <Tariff tariffType="sms" /> },
        { id: 1, label: t('outbound-voice-tariffs'), component: <Tariff tariffType="voice" /> },

    ];
    
    const TITLE = "Outbound";
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
