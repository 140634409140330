import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import {  TextSpeechTariff, TFATariff, NumberMaskingTariff, UcassTariff} from  './tariff';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------


export default function ApplicationsSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('tts-tariffs'), component: <TextSpeechTariff tariffType="tts" /> },
        { id: 1, label: t('stt-tariffs'), component: <TextSpeechTariff tariffType="stt" /> },
        { id: 2, label: t('2fa-tariffs'), component: <TFATariff /> },
        { id: 3, label: t('number-masking-tariffs'), component: <NumberMaskingTariff /> },
    ];

    const TITLE = t('tariffs');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
