import { useState } from "react";
// material
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { RoleService } from "src/api/services";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function DeleteAdminRoleForm({
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);


  const deleteRole = () => {
    setLoading(true);
    RoleService.deleteAdminRole(formData.id)
      .then((response) => {
        if (response.status === 200) {
          setMessage(
            t("has-been-successfully-deleted", { name: formData.name })
          );
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
          successCallback();
        } else {
          throw t("role-delete-fail");
        }
      })
      .catch((err) => {
        setLoading(true);
        setTimeout(function () {
          if (err.response.status === 400) {
            setModalStatus(false);
            successCallback(true);
          } else {
            setMessage(t('could-not-be-deleted', {name: formData.name}));
            setSnackbarStatus(true);
            setModalStatus(false);
          }
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography component="h6">
          {t("are-you-sure-delete-this-role")}
        </Typography>
        <Typography component="subtitle1" variant="h6">
          {formData.name}
        </Typography>
        <Stack sx={{ display: "block" }} direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteRole}
          >
            {t("delete")}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
