import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import UcaasTariff from './ucConfig/ucTariff/UcaasTariff';

// ----------------------------------------------------------------------


export default function UcTariffs() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('ucaas-tariff'), component: <UcaasTariff/> },
    ];
    
    const TITLE = t("ucaas-tariff");
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
