import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { PackagesService, TariffService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject, formatDate } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import CpaasTariffForm from "./forms/CpaasTariffForm";
import DeleteCpaasTariffForm from "./forms/DeleteCpaasTariffForm";
import DeleteAnyway from "src/components/DeleteAnyway";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function CpaasTariff() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "effective_start", label: t("effective-start") },
    { key: "effective_end", label: t("effective-end") },
    { key: "action", label: t("actions"), align: "right", padding: "1.8em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    effective_date_start: { key: "effective_start", index: 2 },
    number_masking: { key: "number_masking", index: 3, noRender: true },
    multi_factor_authentication: {
      key: "multi_factor_authentication",
      index: 4,
      noRender: true,
    },
    campaign_management: {
      key: "campaign_management",
      index: 5,
      noRender: true,
    },
    tts: { key: "text_to_speech", index: 6, noRender: true },
    stt: { key: "speech_to_text", index: 7, noRender: true },
    email_outbound: { key: "email_outbound", index: 8, noRender: true },
    email_inbound: { key: "email_inbound", index: 9, noRender: true },
    voice_outbound: { key: "voice_outbound", index: 10, noRender: true },
    voice_inbound: { key: "voice_inbound", index: 11, noRender: true },
    webrtc_voice: { key: "webrtc_voice", index: 12, noRender: true },
    webrtc_video: { key: "webrtc_video", index: 13, noRender: true },
    whatsapp_outbound_message: {
      key: "whatsapp_outbound",
      index: 14,
      noRender: true,
    },
    telegram_outbound_message: {
      key: "telegram_outbound",
      index: 15,
      noRender: true,
    },
    viber_outbound_message: {
      key: "viber_outbound",
      index: 16,
      noRender: true,
    },
    apple_business_outbound_message: {
      key: "apple_business_outbound",
      index: 17,
      noRender: true,
    },
    google_business_outbound_message: {
      key: "google_business_outbound",
      index: 18,
      noRender: true,
    },
    effective_date_end: { key: "effective_end", index: 19 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterPackage, setPackage] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);

  const fetchTariffs = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      page_size: paginationData.rowsPerPage,
      name: filterPackage ? filterPackage : undefined,
    };
    TariffService.listCpaasTariff(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchTariffs();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "effective_start") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else if (d.key === "effective_end") {
        formatted.push({
          ...d,
          value: formatDate(d.value),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-cpaas-tariff")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-cpaas-tariff")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchTariffs();
    }
  };
  
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{ minWidth: "40rem", width: "30%" }}
        title={t("add-new-cpaas-tariff")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <CpaasTariffForm
            formType="add"
            successCallback={fetchTariffs}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        sx={{ minWidth: "40rem", width: "30%" }}
        title={t("edit-cpaas-tariff")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <CpaasTariffForm
            formType="edit"
            successCallback={fetchTariffs}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-cpaas-tariff")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteCpaasTariffForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-cpaas-tariff")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchTariffs}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              TariffService.deleteCpaasTariff(selectedRow.id, { proceed: true })
            }
            name={t("cpaas-tariff")}
            usedIn={t("cpaas-package")}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPackage}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setPackage(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchTariffs();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-new-cpaas-tariff")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
