import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { NetworksService, UserService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import SmsEndpointForm from "./forms/SmsEndpointForm";
import DeleteSmsEndpoint from "./forms/DeleteSmsEndpoint";
import { useStore } from "src/store/Store";

export default function ManageSmsNetwork() {
  const { t } = useTranslation();
  const [store] = useStore();

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "http_endpoint", label: t("http-endpoint") },
    { key: "from_field", label: t("from-field") },
    { key: "to_field", label: t("to-field") },
    { key: "body_field", label: t("body-field") },
    { key: "id_field", label: t("id-field") },
    { key: "is_default_value", label: t("default") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    http_post_url: { key: "http_endpoint", index: 2 },
    from_field: { key: "from_field", index: 3 },
    to_field: { key: "to_field", index: 4 },
    body_field: { key: "body_field", index: 5 },
    id_field: { key: "id_field", index: 6 },
    is_default_value: { key: "is_default_value", index: 7 },
    is_default: { key: "is_default", index: 8, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchSmsEndpoints = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      /* name: filterName ? filterName : undefined, */
    };
    NetworksService.listSmsEndpoints(params)
      .then((response) => {
        response.data = {
          count: 2,
          items: [
            {
              body_field: "localhost",
              from_field: "localhost",
              http_post_url: "http://localhost",
              id: 1,
              id_field: "localhost",
              is_default: true,
              name: "test",
              protocol_type: 1,
              to_field: "localhost",
              type_field: "localhost",
            },
            {
              body_field: "qwe",
              from_field: "asd",
              http_post_url: "http://localhost",
              id: 2,
              id_field: "11",
              is_default: false,
              name: "asd",
              protocol_type: 1,
              to_field: "qwe",
              type_field: "asd",
            },
          ],
          page: 1,
          pages: 1,
        };
        let values = [];
        setTotalCount(response.data.count);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          let defaultValue = itemData.is_default ? (
            <Typography color={store.palette.success.dark}>
              {t("yes")}
            </Typography>
          ) : (
            <Typography color={store.palette.error.main}>{t("no")}</Typography>
          );
          item[TABLE_FIELD_MAPPING["is_default_value"].index] = {
            ...TABLE_FIELD_MAPPING["is_default_value"],
            value: defaultValue,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchSmsEndpoints();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    }
    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("edit-sms-endpoint")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-sms-endpoint"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-endpoint"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        sx={{
          maxHeight: "100%",
          minHeight: "20%",
          overflowY: "auto",
          width: "45%",
        }}
        title={t("add-sms-endpoint")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <SmsEndpointForm
            formType={"add"}
            formData={{}}
            successCallback={fetchSmsEndpoints}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        sx={{
          maxHeight: "100%",
          minHeight: "20%",
          overflowY: "auto",
          width: "45%",
        }}
        title={t("edit-sms-endpoint")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <SmsEndpointForm
            formType={"edit"}
            formData={selectedRow}
            successCallback={fetchSmsEndpoints}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <BaseModal
        title={t("delete-sms-endpoint")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteSmsEndpoint
            successCallback={fetchSmsEndpoints}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />

      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={5} xs={12}>
            <Box textAlign={"left"} padding={2}>
              <Typography>
                <strong>{t("results")} : </strong>
                {t("total-records", { count: totalCount })}{" "}
              </Typography>
              <Typography mt={1}>
                <strong>{t("signalling-ip")} :</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <AddNewButton
              label={t("add-sms-endpoint")}
              onClick={() => modalHandler("add")}
            />
          </Grid>
        </Grid>
      </TableFilterContainer>

      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
