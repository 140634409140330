import api from "src/api/Api";
import urls from "src/api/Urls";

export const listOrderableUcPackages = (params) => {
  return api.get(urls.list_orderable_uc_packages, { params: params });
};

export const createUcOrder = (payload) => {
  return api.post(urls.create_uc_order, payload);
};

export const getCustomerUcOrders = (params) => {
  return api.get(urls.get_customer_uc_orders, { params: params });
};
export const getCustomerUcOrder = (id) => {
  return api.get(urls.get_customer_uc_order(id));
};

export const updateUcOrder = (id, payload) => {
  return api.put(urls.update_customer_uc_order(id), payload);
};

export const deleteDidType = (id) => {
  return api.delete(urls.delete_customer_uc_order(id));
};
