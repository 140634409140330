import { Typography } from "@mui/material";
import React from "react";

export default function LoadingSpinner({
  message,
  size,
  spinnerColor,
  spinnerBorderColor,
}) {
  const spinnerSize = size || 80;
  const color = spinnerColor || "#3498db";
  const borderColor = spinnerBorderColor || "#f3f3f3";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="h6" component="h2">
        {message}
      </Typography>
      <div
        className="loader"
        style={{ width: spinnerSize, height: spinnerSize }}
      >
        <style>
          {`
          .loader {
            border: ${spinnerSize * 0.1}px solid ${borderColor};
            border-top: ${spinnerSize * 0.1}px solid ${color};
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
        </style>
      </div>
    </div>
  );
}
