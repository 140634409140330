import api from "src/api/Api";
import urls from "src/api/Urls";


export const listServiceTariffMappings = (params) => {
    return api.get(urls.list_service_tariff_mappings, {params});
  };
  
  export const addServiceTariffMapping = (payload) => {
    return api.post(urls.add_service_tariff_mapping, payload)
  }
  
  export const updateServiceTariffMapping = (id, payload) => {
    return api.put(urls.update_service_tariff_mapping(id), payload);
  }
  
  export const deleteServiceTariffMapping = (id) => {
    return api.delete(urls.delete_service_tariff_mapping(id))
  }
  