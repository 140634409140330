import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { useTranslation } from 'react-i18next';
import VoiceReports from './numberManagement/numberReports/VoiceReports';
import SmsReports from './numberManagement/numberReports/SmsReports';
// ----------------------------------------------------------------------


export default function NumberReports() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('number-voice-cdr'), component: <VoiceReports /> },
        { id: 1, label: t('number-sms-cdr'), component: <SmsReports /> },
    ];

    const TITLE = t('number-reports');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
