import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { isMobile } from "src/utils/Util";
import {
  Box,
  Button,
  Divider,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useStore } from "src/store/Store";
import { NumberService } from "src/api/services";
import FileUpload from "src/components/FileUpload";
import { LoadingButton } from "@mui/lab";
export default function PurchaseNumberForm({
  purchaseType,
  formData,
  successCallback,
  setModalStatus,
  setMessage,
  setSnackbarStatus,
}) {
  const { t } = useTranslation();
  const [store] = useStore();
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [taxID, setTaxID] = useState(null);
  const [country, setCountry] = useState(null);
  const [note, setNote] = useState(null);
  const [docGuids, setDocGuids] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isSubmiting, setIsSubmitting] = useState(false);

  if (formData.did === "6200000045") {
    formData = {
      address_required: true,
      capability: 3,
      comp_address_required: true,
      comp_cond_explanation: [
        "Stock Order ETA (in working days) (1 - 5)",
        "Back Order ETA (in working days) (10 - 15)",
      ],
      comp_country_required: true,
      comp_doc_required: true,
      comp_email_required: true,
      comp_legal_restrictions: [
        "comp legal restricition example 1",
        "comp legal restricition example 1",
      ],
      comp_required_doc_labels: [
        " Company Registration",
        " ID of person in charge of order",
        " Notification to NRAs",
        " Order Form JPN End-User",
        " Order Form JPN Service Provider",
        "ID of person owning 25%+ of shares",
      ],
      comp_service_restrictions: ["service restriction example sss"],
      comp_tax_id_required: true,
      cond_explanation: [
        "Stock Order ETA (in working days) (1 - 5)",
        "Back Order ETA (in working days) (10 - 15)",
      ],
      connect_fee: 1,
      country_code: "ID",
      country_required: true,
      currency: "$",
      did: "6200000045",
      did_type: "Mobile",
      doc_required: true,
      email_required: true,
      initial_time: 1,
      legal_restrictions: [
        "For 0120 numbers the end user (corporate) must be located in the country (mainland).",
        "For 0800 numbers the end user (corporate) can be located worldwide.",
        "For 053 numbers the end user (corporate) name is requested before activation.",
        "Service Providers can be",
      ],
      mrc: 1,
      nrc: 1,
      provider_code: null,
      required_doc_labels: ["Proof of Address"],
      rounding: 1,
      service_restrictions: [
        "Dialing format: 0120-XXX-XXX; 0800-YYY-YYYY..",
        "National reachability: ALL expect minor IP Phones and Cable TV providers.",
        "International reachability: no.",
        "Concurrent calls: No limitation.",
        "Max call duration (min): 360.",
        "CLI format: no info.",
      ],
      sms_price: 1,
      tax_id_required: true,
      translated_did: null,
      voice_mobile_price: 0.1,
      voice_offpeak_price: 1,
      voice_payphone_price: 0.1,
      voice_price: 1,
      voice_weekend_price: 1,
    };
  }

  const handleSumbit = () => {
    setIsSubmitting(true);
    let payload = {
      number: formData.did,
      customer_notes: note,
      doc_guids: docGuids,
      extra_params: {},
      email: email,
      tax_id: taxID,
      address: address,
      country: country,
    };

    let succesMessage = t("number-buy-request-success");
    let failMessage = t("number-buy-request-fail");
    NumberService.buyNumber(payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsSubmitting(false);
          setMessage(succesMessage);
          setModalStatus(false);
          setSnackbarStatus(true);
          successCallback();
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        setMessage(failMessage);
        setSnackbarStatus(true);
        setModalStatus(false);
      });
  };

  const uploadHandler = (file, name) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    NumberService.uploadDocument(formData)
      .then((response) => {
        if (response.status === 200) {
          let guid = response.data.data[0].guid;
          let documentGuids = [...docGuids];
          console.log(response.data.data[0].guid);
          documentGuids.push({
            [name]: guid,
          });
          setDocGuids(documentGuids);
          setMessage(t("document-is-uploaded"));
        } else {
          throw "document failed";
        }
      })
      .catch((err) => {
        setMessage(t("document-could-not-be-uploaded"));
      })
      .finally(() => {
        setSnackbarStatus(true);
        setLoading(false);
      });
  };

  const getFieldByName = (fieldName) => {
    if (fieldName === "email") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="email"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {purchaseType === "individual" ? t("email") : t("company-email")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="email"
            type="email"
            placeholder={t("type-email-here")}
            label={
              isMobile()
                ? purchaseType === "individual"
                  ? t("email")
                  : t("company-email")
                : undefined
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
      );
    }
    if (fieldName === "address") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="address"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {purchaseType === "individual"
                ? t("address")
                : t("company-address")}
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="address"
            label={
              isMobile()
                ? purchaseType === "individual"
                  ? t("address")
                  : t("company-address")
                : undefined
            }
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={t("type-address-here")}
          />
        </Box>
      );
    }
    if (fieldName === "taxID") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="taxID"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {purchaseType === "individual"
                ? t("tax-id")
                : t("company-tax-id")}
              :
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="taxID"
            label={
              isMobile()
                ? purchaseType === "individual"
                  ? t("tax-id")
                  : t("company-tax-id")
                : undefined
            }
            value={taxID}
            onChange={(e) => setTaxID(e.target.value)}
            placeholder={t("type-tax-id-here")}
          />
        </Box>
      );
    }
    if (fieldName === "country") {
      return (
        <Box textAlign="start">
          {!isMobile() && (
            <InputLabel
              htmlFor="country"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {purchaseType === "individual"
                ? t("country")
                : t("company-country")}
              :
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="country"
            label={
              isMobile()
                ? purchaseType === "individual"
                  ? t("country")
                  : t("company-country")
                : undefined
            }
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder={t("type-country-here")}
          />
        </Box>
      );
    }

    if (fieldName === "note") {
      return (
        <Box textAlign="start" mt={3}>
          {!isMobile() && (
            <InputLabel
              htmlFor="note"
              style={{
                color: "secondary",
                marginLeft: "0.2rem",
                fontWeight: 500,
              }}
            >
              {t("note")}:
            </InputLabel>
          )}
          <TextField
            fullWidth
            id="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={t("type-note-here")}
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
      );
    }
    if (fieldName === "submit") {
      return (
        <Box
          sx={{
            mt: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5em",
          }}
        >
          <Button
            sx={{
              backgroundColor: store.palette.error.light,
            }}
            variant="contained"
            onClick={() => setModalStatus(false)}
          >
            <Typography
              fontWeight={1000}
              color={store.palette.success.contrastText}
            >
              {t("cancel")}
            </Typography>
          </Button>
          <LoadingButton
            sx={{
              backgroundColor: store.palette.success.main,
            }}
            loading={isSubmiting}
            variant="contained"
            onClick={() => {
              handleSumbit();
            }}
          >
            <Typography
              fontWeight={1000}
              color={store.palette.success.contrastText}
            >
              {t("buy")}
            </Typography>
          </LoadingButton>
        </Box>
      );
    }
  };

  return (
    <>
      <Typography variant="h4">{t("required-fields")}</Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />
      {purchaseType === "individual" ? (
        <Stack spacing={1}>
          {formData.email_required && getFieldByName("email")}
          {formData.address_required && getFieldByName("address")}
          {formData.tax_id_required && getFieldByName("taxID")}
          {formData.country_required && getFieldByName("country")}
          {!formData.email_required &&
            !formData.address_required &&
            !formData.tax_id_required &&
            !formData.country_required && (
              <Typography textAlign={"start"}>
                #{t("no-fields-required")}
              </Typography>
            )}
        </Stack>
      ) : (
        <Stack spacing={1}>
          {formData.comp_email_required && getFieldByName("email")}
          {formData.comp_address_required && getFieldByName("address")}
          {formData.comp_tax_id_required && getFieldByName("taxID")}
          {formData.comp_country_required && getFieldByName("country")}
          {!formData.comp_email_required &&
            !formData.comp_address_required &&
            !formData.comp_tax_id_required &&
            !formData.comp_country_required && (
              <Typography textAlign={"start"}>
                #{t("no-fields-required")}
              </Typography>
            )}
        </Stack>
      )}
      <Typography mt={2} variant="h4">
        {t("required-documents")}
      </Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />

      {purchaseType === "individual" ? (
        formData.doc_required ? (
          <Stack spacing={1}>
            {formData.required_doc_labels.map((item) => (
              <>
                <FileUpload
                  label={item}
                  key={item}
                  uploadHandler={uploadHandler}
                  item={item}
                  uploading={loading}
                />
              </>
            ))}
          </Stack>
        ) : (
          <Typography textAlign={"start"}>
            #{t("no-document-required")}
          </Typography>
        )
      ) : formData.comp_doc_required ? (
        <Stack spacing={1}>
          {formData.comp_required_doc_labels.map((item) => (
            <>
              <FileUpload
                label={item}
                key={item}
                uploadHandler={uploadHandler}
                item={item}
                uploading={loading}
              />
            </>
          ))}
        </Stack>
      ) : (
        <Typography textAlign={"start"}>
          #{t("no-document-required")}
        </Typography>
      )}

      {getFieldByName("note")}
      {getFieldByName("submit")}
    </>
  );
}
