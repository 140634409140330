import api from "src/api/Api";
import urls from "src/api/Urls";


export const addFeature= (payload) => {
    return api.post(urls.add_feature, payload);
}

export const listFeatures = (params) => {
    return api.get(urls.list_features, { params: params });
}

export const updateFeature = (id, payload) => {
    return api.patch(urls.update_feature(id), payload);
}

export const deleteFeature = (id) => {
    return api.delete(urls.delete_feature(id));
}
