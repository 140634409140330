import SmartcpaasAppLayout from "src/layouts/SmartcpaasAppLayout";
import { useTranslation } from "react-i18next";
import Feature from "./ucConfig/ucFeatures/Feature";

// ----------------------------------------------------------------------

export default function Features() {
  const { t } = useTranslation();

  const TABS = [{ id: 0, label: t("add-feature"), component: <Feature /> }];

  const TITLE = t("features");

  return <SmartcpaasAppLayout tabs={TABS} title={TITLE} />;
}
