import { useEffect, useState } from "react";
import { DefaultPaginationData } from "src/constants/index";
import { PackagesService } from "src/api/services";
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from "src/components/BaseModal";
import SearchButton from "src/components/buttons/SearchButton";
import AddNewButton from "src/components/buttons/AddNewButton";
import { rowArrayToObject } from "src/utils/Util";
import {
  FormControl,
  Grid,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useTranslation } from "react-i18next";
import {  DeleteForm, PackageForm } from "./forms";
import DeleteAnyway from "src/components/DeleteAnyway";
import NumbersPackageConfig from "./forms/NumbersPackageConfig";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "right",
  },
}));

export default function NumbersPackage() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "name", label: t("name") },
    { key: "action", label: t("actions"), align: "right", padding: "3em" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    name: { key: "name", index: 1 },
    package_type: { key: "package_type", index: 2, noRender: true },
    package_product: { key: "package_product", index: 3, noRender: true },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [filterPackage, setPackage] = useState("");
  const [openAddModal, setAddModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [openDeleteAnywayModal, setDeleteAnywayModal] = useState(false);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openConfigModal, setConfigModalStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState({}); 

  const fetchPackages = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      name: filterPackage ? filterPackage : undefined,
      package_type: 3,
    };
    PackagesService.listPackages(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPackages();
    return () => {
      setData([]);
    };
  }, [paginationData, openConfigModal]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      formatted.push(d);
    });
    return formatted;
  };

  const modalHandler = (modalType, index = undefined) => {
    if (modalType === "add") {
      setAddModalStatus(!openAddModal);
    } else if (modalType === "edit") {
      setEditModalStatus(!openEditModal);
    } else if (modalType === "delete") {
      setDeleteModalStatus(!openDeleteModal);
    } else if (modalType === "config") {
      setConfigModalStatus(!openConfigModal);
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => {
    return (
      <>
        <Tooltip title={t("edit-numbers-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => modalHandler("edit", index)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title={t("config-numbers-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="configure-package"
            onClick={() => modalHandler("config", index)}
          >
            <DisplaySettingsIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title={t("config-numbers-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="configure-package"
            onClick={() => modalHandler("config", index)}
          >
            <DisplaySettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete-numbers-package")}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="delete-package"
            onClick={() => modalHandler("delete", index)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleFirstAdd = (data) => {
    setSelectedRow(data);
    setConfigModalStatus(!openConfigModal);
  };
  const deleteCallback = (inUse) => {
    if (inUse) {
      setDeleteAnywayModal(!openDeleteAnywayModal);
    } else {
      fetchPackages();
    }
  };

  const informativeMessage = t("numbers-package-config-info");

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("add-numbers-package")}
        open={openAddModal}
        setOpen={setAddModalStatus}
        children={
          <PackageForm
            formType="add"
            successCallback={handleFirstAdd}
            formData={{}}
            setModalStatus={setAddModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("edit-numbers-package")}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <PackageForm
            formType="edit"
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
      sx={{
        width: "70%",
        maxHeight: "95vh"
      }}
        title={t("numbers-package-config")}
        open={openConfigModal}
        setOpen={setConfigModalStatus}
        children={
          <NumbersPackageConfig
            successCallback={fetchPackages}
            formData={selectedRow}
            setModalStatus={setConfigModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-numbers-package")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={
          <DeleteForm
            successCallback={deleteCallback}
            formData={selectedRow}
            setModalStatus={setDeleteModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-numbers-package")}
        open={openDeleteAnywayModal}
        setOpen={setDeleteAnywayModal}
        children={
          <DeleteAnyway
            successCallback={fetchPackages}
            setModalStatus={setDeleteAnywayModal}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PackagesService.deletePackage(selectedRow.id, { proceed: true })
            }
            name={t("numbers-package")}
            usedIn={t("numbers-container")}
          />
        }
      />
      <TableFilterContainer>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={filterPackage}
                label={t("name")}
                name="name"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={(event) => {
                  setPackage(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <SearchButtonContainer item md={2} xs={12}>
            <SearchButton
              onClick={() => {
                fetchPackages();
              }}
            />
          </SearchButtonContainer>
        </Grid>
      </TableFilterContainer>
      <AddNewButton
        label={t("add-numbers-package")}
        onClick={() => modalHandler("add")}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
